import * as React from 'react';
import {Datagrid, List, ListProps, TextField, EditButton, BulkDeleteButton, usePermissions} from 'react-admin';
import {Fragment, ReactElement} from 'react';

import PostFilter from "./PostFilter";
import {Route} from "react-router";
import SideDrawer from "../../components/list/SideDrawer";
import PostEdit from "./PostEdit";
import CustomTextField from "../../components/list/TextField";
import ListActions from "../../components/list/ListActions";
import ShowOnShop from "../../components/list/ShowOnShop";

const BulkActionButtons = (props: any) => {
    const {permissions} = usePermissions();

    return (
        <Fragment>
            {['admin'].includes(permissions) && <BulkDeleteButton {...props} />}
        </Fragment>);
}

const PostList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List bulkActionButtons={<BulkActionButtons />} {...props} filters={<PostFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="id"/>
                    {/*<ReferenceField label={"resources.admin/posts.fields.author"} source="user_id" reference="admin/users" sortable={false}>*/}
                    {/*  <>*/}
                    {/*      <TextField source={'first_name'} />{' '}<TextField source={'last_name'} />*/}
                    {/*  </>*/}
                    {/*</ReferenceField>*/}
                    <CustomTextField source="type"/>
                    <ShowOnShop source="post" label={'resources.admin/posts.fields.title'}/>
                    <CustomTextField source="status"/>
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''} size={800}>
                            {isMatch && (
                                <PostEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default PostList;
