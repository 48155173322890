import ListAltIcon from '@material-ui/icons/ListAlt';

import TypeList from './TypeList';
import TypeCreate from "./TypeCreate";

const resource = {
    list: TypeList,
    create: TypeCreate,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/cob-manufacturers.name'
    }
};

export default resource;
