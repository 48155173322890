import Share from '@material-ui/icons/Share';
import GsFlowList from './GsFlowList';

const resource = {
    list: GsFlowList,
    icon: Share,
    options: {
        label: 'resources.admin/gs-flows.name'
    }
};

export default resource;
