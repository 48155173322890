import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
//import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia } from '@material-ui/core';
import Logo from "../assets/img/logo.png";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    media: {
        paddingTop: '48px',
        minWidth: '180px',
        backgroundSize: 'contain'
    },
    menu_item_link: {
        color:'#808080'
    },
    menu_item_svg : {
        color: '#808080'
    }
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    const classes = useStyles();
    return (
        <MenuItemLink
            ref={ref}
            to="/admin/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon className={classes.menu_item_svg} />}
            onClick={props.onClick}
            sidebarIsOpen
            className={classes.menu_item_link}
        />
    );
});

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    return (
        <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
            <CardMedia className={classes.media} image={`${Logo}`} />
            <span className={classes.spacer} />
            {/*<Typography*/}
            {/*    variant="h6"*/}
            {/*    color="inherit"*/}
            {/*    className={classes.title}*/}
            {/*    id="react-admin-title"*/}
            {/*    align="center"*/}
            {/*/>*/}
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
