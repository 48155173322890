import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SelectInput, SimpleForm, TextInput, useTranslate, TranslatableInputs} from 'react-admin';
import Title from "../../../components/list/Title";
import cfgLocales from '../../../config/locale';
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const ShapeCreate: FC<CreateProps> = props => {
    const translate = useTranslate();
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <Title label="resources.admin/lod-shapes.titles.create" />
                <TextInput autoFocus source="code"/>
                <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                    <TextInput source="name" fullWidth={true}/>
                </TranslatableInputs>
                <SelectInput source="status" choices={[
                    {id: 'active', name: translate('resources.admin/lod-shapes.status.active')},
                    {id: 'inactive', name: translate('resources.admin/lod-shapes.status.inactive')},
                ]}/>
            </SimpleForm>
        </Create>
    );
};

export default ShapeCreate;
