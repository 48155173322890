import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, TextInput} from 'react-admin';

import {filterProps} from "../../../functions/common";
import Title from "../../../components/list/Title";

const ManufacturerCreate: FC<CreateProps> = props => {
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm>
                <Title label="resources.admin/cob-manufacturers.titles.create"/>
                <TextInput autoFocus source="name" fullWidth={true}/>
            </SimpleForm>
        </Create>
    );
};

export default ManufacturerCreate;
