import * as React from 'react';
import {Datagrid, List, ListProps, EditButton} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import { Route } from 'react-router';
import HoleFilter from "./HoleFilter";
import HoleCreate from "./HoleCreate";
import HoleEdit from "./HoleEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";

const HoleList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<HoleFilter/>} sort={{field: 'code', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="code"/>
                    <TextField source="name_lt"/>
                    <TextField source="name_kt"/>
                    <TextField source="slug"/>
                    <TextField source="status"/>
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/create`}>
                {({ match }) => {
                    return (
                        <SideDrawer open={!!match} basePath={props.basePath??''}>
                            <HoleCreate {...props} />
                        </SideDrawer>
                    )
                }}
            </Route>
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''}>
                            {isMatch && (
                                <HoleEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default HoleList;
