import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, ReferenceField} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import { Route } from 'react-router';

import PriceListFactorFilter from "./PriceListFactorsFilter";
import PriceListFactorsCreate from "./PriceListFactorCreate";
import PriceListFactorEdit from "./PriceListFactorEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";


const PriceListFactorsList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<PriceListFactorFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                     <ReferenceField label={"resources.admin/lod-price-list-factors.fields.price_list"} source="price_list_id" reference="admin/lod-price-list" sortable={false}>
                        <TextField source="preisliste" />
                    </ReferenceField>
                    <TextField source="werk"/>
                    <TextField source="faktor"/>
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/create`}>
                {({ match }) => {
                    return (
                        <SideDrawer open={!!match} basePath={props.basePath??''}>
                            <PriceListFactorsCreate {...props} />
                        </SideDrawer>
                    )
                }}
            </Route>
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''}>
                            {isMatch && (
                                <PriceListFactorEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default PriceListFactorsList;
