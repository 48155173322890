import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, TextInput, SimpleForm, ReferenceInput, SelectInput} from 'react-admin';

import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const TypeEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <ReferenceInput label="resources.admin/cob-types.fields.manufacturer" source={"cob_manufacturer_id"} reference="admin/cob-manufacturers" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }}  sort={{ field: 'name', order: 'ASC' }}
                 >
                    <SelectInput optionText="name" fullWidth={true}/>
                </ReferenceInput>
                <TextInput source="name" helperText={false}/>
            </SimpleForm>
        </Edit>
    );
};

export default TypeEdit;
