import UserIcon from '@material-ui/icons/People';
import UserList from './UserList';

const resource = {
    list: UserList,
    icon: UserIcon,
    options: {
        label: 'resources.admin/users.name'
    }
};

export default resource;