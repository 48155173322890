import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, TextInput, SimpleForm, SelectInput} from 'react-admin';

import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";
import frameMaterialOptions from "./frameMaterialOptions";

const FrameEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <TextInput autoFocus source="matnr" fullWidth={true}/>
                <TextInput source="name" disabled readonly fullWidth/>
                <TextInput source="category" disabled readonly fullWidth/>
                <SelectInput source="material" choices={frameMaterialOptions.map(el => ({id: el, name: el}))}/>
                <TextInput source="cut_out_size"/>
            </SimpleForm>
        </Edit>
    );
};

export default FrameEdit;
