import {makeStyles} from "@material-ui/core/styles";

export const listEditCreate = makeStyles((theme) => ({
    box: {
        padding: 15,
        paddingBottom: 0,
        display: 'flex',
        flexDirection: 'column',
        minWidth: '100%',
        boxSizing: 'border-box',
    },
    title: {
        padding: 15,
        paddingBottom: 0,
        fontWeight: 700
    },
    checkbox: {
        display: 'inline-flex',
        width: 'auto',
        '& .MuiFormControlLabel-root': {
            marginLeft: -10,
        },
        '& .MuiSwitch-switchBase': {
            borderRadius: 3,
            '&.Mui-checked': {
                transform: 'translateX(0)',
                borderRadius: 3,
                '&:before': {
                    content: `''`,
                    display: 'inline-block',
                    transform: 'rotate(45deg)',
                    height: 10,
                    width: 4,
                    borderBottom: '3px solid #002536',
                    borderRight: '3px solid #002536',
                    position: 'absolute',
                    top: 11,
                    left: 17,
                }
            },
        },
        '& .MuiSwitch-root': {
            width: 40,
            top: -1,
        },
        '& .MuiSwitch-thumb': {
            width: 18,
            height: 18,
            borderRadius: 3,
            boxShadow: 'none',
        },
        '& .MuiIconButton-label': {
            color: '#fff',
            borderRadius: 5,
            border: '2px solid rgba(0,0,0,0.15)',
        }
    },
    sidebar: {
        boxSizing: 'border-box',
        padding: `${theme.spacing(3)}px !important`,
        '& .MuiTypography-h6': {
            padding: `0 0 ${theme.spacing(1)}px`,
        },
        '& .MuiToolbar-root': {
            marginLeft: `-${theme.spacing(3)}px`,
            marginRight: `-${theme.spacing(3)}px`
        },
    },
    halfWidth: {
        display: 'inline-flex',
        width: '46.5%',
        paddingRight: theme.spacing(2)
    },
    fullWidth: {
        display: 'inline-flex',
        width: '100%'
    },
    image: {

    },
    thumbnail: {
      maxWidth: 100,
      maxHeight: 100,
    },
    toolbar: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    center: {
        textAlign: 'center',
        display: 'block'
    }
}));