import React from 'react';
import {Filter, TextInput, FilterProps, ReferenceInput, SelectInput} from 'react-admin';

const TypeFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn/>
        <ReferenceInput alwaysOn label="resources.admin/cob-types.fields.manufacturer" source={"cob_manufacturer_id"} reference="admin/cob-manufacturers" sort={{field: 'name', order: 'ASC'}}
        >
            <SelectInput optionText="name"  />
        </ReferenceInput>
    </Filter>
);

export default TypeFilter;
