import * as React from 'react';
import { Route } from 'react-router-dom';

import Configuration from '../resources/configuration/Configuration';
import ScheduleImport from "../resources/schedule-import/ScheduleImport";
import ResetPassword from "../auth/ResetPassword";

const customRoutes = [
    <Route exact path="/admin/configuration" render={() => <Configuration />} />,
    <Route exact path="/admin/schedule-import" render={() => <ScheduleImport />} />,
    <Route exact path="/password-reset" component={ResetPassword} />,
];
export default customRoutes;
