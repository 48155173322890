import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import RolesList from './RolesList';
//import PermissionsEdit from "./PermissionsEdit";

const resource = {
    list: RolesList,
    //edit: PermissionsEdit,
    icon: PermIdentityIcon,
    options: {
        label: 'resources.admin/permissions.name'
    }
};

export default resource;
