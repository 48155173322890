import * as React from 'react';
import {
    Datagrid,
    List,
    TextField,
    ListProps,
    DeleteWithConfirmButton,
    useNotify, useTranslate,
    Button, useRecordContext,
    DateField, useLocale, BooleanField, useRefresh
} from 'react-admin';
import {ReactElement, SyntheticEvent} from 'react';

import BackupFilter from "./BackupFilter";
import axiosForApi from "../../config/axios-for-api";

const Restore = (props: any) => {
    const translate = useTranslate();
    const record = useRecordContext(props);
    if (!record) throw new Error(translate('errors.invalid_record'));
    const handleRestore = props.onRestore;

    return (<Button onClick={(e) => handleRestore(e, +record.id)} label={translate('resources.admin/backups.buttons.restore')}/>);
}

const BackupList = (props: ListProps): ReactElement => {

    const notify = useNotify();
    const translate = useTranslate();
    const locale = useLocale();
    const refresh = useRefresh();

    const handleRestore = (e: SyntheticEvent, id: number) => {
        e.preventDefault();

        axiosForApi.post(`api/admin/backups/restore/${id}`).then(response => {
            notify(translate('resources.admin/backups.messages.restore_with_success'), 'success');
            refresh();
        }).catch(error => {
            notify(translate('resources.admin/backups.messages.restore_failed'), 'error');
        });
    }

    return (
        <List {...props} filters={<BackupFilter/>} sort={{field: 'id', order: 'DESC'}}>
            <Datagrid>
                <TextField source="id"/>
                <DateField source="created_at" locales={locale} showTime={true} />
                <DateField source="updated_at" locales={locale} showTime={true} />
                <TextField source="status" />
                <BooleanField source="in_queue" looseValue={true} />
                <BooleanField source="in_progress" looseValue={true} />
                <TextField source="path"/>
                <TextField source="size"/>
                <Restore onRestore={handleRestore}/>
                <DeleteWithConfirmButton undoable={false}/>
            </Datagrid>
        </List>

    );
};

export default BackupList;