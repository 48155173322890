import React from 'react';
import {Filter, TextInput, FilterProps} from 'react-admin';

const ProductFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput label="resources.admin/products.fields.name_or_id" source='name_or_id' alwaysOn/>
    </Filter>
);

export default ProductFilter;
