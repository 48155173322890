import {useFormState} from "react-final-form";

export const groups = [
    {id: 'hose', name: 'resources.admin/cob-products.group.hose'},
    {id: 'hose_hf', name: 'resources.admin/cob-products.group.hose_hf'},
    {id: 'velcro', name: 'resources.admin/cob-products.group.velcro'},
    {id: 'velcro_silicone_free', name: 'resources.admin/cob-products.group.velcro_silicone_free'},
    {id: 'shs', name: 'resources.admin/cob-products.group.shs'},
    {id: 'return_system', name: 'resources.admin/cob-products.group.return_system'},
    {id: 'system_holder', name: 'resources.admin/cob-products.group.system_holder'},
    {id: 'fhs_c', name: 'resources.admin/cob-products.group.fhs_c'},
    {id: 'fhs_uhe', name: 'resources.admin/cob-products.group.fhs_uhe'},
    {id: 'fhs_sh', name: 'resources.admin/cob-products.group.fhs_sh'},
    {id: 'end_grommet', name: 'resources.admin/cob-products.group.end_grommet'},
    {id: 'hose_ring_fixed', name: 'resources.admin/cob-products.group.hose_ring_fixed'},
    {id: 'hose_ring_zl', name: 'resources.admin/cob-products.group.hose_ring_zl'},
    {id: 'tension_clamp', name: 'resources.admin/cob-products.group.tension_clamp'},
    {id: 'options', name: 'resources.admin/cob-products.group.options'},

];

const groupFields: { [key: string]: string[] } = {
    hose: ['design', 'nominal_size', 'profile', 'fhsc_coefficient_min', 'fhsc_coefficient_max', 'inner_diameter'],
    hose_hf: ['design', 'nominal_size', 'profile', 'fhsc_coefficient_min', 'fhsc_coefficient_max', 'inner_diameter'],
    velcro: ['clamping_diameter_from', 'clamping_diameter_to'],
    velcro_silicone_free: ['clamping_diameter_from', 'clamping_diameter_to'],
    shs: ['clamping_diameter_from', 'clamping_diameter_to'],
    system_holder: ['nominal_size'],
    fhs_c: [],
    fhs_uhe: ['nominal_size'],
    fhs_sh: ['nominal_size'],
    end_grommet: ['nominal_size'],
    hose_ring_fixed: ['nominal_size'],
    hose_ring_zl: ['nominal_size'],
    tension_clamp: [],
    return_system: ['nominal_size', 'profile']
}

const fieldStyle = (group: string, field: string) => {
    const fields = groupFields[group] ?? [];
    if (fields.length === 0) return false;
    return fields.includes(field);
};


export const GroupField = (props: any) => {
    const {name, children} = props;
    const {values} = useFormState();
    if (fieldStyle(values.group, name)) return children;

    return null;
}


