import * as React from 'react';
import {Fragment, ReactElement} from 'react';
import {BooleanField, Datagrid, EditButton, List, ListProps, ReferenceField} from 'react-admin';
import {Route} from 'react-router';

import HoleImageShapeFilter from "./HoleImageShapeFilter";
import HoleImageShapeCreate from "./HoleImageShapeCreate";
import HoleImageShapeEdit from "./HoleImageShapeEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";
import ImageField from "../../../components/list/ImageField";
import {listEditCreate} from "../../../styles/listEditCreate";

const HoleImageShapeList = (props: ListProps): ReactElement => {
    const classes = listEditCreate();

    return (
        <Fragment>
            <List {...props} filters={<HoleImageShapeFilter/>} sort={{field: 'code', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <ReferenceField label={"resources.admin/lod-hole-image-shape.fields.image"} source="image_id" reference="admin/lod-images" sortable={false} className={classes.center} >
                        <ImageField source="id" />
                    </ReferenceField>
                    <TextField source="variable_code" />
                    <TextField source="orientation"/>
                    <ReferenceField label={"resources.admin/lod-hole-image-shape.fields.shape"} source="shape_id" reference="admin/lod-shapes" sortable={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField label={"resources.admin/lod-hole-image-shape.fields.hole"} source="hole_id" reference="admin/lod-holes" sortable={false}>
                        <TextField source="name_lt"/>
                    </ReferenceField>
                    { Array.from({length:11},(v,k)=>k+1).map((el, i)=> (
                        <BooleanField key={i} source={`mass_${el}`} label={`resources.admin/lod-hole-image-shape.fields.mass_${el}`} looseValue={true}/>
                    ))}
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/create`}>
                {({match}) => {
                    return (
                        <SideDrawer open={!!match} basePath={props.basePath ?? ''}>
                            <HoleImageShapeCreate {...props} />
                        </SideDrawer>
                    )
                }}
            </Route>
            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''}>
                            {isMatch && (
                                <HoleImageShapeEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default HoleImageShapeList;
