import * as React from "react";

import {FC} from "react";
import {ImageFieldProps} from "react-admin";
import {listEditCreate} from "../../styles/listEditCreate";

interface Props {
    src: string;
    alt?:string;
    fallback?: string;
    thumbnail?:boolean;
}

const Image: React.FC<Props> = ({src, alt, fallback, thumbnail, ...rest}) => {
    const classes = listEditCreate();
    return (
        <img
            alt={alt}
            className={thumbnail?classes.thumbnail:classes.image}
            src={src}
            onError={(e:any) => e.target.src = fallback}
            {...rest}
        />
    )
}

const ImageField: FC<ImageFieldProps> | string = ({record,source='code'}) => {
    let imageUrl = '';
    const fallbackImage = `${process.env.REACT_APP_API_URL}/storage/no-imgs/product-no-image.jpg`;
    const value = record ? (record[source]??null) : null;

    if(source === 'file_path'){
        imageUrl = value?`${process.env.REACT_APP_API_URL}/${value.replace('public','storage')}`:fallbackImage;
    } else if (['image_id', 'id'].includes(source)) {
        imageUrl = value?`${process.env.REACT_APP_API_URL}/storage/lod/labels/label_${value}.png`:fallbackImage;
    } else {
        imageUrl = fallbackImage;
    }

    return (
        <Image
            alt={''}
            src={imageUrl}
            fallback={fallbackImage}
            thumbnail={source!=='image_id'}
        />
    );
}

export default ImageField;