import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, ReferenceField, ChipField, BulkDeleteWithConfirmButton} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import { Route } from 'react-router';

import CobotFilter from "./CobotFilter";
import CobotEdit from "./CobotEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";

const BulkActionButtons = (props:any) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);


const CobotList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<CobotFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>} bulkActionButtons={<BulkActionButtons/>}>
                <Datagrid>
                    <TextField source="id" />
                    <ReferenceField reference={'admin/cob-manufacturers'} source={'cob_manufacturer_id'} label={'resources.admin/cob-cobots.fields.manufacturer'}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField reference={'admin/cob-types'} source={'cob_type_id'} label={'resources.admin/cob-cobots.fields.type'}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField reference={'admin/cob-models'} source={'cob_model_id'} label={'resources.admin/cob-cobots.fields.model'}>
                        <TextField source="name" />
                    </ReferenceField>
                    <ChipField source={'status'} />
                    <EditButton/>
                </Datagrid>
            </List>

            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''}>
                            {isMatch && (
                                <CobotEdit id={match && isMatch ? match.params.id : undefined} {...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default CobotList;
