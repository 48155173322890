import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, useTranslate, ReferenceField, useRecordContext, BulkActionProps, useGetMany, Identifier, Button, useRefresh, useUnselectAll} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import {Route} from 'react-router';
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import ProductReviewFilter from "./ProductReviewFilter";
import ProductReviewEdit from "./ProductReviewEdit";
import SideDrawer from "../../components/list/SideDrawer";
import TextField from "../../components/list/TextField";
import ListActions from "../../components/list/ListActions";
import {Typography} from "@material-ui/core";
import {useSelector, RootStateOrAny} from "react-redux";
import axiosForApi from "../../config/axios-for-api";
import {useNotify} from "ra-core";
import {handleError} from "../../functions/common";

const RatingStarsField = ({source}: { source: string }) => {
    const record = useRecordContext();
    const value = record[source] ?? 0;

    return (<>
        {[...Array(5)].map((_, idx) => {
            const star = idx + 1;
            return (star <= value) ? <StarIcon key={idx}/> : <StarBorderIcon key={idx}/>;
        })}
    </>)
};

type TStatus = 'open' | 'reject' | 'publish';

type TFilters = {
    status?: TStatus;
}

const BulkActions = ({
                         resource,
                         selectedIds,
                     }: BulkActionProps): ReactElement | null => {
    const {data, loading} = useGetMany(
        'admin/product-reviews',
        selectedIds as Identifier[]
    );

    const router = useSelector((state: RootStateOrAny) => state.router);
    const filters = ((): TFilters => {
        try {
            return JSON.parse(decodeURIComponent(router.location.query.filter));
        } catch (e) {
        }

        return {};
    })();

    const refresh = useRefresh();
    const unselectAll = useUnselectAll('admin/product-reviews');
    const notify = useNotify();

    const possibleBulks: { open: TStatus[], publish: TStatus[], reject: TStatus[] } = {
        open: ['publish', 'reject'],
        publish: ['open', 'reject'],
        reject: ['open', 'publish']
    }

    const status = filters.status ?? null;

    if (!data || loading) {
        return null;
    }

    const bulks = status ? possibleBulks[status] : ['open', 'publish', 'reject'];
    if (!bulks) return null;

    const handleStatus = async (status: TStatus) => {
        axiosForApi.post('api/admin/product-reviews/change-status', {
            status,
            ids: selectedIds,
        })
            .then(() => {
                unselectAll();
                refresh();
                notify('Oooook!', 'success')
            })
            .catch(e => notify(e.response.message ?? handleError(e), 'error'));
    }

    return (
        <>
            {bulks.includes('open') &&
                <Button
                    color="primary"
                    label={"open"}
                    onClick={() => handleStatus('open')}
                />
            }
            {bulks.includes('publish') &&
                <Button
                    color="primary"
                    label={"publish"}
                    onClick={() => handleStatus('publish')}
                />
            }
            {bulks.includes('reject') &&
                <Button
                    color="primary"
                    label={"reject"}
                    onClick={() => handleStatus('reject')}
                />
            }
        </>
    )
};


const ProductReviewList = (props: ListProps): ReactElement => {
    const translate = useTranslate();

    return (
        <Fragment>
            <Typography variant={'h6'}>{translate('resources.admin/product-reviews.name')}</Typography>
            <List {...props} filters={<ProductReviewFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>} bulkActionButtons={<BulkActions/>}>
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source={'status'}/>
                    <RatingStarsField source='star_rating'/>
                    <ReferenceField reference={'admin/products'} source={'product_id'} label={'resources.admin/product-reviews.fields.product'}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField reference={'admin/orders'} source={'order_id'} label={'resources.admin/product-reviews.fields.order_id'}>
                        <TextField source="id"/>
                    </ReferenceField>
                    <ReferenceField reference={'admin/users'} source={'user_id'} label={'resources.admin/product-reviews.fields.user'}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''}>
                            {isMatch && (
                                <ProductReviewEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default ProductReviewList;
