import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import OrderList from './OrderList';

const resource = {
    list: OrderList,
    icon: MonetizationOnIcon,
    options: {
        label: 'resources.admin/orders.name'
    }
};

export default resource;
