import * as React from 'react';
import {FC} from 'react';
import {AutocompleteArrayInput, BooleanInput, Create, CreateProps, ImageField, ImageInput, ReferenceArrayInput, SimpleForm, TextInput, TranslatableInputs, useTranslate} from 'react-admin';
import {Typography, Box} from '@material-ui/core';

import {filterProps} from "../../functions/common";
import cfgLocales from '../../config/locale';
import InfoPopup from "../../components/global/InfoPopup";

const VideoCreate: FC<CreateProps> = props => {
    const newProps = filterProps(props);
    const translate = useTranslate();

    const configurators = ['COB', 'LOD', 'EFKCB', 'KDH', 'KDHWP'];

    const InputWithHelp = ({customBoxStyle, customInnerContainerStyle, source, label, help, children}: any) => {
        const translate = useTranslate();
        return (
            <Box style={customBoxStyle ?? {width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                {children}
                <Box style={customInnerContainerStyle ?? {paddingTop: '0.5rem'}}>
                    <InfoPopup text={translate(help)}/>
                </Box>
            </Box>);
    }

    const TextInputWithHelp = (props: any) => {
        const {source, label, ...other} = props;
        return (
            <InputWithHelp {...other}>
                <TextInput source={source} label={label} fullWidth={true}/>
            </InputWithHelp>
        );
    };

    return (
        <>
            <Typography variant='h6'>
                {translate("resources.admin/videos.titles.create")}
            </Typography>
            <Create {...newProps}>
                <SimpleForm>
                    <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                        <TextInput fullWidth source='title' label={'resources.admin/videos.fields.title'}/>
                        <TextInput multiline rows={10} fullWidth source='description' label={'resources.admin/videos.fields.description'}/>
                        <TextInputWithHelp source='url' label='resources.admin/videos.fields.url' help='resources.admin/videos.info.youtube_url'/>
                    </TranslatableInputs>
                    <Box style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <ImageInput source="cover_photo" multiple={false} accept="image/*">
                            <ImageField source="url" title="caption"/>
                        </ImageInput>
                        <Box style={{marginTop: 40}}>
                            <InfoPopup text={translate('resources.admin/videos.info.cover_photo')}/>
                        </Box>
                    </Box>

                    <Box style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <ReferenceArrayInput source="product_ids" reference="admin/products" label='resources.admin/videos.fields.product_ids'>
                            <AutocompleteArrayInput fullWidth />
                        </ReferenceArrayInput>
                        <InfoPopup text={translate('resources.admin/videos.info.product_ids')}/>
                    </Box>
                    

                    <Box style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <ReferenceArrayInput source="video_category_ids" reference="admin/video-categories" label='resources.admin/videos.fields.video_category_ids'>
                            <AutocompleteArrayInput fullWidth/>
                        </ReferenceArrayInput>
                        <InfoPopup text={translate('resources.admin/videos.info.video_category_ids')}/>
                    </Box>

                    <Box style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <ReferenceArrayInput source="video_playlist_ids" reference="admin/video-playlists" label='resources.admin/videos.fields.video_playlist_ids'>
                            <AutocompleteArrayInput fullWidth/>
                        </ReferenceArrayInput>
                        <InfoPopup text={translate('resources.admin/videos.info.video_playlist_ids')}/>
                    </Box>

                    <Box style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <AutocompleteArrayInput source="video_configurators" fullWidth choices={configurators.map(el => ({id: el, name: el}))}/>
                        <InfoPopup text={translate('resources.admin/videos.info.video_configurators')}/>
                    </Box>

                    <Box style={{display: 'flex'}}>
                        <BooleanInput source="is_recommended"/>
                        <InfoPopup text={translate('resources.admin/videos.info.is_recommended')}/>
                    </Box>
                    <Box style={{display: 'flex'}}>
                        <BooleanInput source="is_highlighted"/>
                        <InfoPopup text={translate('resources.admin/videos.info.is_highlighted')}/>
                    </Box>
                    <Box style={{display: 'flex'}}>
                        <BooleanInput source="is_active"/>
                        <InfoPopup text={translate('resources.admin/videos.info.is_active')}/>
                    </Box>
                </SimpleForm>
            </Create>
        </>
    );
};

export default VideoCreate;
