import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, TextInput, ImageInput, ImageField} from 'react-admin';

import {listEditCreate} from "../../../styles/listEditCreate";
import Title from "../../../components/list/Title";
import {filterProps} from "../../../functions/common";

const ImageCreate: FC<CreateProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <Title label="resources.admin/lod-images.titles.create"/>
                <TextInput autoFocus source="name"/>
                <ImageInput source="file" multiple={false}>
                    <ImageField source="url" />
                </ImageInput>
            </SimpleForm>
        </Create>
    );
};

export default ImageCreate;
