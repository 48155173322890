import * as React from 'react';
import {FC, Fragment, useEffect, useState} from 'react';
import {
    Edit,
    EditProps,
    TextInput,
    FormWithRedirect,
    TranslatableInputs,
    SelectInput, useTranslate,
    Toolbar,
    FieldProps,
    SimpleFormIterator,
    ArrayInput,
    ImageInput,
    ImageField, SaveButton, DeleteButton, usePermissions, DateTimeInput, AutocompleteArrayInput,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import {Typography, Grid, Box} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import {filterProps} from "../../functions/common";

// @ts-ignore
import htmlEditButton from 'quill-html-edit-button';

import Quill from "quill";
import BlotFormatter from 'quill-blot-formatter';

import axios from '../../config/axios-for-api';
import cfgLocales from '../../config/locale';
import {Post} from "../../config/types";
import InfoPopup from "../../components/global/InfoPopup";

Quill.debug(false);
Quill.register({
    "modules/htmlEditButton": htmlEditButton,
    'modules/blotFormatter': BlotFormatter,
});


const options = {
    modules: {
        history: { // History module
            delay: 2000,
            maxStack: 500,
            userOnly: true
        },
        htmlEditButton: {
            debug: false,
            msg: " ",
            okText: "Ok",
            cancelText: "Cancel",
            buttonHTML: "HTML",
            buttonTitle: "Show HTML source",
            prependSelector: null,
            editorModules: {}
        },
        toolbar: {
            container: [
                [{'size': ['small', false, 'large', 'huge']}],
                ['bold', 'italic', 'underline', 'strike'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'color': []}, {'background': []}],
                [{'align': []}],
                ['clean', 'image'],
                [{
                    'shortcode': [
                        'Grid Container',
                        'Grid Item',
                        'Main Teaser',
                        'Slider Teaser',
                        'Info Card',
                        'Category Teaser',
                        'Product Card',
                        'Content Card',
                        'Button',
                        'Input',
                        'Image',
                        'Link',
                        'List',
                        'List Item',
                        'Typography',
                        'Box'
                    ]
                }]
            ],
            handlers: {
                "shortcode": function () {
                }
            }
        },
        blotFormatter: {}
    },
    theme: 'snow'
}


function selectLocalImage(quill: Quill) {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.click();

    // Listen upload local image and save to server
    input.onchange = () => {
        if (!input.files || input.files.length === 0) return;
        const file = input.files[0];

        // file type is only image.
        if (/^image\//.test(file.type)) {
            saveImageToServer(file, quill);
        } else {
            console.warn('You could only upload images.');
        }
    };
}

function saveImageToServer(file: File, quill: Quill) {

    const fd = new FormData();
    fd.append('image', file);

    //console.log(file);

    axios.post('api/admin/posts/upload', fd, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(response => {
        if (response.status === 200) {
            const range = quill.getSelection();
            const imgUrl = response.data.url;
            console.log(imgUrl);
            quill.insertEmbed(range?.index || 0, 'image', imgUrl);

        }
    }).catch(error => {
        console.log(error);
    });
}

const configureQuill = (quill: Quill) => {
    quill.getModule('toolbar').addHandler('image', () => {
        selectLocalImage(quill);
    });

    quill.getModule('toolbar').addHandler('shortcode', (value: string) => {
        let out = "";

        switch (value) {
            case 'Grid Container':
                out = `[grid_container xs="" sm="" md="" lg="" xlg="" class=""]content[/grid_container]\n`;
                break;
            case 'Grid Item':
                out = `[grid_item xs="" sm="" md="" lg="" xlg="" class=""]content[/grid_item]\n`;
                break;
            case 'Main Teaser':
                out = `[main_teaser title="" price="" price_info="" primary_button_link="" primary_button_text="" secondary_button_link="" secondary_button_text="" background_image="" ]\n`;
                break;
            case 'Slider Teaser':
                out = `[slider_teaser][slide_teaser id="" image="" title="" subtitle="" text="" button_link="" button_text="" ][/slider_teaser]\n`;
                break;
            case 'Info Card':
                out = `[info_card image_src="" title="" text="" button_link="" button_text="" class="" ]\n`;
                break;
            case 'Category Teaser':
                out = `[category_teaser id="" category_color="" category_image=""]\n`;
                break;
            case 'Product Card':
                out = `[product_card product_id="" type="list|grid" size="normal|small"]\n`;
                break;
            case 'Content Card':
                out = `[content_card image_src="" title="" text=""]content[/content_card]\n`;
                break;
            case 'Button':
                out = `[button href="/"]Button Text[/button]\n`;
                break;
            case 'Input':
                out = `[input type="text"]\n`;
                break;
            case 'Image':
                out = `[image src="" alt=""]\n`;
                break;
            case 'Link':
                out = `[link href=""]Link Text[/link]\n`;
                break;
            case 'Typography':
                out = `[typography tag="p"]Text[/typography]\n`;
                break;
            case 'List':
                out = `[list][list_item]List item[/list_item][/list]\n`;
                break;
            case 'List Item':
                out = `[list_item]List item[/list_item]\n`;
                break;
            case 'Box':
                out = `[box]Box Content[/box]\n`;
                break;

        }

        const range = quill.getSelection();
        quill.insertText(range?.index || 0, out);

    });

    const shortcodePickerItems = [].slice.call(document.querySelectorAll('.ql-shortcode .ql-picker-item'));
    shortcodePickerItems.forEach((item: HTMLElement | null) => item && (item.textContent = item.dataset.value || ''));

    //crazy hacking
    const style = document.createElement('style');
    style.textContent = ".ql-shortcode .ql-picker-label { width:100px; }" +
        ".ql-shortcode .ql-picker-label:before { content:'Shortcode'}";
    document.head.append(style);
}


const PostEdit: FC<EditProps> = props => {

    const [errorMessage, setErrorMessage] = useState<string>('');
    const newProps = filterProps(props);

    return (
        <Edit
            {...newProps}
            undoable={false}
            onFailure={(error) => setErrorMessage(error)}
        >
            <PostForm errorMessage={errorMessage}/>
        </Edit>
    );
};

const Title: FC<FieldProps<Post>> = ({record}) => {
    const translate = useTranslate();
    return record ? (<Typography variant='h6'><b>{translate('resources.admin/posts.titles.edit_post', {id: `${record.id}`})}</b></Typography>) : null;
}

const PostEditToolbar = (props: any) => {
    const {permissions} = usePermissions();

    return (<Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
            <SaveButton saving={props.saving}
                        handleSubmitWithRedirect={props.handleSubmitWithRedirect}/>
            {['admin'].includes(permissions) && <DeleteButton record={props.record}/>}
        </Box>
    </Toolbar>);
}

const PostForm = (props: any) => {

    const [availableVisitorCountries, setAvailableVisitorCountries] = useState([]);
    const translate = useTranslate();

    const record = props.record;
    const errorMessage = props.errorMessage;
    const metaConfig = record.meta_config;
    const metaFields = (Object.keys(record.meta_config ?? {}) ?? []).filter(metaField => !['seo_title', 'seo_description', 'seo_keywords', 'seo_robots'].includes(metaField));


    const newProps = filterProps(props);

    useEffect(() => {
        axios.get('api/admin/available-visitor-countries').then(response => {
            const {data} = response;
            setAvailableVisitorCountries(() => data.map((el:any) => ({id:el.code, name:el.name})));
        });
    }, []);

    return (
        <FormWithRedirect
            {...newProps}
            render={(formProps: any) => (
                <Fragment>
                    <Grid container spacing={3} style={{padding: "1rem"}}>
                        <Grid item xs={12} style={{paddingBottom: 0}}>
                            <Title record={formProps.record}/>
                        </Grid>
                        <Grid item xs={12}>

                            <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                                <>
                                    <Typography variant="h6">
                                        {translate(
                                            'resources.admin/posts.section.post_info'
                                        )}
                                    </Typography>
                                </>
                                <TextInput autoFocus source="title" fullWidth={true}/>
                                <TextInput source="slug" fullWidth={true}/>

                                {record.type === 'page' ? (<RichTextInput source="content" options={options} configureQuill={configureQuill}/>) : (<TextInput fullWidth={true} multiline={true} rows={5} source="content"/>)}

                                {metaFields.length > 0 && (<>
                                    <Typography variant="h6">
                                        {translate(
                                            'resources.admin/posts.section.meta'
                                        )}
                                    </Typography>
                                </>)}
                                {metaFields.map(metaField => {
                                    const fieldConfig = metaConfig[metaField];
                                    switch (fieldConfig['type']) {
                                        case 'textarea':
                                            return (<TextInput key={`field_${metaField}`} source={`meta.${metaField}`} fullWidth={true} rows={3} multiline={true}/>);
                                        case 'text':
                                            return (<TextInput key={`field_${metaField}`} source={`meta.${metaField}`} fullWidth={true}/>);
                                        case 'image':
                                            return (<ImageInput key={`field_${metaField}`} source={`meta.${metaField}`} multiple={false} accept="image/*">
                                                {<ImageField source="src" title="caption"/>}
                                            </ImageInput>);
                                        case 'repeater':
                                            return (<ArrayInput source={`meta.${metaField}`} key={`a_meta_${metaField}`}>
                                                <SimpleFormIterator key={`iterator_${metaField}`}>
                                                    {fieldConfig.fields.map((field: string, index: number) => {
                                                        const isImage = ['image_path'].includes(field);
                                                        const isDateTime = ['available_from', 'available_to'].includes(field);
                                                        const isTextarea = ['text'].includes(field);

                                                        if (isImage) {
                                                            return (<ImageInput key={`field_${field}_${index}`} source={field} multiple={false} accept="image/*">
                                                                {<ImageField source="src" title="caption"/>}
                                                            </ImageInput>);
                                                        } else if (isDateTime) return (<DateTimeInput key={`field_${field}_${index}`} source={field} fullWidth={true}/>);
                                                        else if (isTextarea) return (<TextInput multiline={true} rows={2} key={`field_${field}_${index}`} source={field} fullWidth={true}/>)
                                                        else if (field === 'visitor_countries') {
                                                            return <AutocompleteArrayInput fullWidth={true} key={`field_${field}_${index}`} source={field} choices={availableVisitorCountries} />
                                                        } else return (<TextInput key={`field_${field}_${index}`} source={field} fullWidth={true}/>);
                                                    })}
                                                </SimpleFormIterator>
                                            </ArrayInput>);

                                        case 'wysiwyg':
                                            return (<RichTextInput key={`field_${metaField}`} source={`meta.${metaField}`} options={options}/>);

                                        default:
                                            return null;
                                    }
                                })}


                                {record.type === 'page' && <>
                                    <Typography variant="h6">
                                        {translate(
                                            'resources.admin/posts.section.seo'
                                        )}
                                    </Typography>
                                </>}
                                {record.type === 'page' && <TextInput source="meta.seo_title" fullWidth={true}/>}
                                {record.type === 'page' && <TextInput source="meta.seo_description" fullWidth={true}/>}
                                {record.type === 'page' && <TextInput source="meta.seo_keywords" fullWidth={true}/>}
                                {record.type === 'page' && <SelectInput source="meta.seo_robots" choices={[
                                    {id: 'all', name: 'all'},
                                    {id: 'noindex,nofollow', name: 'noindex, nofollow'},
                                    {id: 'noindex', name: 'noindex'},
                                    {id: 'nofollow', name: 'nofollow'},
                                    {id: 'noarchive', name: 'noarchive'},
                                    {id: 'noimageindex', name: 'noimageindex'},
                                ]}/>}

                                <SelectInput source="translation_status" defaultValue={'active'} choices={[
                                    {id: 'active', name: translate('resources.admin/posts.translationStatusOptions.active')},
                                    {id: 'inactive', name: translate('resources.admin/posts.translationStatusOptions.inactive')},
                                ]}/>

                            </TranslatableInputs>
                        </Grid>
                        <Grid item xs={12} sm={4} style={{display: 'flex', paddingBottom: 0}}>
                            <DateTimeInput fullWidth source='available_from'/>
                            <Box style={{paddingTop: '0.5rem'}}>
                                <InfoPopup text={translate('resources.admin/posts.info.available_from')}/>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={4} style={{display: 'flex', paddingBottom: 0}}>
                            <DateTimeInput fullWidth source='available_to'/>
                            <Box style={{paddingTop: '0.5rem'}}>
                                <InfoPopup text={translate('resources.admin/posts.info.available_to')}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectInput source="status" choices={[
                                {id: 'draft', name: translate('resources.admin/posts.statusOptions.draft')},
                                {id: 'publish', name: translate('resources.admin/posts.statusOptions.publish')},
                            ]}/>
                        </Grid>
                        {(errorMessage !== '') && (
                            <Grid item xs={12}>
                                <Box
                                    display={'flex'}
                                    borderRadius={5}
                                    color={'white'}
                                    p={2}
                                    style={{backgroundColor: '#e2001ac7'}}>
                                    <Box mr={2}>
                                        <WarningIcon/>
                                    </Box>
                                    {errorMessage}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    <PostEditToolbar {...formProps} />
                </Fragment>
            )}
        />
    );
};

export default PostEdit;
