import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import AbandonedCartList from './AbandonedCartList'
//import AbandonedCartShow from "./AbandonedCartShow";

const resource = {
    list: AbandonedCartList,
    //show: AbandonedCartShow,
    icon: RemoveShoppingCartIcon,
    options: {
        label: 'resources.admin/abandoned-carts.name'
    }
};

export default resource;
