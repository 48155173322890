import ListAltIcon from '@material-ui/icons/ListAlt';
import VideoPlaylistList from './VideoPlaylistList';
import VideoPlaylistCreate from "./VideoPlaylistCreate";

const resource = {
    list: VideoPlaylistList,
    create: VideoPlaylistCreate,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/video-playlists.name'
    }
};

export default resource;
