import * as React from 'react';
import {FC} from 'react';
import {BooleanInput, Edit, EditProps, SelectArrayInput, SelectInput, SimpleForm, TextInput, TranslatableInputs, useTranslate} from 'react-admin';

import Title from '../../components/list/Title';
import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps} from "../../functions/common";
import cfgLocales from '../../config/locale';
import {Typography} from "@material-ui/core";

const VisitorCountryEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);
    const translate = useTranslate();

    return (
        <Edit{...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="code" label="resources.admin/visitor-countries.titles.edit"/>
                <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                    <TextInput source="name" fullWidth={true}/>
                </TranslatableInputs>
                <TextInput source='code' label={'resources.admin/visitor-countries.fields.code'}/>
                <TextInput source='vkorg' label={'resources.admin/visitor-countries.fields.vkorg'}/>
                <TextInput source='terms_link' label={'resources.admin/visitor-countries.fields.terms_link'}/>
                <SelectArrayInput
                    source="payment_type"
                    choices={[
                        {id: 'invoice', name: translate('resources.admin/visitor-countries.paymentOptions.invoice')},
                        {id: 'unzer', name: translate('resources.admin/visitor-countries.paymentOptions.unzer')}
                    ]}/>
                <SelectInput source="default_language" choices={Object.keys(cfgLocales.languageOptions).map(el => ({id: el, name: el}))}/>
                <SelectInput
                    source="status"
                    choices={[
                        {id: 'active', name: translate('resources.admin/visitor-countries.statusOptions.active')},
                        {id: 'inactive', name: translate('resources.admin/visitor-countries.statusOptions.inactive')}
                    ]}/>
                {/* <>
                    <Typography variant='h6' className={classes.title}>
                        {translate("resources.admin/visitor-countries.titles.catalog")}
                    </Typography>
                </>
                <BooleanInput source='is_catalog_only' label={'resources.admin/visitor-countries.fields.is_catalog_only'}/>
                <TextInput source='catalog_price_group' label={'resources.admin/visitor-countries.fields.catalog_price_group'}/>
                <BooleanInput source='catalog_show_prices' label={'resources.admin/visitor-countries.fields.catalog_show_prices'}/>
                <TextInput source='catalog_currency' label={'resources.admin/visitor-countries.fields.catalog_currency'}/>
                <TextInput source='catalog_delivery_price' label={'resources.admin/visitor-countries.fields.catalog_delivery_price'}/>
                <TextInput source='catalog_vat_percentage' label={'resources.admin/visitor-countries.fields.catalog_vat_percentage'}/>
                <>
                    <Typography variant='h6' className={classes.title}>
                        {translate("resources.admin/visitor-countries.titles.murr_contact_info")}
                    </Typography>
                    <TextInput fullWidth source='murr_contact_info.company_name' label={'resources.admin/visitor-countries.fields.murr_contact_info.company_name'}/>
                    <TextInput fullWidth source='murr_contact_info.address_line_1' label={'resources.admin/visitor-countries.fields.murr_contact_info.address_line_1'}/>
                    <TextInput fullWidth source='murr_contact_info.address_line_2' label={'resources.admin/visitor-countries.fields.murr_contact_info.address_line_2'}/>
                    <TextInput fullWidth source='murr_contact_info.country' label={'resources.admin/visitor-countries.fields.murr_contact_info.country'}/>
                    <TextInput fullWidth source='murr_contact_info.phone' label={'resources.admin/visitor-countries.fields.murr_contact_info.phone'}/>
                    <TextInput fullWidth source='murr_contact_info.fax' label={'resources.admin/visitor-countries.fields.murr_contact_info.fax'}/>
                    <TextInput fullWidth source='murr_contact_info.url' label={'resources.admin/visitor-countries.fields.murr_contact_info.url'}/>
                </> */}
            </SimpleForm>
        </Edit>
    );
};

export default VisitorCountryEdit;
