import React from 'react';
import { Filter, TextInput, FilterProps } from 'react-admin';

const MailTemplateFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput label='resources.admin/mail-templates.fields.subject' source="subject" alwaysOn />
    </Filter>
);

export default MailTemplateFilter;
