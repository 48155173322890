import * as React from 'react';
import {FC} from 'react';
import {AutocompleteArrayInput, Create, CreateProps, ImageField, ImageInput, Record, ReferenceArrayInput, SimpleForm, TextInput, TranslatableInputs, useTranslate} from 'react-admin';
import {Typography, Box} from "@material-ui/core";

import {filterProps} from "../../functions/common";
import cfgLocales from '../../config/locale';
import InfoPopup from "../../components/global/InfoPopup";

const VideoPlaylistCreate: FC<CreateProps> = props => {
    const newProps = filterProps(props);
    const translate = useTranslate();

    return (
        <>
            <Typography variant='h6'>
                {translate("resources.admin/video-playlists.titles.create")}
            </Typography>
            <Create {...newProps}>
                <SimpleForm>
                    <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                        <TextInput fullWidth source='name' label={'resources.admin/video-playlists.fields.name'}/>
                    </TranslatableInputs>
                    <Box style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <ReferenceArrayInput source="video_category_ids" reference="admin/video-categories" label='resources.admin/videos.fields.video_category_ids'>
                            <AutocompleteArrayInput fullWidth optionText={(record?: Record) => {
                                if (typeof record?.name !== 'string') {
                                    //console.log('Unexpected non-string record: ', record);
                                    return '';
                                }
                                return record.name;
                            }}/>
                        </ReferenceArrayInput>
                        <InfoPopup text={translate('resources.admin/videos.info.playlist_category_ids')}/>
                    </Box>
                    <Box style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <ImageInput source="cover_photo" multiple={false} accept="image/*">
                            <ImageField source="url" title="caption"/>
                        </ImageInput>
                        <Box style={{marginTop: 40}}>
                            <InfoPopup text={translate('resources.admin/videos.info.playlist_cover_photo')}/>
                        </Box>
                    </Box>
                </SimpleForm>
            </Create>
        </>
    );
};

export default VideoPlaylistCreate;
