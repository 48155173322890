import ListAltIcon from '@material-ui/icons/ListAlt';
import PriceListFactorsList from './PriceListFactorsList';

const resource = {
    list: PriceListFactorsList,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/lod-price-factors.name'
    }
};

export default resource;
