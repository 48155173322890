import React from 'react';
import {Filter, TextInput, FilterProps} from 'react-admin';

const ImageFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput source="id" alwaysOn/>
    </Filter>
);

export default ImageFilter;
