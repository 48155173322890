import * as React from 'react';
import {useDataProvider, useTranslate} from 'react-admin';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {Order} from '../../config/types';
import CardWithIcon from "./CardWithIcon";
import {useCallback, useEffect, useState} from "react";
import {subDays} from "date-fns";
import {CircularProgress, Box} from "@material-ui/core";
import ResultsTable from "./ResultsTable";

const LatestOrders = ({countries}: { countries: string[] | null }) => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [shouldFetchOrders, setShouldFetchOrders] = useState<boolean>(false);
    const [newOrders, setNewOrders] = useState<Order[]>([]);
    const [totalOrders, setTotalOrders] = useState<number | string>('');

    const fetchLatestOrders = useCallback(async () => {
        const aMonthAgo = subDays(new Date(), 30);
        await dataProvider.getList<Order>(
            'admin/orders',
            {
                filter: {
                    created_at_from: aMonthAgo.toISOString().split('T')[0],
                    source: 'shop',
                    countries
                },
                sort: {field: 'created_at', order: 'DESC'},
                pagination: {page: 1, perPage: 10},
            }
        ).then(({data, total}) => {
            setNewOrders(data);
            setTotalOrders(total)
        }).catch(err => Promise.reject(err.response));
        setShouldFetchOrders(false);
    }, [dataProvider, countries]);

    useEffect(() => {
        if (shouldFetchOrders) {
            fetchLatestOrders();
        }
    }, [shouldFetchOrders, fetchLatestOrders]);

    useEffect(() => {
        setShouldFetchOrders(true);
    }, [countries]);


    const tableColumns = ['created_at', 'sap_order_id', 'status', 'total_formatted'];

    return (
        <CardWithIcon
            to="admin/orders"
            icon={MonetizationOnIcon}
            title={translate('resources.admin/dashboard.orders')}
            subtitle={totalOrders}
            showButton={newOrders.length > 0}
        >
            {newOrders.length ? (
                <ResultsTable columns={tableColumns} results={newOrders} to={'admin/orders'} linkArray={['sap_order_id']}/>
            ) : (
                <Box display={'flex'} justifyContent={'center'} p={2}>
                    <CircularProgress/>
                </Box>
            )}
        </CardWithIcon>
    );
};

export default LatestOrders;
