import * as React from 'react';
import {FC} from 'react';
import {
    Show,
    ShowProps,
    SimpleShowLayout,
    RichTextField,
    DateField,
    TranslatableFields,
    useLocale,
    useTranslate,
    TextField,
} from 'react-admin';
import {Typography, Box} from "@material-ui/core";
import Title from '../../components/list/Title';
import Variations from '../../components/products/Variations';
import cfgLocales from '../../config/locale';
import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps, renderElement} from "../../functions/common";

export const ProductShow: FC<ShowProps> = (props: any) => {
    const locale = useLocale();
    const translate = useTranslate();
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Show {...newProps} >
            <SimpleShowLayout className={classes.sidebar}>
                <Title source="id" label="resources.admin/products.titles.show_product" />
                <TranslatableFields locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                    <TextField source="name" />
                    <TextField source="slug"/>
                    <RichTextField source="description"/>
                    <RichTextField source="short_description"/>
                </TranslatableFields>
                {renderElement(
                    <>
                        <Box display={'flex'} mb={3} />
                        <Typography variant='h6'>
                            <b>{translate('resources.admin/products.titles.product_variations')}</b>
                        </Typography>
                    </>
                )}
                <Variations/>
                <DateField source="created_at" locales={locale}/>
                <RichTextField source="attributes"/>
                <RichTextField source="categoryPaths"/>
            </SimpleShowLayout>
        </Show>
    );
}

export default ProductShow;