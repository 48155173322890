import React, {useEffect, useState} from "react";
import {useFormState} from "react-final-form";
import {Typography} from "@material-ui/core";

import axiosForApi from "../../../config/axios-for-api";

const ProductName = (props: any) => {
    const [productName, setProductName] = useState('');
    const {values} = useFormState();

    useEffect(() => {
        const fetchProductName = async (matnr: string) => {
            const {name} = (await axiosForApi.get(`/api/admin/product-name-by-matnr/${matnr}`)).data;
            setProductName(name);
        };

        fetchProductName(values.matnr);
    }, [values.matnr])

    return (
        <Typography gutterBottom variant="h6">
            {productName}
        </Typography>
    );
}

export default  ProductName;
