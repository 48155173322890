import ListAltIcon from '@material-ui/icons/ListAlt';
import QuellwerkList from './QuellwerkList';

const resource = {
    list: QuellwerkList,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/lod-quellwerk.name'
    }
};

export default resource;
