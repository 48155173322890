import ListAltIcon from '@material-ui/icons/ListAlt';
import VisitorCountryList from './VisitorCountryList';
import VisitorCountryCreate from "./VisitorCountryCreate";

const resource = {
    list: VisitorCountryList,
    create: VisitorCountryCreate,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/visitor-countries.name'
    }
};

export default resource;
