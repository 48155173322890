import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, TextInput, SimpleForm, SelectInput, ReferenceInput, NumberInput} from 'react-admin';
import Title from '../../../components/list/Title';
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const RawMaterialPricesEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit {...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="name_lt" label="resources.admin/lod-raw-material-prices.titles.edit" />
                <TextInput source="matnr" fullWidth={true}/>
                <TextInput source="designation" fullWidth={true}/>
                <ReferenceInput label="resources.admin/lod-price-matrix.fields.material" source="material_id" reference="admin/lod-materials" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }} filter={{status: 'active'}}>
                    <SelectInput optionText="name_lt" fullWidth={true}/>
                </ReferenceInput>
                <ReferenceInput label="resources.admin/lod-raw-material-prices.fields.thickness" source="thickness_id" reference="admin/lod-thickness" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }} filter={{status: 'active'}}>
                    <SelectInput optionText="name_lt" fullWidth={true}/>
                </ReferenceInput>
                <ReferenceInput label="resources.admin/lod-raw-material-prices.fields.color" source="color_id" reference="admin/lod-colors" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }} filter={{status: 'active'}}>
                    <SelectInput optionText="name_lt" fullWidth={true}/>
                </ReferenceInput>
                <ReferenceInput label="resources.admin/lod-raw-material-prices.fields.foil" source="foil_id" reference="admin/lod-foils" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }} filter={{status: 'active'}}>
                    <SelectInput optionText="name_lt" fullWidth={true}/>
                </ReferenceInput>
                <NumberInput source="price" fullWidth={true}/>
            </SimpleForm>
        </Edit>
    );
};

export default RawMaterialPricesEdit;
