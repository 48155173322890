import LineWeightIcon from '@material-ui/icons/LineWeight';
import ThicknessList from './ThicknessList';

const resource = {
    list: ThicknessList,
    icon: LineWeightIcon,
    options: {
        label: 'resources.admin/lod-thickness.name'
    }
};

export default resource;
