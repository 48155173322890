import {Dialog, DialogContent, DialogActions, Button as MUIButton, TextField as MUITextField} from '@material-ui/core';
import {useCreate, useCreateSuggestionContext, useTranslate} from "react-admin";
import {useState} from "react";

const CobModelCreate = (props: any) => {
    const {filter, onCancel, onCreate} = useCreateSuggestionContext();
    const [value, setValue] = useState(filter || '');
    const [create] = useCreate('admin/cob-models');

    const form = props.form;
    const translate = useTranslate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        create(
            {
                payload: {
                    data: {
                        cob_manufacturer_id: props.cob_manufacturer_id,
                        cob_type_id: props.cob_type_id,
                        name: value
                    },
                },
            },
            {
                onSuccess: ({data}: any) => {
                    setValue('');

                    const choice = {
                        id: data.id,
                        name: data.name,
                        manufacturer_id: data.cob_manufacturer_id,
                        type_id: data.cob_type_id
                    }

                    onCreate(data);
                    form.change('cob_model_id', data.id);

                    props.setChoicesData((oldChoicesData: any) => {
                        const newChoices = Object.assign({}, oldChoicesData);
                        newChoices.modelChoices = [...newChoices.modelChoices, choice];
                        return newChoices;
                    });
                },
            }
        );
    };

    return (
        <Dialog open={true} onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MUITextField
                        label={translate('resources.admin/cob-cobots.fields.type')}
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <MUIButton type="submit">Save</MUIButton>
                    <MUIButton onClick={onCancel}>Cancel</MUIButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CobModelCreate;
