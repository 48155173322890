import {Typography} from '@material-ui/core';
import Help from "./Help";

export default function StatHeader(props: any) {
    return (
        <Typography component="h2" style={{'paddingBottom': '10px', display:'flex', alignItems:'center', justifyContent:props.justifyContent??'start'}}>
            <span>{props.title ?? ''}</span>
            {props.help && (<Help content={props.help ?? ''}/>)}
        </Typography>);
}
