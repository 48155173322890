import React from 'react';
import {Filter, FilterProps, ReferenceInput, SelectInput} from 'react-admin';

const PriceMatrixFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <ReferenceInput label="resources.admin/lod-price-matrix.fields.material" source="material_id" reference="admin/lod-materials" alwaysOn options={{
            suggestionsContainerProps: {
                disablePortal: true,
            }
        }}>
            <SelectInput optionText="name_lt" />
        </ReferenceInput>
        <ReferenceInput label="resources.admin/lod-price-matrix.fields.thickness" source="thickness_id" reference="admin/lod-thickness" alwaysOn options={{
            suggestionsContainerProps: {
                disablePortal: true,
            }
        }}>
            <SelectInput optionText="value" />
        </ReferenceInput>
    </Filter>
);

export default PriceMatrixFilter;
