import * as React from 'react';
import {useSafeSetState, useTranslate} from 'ra-core';
import {Box, Button, CircularProgress, Grid, Typography} from '@material-ui/core';
import axiosForApi from "../config/axios-for-api";
import {loginForm} from "../styles/ui/loginForm";
// import {useEffect} from "react";
import FieldInput from "../components/ui/FieldInput";
import FieldInputEndIcon from "../components/ui/FieldInputEndIcon";
import AuthCode from "../components/2fa/AuthCode";

interface FormData {
    code?: string;
    recovery_code?: string;
}

export default function TwoFALoginForm({onVerify, onFail}: any) {
    const classes = loginForm();
    const [loading, setLoading] = useSafeSetState<boolean>(false);
    const [useRecoveryCode, setUseRecoveryCode] = useSafeSetState<boolean>(false);
    const [contactSupport, setContactSupport] = useSafeSetState<boolean>(false);
    const [code, setCode] = useSafeSetState<string>('');
    const translate = useTranslate();
    // const [maskFieldValues, setMaskFieldValues] = useSafeSetState<any>({ 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' });
    //cr
    //const [errorMessage, setErrorMessage] = useSafeSetState<any>(null);
    const [errorMessage] = useSafeSetState<any>(null);

    // useEffect(() => {
    //     let newValue = '';
    //     Object.keys(maskFieldValues).forEach(el => newValue = newValue + maskFieldValues[el])
    //     setCode(newValue);
    // }, [maskFieldValues, setCode]);

    // const handleFocusNextInput = (index: number) => {
    //     const nextInput = document.getElementById('maskInput_' + index);
    //     if (nextInput) nextInput.focus();
    // }

    const submit = () => {
        let data: FormData = {};

        if (useRecoveryCode) {
            data.recovery_code = code;
        } else {
            data.code = code;
        }

        axiosForApi.post('/two-factor-challenge', data).then(() => {
            onVerify();
        }).catch(() => {
            setLoading(false);
            onFail();
        })
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} >
                <Typography className={classes.title}>
                    {translate(contactSupport ? "twoFA.loginTwoFAContactSupportTitle" : useRecoveryCode ? "twoFA.loginTwoFARestoreAccessTitle" :  "twoFA.loginTwoFATitle")}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={1} justify="center">
                    {contactSupport ? (
                        <>
                            <Grid item xs={12} style={{marginTop: 5}}>
                                <Typography variant="body2" className={classes.text}>
                                    {translate('twoFA.loginTwoFAContactSupportText')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.actions} style={{marginTop: 20}}>
                                <Button
                                    variant="outlined"
                                    className={classes.button}
                                    href={translate('twoFA.loginTwoFAContactSupportUrl')}
                                    target="_blank"
                                >
                                    {translate('global.support')}
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <>
                            {useRecoveryCode ? (
                                <Grid item xs={12} className={classes.input}>
                                    <FieldInput
                                        type="text"
                                        isDark
                                        hasError={errorMessage!==null}
                                        value={code}
                                        onChange={(e:any) => setCode(e.target.value)}
                                        endAdornment={
                                            <FieldInputEndIcon
                                                errors={errorMessage!==null}
                                                isDark={true}
                                                showArrow={true}
                                            />
                                        }
                                    />
                                </Grid>
                            ) : (
                                <>
                                    <Grid item xs={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <Box style={{marginTop: 15}}>
                                            <AuthCode
                                                length={6}
                                                onChange={(e) => setCode(e)}
                                                allowedCharacters="numeric"
                                                containerClassName="dark"
                                            />
                                        </Box>
                                    </Grid>

                                    {/*{[...Array(6)].map((_, i) => (*/}
                                    {/*    <Grid item xs={2} key={'gr_' + i} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>*/}
                                    {/*        <Box className={`${classes.input} textCenter`} style={{width: 56, maxWidth: '100%'}} key={"box_" + i}>*/}
                                    {/*            <FieldInput*/}
                                    {/*                key={i}*/}
                                    {/*                type="text"*/}
                                    {/*                min={0}*/}
                                    {/*                max={9}*/}
                                    {/*                showNumericPhone*/}
                                    {/*                className="darkInput"*/}
                                    {/*                isDark*/}
                                    {/*                maxLength={1}*/}
                                    {/*                id={`maskInput_${i+1}`}*/}
                                    {/*                value={maskFieldValues[i+1]}*/}
                                    {/*                customStyle={{*/}
                                    {/*                    borderWidth: 2,*/}
                                    {/*                    display: 'flex',*/}
                                    {/*                    minHeight: 56,*/}
                                    {/*                    textAlign: 'center',*/}
                                    {/*                }}*/}
                                    {/*                customInputProps={{*/}
                                    {/*                    style: {*/}
                                    {/*                        display: 'flex',*/}
                                    {/*                        minHeight: 56,*/}
                                    {/*                        textAlign: 'center',*/}
                                    {/*                    }*/}
                                    {/*                }}*/}
                                    {/*                onChange={(e:any) => {*/}
                                    {/*                    const value = e.target.value;*/}

                                    {/*                    setMaskFieldValues((prevMaskFieldValues:any) => ({*/}
                                    {/*                        ...prevMaskFieldValues,*/}
                                    {/*                        [i+1]: value // Update the specific field with new value*/}
                                    {/*                    }));*/}
                                    {/*                    if (value !== '') handleFocusNextInput(i+2);*/}

                                    {/*                }}*/}
                                    {/*            />*/}
                                    {/*        </Box>*/}
                                    {/*    </Grid>*/}
                                    {/*))}*/}
                                </>
                            )}
                        </>
                    )}
                </Grid>
            </Grid>

            {!contactSupport ? (
                <>
                    <Grid item xs={12} className={classes.actions} style={{marginTop: 20}}>
                        <Button
                            variant="contained"
                            type="submit"
                            className={classes.button}
                            disabled={loading || !code || code.length < 6}
                            onClick={() => submit()}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('global.loginLink')}
                        </Button>
                    </Grid>

                    <Grid item xs={12} className={`${classes.text} isLink`}>
                        <Typography
                            variant="caption"
                            onClick={() => {
                                if(useRecoveryCode) {
                                    setContactSupport(true);
                                } else {
                                    setUseRecoveryCode(true);
                                }
                            }}
                        >
                            {translate(useRecoveryCode ? 'twoFA.loginTwoFARestoreAccessNotFound' : 'twoFA.loginTwoFANotFound' )}
                        </Typography>
                    </Grid>
                </>
            ) : null}
        </Grid>
    );
}
