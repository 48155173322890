import {cloneElement, FC, ReactElement} from 'react';
import {Button, Datagrid, List, ListProps, sanitizeListRestProps, TextField, TopToolbar} from 'react-admin';
import GsFlowFilter from "./GsFlowFilter";
import AddIcon from '@material-ui/icons/Add';
import {Record} from "ra-core";

interface FlowActionProps {
    basePath?: string;
    filters?: ReactElement<any>;
    displayedFilters?: any;
    filterValues?: any;
    resource?: string;
    showFilter?: (filterName: string, defaultValue: any) => void;
    total?: number;
}

const FlowActions: FC<FlowActionProps> = ({
                                              basePath = '',
                                              filters,
                                              displayedFilters,
                                              filterValues,
                                              resource,
                                              showFilter,
                                              ...rest
                                          }: FlowActionProps) => {

    const gotoCreate = () => {
        window.location.href = `${process.env.REACT_APP_SHOP_URL}/shop`;
    }

    return (
        <TopToolbar {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <Button onClick={gotoCreate} startIcon={<AddIcon/>} label="ra.action.create"/>
        </TopToolbar>
    )
};

const EditButton = ({record}: { record?: Record }) => {
    const handleClick = () => {
        const path = record?.path ?? null;
        if (path)
            window.location.href = `${process.env.REACT_APP_SHOP_URL}${path}`;
    }

    return <Button type="button" label="ra.action.edit" onClick={handleClick}/>
}

const Category = ({record}: { record?: Record }) => {
    const ancestors = record?.category?.ancestors??[];
    const ancestorsNames = ancestors.map((ancestor: any) => ancestor.name).join(' / ');

    return <span>{ancestorsNames}{' / '}{record?.category?.name??"N/A"}</span>;
}

const GsFlowList = (props: ListProps): ReactElement => {

    return (
        <List actions={<FlowActions/>} {...props} filters={<GsFlowFilter/>} sort={{field: 'created_at', order: 'DESC'}}>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="name"/>
                <TextField source="total_questions" sortable={false}/>
                <Category />
                <EditButton/>
            </Datagrid>
        </List>
    );
};

export default GsFlowList;
