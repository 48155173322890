import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, DateField} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import { Route } from 'react-router';

import PriceListFilter from "./PriceListFilter";
import PriceListCreate from "./PriceListCreate";
import PriceListEdit from "./PriceListEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";


const PriceListList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<PriceListFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="preisliste"/>
                    <TextField source="betrag"/>
                    <TextField source="wahrung"/>
                    <TextField source="preiseinheit"/>
                    <DateField source="gultig_bis" locales={"de"} />
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/create`}>
                {({ match }) => {
                    return (
                        <SideDrawer open={!!match} basePath={props.basePath??''}>
                            <PriceListCreate {...props} />
                        </SideDrawer>
                    )
                }}
            </Route>
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''}>
                            {isMatch && (
                                <PriceListEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default PriceListList;
