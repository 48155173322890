import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, TextInput, SimpleForm} from 'react-admin';

import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const WintypEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <TextInput source="code" helperText={false}/>
            </SimpleForm>
        </Edit>
    );
};

export default WintypEdit;
