import React from "react";

import Box from "@material-ui/core/Box";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import IconFieldError from "../../assets/icons/field-error.svg";
import IconFieldArrow from "../../assets/icons/field-arrow.svg";

import {fields} from "../../styles/ui/fields";

const FieldInputEndIcon = ({isPassword, showPassword, setShowPassword, errors, isDark, showArrow}: {isPassword?: boolean, showPassword?: boolean, setShowPassword?: React.SetStateAction<any>, errors?: boolean, isDark?: boolean, showArrow?: boolean}) => {
    const classes = fields();
    const endIconClass = `${classes.endIcon} ${isDark?classes.whiteIcon:''}`;
    const endIconErrorClass = `${classes.endIcon} ${isDark?classes.whiteIcon:classes.endIconError}`;

    return (
        <Box className={`${classes.endIcons} `}>
            {isPassword ? (
                <Box
                    className={`${classes.toggleVisibility}`}
                    onClick={() => setShowPassword ? setShowPassword(!showPassword) :  null}
                >
                    {showPassword ? (
                        <VisibilityOff className={endIconClass} />
                    ) : (
                        <Visibility className={endIconClass}  />
                    )}
                </Box>
            ) : null}
            {errors ? (
                <Box className={`${endIconErrorClass}`} style={{marginRight: 14}}>
                    <img alt="field-error" width={19} height={19} style={{objectFit: 'contain'}} src={IconFieldError} />
                </Box>
            ) : (
                <Box className={`${endIconClass}`} style={{marginRight: 14}}>
                    {showArrow ? <img alt="field-arrow" width={19} height={19} style={{objectFit: 'contain'}} src={IconFieldArrow}/> : null}
                </Box>
            )}
        </Box>
    )
}

export default FieldInputEndIcon;
