import {FC} from 'react';
import {AutocompleteArrayInput, BooleanInput, Edit, EditProps, ImageField, ImageInput, Record, ReferenceArrayInput, SimpleForm, TextInput, TranslatableInputs} from 'react-admin';

import Title from '../../components/list/Title';
import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps} from "../../functions/common";
import cfgLocales from '../../config/locale';

const VideoEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    const configurators = ['COB', 'LOD', 'EFKCB', 'KDH', 'KDHWP'];

    return (
        <Edit{...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="title" label="resources.admin/videos.titles.edit"/>
                <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                    <TextInput fullWidth source='title' label={'resources.admin/videos.fields.title'}/>
                    <TextInput multiline rows={10} fullWidth source='description' label={'resources.admin/videos.fields.description'}/>
                    <TextInput fullWidth source='url' label={'resources.admin/videos.fields.url'}/>
                </TranslatableInputs>
                <ImageInput source="cover_photo" multiple={false} accept="image/*">
                    <ImageField source="url" title="caption"/>
                </ImageInput>

                <ReferenceArrayInput source="product_ids" reference="admin/products" label='resources.admin/videos.fields.product_ids'>
                    <AutocompleteArrayInput fullWidth optionText={(record?: Record) => {
                        if (typeof record?.name !== 'string') {
                            //console.log('Unexpected non-string record: ', record);
                            return '';
                        }
                        return record.name;
                    }}/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="video_category_ids" reference="admin/video-categories" label='resources.admin/videos.fields.video_category_ids'>
                    <AutocompleteArrayInput fullWidth optionText={(record?: Record) => {
                        if (typeof record?.name !== 'string') {
                            //console.log('Unexpected non-string record: ', record);
                            return '';
                        }
                        return record.name;
                    }}/>
                </ReferenceArrayInput>

                <ReferenceArrayInput source="video_playlist_ids" reference="admin/video-playlists" label='resources.admin/videos.fields.video_playlist_ids'>
                    <AutocompleteArrayInput fullWidth optionText={(record?: Record) => {
                        if (typeof record?.name !== 'string') {
                            //console.log('Unexpected non-string record: ', record);
                            return '';
                        }
                        return record.name;
                    }}/>
                </ReferenceArrayInput>

                <AutocompleteArrayInput source="video_configurators" fullWidth choices={configurators.map(el => ({id: el, name: el}))}/>

                <BooleanInput source="is_recommended"/>
                <BooleanInput source="is_highlighted"/>
                <BooleanInput source="is_active"/>
            </SimpleForm>
        </Edit>
    );
};

export default VideoEdit;
