import * as React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';

import {DashboardMenuItem, MenuItemLink, MenuProps, ReduxState, usePermissions, useTranslate,} from 'react-admin';
import ScheduleIcon from "@material-ui/icons/Schedule";
import Videocam from "@material-ui/icons/Videocam";

import backups from '../resources/backups';
// import lodColors from '../resources/lod/colors';
// import lodImages from '../resources/lod/images';
// import lodFoils from '../resources/lod/foils';
// import lodThickness from '../resources/lod/thickness';
// import lodMaterials from '../resources/lod/materials';
// import lodHoles from '../resources/lod/holes';
// import lodShapes from '../resources/lod/shapes';
// import lodPriceMatrix from '../resources/lod/price-matrix';
// import lodRawMaterialPrices from '../resources/lod/raw-material-prices';
// import lodHoleImageShape from '../resources/lod/hole-image-shape';
// import lodMaterialConfigs from '../resources/lod/material-configs';
// import lodPriceList from '../resources/lod/price-list';
// import lodPriceListFactors from '../resources/lod/price-list-factors';
// import lodWintyps from '../resources/lod/wintyps';
// import lodQuellwerk from '../resources/lod/quellwerk';
import mailTemplates from '../resources/mail-templates';
import orders from '../resources/orders';
import posts from '../resources/posts';
import modals from "../resources/modals";
import products from '../resources/products';
import translations from '../resources/translations';
import users from '../resources/users';
import offers from '../resources/offers';
import shippingDates from "../resources/shipping-dates"
import shippingCountries from "../resources/shipping-countries"
import visitorCountries from "../resources/visitor-countries"
//import permissionsResource from "../resources/permissions";
import vouchers from "../resources/vouchers";

// import cobCobots from "../resources/cob/cobots";
// import cobManufacturers from "../resources/cob/manufacturers";
// import cobTypes from "../resources/cob/types";
// import cobModels from "../resources/cob/models";
// import cobProducts from "../resources/cob/products";

import kdhFrames from "../resources/kdh/frames";
import kdhGrommets from "../resources/kdh/grommets";
import abandonedCarts from "../resources/abandoned-carts";

// import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import productReviews from "../resources/product-reviews";
import videos from "../resources/videos";
import videoPlaylists from "../resources/video-playlists";
import videoCategories from "../resources/video-categories";
// import gsFlows from "../resources/gs-flows";


import SubMenu from './SubMenu';
import {Colors} from "../styles/theme/colors";

type MenuName = 'menuLOD' | 'menuCOB' | 'menuSHIPPING' | 'menuKDH';

interface MenuItem {
    name: string;
    icon: any;
    items?: {
        name: string;
        icon: any;
    }[];
}

const Menu = ({dense = false}: MenuProps) => {
    const [state, setState] = useState({
        menuLOD: window.location.href.indexOf("lod") > -1,
        menuCOB: window.location.href.indexOf("cob") > -1,
        menuSHIPPING: window.location.href.indexOf('shipping') > -1,
        menuKDH: window.location.href.indexOf("kdh") > -1
    });
    const translate = useTranslate();
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    const classes = useStyles();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({...state, [menu]: !state[menu]}));
    };

    const {permissions} = usePermissions();

    const menu: MenuItem[] = [
        {name: 'visitor-countries', icon: <visitorCountries.icon/>},
        {name: 'product-reviews', icon: <productReviews.icon/>},
        {name: 'products', icon: <products.icon/>},
        {name: 'posts', icon: <posts.icon/>},
        {name: 'modals', icon: <modals.icon/>},
        {name: 'users', icon: <users.icon/>},
        // {name: 'permissions', icon: <permissionsResource.icon/>},
        {name: 'offers', icon: <offers.icon/>},
        {name: 'abandoned-carts', icon: <abandonedCarts.icon/>},
        {name: 'orders', icon: <orders.icon/>},
        {name: 'vouchers', icon: <vouchers.icon/>},
        // {
        //     name: 'lod', icon: <PermDataSettingIcon/>, items: [
        //         {name: 'lod-colors', icon: <lodColors.icon/>},
        //         {name: 'lod-foils', icon: <lodFoils.icon/>},
        //         {name: 'lod-thickness', icon: <lodThickness.icon/>},
        //         {name: 'lod-materials', icon: <lodMaterials.icon/>},
        //         {name: 'lod-holes', icon: <lodHoles.icon/>},
        //         {name: 'lod-shapes', icon: <lodShapes.icon/>},
        //         {name: 'lod-images', icon: <lodImages.icon/>},
        //         {name: 'lod-price-matrix', icon: <lodPriceMatrix.icon/>},
        //         {name: 'lod-price-list', icon: <lodPriceList.icon/>},
        //         {name: 'lod-price-list-factors', icon: <lodPriceListFactors.icon/>},
        //         {name: 'lod-quellwerk', icon: <lodQuellwerk.icon/>},
        //         {name: 'lod-raw-material-prices', icon: <lodRawMaterialPrices.icon/>},
        //         {name: 'lod-hole-image-shape', icon: <lodHoleImageShape.icon/>},
        //         {name: 'lod-material-configs', icon: <lodMaterialConfigs.icon/>},
        //         {name: 'lod-wintyps', icon: <lodWintyps.icon/>},
        //     ]
        // },
        // {
        //     name: 'cob', icon: <cobCobots.icon/>,
        //     items: [
        //         {name: 'cob-products', icon: <cobProducts.icon/>},
        //         {name: 'cob-cobots', icon: <cobCobots.icon/>},
        //         {name: 'cob-manufacturers', icon: <cobManufacturers.icon/>},
        //         {name: 'cob-types', icon: <cobTypes.icon/>},
        //         {name: 'cob-models', icon: <cobModels.icon/>},
        //     ]
        // },
        {
            name: 'kdh', icon: <kdhFrames.icon/>,
            items: [
                {name: 'kdh-frames', icon: <kdhFrames.icon/>},
                {name: 'kdh-grommets', icon: <kdhGrommets.icon/>},
            ]
        },
        {
            name: 'shipping', icon: <LocalShippingIcon/>,
            items: [
                {name: 'shipping-dates', icon: <shippingDates.icon/>},
                {name: 'shipping-countries', icon: <shippingCountries.icon/>},
            ]
        },
        {name: 'mail-templates', icon: <mailTemplates.icon/>},
        {name: 'translations', icon: <translations.icon/>},
        {name: 'backups', icon: <backups.icon/>},
        {
            name: 'videos', icon: <Videocam/>,
            items: [
                {name: 'videos', icon: <videos.icon/>},
                {name: 'video-playlists', icon: <videoPlaylists.icon/>},
                {name: 'video-categories', icon: <videoCategories.icon/>},
            ]
        },
        // {name: 'gs-flows', icon:<gsFlows.icon />}
    ].filter(el => {
        if (['sap', 'account-manager', 'location-manager'].includes(permissions) && el.name === 'users') return true;
        if (["location-manager", "country-manager"].includes(permissions) && ['offers', 'orders', 'product-reviews'].includes(el.name)) return true;
        if (permissions === 'content-manager' && ['mail-templates', 'translations', 'posts', 'modals', 'videos'].includes(el.name)) return true;
        return permissions === 'admin';
    });

    return (
        <div className={`${classes.root} ${open ? classes.open : classes.closed}`}>
            <DashboardMenuItem/>
            {menu.map((menuItem, i) => menuItem.items ? (
                <SubMenu
                    key={i}
                    handleToggle={() => handleToggle(`menu${menuItem.name.toUpperCase()}` as MenuName)}
                    isOpen={state[`menu${menuItem.name.toUpperCase()}` as MenuName]}
                    name={`resources.admin/${menuItem.name.toLowerCase()}.name`}
                    icon={menuItem.icon}
                    dense={dense}
                >
                    {menuItem.items.map((subMenuItem, j) => (
                        <MenuItemLink
                            key={j}
                            to={{
                                pathname: `/admin/${subMenuItem.name}`,
                                state: {_scrollToTop: true},
                            }}
                            primaryText={translate(`resources.admin/${subMenuItem.name}.name`, {
                                smart_count: 2,
                            })}
                            leftIcon={subMenuItem.icon}
                            dense={dense}
                            activeClassName={classes.activeMenuLink}
                        />
                    ))}
                </SubMenu>
            ) : (
                <MenuItemLink
                    key={i}
                    to={{
                        pathname: `/admin/${menuItem.name}`,
                        state: {_scrollToTop: true},
                    }}
                    primaryText={translate(`resources.admin/${menuItem.name}.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={menuItem.icon}
                    dense={dense}
                    activeClassName={classes.activeMenuLink}
                />
            ))}
            {permissions === 'admin' && <MenuItemLink
                to={{
                    pathname: `/admin/schedule-import`,
                    state: {_scrollToTop: true},
                }}
                primaryText="Schedule Import"
                dense={dense}
                leftIcon={<ScheduleIcon/>}
            />}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: '100%',
    },
    closed: {
        width: 55,
    },
    activeMenuLink: {
        backgroundColor: Colors.background.default,
        color: Colors.dark.main,
        boxShadow: `10px 0 0 ${Colors.primary.main} inset`,

        '&:hover': {
            backgroundColor: Colors.background.default,
            color: Colors.dark.main,
            boxShadow: `10px 0 0 ${Colors.primary.main} inset`,
        },

        '& svg': {
            fill: Colors.dark.main,
        }
    }
}));

export default Menu;
