import ListAltIcon from '@material-ui/icons/ListAlt';
import RawMaterialPriceList from './RawMaterialPriceList';

const resource = {
    list: RawMaterialPriceList,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/lod-raw-material-price.name'
    }
};

export default resource;
