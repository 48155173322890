import * as React from 'react';
import {FC} from 'react';
import {
    Create,
    CreateProps,
    SimpleForm,
    TextInput,
    useTranslate,
    PasswordInput,
    required,
    email,
    SelectInput
} from 'react-admin';
import {AnyObject} from 'react-final-form';
import Title from "../../components/list/Title";
import {filterProps} from "../../functions/common";
import {listEditCreate} from "../../styles/listEditCreate";
import countries from "../../config/countries";

export const validatePasswords = ({password, password_confirmation,}: AnyObject) => {
    const errors = {} as any;

    if (password && password_confirmation && password !== password_confirmation) {
        errors.password = [
            'resources.admin/users.errors.password_mismatch',
        ];
    }

    return errors;
};

const UserCreate: FC<CreateProps> = props => {
    const translate = useTranslate();
    const newProps = filterProps(props);
    const classes = listEditCreate();

    return (
        <Create {...newProps}>
            <SimpleForm validate={validatePasswords} className={classes.sidebar}>
                <Title label="resources.admin/users.fieldGroups.identity"/>
                <TextInput
                    fullWidth
                    autoFocus
                    source="first_name"
                    validate={requiredValidate}
                />
                <TextInput
                    fullWidth
                    source="last_name"
                    validate={requiredValidate}
                />

                <SelectInput fullWidth={true} source="country" choices={countries.map(el => ({id: el.code.toLowerCase(), name: el.name}))}/>

                <TextInput
                    source="kunnr"
                    resource="users"
                    fullWidth
                    helperText={false}
                />
                <TextInput
                    source="parnr"
                    resource="users"
                    fullWidth
                    helperText={false}
                />

                <TextInput
                    type="text"
                    source="taxnumber"
                    resource="users"
                    fullWidth
                    helperText={false}
                />

                <TextInput
                    type="email"
                    source="email"
                    validation={{email: true}}
                    fullWidth
                    validate={[required(), email()]}
                />
                <SelectInput validate={[required()]} source="role" choices={[
                    {id: 'admin', name: translate('resources.admin/users.roleOptions.admin')},
                    {id: 'location-manager', name: translate('resources.admin/users.roleOptions.location-manager')},
                    {id: 'customer', name: translate('resources.admin/users.roleOptions.customer')},
                    {id: 'catalog-customer', name: translate('resources.admin/users.roleOptions.catalog-customer')},
                    {id: 'sap', name: translate('resources.admin/users.roleOptions.sap')},
                    {id: 'sales-manager', name: translate('resources.admin/users.roleOptions.sales-manager')},
                    {id: 'sales-professional', name: translate('resources.admin/users.roleOptions.sales-professional')},
                    {id: 'content-manager', name: translate('resources.admin/users.roleOptions.content-manager')},
                    {id: 'partner', name: translate('resources.admin/users.roleOptions.partner')},
                    {id: 'account-manager', name: translate('resources.admin/users.roleOptions.account-manager')},
                ]}/>
                <Title label="resources.admin/users.fieldGroups.password"/>
                <PasswordInput
                    fullWidth
                    source="password"
                />
                <PasswordInput
                    fullWidth
                    source="password_confirmation"
                />
            </SimpleForm>
        </Create>
    );
};

const requiredValidate = [required()];

export default UserCreate;
