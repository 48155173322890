import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const customBreakpointValues = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xlg: 1480,
        xl: 1920,
        miniCart: 1820,
        container: 1450,
    },
}

export const breakPoints = createBreakpoints({ ...customBreakpointValues });

export const filterProps = (props: any) => {
    return Object.keys(props)
        .filter((key) => key !== 'syncWithLocation')
        .reduce((cur, key) => {
            return Object.assign(cur, {[key]: props[key]})
        }, {});
}

export const renderElement = (children: any) => children;

export const handleError = (error: any) => {

    if(typeof error === 'undefined') return 'Unknown error!';
    if(typeof error === 'string') return `${error}`;

    const {message, errors} = error.response?.data?? {};
    const out:string[] = [];
    if(typeof errors === 'object') {
        Object.keys(errors).forEach(el => out.push(errors[el].pop()));
        return out.join(", ");
    }

    const errMessage = error?.message??null;
    if(errMessage!==null) return errMessage;

    return message?message:'Unknown error!';
}

export const hexToRgba = (hex: any, opacity: any) => {
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity??1})`;
};
