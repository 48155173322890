import {Card, Box, TableContainer, Table, TableCell, TableRow, TableBody} from '@material-ui/core';
import {useEffect, useState} from "react";
import {useTranslate} from "react-admin";

import {dashboard} from '../../styles/dashboard';
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosForApi from "../../config/axios-for-api";
import StatHeader from "./StatHeader";

interface StatData {
    nr_lost_orders: number;
    nr_abandoned_carts: number;
    nr_order_from_abandoned_carts: number;
    recover_rate: number;
    recover_rate_fmt: string;
}

const Loading = ({classes}: any) => {
    return (
        <Card className={classes.card}>
            <Box p={2} display={'flex'} justifyContent={'center'} alignItems={'center'} height={200}>
                <CircularProgress/>
            </Box>
        </Card>
    );
}

const TopProductsByRevenueThisYear = ({countries}: { countries: string[] | null }) => {
    const classes = dashboard();
    const translate = useTranslate();

    const [data, setData] = useState<StatData>();
    const [shouldLoadStatsData, setShouldLoadStatsData] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let cancel = false;
        const loadStatsData = async () => {
            const {data} = await axiosForApi.get('api/admin/stats/abandoned-carts', {
                params: {
                    countries
                }
            });

            if (!cancel) {
                setData(data);
                setLoading(false);
                setShouldLoadStatsData(false);
            }
        }

        shouldLoadStatsData && loadStatsData();

        return () => {
            cancel = true;
        }
    }, [shouldLoadStatsData, countries]);

     useEffect(() => {
        setLoading(true);
        setShouldLoadStatsData(true);
    }, [countries]);

    if (loading) return (<Loading classes={classes}/>);

    return (
        <Card className={classes.card}>
            <Box p={2}>
                <StatHeader
                    justifyContent={'center'}
                    title={translate('resources.admin/dashboard.headers.abandoned-carts')}
                    help={'resources.admin/dashboard.help.abandoned-carts'}
                />
                {!data && (
                    <div style={{height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{color: '#c00'}}>No data available</span>
                    </div>
                )}
                {data && (
                    <div className='scrollable' style={{height: '300px', overflowY: 'scroll', overflowX: 'hidden'}}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {translate('resources.admin/dashboard.headers.nr_abandoned_carts')}
                                        </TableCell>
                                        <TableCell>
                                            {data.nr_abandoned_carts}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            {translate('resources.admin/dashboard.headers.nr_lost_orders')}
                                        </TableCell>
                                        <TableCell>
                                            {data.nr_lost_orders}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            {translate('resources.admin/dashboard.headers.nr_orders_from_abandoned_carts')}
                                        </TableCell>
                                        <TableCell>
                                            {data.nr_order_from_abandoned_carts}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            {translate('resources.admin/dashboard.headers.recover_rate')}
                                        </TableCell>
                                        <TableCell>
                                            {data.recover_rate_fmt}
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </Box>
        </Card>
    );
}

export default TopProductsByRevenueThisYear;
