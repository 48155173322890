import React from 'react';
import {Filter, FilterProps} from 'react-admin';

const ShippingDateFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <></>
    </Filter>
);

export default ShippingDateFilter;
