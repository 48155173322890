import React from 'react';
import {Filter, FilterProps, ReferenceInput, SelectInput} from 'react-admin';

const CobotFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <ReferenceInput alwaysOn label="resources.admin/cob-cobots.fields.manufacturer" source={"cob_manufacturer_id"} reference="admin/cob-manufacturers" sort={{field: 'name', order: 'ASC'}}
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput alwaysOn label="resources.admin/cob-cobots.fields.type" source={"cob_type_id"} reference="admin/cob-types" sort={{field: 'name', order: 'ASC'}}
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput alwaysOn label="resources.admin/cob-cobots.fields.model" source={"cob_model_id"} reference="admin/cob-models" sort={{field: 'name', order: 'ASC'}}
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <SelectInput alwaysOn source="status" choices={[
            {id: 'publish', name: 'Publish'},
            {id: 'draft', name: 'Draft'},
        ]}/>
    </Filter>
);

export default CobotFilter;
