import {useCreate, useCreateSuggestionContext, useTranslate} from "react-admin";
import {useState} from "react";

import {Dialog, DialogContent, DialogActions, Button as MUIButton, TextField as MUITextField} from '@material-ui/core';

const CobotTypeCreate = (props: any) => {
    const {filter, onCancel, onCreate} = useCreateSuggestionContext();
    const [value, setValue] = useState(filter || '');
    const [create] = useCreate('admin/cob-types');

    const form = props.form;
    const translate = useTranslate();

    const handleSubmit = (event: any) => {
        event.preventDefault();
        create(
            {
                payload: {
                    data: {
                        cob_manufacturer_id: props.cob_manufacturer_id,
                        name: value
                    },
                },
            },
            {
                onSuccess: ({data}: any) => {
                    setValue('');

                    const choice = {
                        id: data.id,
                        name: data.name,
                        manufacturer_id: data.cob_manufacturer_id
                    }

                    onCreate(data);

                    form.batch(() => {
                        form.change('cob_type_id', data.id);
                        form.change('cob_model_id', null);
                    });

                    props.setChoicesData((oldChoicesData: any) => {
                        const newChoices = Object.assign({}, oldChoicesData);
                        newChoices.typeChoices = [...newChoices.typeChoices, choice];
                        return newChoices;
                    });
                },
            }
        );
    }

    return (
        <Dialog open={true} onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <MUITextField
                        label={translate('resources.admin/cob-cobots.fields.type')}
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <MUIButton type="submit">Save</MUIButton>
                    <MUIButton onClick={onCancel}>Cancel</MUIButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CobotTypeCreate;
