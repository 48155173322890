import React from 'react';
import {Filter, FilterProps, SelectInput, TextInput } from 'react-admin';

import {groups} from "./Groups";
const ProductFilter = (props: Omit<FilterProps, 'children'>) => {
    return (
        <Filter {...props}>
            <SelectInput source="group" choices={groups} alwaysOn />
            <TextInput source="matnr" alwaysOn />
        </Filter>
    );
}

export default ProductFilter;
