import React from 'react';
import {Filter, FilterProps, SelectInput} from 'react-admin';

const VoucherFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SelectInput alwaysOn source="status" emptyText="resources.admin/vouchers.statusOptions.all"
                     choices={[
                         {id: 'pending', name: 'resources.admin/vouchers.statusOptions.pending'},
                         {id: 'sent', name: 'resources.admin/vouchers.statusOptions.sent'},
                         {id: 'expired', name: 'resources.admin/vouchers.statusOptions.expired'},
                     ]}/>
    </Filter>
);

export default VoucherFilter;
