import {useLocation} from "react-router-dom";
import {Field, Form} from 'react-final-form';
import {useRedirect, useTranslate} from "react-admin";
import {Box, Button, CardActions, CircularProgress, TextField, Typography} from "@material-ui/core";

import {loginForm} from "../styles/ui/loginForm";
import IconEyeOpen from '../assets/icons/eye-open-white.svg';
import IconEyeClosed from '../assets/icons/eye-closed-white.svg';
import {useNotify, useSafeSetState} from "ra-core";
import axiosForApi from "../config/axios-for-api";
import {handleError} from "../functions/common";
import React from "react";

type TFormData = {
    new_password: string;
    repeat_password: string;
}

const Input = ({
                   // @ts-ignore
                   meta: {touched, error}, // eslint-disable-line react/prop-types
                   // @ts-ignore
                   input: inputProps, // eslint-disable-line react/prop-types
                   ...props
               }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const ResetPassword = () => {
    const [loading, setLoading] = useSafeSetState(false);
    const [showNewPassword, setShowNewPassword] = useSafeSetState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useSafeSetState(false);
    const [passwordChanged, setPasswordChanged] = useSafeSetState(false);

    const redirect = useRedirect();
    const notify = useNotify();
    const classes = loginForm();
    const translate = useTranslate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const email = searchParams.get("email");

    const submit = (values: TFormData) => {
        setLoading(true);

        axiosForApi.post("/reset-password", {
            email,
            token,
            password: values.new_password,
            password_confirmation: values.repeat_password
        }).then(response => {
            setPasswordChanged(true);
        }).catch(error => {
            notify(handleError(error), 'error');
        }).finally(() => {
            setLoading(false);
        });
    }

    const validate = (values: TFormData) => {
        const errors: { new_password?: string, repeat_password?: string } = {new_password: undefined, repeat_password: undefined};

        if (!values.new_password) {
            errors.new_password = translate('ra.validation.required');
        }

        if (!values.repeat_password) {
            errors.repeat_password = translate('ra.validation.required');
        }

        return errors;
    };

    if (passwordChanged) return (
        <>
            <Typography className={classes.title} variant="h5">
                {translate('resetPassword.successTitle')}
            </Typography>

            <Typography
                variant="body2"
                className={`${classes.text}`}
                dangerouslySetInnerHTML={{__html:translate('resetPassword.successMessage')}}
            />

            <div className={classes.actions}>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={loading}
                    className={classes.button}
                    onClick={() => redirect('/login')}
                >
                    {loading && (
                        <CircularProgress
                            className={classes.icon}
                            size={18}
                            thickness={2}
                        />
                    )}
                    {translate('resetPassword.loginLink')}
                </Button>
            </div>
        </>
    );

    return (
        <Form
            onSubmit={submit}
            validate={validate}
            render={({handleSubmit}) => (
                <form onSubmit={handleSubmit} noValidate>
                    <div className={classes.form}>
                        <Typography className={classes.title} variant="h5">
                            {translate('resetPassword.title')}
                        </Typography>

                        <div className={classes.input}>
                            <Field
                                id="new_password"
                                name="new_password"
                                component={Input}
                                label={`${translate('resetPassword.newPassword')}*`}
                                type={showNewPassword ? 'text' : 'password'}
                                disabled={loading}
                                autoComplete="current-password"
                                InputProps={{
                                    endAdornment: (
                                        <Box
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                            style={{marginRight: 10, display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                                        >
                                            <img
                                                alt="toggle-password-visibility"
                                                width={21}
                                                height={21}
                                                src={showNewPassword ? IconEyeClosed : IconEyeOpen}
                                            />
                                        </Box>
                                    )
                                }}
                            />
                        </div>

                        <div className={classes.input}>
                            <Field
                                id="repeat_password"
                                name="repeat_password"
                                component={Input}
                                label={`${translate('resetPassword.repeatPassword')}*`}
                                type={showNewPassword ? 'text' : 'password'}
                                disabled={loading}
                                autoComplete="current-password"
                                InputProps={{
                                    endAdornment: (
                                        <Box
                                            onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                            style={{marginRight: 10, display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                                        >
                                            <img
                                                alt="toggle-password-visibility"
                                                width={21}
                                                height={21}
                                                src={showRepeatPassword ? IconEyeClosed : IconEyeOpen}
                                            />
                                        </Box>
                                    )
                                }}
                            />
                        </div>

                    </div>
                    <CardActions className={classes.actions}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className={classes.button}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('resetPassword.submit')}
                        </Button>
                    </CardActions>
                </form>
            )}/>);

}

export default ResetPassword;
