import React from 'react';
import {
    Filter,
    FilterProps,
    SelectInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';

const OfferFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <ReferenceInput label="resources.admin/orders.fields.customer_name_or_email" source="customer_id" reference="admin/users" alwaysOn options={{
            suggestionsContainerProps: {
                disablePortal: true,
            }
        }}>
            <AutocompleteInput optionText="name_and_email" />
        </ReferenceInput>
        <SelectInput alwaysOn source="status" choices={[
            {id: 'processed', name: "resources.admin/offers.status_options.processed"},
            {id: 'active', name: "resources.admin/offers.status_options.active"},
            {id: 'expired', name: "resources.admin/offers.status_options.expired"},
        ]}/>
         <SelectInput alwaysOn source="is_catalog_offer" label="resources.admin/offers.fields.offer_type" choices={[
            {id: 'all', name: "resources.admin/offers.is_catalog_offer_options.all"},
            {id: 'no', name: "resources.admin/offers.is_catalog_offer_options.no"},
            {id: 'yes', name: "resources.admin/offers.is_catalog_offer_options.yes"},
        ]}/>
    </Filter>
);

export default OfferFilter;
