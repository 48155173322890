import Icon from '@material-ui/icons/Image';
import ImageList from './ImageList';

const resource = {
    list: ImageList,
    icon: Icon,
    options: {
        label: 'resources.admin/lod-images.name'
    }
};

export default resource;
