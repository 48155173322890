import React from 'react';
import {Filter, FilterProps, ReferenceInput, SelectInput, TextInput} from 'react-admin';

const PriceMatrixFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput source="code" alwaysOn/>
        <ReferenceInput label="resources.admin/lod-raw-material-prices.fields.material" source="material_id" reference="admin/lod-materials" alwaysOn options={{
            suggestionsContainerProps: {
                disablePortal: true,
            }
        }}>
            <SelectInput optionText="name_lt"/>
        </ReferenceInput>
        <ReferenceInput label="resources.admin/lod-raw-material-prices.fields.thickness" source="thickness_id" reference="admin/lod-thickness" alwaysOn options={{
            suggestionsContainerProps: {
                disablePortal: true,
            }
        }}>
            <SelectInput optionText="value"/>
        </ReferenceInput>
        <ReferenceInput label="resources.admin/lod-raw-material-prices.fields.color" source="color_id" reference="admin/lod-colors" alwaysOn options={{
            suggestionsContainerProps: {
                disablePortal: true,
            }
        }}>
            <SelectInput optionText="name_lt"/>
        </ReferenceInput>
        <ReferenceInput label="resources.admin/lod-raw-material-prices.fields.foil" source="foil_id" reference="admin/lod-foils" alwaysOn options={{
            suggestionsContainerProps: {
                disablePortal: true,
            }
        }}>
            <SelectInput optionText="name_lt"/>
        </ReferenceInput>
    </Filter>
);

export default PriceMatrixFilter;
