import ListAltIcon from '@material-ui/icons/ListAlt';
import ShippingCountryList from './ShippingCountryList';
import ShippingCountryCreate from "./ShippingCountryCreate";

const resource = {
    list: ShippingCountryList,
    create: ShippingCountryCreate,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/shipping-countries.name'
    }
};

export default resource;
