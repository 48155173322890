import * as React from 'react';
import {Datagrid, List, ListProps, ShowButton, DateField, useLocale, BooleanField} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import TextField from "../../components/list/TextField";
import ProductFilter from "./ProductFilter";
import {Route} from "react-router";
import SideDrawer from "../../components/list/SideDrawer";
import ShowOnShop from "../../components/list/ShowOnShop";
import ProductShow from "./ProductShow";

const ProductList = (props: ListProps): ReactElement => {
    const locale = useLocale();
    return (
        <Fragment>
            <List {...props} filters={<ProductFilter />} sort={{field:'id', order:'DESC'}} >
                <Datagrid rowClick="edit">
                    <TextField source="id" />
                    <BooleanField source="top_seller" looseValue={true} />
                    <DateField source="created_at" locales={locale} />
                    <ShowOnShop source={'product'} />
                    <ShowButton />
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''} size={900}>
                            {isMatch && (
                                <ProductShow id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default ProductList;
