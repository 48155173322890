import * as React from 'react';
import {Datagrid, List, ListProps, ShowButton, useTranslate} from 'react-admin';
import {Fragment, ReactElement} from 'react';

import VoucherFilter from "./VoucherFilter";
import TextField from "../../components/list/TextField";
import {Typography} from "@material-ui/core";
import {Route} from "react-router";
import SideDrawer from "../../components/list/SideDrawer";
import VoucherShow from "./VoucherShow";

const VoucherList = (props: ListProps): ReactElement => {
    const translate = useTranslate();

    return (
        <Fragment>
            <Typography variant={'h6'}>{translate('resources.admin/vouchers.name')}</Typography>
            <List {...props} filters={<VoucherFilter/>} sort={{field: 'id', order: 'DESC'}}>
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="promotion" />
                    <TextField source="created" />
                    <TextField source={'status'} sortable={false} />
                    <TextField source={'code'}/>
                    <TextField source={'sent_to_email'}/>
                    <TextField source={'usage'}/>
                    <ShowButton />
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''} size={900}>
                            {isMatch && (
                                <VoucherShow id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default VoucherList;
