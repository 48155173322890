import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, SimpleForm, TextInput} from 'react-admin';

import Title from '../../components/list/Title';
import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps} from "../../functions/common";

const ShippingCountryEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit{...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="code" label="resources.admin/shipping-countries.titles.edit"/>
                <TextInput source='code' label={'resources.admin/shipping-countries.fields.code'} />
            </SimpleForm>
        </Edit>
    );
};

export default ShippingCountryEdit;
