import ModalIcon from '@material-ui/icons/BrandingWatermarkOutlined';
import ModalList from './ModalList';
import ModalCreate from "./ModalCreate";

const resource = {
    list: ModalList,
    create: ModalCreate,
    icon: ModalIcon,
    options: {
        label: 'resources.admin/modals.name'
    }
};

export default resource;
