import * as React from 'react';
import {Datagrid, List, ListProps, EditButton } from 'react-admin';
import {Fragment, ReactElement} from 'react';
import { Route } from 'react-router';

import ImageFilter from "./ImageFilter";
import ImageCreate from "./ImageCreate";
import ImageEdit from "./ImageEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";
import {listEditCreate} from "../../../styles/listEditCreate";
import ImageField from "../../../components/list/ImageField";

const ImageList = (props: ListProps): ReactElement => {
    const classes = listEditCreate();

    return (
        <Fragment>
            <List {...props} filters={<ImageFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="id" />
                    <ImageField source="file_path" className={classes.thumbnail} />
                    <TextField source="name" />
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/create`}>
                {({ match }) => {
                    return (
                        <SideDrawer open={!!match} basePath={props.basePath??''}>
                            <ImageCreate {...props} />
                        </SideDrawer>
                    )
                }}
            </Route>
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''}>
                            {isMatch && (
                                <ImageEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default ImageList;
