import {Colors} from "../styles/theme/colors";

const generalTheme = {
    palette: {
        primary: {
            main: '#002536',
        },
        secondary: {
            light: '#002536',
            main: '#002536',
            dark: '#002536',
            contrastText: '#fff',
        },
        background: {
            default: '#F5F2F3',
        },
        type: 'light' as 'light',
    },
    shape: {
        borderRadius: 10,
    },
    overrides: {
        RaMenuItemLink: {
            root: {
                color: Colors.white.main,
            },
            active: {
                backgroundColor: Colors.dark.main,
                color: `${Colors.white.main} !important`,
                '&:hover': {
                    backgroundColor: Colors.dark.main,
                    color: `${Colors.white.main} !important`,
                }
            },
            icon: {
                color: `${Colors.white.main} !important`,
            },
        },
        RaLayout: {
          content: {
              padding: '1rem !important'
          },
        },
        MuiDrawer: {
            root: {
                backgroundColor: Colors.dark.main,
                color: Colors.white.main,
                margin: '15px',
                borderRadius: '6px',
            }
        },
        MuiPaper: {
            elevation1: {
                boxShadow: 'none',
            },
            root: {
                border: '1px solid #e0e0e3',
                backgroundClip: 'padding-box',
            },
        },
        MuiButton: {
            root: {
                minWidth: '40px'
            },
            contained: {
                backgroundColor: Colors.dark.main,
                boxShadow: 'none',
                color: Colors.white.main,

                '&:hover': {
                    backgroundColor: Colors.dark.accent,
                }
            },
            containedPrimary: {
                backgroundColor: Colors.primary.main,

                '&:hover': {
                    backgroundColor: Colors.primary.accent,
                }
            },
        },
        MuiInputBase: {
            root: {
                borderRadius: '5px',
                '&.Mui-error': {

                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 2
                    }
                }
            },
            input: {
                borderRadius: '5px',
                '&.Mui-error': {

                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 2
                    }
                }
            },
        },
        MuiButtonBase: {
            root: {
                '&:hover:active::after': {
                    content: '""',
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.3,
                    borderRadius: 'inherit',
                },
                '&.Mui-error': {

                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 2
                    }
                }
            },
        },
        MuiTableCell: {
            sizeSmall: {
                padding: '12px 24px 12px 16px',
            }
        },
        MuiAppBar: {
            root: {
                '& .MuiCardMedia-root': {
                    marginLeft: '0'
                },
                '& .MuiButtonBase-root': {
                    marginLeft: '20px'
                },
            },
            colorSecondary: {
                color: '#808080',
                backgroundColor: '#fff',
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: '#f5f5f5',
            },
            barColorPrimary: {
                backgroundColor: '#d7d7d7',
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                borderRadius: '6px !important',
                '&$disabled': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                '&:hover': {
                    backgroundColor: '#fff !important',
                    border: '1px solid #aaa',
                },
                '&.Mui-focused': {
                    backgroundColor: '#fff !important',
                    border: '1px solid #aaa',
                },
                '&:before': {
                    display: 'none',
                },
                '&:after': {
                    display: 'none',
                },
            },
        },
        MuiSnackbarContent: {
            root: {
                border: 'none',
            },
        },
    },
    props: {
        MuiButtonBase: {
            // disable ripple for perf reasons
            disableRipple: true,
        },
    },
}

export const darkTheme = generalTheme;
export const lightTheme = generalTheme;