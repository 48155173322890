import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, TextInput, SimpleForm, SelectInput} from 'react-admin';

import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";
import grommetMaterialOptions from "./grommetMaterialOptions";
import grommetTypes from "./grommetTypes";

const GrommetEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <TextInput autoFocus source="matnr" fullWidth={true}/>
                <TextInput source="name" disabled readonly fullWidth/>
                <TextInput source="category" disabled readonly fullWidth/>
                <SelectInput source="type" choices={grommetTypes.map(el => ({id: el, name: el}))}/>
                <SelectInput source="material" choices={grommetMaterialOptions.map(el => ({id: el, name: el}))}/>
            </SimpleForm>
        </Edit>
    );
};

export default GrommetEdit;
