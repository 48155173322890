import { makeStyles } from "@material-ui/styles";
import {Colors} from "../theme/colors";

export const loadMask = makeStyles({
    default: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: Colors.background.default,
        zIndex: 1,
    },
    fullscreen: {
        height: '100vh',
        position: 'fixed',
        bottom: 'initial',
        zIndex: 10000 + 20,
    },
    transparent: {
        backgroundColor: 'rgba(255,255,255,0.5)',
    },
    alignTop: {
        alignItems: 'flex-start',
        paddingTop: '200px'
    },
});