import Icon from '@material-ui/icons/StarHalf';
import ProductReviewList from './ProductReviewList';
import ProductReviewCreate from "./ProductReviewCreate";

const resource = {
    list: ProductReviewList,
    create: ProductReviewCreate,
    icon: Icon,
    options: {
        label: 'resources.admin/product-reviews.name'
    }
};

export default resource;
