import ColorLensIcon from '@material-ui/icons/ColorLens';
import ColorList from './ColorList';

const resource = {
    list: ColorList,
    icon: ColorLensIcon,
    options: {
        label: 'resources.admin/lod-colors.name'
    }
};

export default resource;
