import React, {FC, useState} from 'react';

import {FieldProps} from "react-admin";
import Box from "@material-ui/core/Box";
import IconButton from '@material-ui/core/IconButton';
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";


const InfoPopup: FC<FieldProps & {text?:string}> = ({text}) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // @ts-ignore
    return text ? (
        <Box>
            <IconButton onClick={handleClick} aria-describedby={id} >
                <HelpOutlineIcon fontSize="small" />
            </IconButton>
            <Popover
                id="mouse-over-popover"
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleClose}
                disableRestoreFocus
            >
                <Typography variant="body2" style={{padding: '1rem'}}>
                    {text}
                </Typography>
            </Popover>
        </Box>
    ) : null;
}

export default InfoPopup;
