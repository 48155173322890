import * as React from 'react';
import { FC, createElement } from 'react';
import { Card, Box, Typography, Divider, CircularProgress, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactNode } from 'react';
import {dashboard} from '../../styles/dashboard';
import {useTranslate} from "react-admin";
import VisibilityIcon from '@material-ui/icons/Visibility';

interface Props {
    icon: FC<any>;
    to: string;
    title?: string;
    subtitle?: string | number;
    children?: ReactNode;
    showButton?: boolean;
}

const CardWithIcon = (props: Props) => {
    const { icon, title, subtitle, to, children, showButton } = props;
    const classes = dashboard();
    const translate = useTranslate();

    return (
        <Card className={classes.card}>
            <Link to={to}>
                <div className={classes.cardMain}>
                    <Box width="3em" className="icon">
                        {createElement(icon, { fontSize: 'large' })}
                    </Box>
                    <Box textAlign="right">
                        <Typography className={classes.cardTitle} color="textSecondary">
                            {title}
                        </Typography>
                        <Typography variant="h5" component="h2">
                            {subtitle || <CircularProgress size={20} />}
                        </Typography>
                    </Box>
                </div>
            </Link>
            {children && <Divider />}
            {children}
            {showButton && (
                <Box p={2} display={'flex'} justifyContent={'flex-end'}>
                    <Link to={to}>
                        <Button variant={'contained'} color={'primary'}>
                            <Box mr={1} display={'inline-flex'}>
                                <VisibilityIcon fontSize={'small'} />
                            </Box>
                            {translate('resources.admin/dashboard.see_all')}
                        </Button>
                    </Link>
                </Box>
            )}
        </Card>
    );
};

export default CardWithIcon;
