import * as React from 'react';
import {FC} from 'react';
import {BooleanInput, Create, CreateProps, SelectInput, SimpleForm, TextInput, TranslatableInputs, useTranslate} from 'react-admin';
import {Typography} from "@material-ui/core";

import {filterProps} from "../../functions/common";
import cfgLocales from '../../config/locale';
import {listEditCreate} from "../../styles/listEditCreate";

const VisitorCountryCreate: FC<CreateProps> = props => {
     const classes = listEditCreate();
    const newProps = filterProps(props);
    const translate = useTranslate();

    return (
        <>
            <Typography variant='h6'>
                {translate("resources.admin/visitor-countries.titles.create")}
            </Typography>
            <Create {...newProps}>
                <SimpleForm>
                    <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                        <TextInput source="name" fullWidth={true}/>
                    </TranslatableInputs>
                    <TextInput source='code' label={'resources.admin/visitor-countries.fields.code'}/>
                    <TextInput source='vkorg' label={'resources.admin/visitor-countries.fields.vkorg'}/>
                    <SelectInput source="default_language" choices={Object.keys(cfgLocales.languageOptions).map(el => ({id: el, name: el}))}/>
                    <>
                        <Typography variant='h6' className={classes.title}>
                            {translate("resources.admin/visitor-countries.titles.catalog")}
                        </Typography>
                    </>
                    <BooleanInput source='is_catalog_only' label={'resources.admin/visitor-countries.fields.is_catalog_only'}/>
                    <TextInput source='catalog_price_group' label={'resources.admin/visitor-countries.fields.catalog_price_group'}/>
                    <BooleanInput source='catalog_show_prices' label={'resources.admin/visitor-countries.fields.catalog_show_prices'}/>
                    <TextInput source='catalog_currency' label={'resources.admin/visitor-countries.fields.catalog_currency'}/>
                    <TextInput source='catalog_delivery_price' label={'resources.admin/visitor-countries.fields.catalog_delivery_price'}/>
                    <TextInput source='catalog_vat_percentage' label={'resources.admin/visitor-countries.fields.catalog_vat_percentage'}/>
                </SimpleForm>
            </Create>
        </>
    );
};

export default VisitorCountryCreate;
