import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, TextInput, DateInput} from 'react-admin';

import Title from "../../../components/list/Title";
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const PriceListCreate: FC<CreateProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <Title label="resources.admin/lod-price-list.titles.create" />

                <TextInput source="preisliste" fullWidth={true} />
                <TextInput source="betrag" fullWidth={true} />
                <TextInput source="wahrung" />
                <TextInput source="preiseinheit" />
                <DateInput source="gultig_bis" locales={'de'} />
            </SimpleForm>
        </Create>
    );
};

export default PriceListCreate;
