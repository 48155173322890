import * as React from 'react';
import {FC, Fragment, useState, useEffect} from 'react';
import {
    Edit,
    EditProps,
    TextInput,
    FormWithRedirect,
    TranslatableInputs,
    SelectInput, useTranslate,
    Toolbar,
    FieldProps,
    BooleanInput, AutocompleteArrayInput, ImageInput, ImageField,
    DateInput
} from 'react-admin';
import {Typography, Grid, Box} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import {filterProps} from "../../functions/common";
import cfgLocales from '../../config/locale';
import {Post} from "../../config/types";
import countries from "../../config/countries";
import InfoPopup from "../../components/global/InfoPopup";

const ModalEdit: FC<EditProps> = props => {

    const [errorMessage, setErrorMessage] = useState<string>('');

    const newProps = filterProps(props);

    return (
        <Edit
            {...newProps}
            undoable={false}
            onFailure={(error) => setErrorMessage(error)}
        >
            <ModalForm errorMessage={errorMessage}/>
        </Edit>

    );
};

const Title: FC<FieldProps<Post>> = ({record}) => {
    const translate = useTranslate();
    return record ? (<Typography variant='h6'><b>{translate('resources.admin/modals.titles.edit_modal', {id: `${record.id}`})}</b></Typography>) : null;
}

const InputWithHelp = ({customBoxStyle, customInnerContainerStyle, source, label, help, children}: any) => {
    const translate = useTranslate();
    return (
        <Box style={customBoxStyle ?? {width: '100%', display: 'flex', justifyContent: 'space-between'}}>
            {children}
            <Box style={customInnerContainerStyle ?? {paddingTop: '0.5rem'}}>
                <InfoPopup text={translate(help)}/>
            </Box>
        </Box>);
}

const TextInputWithHelp = (props: any) => {
    const {source, label, ...other} = props;
    return (
        <InputWithHelp {...other}>
            <TextInput source={source} label={label} fullWidth={true}/>
        </InputWithHelp>
    );
};

const BooleanInputWithHelp = (props: any) => {
    const {source, label, ...other} = props;
    return (
        <InputWithHelp {...other}>
            <BooleanInput source={source} label={label} fullWidth={true}/>
        </InputWithHelp>
    );
};

const ImageInputWithHelp = (props: any) => {
    const {source, label, ...other} = props;
    return (
        <InputWithHelp {...other}>
            <ImageInput source={source} labelSingle={label} multiple={false} accept="image/*">
                {<ImageField source="src" title="caption"/>}
            </ImageInput>
        </InputWithHelp>
    );
};

const SelectInputWithHelp = (props: any) => {
    const {source, label, choices, ...other} = props;
    return (
        <InputWithHelp {...other}>
            <SelectInput label={label} fullWidth allowEmpty={true} emptyText={'All'} source={source} choices={choices}/>
        </InputWithHelp>
    );
}


const ModalForm = (props: any) => {

    const translate = useTranslate();

    const errorMessage = props.errorMessage;
    const newProps = filterProps(props);
    const [showCoupon, setShowCoupon] = useState<boolean>(false);
    const [showNewsletter, setShowNewsletter] = useState<boolean>(false);

    const toggleTemplate = (template: string = '') => {
        setShowNewsletter(false);
        setShowCoupon(false);
        switch (template) {
            case 'newsletter':
                setShowNewsletter(true);
                break;
            case 'coupon_code':
                setShowCoupon(true);
                break;
        }
    }

    useEffect(() => {
        toggleTemplate(props?.record?.template ?? '');
    }, [props.record.template]);

    const getTimeOptions = (length: number): { id: string, name: string }[] => Array.from({length}, (_, i) => {
        const val = i.toString().padStart(2, '0');
        return {id: val, name: val};
    });

    return (
        <FormWithRedirect
            {...newProps}
            render={(formProps: any) => (
                <Fragment>
                    <Grid container spacing={3} style={{padding: "1rem"}}>
                        <Grid item xs={12} style={{paddingBottom: 0}}>
                            <Title record={formProps.record}/>
                        </Grid>
                        <Grid item xs={12} style={{display: 'flex', paddingBottom: 0}}>
                            <SelectInput
                                fullWidth
                                source="template"
                                choices={[
                                    {id: 'default', name: translate('resources.admin/modals.templateOptions.default')},
                                    {id: 'newsletter', name: translate('resources.admin/modals.templateOptions.newsletter')},
                                    {id: 'coupon_code', name: translate('resources.admin/modals.templateOptions.coupon_code')},
                                    {id: 'service_notification', name: translate('resources.admin/modals.templateOptions.service_notification')},
                                ]}
                                onChange={(e) => toggleTemplate(e.target.value)}
                            />
                            <Box style={{paddingTop: '0.5rem'}}>
                                <InfoPopup text={translate('resources.admin/modals.templateOptions.info')}/>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} style={{display: 'flex', paddingBottom: 0}}>
                            <SelectInput fullWidth source="status" choices={[
                                {id: 'draft', name: translate('resources.admin/posts.statusOptions.draft')},
                                {id: 'publish', name: translate('resources.admin/posts.statusOptions.publish')},
                            ]}/>
                            <Box style={{paddingTop: '0.5rem'}}>
                                <InfoPopup text={translate('resources.admin/modals.statusOptions.info')}/>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} style={{display: 'flex', paddingBottom: 0, gap:'10px'}} >
                            <DateInput source="available_from_date"/>
                            <SelectInput source="available_from_hh" choices={getTimeOptions(24)}/>
                            <SelectInput source="available_from_mm" choices={getTimeOptions(60)}/>
                            <Box style={{paddingTop: '0.5rem'}}>
                                <InfoPopup text={translate('resources.admin/modals.date.available_from_info')}/>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} style={{display: 'flex', paddingBottom: 0, gap:'10px'}}>
                            <DateInput source="available_to_date"/>
                            <SelectInput source="available_to_hh" choices={getTimeOptions(24)}/>
                            <SelectInput source="available_to_mm" choices={getTimeOptions(60)}/>
                            <Box style={{paddingTop: '0.5rem'}}>
                                <InfoPopup text={translate('resources.admin/modals.date.available_to_info')}/>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>

                                <TextInput autoFocus source="title" fullWidth={true} multiline={true}/>
                                <TextInput source='meta.subtitle' fullWidth={true} multiline={true}/>
                                <TextInput source="content" fullWidth={true} multiline={true}/>
                                <TextInputWithHelp source='meta.button_cta_url' label='resources.admin/modals.fields.button_cta_url' help='resources.admin/modals.info.button_cta_url'/>
                                <TextInput source='meta.button_cta_text' fullWidth={true}/>
                                <TextInputWithHelp source='meta.button_close_url' label='resources.admin/modals.fields.button_close_url' help='resources.admin/modals.info.button_close_url'/>
                                <TextInput source='meta.button_close_text' fullWidth={true}/>
                                <TextInput source="meta.extra_info" fullWidth={true} multiline={true}/>


                                {showNewsletter && (
                                    <BooleanInput source='meta.newsletter'/>
                                )}

                                {showCoupon && (
                                    <TextInput source={'meta.promotion_code'} fullWidth={true}/>
                                )}

                                <TextInputWithHelp source='meta.image_src' label='resources.admin/modals.fields.meta.image_src' help='resources.admin/modals.info.image_src'/>
                                <ImageInputWithHelp source='meta.image_path' label='resources.admin/modals.fields.meta.image_path' help='resources.admin/modals.info.image_path'/>

                                <BooleanInputWithHelp source='meta.is_prio' label='resources.admin/modals.fields.meta.is_prio' help='resources.admin/modals.info.is_prio' customInnerContainerStyle={{paddingTop: 0}}
                                                      customBoxStyle={{width: '100%', display: 'flex', justifyContent: 'flex-start'}}/>
                                <SelectInputWithHelp label="resources.admin/modals.fields.meta.page_type" source="meta.page_type" help="resources.admin/modals.info.pageTypeOptions" choices={[
                                    {id: 'homepage', name: translate('resources.admin/modals.pageTypeOptions.homepage')},
                                    {id: 'category_search', name: translate('resources.admin/modals.pageTypeOptions.category_search')},
                                    {id: 'product_details', name: translate('resources.admin/modals.pageTypeOptions.product_details')},
                                ]}/>
                            </TranslatableInputs>
                        </Grid>

                        <Grid item xs={12} sm={6} style={{display: 'flex', paddingTop: '1.5rem', paddingBottom: 0}}>
                            <BooleanInput source='authenticated_only'/>
                            <Box style={{marginTop: '-0.25rem'}}>
                                <InfoPopup text={translate('resources.admin/modals.info.authenticated_only')}/>
                            </Box>
                        </Grid>

                        <Grid item xs={12} style={{display: 'flex', paddingBottom: 0}}>
                            {/* <AutocompleteInput emptyText={"Choose country"} allowEmpty={true} fullWidth={true} source="country" choices={countries.map(country => ({id: country.code, name: country.name}))}/> */}
                            <AutocompleteArrayInput emptyText={"Choose country"} allowEmpty={true} fullWidth={true} source="country" choices={countries.map(country => ({id: country.code, name: country.name}))}/>
                            <Box style={{paddingTop: '0.5rem'}}>
                                <InfoPopup text={translate('resources.admin/modals.info.country')}/>
                            </Box>
                        </Grid>

                        {(errorMessage !== '') && (
                            <Grid item xs={12}>
                                <Box
                                    display={'flex'}
                                    borderRadius={5}
                                    color={'white'}
                                    p={2}
                                    style={{backgroundColor: '#e2001ac7'}}>
                                    <Box mr={2}>
                                        <WarningIcon/>
                                    </Box>
                                    {errorMessage}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    <Toolbar
                        record={formProps.record}
                        basePath={formProps.basePath}
                        undoable={false}
                        invalid={formProps.invalid}
                        handleSubmit={formProps.handleSubmit}
                        saving={formProps.saving}
                        resource="admin/modals"
                    />
                </Fragment>
            )}
        />
    )
        ;
};

export default ModalEdit;
