import LayersIcon from '@material-ui/icons/Layers';
import MaterialList from './MaterialList';

const resource = {
    list: MaterialList,
    icon: LayersIcon,
    options: {
        label: 'resources.admin/lod-materials.name'
    }
};

export default resource;
