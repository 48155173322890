import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, SimpleForm, TextInput, DateInput} from 'react-admin';

import Title from '../../../components/list/Title';
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const PriceListEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit{...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="id" label="resources.admin/lod-price-list.titles.edit"/>

                <TextInput source="preisliste" fullWidth={true}/>
                <TextInput source="betrag" fullWidth={true}/>
                <TextInput source="wahrung"/>
                <TextInput source="preiseinheit"/>
                <DateInput source="gultig_bis" />
            </SimpleForm>
        </Edit>
    );
};

export default PriceListEdit;
