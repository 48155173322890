import {Card, Box, Typography} from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import {useEffect, useState} from "react";
import {useTranslate} from "react-admin";

import {dashboard} from '../../styles/dashboard';
import axiosForApi from "../../config/axios-for-api";
import StatHeader from "./StatHeader";

const Loading = () => {
    return (
        <Box display={'flex'} justifyContent={'center'}>
            <CircularProgress size={28.8}/>
        </Box>
    );
}

const StatResult = ({classes, avgNrAbos, avgRevenueByAbo}: any) => {
    return (
        <Typography className={classes.statsTotal} color="textSecondary">
            {avgNrAbos}/{avgRevenueByAbo}
        </Typography>
    );
}

const AverageRevenueBySubscriptionsLast12Months = ({countries}: { countries: string[] | null }) => {
    const classes = dashboard();
    const translate = useTranslate();

     const [statsData, setStatsData] = useState({
        avg_nr_abos_last_12_months_fmt: '',
        avg_revenue_by_abo_last_12_months_fmt: ''
    });
    const [shouldLoadStatsData, setShouldLoadStatsData] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let cancel = false;

        const loadStatsData = async () => {
            const {data} = await axiosForApi.get('api/admin/stats/average-revenue-by-subscriptions-last-12-months',
                {
                params: {
                    countries
                }
            });

            if(!cancel) {
                setLoading(false);
                setStatsData(data ?? null);
                setShouldLoadStatsData(false);
            }
        }

        shouldLoadStatsData && loadStatsData();

        return () => {
            cancel = true;
        }
    }, [shouldLoadStatsData, countries]);

    useEffect(() => {
        setLoading(true);
        setShouldLoadStatsData(true);
    }, [countries]);

    return (
        <Card className={classes.card}>
            <Box p={2}>
                <StatHeader
                    // title='Abos / Volumen (p.a.)'
                    title={translate('resources.admin/dashboard.headers.average-revenue-by-subscriptions-last-12-months')}
                    help='resources.admin/dashboard.help.average-revenue-by-subscriptions-last-12-months'
                />
               {loading ? <Loading/> : <StatResult
                    classes={classes}
                    avgNrAbos={statsData.avg_nr_abos_last_12_months_fmt}
                    avgRevenueByAbo={statsData.avg_revenue_by_abo_last_12_months_fmt}
                />}
            </Box>
        </Card>
    );
}

export default AverageRevenueBySubscriptionsLast12Months;
