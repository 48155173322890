import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, ReferenceField} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import {Route} from 'react-router';

import ModelFilter from "./ModelFilter";
import ModelEdit from "./ModelEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";

const ModelList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<ModelFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="id"/>
                    <ReferenceField reference={'admin/cob-manufacturers'} source={'type.cob_manufacturer_id'} label={'resources.admin/cob-models.fields.manufacturer'}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <ReferenceField reference={'admin/cob-types'} source={'cob_type_id'} label={'resources.admin/cob-models.fields.type'}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <TextField source="name"/>
                    <EditButton/>
                </Datagrid>
            </List>

            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''}>
                            {isMatch && (
                                <ModelEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default ModelList;
