import React from 'react';
import {Filter, TextInput, FilterProps, SelectInput, useTranslate, usePermissions, AutocompleteArrayInput} from 'react-admin';
import countries from "../../config/countries";

const UserFilter = (props: Omit<FilterProps, 'children'>) => {
    const translate = useTranslate();
    const {permissions} = usePermissions();

    return (

        <Filter {...props}>
            <TextInput source="email" alwaysOn/>
            <SelectInput alwaysOn source="status" choices={[
                {id: 'active', name: 'Active'},
                {id: 'inactive', name: 'Inactive'},
                {id: 'trashed', name: 'Deleted'},
            ]}/>
            <SelectInput alwaysOn source="country" choices={countries.map(el => ({id: el.code, name: el.name}))} style={{minWidth: 200}} />
            {["admin"].includes(permissions) && <AutocompleteArrayInput alwaysOn source="locations" choices={countries.map(el => ({id: el.code, name: el.name}))}/>}
            {["admin"].includes(permissions) && <SelectInput alwaysOn source="role" choices={[
                {id: 'admin', name: translate('resources.admin/users.roleOptions.admin')},
                {id: 'location-manager', name: translate('resources.admin/users.roleOptions.location-manager')},
                {id: 'customer', name: translate('resources.admin/users.roleOptions.customer')},
                {id: 'sap', name: translate('resources.admin/users.roleOptions.sap')},
                {id: 'sales-manager', name: translate('resources.admin/users.roleOptions.sales-manager')},
                {id: 'sales-professional', name: translate('resources.admin/users.roleOptions.sales-professional')},
                {id: 'content-manager', name: translate('resources.admin/users.roleOptions.content-manager')},
                {id: 'partner', name: translate('resources.admin/users.roleOptions.partner')},
                {id: 'account-manager', name: translate('resources.admin/users.roleOptions.account-manager')},
                {id: 'catalog-customer', name: translate('resources.admin/users.roleOptions.catalog-customer')},
            ]}/>}
            <SelectInput emptyText="All users" alwaysOn source="register_source" label="Register Source" choices={[
                {id: 'new', name: 'Registered by shop'},
                {id: 'existing_sap_user', name: 'Existing SAP user'},
            ]}/>
        </Filter>
    )
};

export default UserFilter;
