import AdjustIcon from '@material-ui/icons/Adjust';
import HoleList from './HoleList';

const resource = {
    list: HoleList,
    icon: AdjustIcon,
    options: {
        label: 'resources.admin/lod-holes.name'
    }
};

export default resource;
