import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, usePermissions, BulkDeleteButton} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import MailTemplateFilter from "./MailTemplateFilter";
import MailTemplateEdit from "./MailTemplateEdit";
import SideDrawer from "../../components/list/SideDrawer";
import {Route} from "react-router";
import TextField from "../../components/list/TextField";

const BulkActionButtons = (props: any) => {
    const {permissions} = usePermissions();

    return (
        <Fragment>
            {['admin'].includes(permissions) && <BulkDeleteButton {...props} />}
        </Fragment>);
}

const MailTemplateList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List bulkActionButtons={<BulkActionButtons />} {...props} filters={<MailTemplateFilter />} sort={{field:'id', order:'DESC'}} >
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="subject" />
                    <TextField source="slug" />
                    <TextField source="status" />
                    <EditButton />
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''}>
                            {isMatch && (
                                <MailTemplateEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default MailTemplateList;
