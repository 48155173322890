import {TranslationMessages} from 'react-admin';
import germanMessages from './ra-language-german';

const customGermanMessages: TranslationMessages = {
    ...germanMessages,
    loading: 'Chargement. Veuillez patienter ...',
    pos: {
        configuration: 'Réglages',
        language: 'Langue',
        languageOptions: {
            english: 'en',
            german: 'de',
            french: 'fr',
            italian: 'it',
            spanish: 'es',
            russian: 'ru',
            swedish: 'se',
        },
        buttons: {
            save: 'Enregistrer',
            delete: 'Supprimer',
            close: 'Fermer',
        },
    },
    errors: {
        invalid_record: 'Enregistrement invalide!'
    },
    resources: {
        'admin/dashboard': {
            name: 'Tableau de bord',
            orders: 'Ordres de la boutique',
            ordersLastDays: 'derniers jours',
            ordersLastMonths: 'derniers mois',
            users: 'Utilisateurs',
            customers: 'Clients',
            products: 'Produits',
            see_all: 'Tout voir',
            see_more: 'Voir plus d\'informations',
            show_more: 'Élargir',
            show_less: 'Réduire',
            offers: 'Offres',
            results: {
                id: 'Id',
                sap_order_id: 'Sap Ordre Id',
                cart_id: 'Panier d\'achat Id',
                total: 'Total (€)',
                total_formatted: 'Total (€)',
                created_at: 'Créé à',
                status: 'Statut',
                first_name: 'Prénom',
                last_name: 'Nom de famille',
                email: 'Email',
                name: 'Nom',
                nrOrders: 'Nombre d\'ordres',
                formattedSumTotalOrders: 'Total (€)',
                formattedStatDate: 'Date',
                formattedCreatedAt: 'Créé à',
                formattedTotal: 'Total (€)',
                company: 'Firma',
            },
            'headers': {
                'total-revenue-this-year': `Ventes ${new Date().getFullYear()} (net / total)`,
                'total-revenue-this-month': `Mois en cours (net / total)`,
                'total-registered-customers-all-time': `Clients enregistrés`,
                'total-registered-customers-now': `Nouveaux clients mensuels / total`,
                'average-revenue-by-nr-orders-last-12months': `⌀ Panier d'achat (net / total)`,
                'average-revenue-by-month-last-12months' : `⌀ Ventes p.m (net / total)`,
                'average-revenue-by-subscriptions-last-12-months': `Abonnements / Volume (p.a.)`,
                'offers-orders-conversion-rate-last-12-months': `Offres / Commandes à partir d'offres / Conversion Offre2Commande`,
                'revenue-this-year': `Evolution des ventes par mois`,
                'total-revenue-by-main-category-this-month': `Ventes mensuelles réelles par secteur de produits`,
                'help.revenue-by-year': `Évolution des ventes par année civile`,
                'total-revenue-by-main-category-this-year': `Ventes ${new Date().getFullYear()} par secteur de produits`,
                'nr_active_users_by_country': `Nombre d'utilisateurs actifs par pays`,
                'top-products-by-revenue-this-year': `Ventes des 5 produits les plus vendus (${new Date().getFullYear()})`,
            },
            'help': {
                //Umsatz 2022 (net / total)
                'total-revenue-this-year': `Ventes pour toutes les commandes qui répondent aux critères:
                    - Statut = traité
                    - Source = Shop
                    - Année de création de la commande = année en cours
                `,
                //Aktueller Monat (net / total)
                'total-revenue-this-month': `Ventes pour toutes les commandes qui répondent aux critères:
                    - Statut = traité
                    - Source = Shop
                    - Année de création de la commande = année en cours
                    - Mois de création de l'ordre = mois en cours`,

                //Angemeldete Kunden
                'total-registered-customers-all-time': `**NOMBRE** de tous les utilisateurs qui répondent aux critères:
                    - Rôle de l'utilisateur = Client
                    - Statut de l'utilisateur = actif
                    - Source d'enregistrement de l'utilisateur = Shop`,
                //Neukunden Monat / Total
                'total-registered-customers-now': `**NOMBRE** de tous les utilisateurs qui répondent aux critères:
                    - Rôle de l'utilisateur = Client
                    - Statut de l'utilisateur = actif
                    - Source d'enregistrement de l'utilisateur = Shop
                    - Mois/année d'enregistrement de l'utilisateur = mois/année en cours`,
                //⌀ Warenkorb (net / total)
                'average-revenue-by-nr-orders-last-12months': `**AVG = VENTES / nombre de commandes** de commandes qui répondent aux critères:
                    - Ordre créé entre aujourd'hui et il y a 12 mois
                    - Source de la commande = Shop
                    - Statut de la commande = traité`,
                //⌀ Umsatz p.m (net / total)
                'average-revenue-by-month-last-12months': `**AVG= VENTES TOTALES / 12** des commandes qui répondent aux critères:
                    - Ordre créé entre aujourd'hui et il y a 12 mois
                    - Source de la commande = Shop
                    - Statut de la commande = traité`,

                //Abos / Volumen (p.a.)
                'average-revenue-by-subscriptions-last-12-months': `**Abonnements**
                        - Identifier tous les articles du panier d'achat qui ont une fréquence (= abonnement) au cours de la période actuelle - 12 mois
                        - Calculer la valeur moyenne = total des abonnements / 12
                    **Ventes (p.a.)**
                        - Identifier tous les articles du panier d'achat qui ont une fréquence (= abonnement) au cours de la période actuelle - 12 mois
                        - Calculer la valeur moyenne = ventes (nombre total d'articles) / nombre d'abonnements`,

                //Angebote / Bestellungen aus Angeboten / Conversion Angebot2Bestellung
                'offers-orders-conversion-rate-last-12-months': `**Offres** : Nombre d'offres répondant aux critères:
                    - Offres créées entre maintenant et 12 mois et maintenant avec un panier d'achat spécifique
                    ---
                    **Commandes d'offres**: Nombre de commandes répondant aux critères:
                    - Commandes créées entre aujourd'hui et il y a 12 mois
                    - Statut de la commande = traité
                    - Source de la commande = Shop
                    - Panier d'achat = tous
                    ---
                    **Taux de conversion** : Nombre de commandes issues des offres / nombre d'offres* 100`,

                //Umsatzentwicklung pro Monat
                'revenue-this-year': `a) Regrouper les commandes par mois de l'année en cours avec les critères de commande suivants:
                    - Statut de la commande = traité
                    - Source de la commande = Shop
                    - Année de création de l'ordre = année en cours
                    b) SOMME (total) pour chaque groupe de commandes
                    c) Calculer l'augmentation pour chaque groupe par rapport à la somme du mois précédent.`,

                //Umsatz Akt. Monat nach Produktbereich
                'total-revenue-by-main-category-this-month': `a) pour chaque commande - nous facturons un montant total par produit
                    Par exemple:
                    - Produit A, montant total : 1000 eur, catégorie principale : ACS
                    - Produit B, montant total : 2000 eur, catégorie principale : ACS
                    b) Regroupement de tous les produits commandés par catégorie principale de produits.
                    c) pour chaque groupe, la somme (montant total) de tous les totaux des produits calculés au point a).
                    d) Les produits commandés doivent faire partie de commandes répondant aux critères:
                        - Source de la commande = Shop
                        - Statut de la commande = traité
                        - Année de création/mois de commande = année/mois en cours`,

                //Umsatzentwicklung pro Kalenderjahr
                'revenue-by-year': `a) Regroupement des commandes par année, commandes avec les critères suivants:
                    - Statut de la commande = traité
                    - Source de la commande = Shop
                    b) Chiffre d'affaires pour chaque groupe de commandes
                    c) Calculer pour chaque groupe l'augmentation par rapport aux ventes totales de l'année précédente.`,

                //`Umsatz 2022 nach Produktbereich
                'total-revenue-by-main-category-this-year': `a) pour chaque commande - nous facturons un montant total par produit
                    Par exemple:
                    - Produit A, montant total : 1000 eur, catégorie principale : ACS
                    - Produit B, montant total : 2000 eur, catégorie principale : ACS
                    b) Regroupement de tous les produits commandés par catégorie principale de produits.
                    c) pour chaque groupe, la somme (montant total) de tous les totaux des produits calculés au point a).
                    d) Les produits commandés doivent faire partie de commandes répondant aux critères:
                        - Source de la commande = Shop
                        - Statut de la commande = traité
                        - Année de création/mois de commande = année en cours`,

                //Umsatz Top 5 Produkte (2022)
                'top-products-by-revenue-this-year': `a) pour chaque commande, nous facturons un montant total par produit
                    b) Regroupement de tous les produits commandés par product_id
                    c) pour chaque groupe, la somme de tous les totaux de produits calculés au point a) est formée
                    d) Les produits commandés doivent faire partie de commandes répondant aux critères:
                        - Source de la commande = Shop
                        - Statut de la commande = traité
                        - Année de création de la commande = année en cours`,
                //Nr active users by country
                'nr_active_users_by_country': `Nombre d'utilisateurs actifs par pays`
            }
        },
        'admin/users': {
            name: 'Utilisateurs',
            last_login: 'Dernière connexion',
            from: 'De',
            fields: {
                name: 'Nom',
                sap_status: 'Sap Statut',
                email_verified_at: 'Vérifié à',
                first_name: 'Prénom',
                last_name: 'Nom de famille',
                email: 'Email',
                company: 'Entreprise',
                send_reset_password_link: 'Envoyer le lien de réinitialisation du mot de passe',
                send_email_confirmation_link: 'Envoyer le lien de confirmation par e-mail',
                email_verified: 'Email vérifié'
            },
            roleOptions: {
                admin: 'Admin',
                sap: 'Sap',
                manager: 'Manager',
                customer: 'Customer',
                "location-manager": "Location Manager"
            },
            statusOptions: {
                active: 'Active',
                inactive: 'Inactive'
            },
            fieldGroups: {
                identity: 'Identity',
                password: 'Password',
                sap_data: 'Sap Data',
                invoice_address: 'Billing Address',
                client_info: 'Client Info'
            },
            fieldButtons: {
                reset_password: 'Reset Password',
                email_confirmation_link: 'Email Confirmation Link'
            },
            page: {
                delete: 'Delete User',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
            titles: {
                edit_user: 'Edit user #%{info}',
            },
            messages: {
                are_you_sure_you_want_to_sent_the_reset_password_link: 'Are you sure you want to send the reset password link?',
                reset_password_link_sent: 'Resent password link sent with success',
                are_you_sure_you_want_to_sent_email_confirmation_link: 'Are you sure you want to send the email confirmation link?',
                email_confirmation_link_sent: 'Email confirmation link sent with success',
                are_you_sure_you_want_to_manually_confirm_the_user_email: 'Are you sure you want to manually confirm the user\'s email?',
                email_confirmed: 'Email confirmed with success'
            }
        },
        'admin/posts': {
            name: 'Posts',
            view: 'View Posts',
            create: 'Create Post',
            fields: { //these are used in the forms
                author: 'Author',
                role: 'Role',
                slug: 'Slug',
                status: 'Status',
                content: 'Content',
                title: 'Title',
                subtitle: 'Subtitle',
                id: 'Id',
                teaser_category_id: 'Category ID',
                bg_color: 'Background Color',
                bg_image_src: 'Background Image Src',
                text: 'Text',
                button_url: 'Button Url',
                button_text: 'Button Text',
                image_src: 'Image Src',
                image_path: 'Image Path',
                hierarchy: 'Hierarchy',
                template: 'Template',
                meta: {
                    price: 'Price',
                    button_text: 'Button text',
                    button_url: 'Button url',
                    title: 'Title',
                    image_src: 'Image src',
                    image_path: 'Upload image',
                    categories: 'Categories',
                    seo_title: 'SEO Title',
                    seo_description: 'SEO Description',
                    seo_keywords: 'SEO Keywords',
                    seo_robots: 'Robots'
                }
            },
            statusOptions: {
                publish: 'Publish',
                draft: 'Draft'
            },
            typeOptions: {
                page: 'Page',
                content: 'Content'
            },
            translationStatusOptions: {
                active: 'Active',
                inactive: 'Inactive'
            },
            section: {
                seo: 'Seo',
                post_info: 'Post Info',
                meta: 'Meta'
            },
            titles: {
                edit_post: 'Edit post #%{id}'
            },
            buttons: {
                show_on_shop: 'Show on Shop'
            }
        },
        'admin/products': {
            name: 'Products',
            titles: {
                show_product: 'Show product: %{name}',
                product_variations: 'Product Variations'
            },
            buttons: {
                show_on_shop: 'Show on Shop'
            },
            fields: {
                id: 'Id',
                name_or_id: 'Name or Id',
                top_seller: 'Top Seller',
                created_at: 'Created At',
                name: 'Name'
            }
        },
        'admin/variations': {
            fields: {
                name: 'Name',
                price: 'Price',
                matnr: 'OrderId'
            }
        },
        'admin/orders': {
            name: 'Commandes',
            fields: {
                product_id: 'Produit#',
                matnr: 'Matnr',
                product: 'Produit',
                manager: 'Manager',
                customer: 'Client',
                qty: 'Qté',
                price: 'Prix',
                variation: 'Variation',
                customer_name_or_email: 'Nom du client / email',
                from: 'A partir de',
                to: 'Au',
                total: 'Total',
                created_at: 'Créé à',
                cad_url: 'CAD',
                print_url: 'Imprimer',
                pending: 'En attente',
                processed: 'Traitée',
                payment_status: 'État des paiements',
                sap_order_id: 'ID de commande sur SAP',
                status: 'Statut',
                source: 'Source',
                payment_method: 'Mode de paiement',
                unzer_short_id: 'Unzer Short ID',
                unzer_payment_id: 'Unzer Payment ID',
                shipping_date: 'Date d\'expédition',
                shipping_date_notification_action: 'Action relative à la date d\'expédition',
                approve_shipping_date: 'Envoyer approuver la date d\'expédition',
                reject_shipping_date: 'Envoyer rejeter la date d\'expédition',
            },
            badge: {
                sample: 'Goûter',
            },
            titles: {
                edit_order: 'Commande #%{id}'
            },
            buttons: {
                submit_to_sap: 'Soumettre à SAP'
            }
        },
        'admin/translations': {
            name: 'Translations',
            buttons: {
                save_translation: 'Save translation',
                publish_translation: 'Publish translation',
                reset: 'Reset',
                remove: 'Remove language',
                translate_field: 'Translate field',
                add_new_language: 'Add new language',
            },
            messages: {
                translation_saved: 'Translation saved with success!',
                translation_not_saved: 'Error! Translation not saved!',
                translation_reset: 'Translation reset with success!',
                translation_not_reset: 'Error! Translation not reset!',
                translation_published: 'Translation published with success!',
                translation_not_published: 'Error! Translation not published!',
                you_have_unsaved_changes: 'Warning! Your have unsaved translations, are you sure your want to continue?',
                language_added: 'Language added successfully.',
                language_not_added: 'Error! Language was not added.',
                cannot_remove_de_or_only_language: 'DE langauge cannot be removed.',
                removed_language: 'Error! Language removed successfully.',
                failed_removed_language: 'Language was not removed',
                please_select_language_from_and_to: 'Please select new language and template language.',
            },
            fields: {
                all_locales: "Choose new language!",
                template_language: "Choose template language!"
            }
        },
        'admin/mail-templates': {
            name: 'Mail Templates',
            fields: {
                subject: 'Subject',
                cc: 'CC',
                to: 'TO',
                bcc: 'BCC'
            },
            titles: {
                edit_mail_template: 'Edit mail template #%{id}'
            },
            statusOptions: {
                active: 'Active',
                draft: 'Draft'
            },
        },
        'admin/backups': {
            name: 'Backups',
            fields: {
                path: 'Path',
            },
            buttons: {
                restore: 'Restore'
            },
            messages: {
                'restore_with_success': 'Restore has been scheduled with success',
                'restore_failed': 'Restore failed'
            }
        },
        'admin/lod': {
            name: 'LOD',
        },
        'admin/lod-colors': {
            name: 'Colors',
            titles: {
                create: 'Add new Color',
                edit: 'Edit Color: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-foils': {
            name: 'Foils',
            titles: {
                create: 'Add new Foil',
                edit: 'Edit Foil: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-thickness': {
            name: 'Thickness',
            titles: {
                create: 'Add new Thickness',
                edit: 'Edit Thickness: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-materials': {
            name: 'Materials',
            titles: {
                create: 'Add new Material',
                edit: 'Edit Material: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-holes': {
            name: 'Holes',
            titles: {
                create: 'Add new Hole',
                edit: 'Edit Hole: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-shapes': {
            name: 'Shapes',
            titles: {
                create: 'Add new Shape',
                edit: 'Edit Shape: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-price-matrix': {
            name: 'Price Matrix',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                signs: 'signs'
            }
        },
        'admin/lod-raw-material-prices': {
            name: 'Raw Material Prices',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                color: 'Color',
                foil: 'Foil',
                price: 'Price'
            }
        },
        'admin/lod-hole-image-shape': {
            name: 'Hole/Image/Shape',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            orientation: {
                landscape: 'Landscape',
                portrait: 'Portrait'
            },
            fields: {
                image: 'Image',
                shape: 'Shape',
                hole: 'Hole',
                mass_1: 'L',
                mass_2: 'B',
                mass_3: 'D',
                mass_4: 'd',
                mass_5: 'R',
                mass_6: 'x',
                mass_7: 'y',
                mass_8: 'X1',
                mass_9: 'X2',
                mass_10: 'Y1',
                mass_11: 'Y2'
            }
        },
        'admin/lod-material-configs': {
            name: 'Material Configs',
            titles: {
                edit: 'Edit material config',
                create: 'Create material config'
            },
            sections: {
                gewicht: 'Gewicht',
                mattenbreite: 'Mattenbreite',
                menge: 'Menge',
                wkz: 'WKZ',
                rohmaterial: 'Rohmaterial',
                rabatt: 'Rabatt'
            },
            status: {
                'active': 'Active',
                'inactive': 'Inactive'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                gewicht: {
                    matte_248x248_in_gramm: 'Matte 248x248 in Gramm',
                    pro_mm2_in_g: 'Pro mm2 in g'
                },
                wkz: {
                    '1_klein': 'WKZ 1 (klein)',
                    '2_gross': 'WKZ 2 (gross)',
                    'umfang': 'WKZ Umfang'
                },
                foil_id: "Foil",
                color_id: "Color",
                extra_charge: "Extra charge"
            }
        },
        'admin/lod-images': {
            name: 'Images',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                image_path: 'Image file'
            }
        },
        'admin/lod-price-list': {
            name: 'Price list',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {}
        },
        'admin/lod-price-list-factors': {
            name: 'Price list Factors',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                price_list: 'Price list'
            }
        },
        'admin/lod-quellwerk': {
            name: 'Quellwerk',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {}
        },
        'admin/offers': {
            name: 'Offres',
            fields: {
                total: 'Total',
                created_at: 'Créé à',
                company: 'Entreprise',
                processed: 'Traitée',
                active: 'Actif',
                expired: 'Expiré',
                cart_status: 'Statut du panier',
                client_offer_id: 'ID de l\'offre du client',
                cart_id: 'ID du panier'
            },
            buttons: {
                export: 'PDF'
            }
        },
        'admin/lod-wintyps': {
            name: 'Wintyps',
            titles: {
                create: 'Add new Wintyp',
                edit: 'Edit Wintyp: %{name}'
            },
            fields: {
                blocked_at: 'Blocked',
                used_at: 'Used',
                product: 'Product',
                order_id: 'Order #'
            }
        },
        'admin/shipping-dates': {
            name: 'Shipping Dates',
            titles: {
                shipping_countries: 'Shipping Countries',
                shipping_date: 'Shipping Date',
                create: 'Add new Shipping Date',
                edit: 'Edit Record: %{name}',
            },
            sections: {
                recurrence: 'Recurrence'
            },
            fields: {
                shipping_country_ids: 'Shipping Countries (iso2)'
            }
        },
        'admin/shipping-countries': {
            name: 'Shipping Countries',
            titles: {
                create: 'Add new Shipping Country',
                edit: 'Edit Shipping Country: %{name}'
            },
            fields: {
                code: 'Country Code (iso 2)'
            }
        },
        'admin/shipping': {
            name: 'Shipping'
        },
        'admin/cob': {
            name: 'COB'
        },
        'admin/cob-cobots': {
            name: 'Cobots',
            fields: {
                manufacturer: 'Manufacturer',
                type: 'Type',
                model: 'Model',
                axis6_tape: 'Axis 6 Tape',
                system_holder_1: 'System Holder #1',
                system_holder_2: 'System Holder #2',
                hose_length: 'Hose Length',
                tension_clamp: 'Tension Clamp',

                //ua
                ua_shs_center: 'Center',
                ua_shs_outside: 'Outside',
                ua_shs_inside: 'Inside',
                ua_tape_center: 'Center',
                ua_tape_outside: 'Outside',
                ua_tape_inside: 'Inside',
                ua_feeding_system: 'Feeding System',

                //lq
                la_shs_center: 'Center',
                la_shs_outside: 'Outside',
                la_shs_inside: 'Inside',
                la_tape_center: 'Center',
                la_tape_outside: 'Outside',
                la_tape_inside: 'Inside',
                la_feeding_system: 'Feeding System'

            },
            titles: {
                create: 'Add New Cobot',
                edit: 'Edit Cobot %{name}'
            },
            fieldGroups: {
                manufacturer_details: 'Manufacturer Details',
                technical_master_data: 'Technical Master Data',
                technical_details_upper_support_arm: 'Technical Details - Upper Arm',
                technical_details_bottom_support_arm: 'Technical Details - Lower Arm',
                shs: 'SHS',
                tape: 'Tape'
            }
        },
        'admin/cob-manufacturers': {
            name: 'Manufacturers',
            titles: {
                create: 'Add New Manufacturer',
                edit: 'Edit Manufacturer: %{name}'
            }
        },
        'admin/cob-types': {
            name: 'Types',
            titles: {
                create: 'Add New Type',
                edit: 'Edit Type: %{name}'
            },
            fields: {
                manufacturer: 'Manufacturer'
            }
        },
        'admin/cob-models': {
            name: 'Models',
            titles: {
                create: 'Add New Model',
                edit: 'Edit Model: %{name}'
            },
            fields: {
                manufacturer: 'Manufacturer',
                type: 'Type'
            }
        },
        'admin/cob-products': {
            name: 'Products',
            titles: {
                create: 'Add New Product',
                edit: 'Edit Product: %{name}'
            },
            fieldGroups: {
                product_details: 'Details',
                product: 'Product'
            },
            fields: {
                name: 'Name',
                nominal_size: 'Nominal size',
                profile: 'Profile',
                configuratorStatus: 'Configurator status'
            },
            group: {
                hose: 'Hose',
                hose_hf: 'Hose Highly Flexible',
                tension_clamp: 'Tension Clamp',
                belt: 'Belt',
                system_holder: 'System Holder',
                end_grommet: 'End Grommet',
                hose_ring_zl: 'Hose Ring Zl',
                hose_ring_fixed: 'Hose Ring Fixed',
                shs: 'SHS',
                fhs_c: 'System Holder FHS-C',
                fhs_uhe: 'System Holder FHS-UHE',
                fhs_sh: 'System Holder FHS-SH',
                options: 'Option',
                velcro: 'Velcro',
                velcro_silicone_free: 'Velcro silicone free',
                stainless_steel_band: 'Stainless Steel Band',
                return_system: 'Return System'
            },
            design: {
                slotted: 'Slotted',
                closed: 'Closed'
            },
            status: {
                active: 'Active',
                inactive: 'Inactive'
            }
        }
    }
};

export default customGermanMessages;
