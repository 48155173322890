import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {useTranslate, SelectInput, TextInput, FormWithRedirect, BooleanInput, Toolbar, DeleteButton, SaveButton, Button, CreateProps, Create, AutocompleteInput} from 'react-admin';
import {Typography, Grid, Box} from '@material-ui/core';
import {useFormState} from "react-final-form";
import {ChromePicker} from 'react-color';

import {filterProps} from "../../../functions/common";
import axiosForApi from "../../../config/axios-for-api";
import CobotTypeCreate from "./CobotTypeCreate";
import CobModelCreate from "./CobotModelCreate";

type SelectOption = {
    id: number,
    name: string,
    manufacturer_id?: number,
    type_id?: number
}

type ChoicesData = {
    manufacturerChoices: SelectOption[],
    typeChoices: SelectOption[],
    modelChoices: SelectOption[]
}

const CobotCreate: FC<CreateProps> = props => {
    const newProps = filterProps(props);
    const translate = useTranslate();
    const [choicesData, setChoicesData] = useState<ChoicesData | null>(null);

    const [color, setColor] = useState<string | null>(null);
    const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

    const handleColorChangeComplete = (color: any, form: any) => {
        setColor(color.hex);
        form.change('color', color.hex);
    };

    useEffect(() => {
        (async () => {
            const manufacturerChoices = ((await axiosForApi.get(`api/admin/cob-manufacturers`, {params: {_end: 1000}})).data ?? []).map((el: any) => ({id: el.id, name: el.name}));
            const typeChoices = ((await axiosForApi.get(`api/admin/cob-types`, {params: {_end: 1000}})).data ?? []).map((el: any) => ({id: el.id, name: el.name, manufacturer_id: el.cob_manufacturer_id}));
            const modelChoices = ((await axiosForApi.get(`api/admin/cob-models`, {params: {_end: 1000}})).data ?? []).map((el: any) => ({id: el.id, name: el.name, type_id: el.cob_type_id}));

            //TODO - cache
            const choices = {
                manufacturerChoices,
                typeChoices,
                modelChoices
            };

            setChoicesData(choices);
        })();

    }, [])

    const CobManufacturerInput = (props: any) => {
        const choices = choicesData?.manufacturerChoices ?? [];
        const form = props.form;

        const handleChange = () => {
            form.batch(() => {
                form.change('cob_type_id', '');
                form.change('cob_model_id', '');
            });
        }

        return (
            <SelectInput
                fullWidth={true}
                choices={choices}
                onChange={handleChange}
                {...props}
            />
        );
    };


    const CobTypeInput = (props: any) => {
        const {values} = useFormState();

        const manufacturerId = +values.cob_manufacturer_id;
        const choices = (choicesData?.typeChoices ?? []).filter((el: SelectOption) => el.manufacturer_id === manufacturerId);

        return (
            // <SelectInput
            //     fullWidth={true}
            //     choices={choices}
            //     allowEmpty={true}
            //     {...props}
            // />
            <AutocompleteInput
                {...props}
                choices={choices}
                fullWidth={true}
                create={<CobotTypeCreate setChoicesData={setChoicesData} {...props} cob_manufacturer_id={manufacturerId}/>}/>
        );
    };

    const CobModelInput = (props: any) => {
        const {values} = useFormState();

        const typeId = +values.cob_type_id;
        const manufacturerId = +values.cob_manufacturer_id;
        const choices = typeId ? (choicesData?.modelChoices ?? []).filter((el: SelectOption) => el.type_id === typeId) : [];

        return (
            // <SelectInput
            //     allowEmpty={true}
            //     fullWidth={true}
            //     choices={choices}
            //     {...props}
            // />
            <AutocompleteInput
                {...props}
                choices={choices}
                fullWidth={true}
                optionText={(record: any) => record?.name ?? ''}
                create={
                    <CobModelCreate setChoicesData={setChoicesData} {...props} cob_type_id={typeId} cob_manufacturer_id={manufacturerId}/>
                }
            />
        );
    };

    const ColorPicker = (props: any) => {
        return (
            <>
                {showColorPicker && (<div style={{position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px'}} onClick={() => setShowColorPicker(false)}></div>)}
                <div style={{position: "relative", marginLeft: "5px"}}>
                    <Button style={{width: '48px', height: '48px', border: "1px solid #ccc", backgroundColor: props.color}} onClick={() => setShowColorPicker(!showColorPicker)}></Button>
                    {showColorPicker && (
                        <div style={{"position": 'absolute', "zIndex": 10, 'top': '48px', 'right': '0'}}>
                            <ChromePicker color={props.color??'#000000'} onChangeComplete={(newColor) => handleColorChangeComplete(newColor, props.form)}/>
                        </div>)}
                </div>
            </>);
    }

    return (
        <Create {...newProps}>
            <FormWithRedirect
                {...newProps}
                render={(formProps: any) => {
                    return (
                        <div style={{padding: '16px'}}>
                            <Grid container>
                                <Grid item xs={12} lg={6}>
                                    <Typography gutterBottom variant="h5">
                                        {translate(
                                            'resources.admin/cob-cobots.fieldGroups.manufacturer_details'
                                        )}
                                    </Typography>

                                    <Grid container style={{marginBottom: '20px'}}>
                                        <Grid item xs={12}>
                                            {choicesData ? (<CobManufacturerInput source="cob_manufacturer_id" form={formProps.form}/>) : 'Loading ...'}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {choicesData && (<CobTypeInput source="cob_type_id" form={formProps.form} />)}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {choicesData && (<CobModelInput source="cob_model_id" form={formProps.form} />)}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{display: "flex", alignItems: "center"}}>
                                                <TextInput source="color" helperText={false} onChange={() => setColor(formProps.record.color)}/>
                                                <ColorPicker color={color ?? formProps.record.color} form={formProps.form}/>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <SelectInput source={'status'} choices={[{id: 'publish', name: 'Publish'}, {id: 'draft', name: 'Draft'}]} defaultValue={'draft'}/>
                                        </Grid>
                                    </Grid>

                                    <Typography gutterBottom variant="h5">
                                        {translate(
                                            'resources.admin/cob-cobots.fieldGroups.technical_master_data'
                                        )}
                                    </Typography>

                                    <Grid container style={{marginBottom: '20px'}}>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="axis6_tape" helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                        <Grid item container xs={12} sm={6}>
                                            <TextInput source="tension_clamp"/>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextInput source="system_holder_1"/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextInput source="system_holder_2"/>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="hose_length" helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    {/*UPPER ARM*/}
                                    <Typography gutterBottom variant="h5">
                                        {translate(
                                            'resources.admin/cob-cobots.fieldGroups.technical_details_upper_support_arm'
                                        )}
                                    </Typography>
                                    <Typography gutterBottom variant="h6">
                                        {translate(
                                            'resources.admin/cob-cobots.fieldGroups.shs'
                                        )}

                                    </Typography>
                                    <Grid container style={{marginBottom: '20px'}}>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="ua_shs_outside" label={'resources.admin/cob-cobots.fields.ua_shs_outside'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="ua_shs_center" label={'resources.admin/cob-cobots.fields.ua_shs_center'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="ua_shs_inside" label={'resources.admin/cob-cobots.fields.ua_shs_inside'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                    </Grid>


                                    <Typography gutterBottom variant="h6">
                                        {translate(
                                            'resources.admin/cob-cobots.fieldGroups.tape'
                                        )}
                                    </Typography>

                                    <Grid container style={{marginBottom: '20px'}}>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="ua_tape_outside" label={'resources.admin/cob-cobots.fields.ua_tape_outside'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="ua_tape_center" label={'resources.admin/cob-cobots.fields.ua_tape_center'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="ua_tape_inside" label={'resources.admin/cob-cobots.fields.ua_tape_inside'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <BooleanInput source="ua_feeding_system" label={'resources.admin/cob-cobots.fields.ua_feeding_system'}/>
                                        </Grid>
                                    </Grid>

                                    {/*BOTTOM ARM*/}
                                    <Typography gutterBottom variant="h5">
                                        {translate(
                                            'resources.admin/cob-cobots.fieldGroups.technical_details_bottom_support_arm'
                                        )}
                                    </Typography>
                                    <Typography gutterBottom variant="h6">
                                        {translate(
                                            'resources.admin/cob-cobots.fieldGroups.shs'
                                        )}

                                    </Typography>
                                    <Grid container style={{marginBottom: '20px'}}>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="la_shs_outside" label={'resources.admin/cob-cobots.fields.la_shs_outside'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="la_shs_center" label={'resources.admin/cob-cobots.fields.la_shs_center'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="la_shs_inside" label={'resources.admin/cob-cobots.fields.la_shs_inside'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                    </Grid>


                                    <Typography gutterBottom variant="h6">
                                        {translate(
                                            'resources.admin/cob-cobots.fieldGroups.tape'
                                        )}
                                    </Typography>

                                    <Grid container style={{marginBottom: '20px'}}>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="la_tape_outside" label={'resources.admin/cob-cobots.fields.la_tape_outside'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="la_tape_center" label={'resources.admin/cob-cobots.fields.la_tape_center'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display={"flex"} alignItems={'center'} gridGap={6}>
                                                <TextInput source="la_tape_inside" label={'resources.admin/cob-cobots.fields.la_tape_inside'} helperText={false}/>
                                                <span>cm</span>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <BooleanInput source="la_feeding_system" label={'resources.admin/cob-cobots.fields.la_feeding_system'}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Toolbar>
                                <SaveButton
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                />
                                <DeleteButton/>
                            </Toolbar>
                        </div>
                    )
                }}
            />
        </Create>
    );
};

export default CobotCreate;
