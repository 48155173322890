import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, TextInput, TranslatableInputs, useTranslate} from 'react-admin';
import {Typography} from "@material-ui/core";

import {filterProps} from "../../functions/common";
import cfgLocales from '../../config/locale';

const VideoCategoryCreate: FC<CreateProps> = props => {
    const newProps = filterProps(props);
    const translate = useTranslate();

    return (
        <>
            <Typography variant='h6'>
                {translate("resources.admin/video-categories.titles.create")}
            </Typography>
            <Create {...newProps}>
                <SimpleForm>
                    <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                        <TextInput fullWidth source='name' label={'resources.admin/video-categories.fields.name'}/>
                    </TranslatableInputs>
                </SimpleForm>
            </Create>
        </>
    );
};

export default VideoCategoryCreate;
