import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, TextInput} from 'react-admin';

import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const WintypCreate: FC<CreateProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <TextInput autoFocus source="codes" multiline={true} rows={10} fullWidth={true}/>
            </SimpleForm>
        </Create>
    );
};

export default WintypCreate;
