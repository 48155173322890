import React from 'react';
import {
    Filter,
    TextInput,
    FilterProps,
    DateInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';

const OrderFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <ReferenceInput label="resources.admin/orders.fields.customer_name_or_email" source="customer_id" reference="admin/users" alwaysOn options={{
            suggestionsContainerProps: {
                disablePortal: true,
            }
        }}>
            <AutocompleteInput optionText="name_and_email" />
        </ReferenceInput>
        <TextInput source="sap_order_id" alwaysOn/>
        <DateInput label="resources.admin/orders.fields.from" source="created_at_from" alwaysOn locale={'de'} />
        <DateInput label="resources.admin/orders.fields.to" source="created_at_to" alwaysOn locale={'de'} />
        <SelectInput alwaysOn source="status" choices={[
            {id: 'pending', name: "resources.admin/orders.fields.pending"},
            {id: 'processed', name: "resources.admin/orders.fields.processed"},
        ]}/>
        <SelectInput alwaysOn source="source" choices={[
            {id: 'shop', name: 'Shop'},
            {id: 'sap', name: 'SAP'},
        ]}/>
    </Filter>
);

export default OrderFilter;
