import * as React from 'react';
import {useDataProvider, useTranslate} from 'react-admin';
import PeopleIcon from '@material-ui/icons/People';
import { User } from '../../config/types';
import CardWithIcon from "./CardWithIcon";
import {useCallback, useEffect, useState} from "react";
import ResultsTable from "./ResultsTable";
import {Box, CircularProgress} from "@material-ui/core";

const NewCustomers = ({countries}: { countries: string[] | null }) => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [shouldFetchUsers, setShouldFetchUsers] = useState<boolean>(false);
    const [newUsers, setNewUsers] = useState<User[]>([]);
    const [totalUsers, setTotalUsers] = useState<number | string>('');

    const fetchNewCustomers = useCallback(async () => {
        await dataProvider.getList<User>(
            'admin/users',
            {
                filter: { role: 'customer', status:'active', countries },
                sort: { field: 'id', order: 'DESC' },
                pagination: { page: 1, perPage: 10 }
            }
        ).then(({data,total}) => {
            setNewUsers(data);
            setTotalUsers(total)
        }).catch(err => Promise.reject(err.response));
        setShouldFetchUsers(false);
    }, [dataProvider, countries]);

    useEffect(() => {
        if(shouldFetchUsers) {
            fetchNewCustomers();
        }
    }, [shouldFetchUsers,fetchNewCustomers]);

     useEffect(() => {
        setShouldFetchUsers(true);
    }, [countries]);

    const tableColumns = ['id', 'first_name', 'last_name', 'email'];

    return (
        <CardWithIcon
            to="admin/users"
            icon={PeopleIcon}
            title={translate('resources.admin/dashboard.customers')}
            subtitle={totalUsers}
            showButton={newUsers.length > 0}
        >
            {newUsers.length ? (
                <ResultsTable columns={tableColumns} results={newUsers} to={'admin/users'} linkArray={['first_name', 'last_name']} />
            ) : (
                <Box display={'flex'} justifyContent={'center'} p={2}>
                    <CircularProgress />
                </Box>
            )}
        </CardWithIcon>
    );
};

export default NewCustomers;
