import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {Edit, EditProps, TextInput, SelectInput, AutocompleteInput, FormWithRedirect, SaveButton, DeleteButton, Toolbar} from 'react-admin';

import {filterProps} from "../../../functions/common";
import axiosForApi from "../../../config/axios-for-api";
import {useFormState} from "react-final-form";
import CobotTypeCreate from "../cobots/CobotTypeCreate";

type SelectOption = {
    id: number,
    name: string,
    manufacturer_id?: number,
    type_id?: number
}

type ChoicesData = {
    manufacturerChoices: SelectOption[],
    typeChoices: SelectOption[]
}

const ModelEdit: FC<EditProps> = props => {
    const newProps = filterProps(props);

    const [choicesData, setChoicesData] = useState<ChoicesData | null>(null);

    useEffect(() => {
        (async () => {
            const manufacturerChoices = ((await axiosForApi.get(`api/admin/cob-manufacturers`, {params: {_end: 1000}})).data ?? []).map((el: any) => ({id: el.id, name: el.name}));
            const typeChoices = ((await axiosForApi.get(`api/admin/cob-types`, {params: {_end: 1000}})).data ?? []).map((el: any) => ({id: el.id, name: el.name, manufacturer_id: el.cob_manufacturer_id}));

            //TODO - cache
            const choices = {
                manufacturerChoices,
                typeChoices
            };

            setChoicesData(choices);
        })();

    }, [])

    const CobManufacturerInput = (props: any) => {
        const choices = choicesData?.manufacturerChoices ?? [];
        const form = props.form;

        const handleChange = () => {
            form.batch(() => {
                form.change('cob_type_id', '');
                form.change('cob_model_id', '');
            });
        }

        return (
            <SelectInput
                fullWidth={true}
                choices={choices}
                onChange={handleChange}
                {...props}
            />
        );
    };


    const CobTypeInput = (props: any) => {
        const {values} = useFormState();

        const manufacturerId = +values.cob_manufacturer_id;
        const choices = (choicesData?.typeChoices ?? []).filter((el: SelectOption) => el.manufacturer_id === manufacturerId);

        return (
            // <SelectInput
            //     fullWidth={true}
            //     choices={choices}
            //     allowEmpty={true}
            //     {...props}
            // />
            <AutocompleteInput
                {...props}
                choices={choices}
                fullWidth={true}
                create={<CobotTypeCreate setChoicesData={setChoicesData} {...props} cob_manufacturer_id={manufacturerId}/>}/>
        );
    };


    return (
        <Edit {...newProps}>
            <FormWithRedirect
                {...newProps}
                render={(formProps: any) => {
                    return (
                        <div style={{padding: '16px'}}>
                            {choicesData ? (<CobManufacturerInput source="cob_manufacturer_id" form={formProps.form}/>) : 'Loading ...'}
                            {choicesData && (<CobTypeInput source="cob_type_id" form={formProps.form}/>)}
                            <TextInput autoFocus source="name" fullWidth={true}/>

                            <Toolbar>
                                <SaveButton
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                />
                                <DeleteButton/>
                            </Toolbar>
                        </div>
                    )
                }}/>
        </Edit>
    );
};

export default ModelEdit;
