import * as React from 'react';
import {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {Title, useLocale, useSetLocale, useTranslate} from 'react-admin';

import cfgLocale from '../../config/locale';
import axiosForApi from "../../config/axios-for-api";
import {useLocation} from "react-router-dom";
import {Grid, Typography} from "@material-ui/core";
import TwoFAInfo from "../../components/2fa/TwoFAInfo";
import TwoFAActivation from "../../components/2fa/TwoFAActivation";
import LoadMask from "../../components/ui/LoadMask";

const Configuration = () => {
    const translate = useTranslate();
    const locale = useLocale();
    const setLocale = useSetLocale();
    const languageOptions = cfgLocale.languageOptions;
    const location = useLocation();

    const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState<any>(false);

    useEffect(() => {
        axiosForApi.get('/api/me').then(result => {
            setTwoFactorAuthEnabled(Boolean(result?.data?.has2FA ?? false));
        })
    }, [location.search]);

    //CR: we don't want the configuration to redirect to 2FA
    // useEffect(() => {
    //     if(!twoFactorAuthEnabled && location.search !== '?enable-2fa') {
    //         redirect('/admin/configuration?enable-2fa');
    //         return;
    //     }
    // }, [redirect, twoFactorAuthEnabled, location]);

    return twoFactorAuthEnabled !== null ? (
        <>

            {!(location.search === '?enable-2fa') ? (
                <>
                    <Title title={translate('pos.configuration')}/>
                    <Card style={{marginBottom: '1rem'}}>
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography style={{fontWeight: 700}}>
                                        {translate('pos.language')}
                                    </Typography>
                                </Grid>
                                {Object.keys(languageOptions).filter(function (langCode) {
                                    if (!['de', 'en'].includes(langCode)) {
                                        return false;
                                    }
                                    return true;
                                }).map(langCode =>
                                    <Grid item key={`lang_${langCode}`}>
                                        <Button
                                            variant="contained"
                                            color={locale === langCode ? 'primary' : 'default'}
                                            onClick={() => setLocale(langCode)}
                                        >
                                            {translate(languageOptions[langCode].label)}
                                        </Button>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <TwoFAInfo twoFactorAuthEnabled={twoFactorAuthEnabled} setTwoFactorAuthEnabled={setTwoFactorAuthEnabled}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            ) : (
                <TwoFAActivation twoFactorAuthEnabled={twoFactorAuthEnabled}/>
            )}
        </>
    ) : <LoadMask/>;
};

export default Configuration;
