import {TranslationMessages} from 'react-admin';
import germanMessages from './ra-language-german';

const customGermanMessages: TranslationMessages = {
    ...germanMessages,
    loading: 'Laden. Bitte warten ...',
    pos: {
        configuration: 'Einstellungen',
        language: 'Sprache',
        languageOptions: {
            english: 'en',
            german: 'de',
            french: 'fr',
            italian: 'it',
            spanish: 'es',
            russian: 'ru',
            swedish: 'se',
        },
        buttons: {
            save: 'Speichern',
            delete: 'Löschen',
            close: 'Schließen',
        },
    },
    twoFA: {
        bannerTitle: "Zweistufige Überprüfung einrichten",
        bannerShortText: "Erwäge, die Sicherheit deines Kontos zu erhöhen, indem du die Zwei-Faktor-Authentifizierung (2FA) aktivierst. Diese Funktion bietet eine zusätzliche Schutzschicht für dein Konto.",
        bannerButtonText: "Zwei-Faktor-Authentifizierung einrichten",
        bannerButtonDismissText: "Nicht interessiert",
        infoTitle: "Zweistufige Überprüfung",
        infoShortText: "Ein Passwort allein bietet oft keinen ausreichenden Schutz vor potenziellen Hackern. Wir empfehlen die Einrichtung einer zweistufigen Überprüfung, um sicherzustellen, dass niemand anders auf dein Konto zugreifen kann, auch wenn dein Passwort kompromittiert wurde.",
        infoText: "Ein Passwort allein ist oft nicht der beste Schutz vor potenziellen Hackern. Wir empfehlen die Einrichtung einer zweistufigen Überprüfung, um sicherzustellen, dass niemand anders auf dein Konto zugreifen kann, auch wenn dein Passwort kompromittiert ist. <br /> <br /> Die zweistufige Überprüfung bietet eine zusätzliche Sicherheitsebene, indem sie dich auffordert, einen an dein Smartphone gesendeten Code einzugeben, wenn du dich einloggst. Dies bestätigt uns, dass du es wirklich bist. Die Einrichtung der zweistufigen Überprüfung ist schnell und einfach.",
        infoButtonText: "Zwei-Faktor-Authentifizierung einrichten",
        recoveryCodesTitle: "Ersatz-Wiederherstellungscodes",
        recoveryCodesInfo: "Verwende einen der aufgelisteten Codes zum Einloggen, wenn du keinen Zugriff mehr auf dein Authenticator-Gerät hast. Jeder Code kann nur einmal verwendet werden.",
        recoveryCodesDownload: "Wiederherstellungscodes herunterladen",
        qrCodeTitle: "QR-Code scannen oder Schlüssel eingeben",
        qrCodeText: "Scan den unten stehenden Code mit deiner Authenticator-App, um dieses Konto hinzuzufügen. Einige Authenticator-Apps ermöglichen es dir auch, die Textversion stattdessen einzugeben.",
        qrCodeInfo: "Don't have an authentication app yet? Download one the authentication app that is recommended by your company. If your free to choose, you could use for example Google Authenticator.",
        qrCodeAlternativeKey: "Alternative QR-Schlüssel",
        qrCodeConfirmCode: "Confirm key",
        disableTwoFATitle: "Zwei-Stufen-Überprüfung deaktivieren",
        disableTwoFAText: "<strong class='primaryColor'>Bist du sicher, dass du die Zwei-Schritt-Authentifizierung deaktivieren möchtest?</strong> <br><br> Bitte beachte, dass dein Konto dadurch weniger sicher und anfälliger für unbefugten Zugriff ist. <br><br> Wir empfehlen dringend, die Zwei-Schritt-Authentifizierung aktiviert zu lassen für eine verbesserte Sicherheit. <br> Wenn du dennoch fortfahren möchtest, klicke auf <strong>'Bestätigen'</strong>, ansonsten klicke auf <strong>'Abbrechen'</strong>, um deine aktuellen Sicherheitseinstellungen beizubehalten.",
        disableTwoFAConfirmText: "Bestätigen",
        disableTwoFACancelText: "Abbrechen",
        enterTwoFACodeTitle: "Code aus Authenticator-App eingeben",
        enterTwoFACodeText: "Gib den Code aus deiner Authenticator-App unten ein, um die Zwei-Faktor-Authentifizierung für dieses Konto zu überprüfen und zu aktivieren.",
        enterTwoFACodeError: "Ungültiger Schlüssel hinzugefügt",
        enterTwoFACodeButtonText: "Schlüssel bestätigen",
        enableTwoFAButtonText: "Zwei-Faktor-Authentifizierung einrichten",
        disableTwoFAButtonText: "Zwei-Stufen-Authentifizierung entfernen",
        twoFAButtonActivationSuccess: "Zwei-Stufen-Überprüfung wurde aktiviert",
        active: "Aktiv",
        confirm: "Bestätigen",
        cancel: "Abbrechen",
        notificationHeader2FATitle: "Zwei-Faktor-Authentifizierung einrichten",
        notificationHeader2FAText: "Erwägen Sie, die Sicherheit Ihres Kontos zu verbessern, indem Sie die Zwei-Faktor-Authentifizierung (2FA) aktivieren. Diese Funktion bietet eine zusätzliche Schutzschicht für Ihr Konto.",
        notificationHeader2FAButtonText: "Zwei-Faktor-Authentifizierung einrichten",
        notificationHeader2FADismissText: "Diese Meldung nicht mehr anzeigen.",
        confirmLeave2FASetupTitle: "Zwei-Faktor-Authentifizierung Setup verlassen",
        confirmLeave2FASetupText: "<strong class='primaryColor'>Sind Sie sicher, dass Sie den Einrichtungsprozess für die Zwei-Schritt-Authentifizierung verlassen möchten?</strong> <br><br> Bitte bedenken Sie, dass Ihr Konto weniger sicher ist und anfälliger für unbefugten Zugriff. <br> Wir empfehlen dringend, die Zwei-Schritt-Authentifizierung aktiviert zu lassen, um die Sicherheit zu erhöhen. <br><br> Wenn Sie den Vorgang fortsetzen möchten, klicken Sie auf <strong>'Bestätigen'</strong>, ansonsten klicken Sie auf <strong>'Abbrechen'</strong>, um mit der Einrichtung fortzufahren.",
        errorFetchingRecoveryCodes: "Error fetching recovery codes",
        loginTwoFATitle: "Verifizierungscode",
        loginTwoFAText: "Geben Sie den Code aus Ihrer Authenticator-App ein",
        loginTwoFANotFound: "Ich habe keinen Verifizierungscode",
        loginTwoFARestoreAccessTitle: "Zugang wiederherstellen",
        loginTwoFARestoreAccessText: "Geben Sie einen Wiederherstellungscode ein, den Sie erhalten haben, als Sie die Zwei-Faktor-Authentifizierung zum ersten Mal eingerichtet haben.",
        loginTwoFARestoreAccessNotFound: "Ich kann meinen Wiederherstellungscode nicht finden",
        loginTwoFAContactSupportTitle: "Kontakt Support",
        loginTwoFAContactSupportText: "Um Ihren Account ohne Ihren Wiederherstellungscode für die 2-Faktor-Authentifizierung wiederherzustellen, kontaktieren Sie bitte unseren Support direkt. Sie sind darauf vorbereitet, Ihre Identität zu überprüfen und beim Wiederherstellen des Zugangs zu Ihrem Konto zu helfen. Ihre Geduld und Ihr Verständnis werden geschätzt.",
        loginTwoFAContactSupportUrl: "https://www.murrplastik.de/de/kontakt/"
    },
    confirmPassword: {
        title: "Passwort bestätigen",
        text: "Bitte bestätigen Sie Ihr Passwort, um fortzufahren.",
        passwordLabel: "Passwort",
        confirm: "Bestätigen",
        cancel: "Abbrechen",
        success: "Passwort erfolgreich aktualisiert.",
        fail: "Incorrect Passwort",
    },
    global: {
        further: "Weiter",
        back: "Zurück",
        backToShop: "Zurück zur Shop",
        adminLogin: "murrplastik admin",
        forgotPassword: "Passwort zurücksetzen",
        loginLink: "Anmeldung",
        support: "Support",
    },
    forgotPassword: {
        successTitle: "Link zum Zurücksetzen des Passworts gesendet",
        resetPasswordButton: "Passwort zurücksetzen",
        sendButton: "Senden",
        loginLink: "Anmeldung",
        sendAgain: "Erneut senden",
        toLogin: "zur Anmeldung",
        contactSupport: "Sie benötigen Hilfe? Bitte wenden Sie sich an unseren ",
        headline: "Sie haben Ihr Passwort vergessen? Wir helfen Ihnen gerne! Sie erhalten von uns eine E-Mail mit einem Link, mit dem Sie Ihr Passwort ändern können. Bitte geben Sie Ihre E-Mail-Adresse ein und drücken Sie auf „Senden“.",
        successMessage: "Vielen Dank für Ihre Nachricht.<br /> Sie erhalten von uns eine E-Mail mit einem Aktivierungslink. Bitte klicken Sie den Link in Ihrer E-Mail an, um Ihr Passwort zu ändern. <strong>Prüfen Sie Ihre E-Mails</strong>",
        alreadyRegistered: "Bereits Kunde?",
        email: "E-Mail Adresse*",
        emailPlaceholder: "E-Mail Adresse eingeben",
        support: "Support",
    },
    resetPassword: {
        title: "Passwort zurücksetzen",
        loginLink: "Anmeldung",
        newPassword: "Neues Passwort",
        repeatPassword: "Passwort wiederholen",
        submit: "Absenden",
        successTitle: "Passwort geändert",
        successMessage: "Ihr Passwort wurde erfolgreich geändert.",
        passwordAlreadyUsedInThePast: "Passwort-Aktualisierung nicht möglich. Sie haben dieses Passwort bereits verwendet. Bitte verwenden Sie ein neues Passwort.",
    },
    errors: {
        invalid_record: 'Ungültiger Datensatz!'
    },
    resources: {
        'admin/dashboard': {
            name: 'Dashboard',
            orders: 'Shop-Bestellungen',
            ordersLastDays: 'letzte Tage',
            ordersLastMonths: 'letzte Monate',
            users: 'Benutzer',
            customers: 'Kunden',
            products: 'Produkte',
            see_all: 'Alle sehen',
            see_more: 'Mehr sehen',
            show_more: 'Erweitern',
            show_less: 'Schrumpfen',
            offers: 'Angebote',
            results: {
                id: 'Id',
                sap_order_id: 'Sap Order Id',
                cart_id: 'Warenkorb Id',
                total: 'Total (€)',
                total_formatted: 'Total (€)',
                created_at: 'Erstellt am',
                status: 'Status',
                first_name: 'Vorname',
                last_name: 'Nachname',
                email: 'Email',
                name: 'Name',
                nrOrders: 'No. Bestellungen',
                formattedSumTotalOrders: 'Total (€)',
                formattedStatDate: 'Datum',
                formattedCreatedAt: 'Erstellt am',
                formattedTotal: 'Total (€)',
                company: 'Firma',
            },
            'headers': {
                'total-revenue-this-year': `Umsatz ${new Date().getFullYear()} (net / total)`,
                'total-revenue-this-month': `Aktueller Monat (net / total)`,
                'total-registered-customers-all-time': `Angemeldete Kunden`,
                'total-registered-customers-now': `Neukunden Monat / Total`,
                'average-revenue-by-nr-orders-last-12months': `⌀ Warenkorb (net / total)`,
                'average-revenue-by-month-last-12months': `⌀ Umsatz p.m (net / total)`,
                'average-revenue-by-subscriptions-last-12-months': `Abos / Volumen (p.a.)`,
                'offers-orders-conversion-rate-last-12-months': `Angebote / Bestellungen aus Angeboten / Conversion Angebot2Bestellung`,
                'revenue-this-year': `Umsatzentwicklung pro Monat`,
                'total-revenue-by-main-category-this-month': `Umsatz Akt. Monat nach Produktbereich`,
                'help.revenue-by-year': `Umsatzentwicklung pro Kalenderjahr`,
                'total-revenue-by-main-category-this-year': `Umsatz ${new Date().getFullYear()} nach Produktbereich`,
                'nr_active_users_by_country': `Anzahl der aktiven Benutzer nach Land`,
                'top-products-by-revenue-this-year': `Umsatz Top 5 Produkte (${new Date().getFullYear()})`,
                'abandoned-carts': 'Abandoned Carts in last 30 days',
                'nr_orders_from_abandoned_carts': 'Nr. orders from abandoned carts',
                'nr_abandoned_carts': 'Nr. abandoned carts',
                'nr_lost_orders': 'Nr. lost orders',
                'recover_rate': 'Recover Rate'
            },
            'help': {
                //Umsatz 2022 (net / total)
                'total-revenue-this-year': `Umsatz für alle Bestellungen, die den Kriterien entsprechen:
                    - Status = bearbeitet
                    - Quelle = Shop
                    - Jahr der Auftragserstellung = aktuelles Jahr
                `,
                //Aktueller Monat (net / total)
                'total-revenue-this-month': `Umsatz für alle Aufträge, die den Kriterien entsprechen:
                        - Status = bearbeitet
                        - Quelle = Shop
                        - Jahr der Auftragserstellung = aktuelles Jahr
                        - Monat der Auftragserstellung = aktueller Monat`,
                //Angemeldete Kunden
                'total-registered-customers-all-time': `ANZAHL aller Benutzer, die den Kriterien entsprechen:
                        - Benutzerrolle = Kunde
                        - Benutzerstatus = aktiv
                        - Quelle der Benutzerregistrierung = Shop`,
                //Neukunden Monat / Total
                'total-registered-customers-now': `**ANZAHL** aller Benutzer, die den Kriterien entsprechen:
                        - Benutzerrolle = Kunde
                        - Benutzerstatus = aktiv
                        - Quelle der Benutzerregistrierung = Shop
                        - Monat/Jahr der Benutzerregistrierung = aktueller Monat/Jahr`,
                //⌀ Warenkorb (net / total)
                'average-revenue-by-nr-orders-last-12months': `**AVG = UMSATZ / Anzahl Bestellungen** von  Bestellungen, die den Kriterien entsprechen:
                        - Bestellung erstellt zwischen jetzt - 12 Monate 
                        - Quelle der Bestellung = Shop
                        - Bestellungsstatus = bearbeitet`,
                //⌀ Umsatz p.m (net / total)
                'average-revenue-by-month-last-12months': `**AVG= SUMME UMSATZ  / 12** von Bestellungen, die den Kriterien entsprechen:
                    - Bestellungen, die zwischen jetzt - 12 Monaten erstellt wurden
                    - Quelle der Bestellung = Shop
                    - Bestellungsstatus = bearbeitet`,
                //Abos / Volumen (p.a.)
                'average-revenue-by-subscriptions-last-12-months': `**Abos**
                        - ermittle alle Warenkorbartikel, die eine Häufigkeit (= Abo) im Zeitraum jetzt - 12 Monate haben
                        - Durchschnittswert berechnen = Gesamtabos / 12
                    **Umsatz (p.a.)**
                        - Identifiziere alle Warenkorbartikel, die im Zeitraum jetzt - 12 Monate eine Häufigkeit haben (= Abo)
                        - berechne Durchschnitt = Umsatz (Artikel gesamt) / anzahl abos`,
                //Angebote / Bestellungen aus Angeboten / Conversion Angebot2Bestellung
                'offers-orders-conversion-rate-last-12-months': `**Offers**: Anzahl  Angebote, die den Kriterien entsprechen:
                    - Angebote, die zwischen jetzt - 12 Monaten und jetzt mit einem bestimmten Warenkorb erstellt wurden
                    ---
                    **Bestellungen aus Angeboten**: Anzahl Bestellungen, die die Kriterien erfüllen:
                    - Bestellungen, die zwischen jetzt - 12 Monaten erstellt wurden
                    - Auftragsstatus = bearbeitet
                    - Bestellungsquelle = Shop
                    - Warenkobr = alle
                    ---
                    **Conversion Rate**: Anzahl Bestellungen aus Angeboten / Anzahl Angeboten* 100`,

                //Umsatzentwicklung pro Monat
                'revenue-this-year': `a) Bestellungen nach Monat im laufenden Jahr mit den folgenden Auftragskriterien gruppieren:
                    - Status = bearbeitet
                    - Quelle = Shop
                    - Jahr der Auftragserstellung = laufendes Jahr
                    b) SUMME (Summe) für jede Auftragsgruppe
                    c) Berechne für jede Gruppe den Zuwachs im Verhältnis zur Summe des Vormonats`,

                //Umsatz Akt. Monat nach Produktbereich
                'total-revenue-by-main-category-this-month': `a) für jede Bestellung - wir berechnen eine Gesamtsumme pro Produkt
                    z.B.:
                    - Produkt A, Gesamtbetrag: 1000 eur, Hauptkategorie: ACS
                    - Produkt B, Gesamtbetrag: 2000 eur, Hauptkategorie: ACS
                    b) Gruppierung aller bestellten Produkte nach Produkt-Hauptkategorie
                    c) für jede Gruppe die Summe (Gesamtbetrag) aller unter Punkt a) berechneten Produktgesamtbeträge
                    d) Die bestellten Produkte sollten Teil von Bestellungen sein, die den Kriterien entsprechen:
                        - Quelle der Bestellung = Shop
                        - Status der Bestellung = bearbeitet
                        - Erstellungsjahr/Monat der Bestellung = aktuelles Jahr/Monat`,

                //Umsatzentwicklung pro Kalenderjahr
                'revenue-by-year': `a) Gruppierung von Bestellungen nach Jahr, Bestellungen mit den folgenden Kriterien:
                    - Status = bearbeitet
                    - Quelle = Shop
                    b) Umsatz für jede Auftragsgruppe
                    c) Berechne für jede Gruppe den Zuwachs im Verhältnis zur Gesamtumsatz des Vorjahres`,

                //`Umsatz 2022 nach Produktbereich
                'total-revenue-by-main-category-this-year': `a) für jede Bestellung - wir berechnen eine Gesamtsumme pro Produkt
                    z.B.:
                    - Produkt A, Gesamtbetrag: 1000 eur, Hauptkategorie: ACS
                    - Produkt B, Gesamtbetrag: 2000 eur, Hauptkategorie: ACS
                    b) Gruppierung aller bestellten Produkte nach Produkt-Hauptkategorie
                    c) für jede Gruppe die Summe (Gesamtbetrag) aller unter Punkt a) berechneten Produktgesamtbeträge
                    d) Die bestellten Produkte sollten Teil von Bestellungen sein, die den Kriterien entsprechen:
                        - Quelle der Bestellung = Shop
                        - Status der Bestellung = bearbeitet
                        - Erstellungsjahr/Monat der Bestellung = aktuelles Jahr`,

                //Umsatz Top 5 Produkte (2022)
                'top-products-by-revenue-this-year': `a) für jede Bestellung berechnen wir eine Gesamtsumme nach Produkt
                    b) Gruppierung aller bestellten Produkte nach product_id
                    c) für jede Gruppe wird die Summe aller unter Punkt a) berechneten Produktgesamtbeträge gebildet
                    d) Die bestellten Produkte sollten Teil von Bestellungen sein, die den Kriterien entsprechen:
                        - Quelle der Bestellung = Shop
                        - Status der Bestellung = bearbeitet
                        - Erstellungsjahr der Bestellung = aktuelles Jahr`,
                //Nr active users by country
                'nr_active_users_by_country': `Anzahl der aktiven Benutzer nach Land`,
                'abandoned-carts': `a) nr lost orders: number of carts with the status different than "processed" that were abandoned in the last 30 days
                    b) nr abandoned carts: number of carts abandoned in the last 30 days
                    c) nr orders from abandoned carts: orders with the status "processed" created in the last 30 days with a cart marked as "abandoned"
                    d) recover rate: (nr orders from abandoned carts / nr abandoned carts) * 100
                `
            },
            placeholders: {
                all_countries: 'All countries',
                countries: 'Countries'
            },
        },
        'admin/users': {
            name: 'Benutzer',
            last_login: 'Letzte Anmeldung',
            from: 'Von',
            action_buttons: {
                sap_activate: 'SAP activate',
            },
            fields: {
                name: 'Name',
                sap_status: 'Sap Status',
                email_verified_at: 'Geprüft am',
                first_name: 'Vorname',
                last_name: 'Nachname',
                email: 'Email',
                company: 'Firma',
                send_reset_password_link: 'Link zum Zurücksetzen des Passworts senden',
                send_email_confirmation_link: 'E-Mail-Bestätigungslink senden',
                email_verified: 'E-Mail verifiziert',
                country: 'SAP/VKORG country'
            },
            roleOptions: {
                admin: 'Admin',
                sap: 'Sap',
                manager: 'Manager',
                customer: 'Customer',
                "location-manager": "Location Manager",
                "murr-intern": "Murr-Intern",
                "content-manager": "Content Manager",
                "country-manager": "Country Manager",
                "sales-manager": "Sales Manager",
                "sales-professional": "Sales Professional",
                "partner": "Partner",
                "account-manager": "Account Manager",
                "catalog-customer": "Catalog Customer"
            },
            statusOptions: {
                active: 'Active',
                inactive: 'Inactive',
            },
            fieldGroups: {
                identity: 'Identity',
                password: 'Password',
                sap_data: 'Sap Data',
                invoice_address: 'Billing Address',
                client_info: 'Client Info'
            },
            fieldButtons: {
                reset_password: 'Reset Password',
                email_confirmation_link: 'Email Confirmation Link'
            },
            page: {
                delete: 'Delete User',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
            titles: {
                edit_user: 'Edit user #%{info}',
            },
            messages: {
                are_you_sure_you_want_to_sent_the_reset_password_link: 'Are you sure you want to send the reset password link?',
                reset_password_link_sent: 'Resent password link sent with success',
                are_you_sure_you_want_to_sent_email_confirmation_link: 'Are you sure you want to send the email confirmation link?',
                email_confirmation_link_sent: 'Email confirmation link sent with success',
                are_you_sure_you_want_to_manually_confirm_the_user_email: 'Are you sure you want to manually confirm the user\'s email?',
                email_confirmed: 'Email confirmed with success',
                sap_activation_success: 'User activated with success',
                sap_activation_fail: 'User activation failed'
            }
        },
        'admin/posts': {
            name: 'Posts',
            view: 'View Posts',
            create: 'Create Post',
            fields: { //these are used in the forms
                author: 'Author',
                role: 'Role',
                slug: 'Slug',
                status: 'Status',
                content: 'Content',
                title: 'Title',
                subtitle: 'Subtitle',
                id: 'Id',
                teaser_category_id: 'Category ID',
                bg_color: 'Background Color',
                bg_image_src: 'Background Image Src',
                text: 'Text',
                button_url: 'Button Url',
                button_text: 'Button Text',
                image_src: 'Image Src',
                image_path: 'Image Path',
                hierarchy: 'Hierarchy',
                template: 'Template',
                available_from: 'Available From',
                available_to: 'Available To',
                price: 'Price',
                nr_variations: 'Nr. Variations',
                visitor_countries: 'Visitor Countries',
                meta: {
                    price: 'Price',
                    button_text: 'Button text',
                    button_url: 'Button url',
                    title: 'Title',
                    image_src: 'Image src',
                    image_path: 'Upload image',
                    categories: 'Categories',
                    seo_title: 'SEO Title',
                    seo_description: 'SEO Description',
                    seo_keywords: 'SEO Keywords',
                    seo_robots: 'Robots',
                    slides: 'Slides',
                    slide_duration: 'Slide duration (seconds)',
                }
            },
            statusOptions: {
                publish: 'Publish',
                draft: 'Draft'
            },
            typeOptions: {
                page: 'Page',
                content: 'Content',
                modal: 'Modal'
            },
            translationStatusOptions: {
                active: 'Active',
                inactive: 'Inactive'
            },
            section: {
                seo: 'Seo',
                post_info: 'Post Info',
                meta: 'Meta'
            },
            titles: {
                edit_post: 'Edit post #%{id}'
            },
            buttons: {
                show_on_shop: 'Show on Shop'
            },
            info: {
                available_from: 'Choose date from when will be published',
                available_to: 'Choose date till when will be published',
            }
        },
        'admin/products': {
            name: 'Products',
            titles: {
                show_product: 'Show product: %{name}',
                product_variations: 'Product Variations'
            },
            buttons: {
                show_on_shop: 'Show on Shop'
            },
            fields: {
                id: 'Id',
                name_or_id: 'Name or Id',
                top_seller: 'Top Seller',
                created_at: 'Created At',
                name: 'Name'
            }
        },
        'admin/variations': {
            fields: {
                name: 'Name',
                price: 'Price',
                matnr: 'OrderId'
            }
        },
        'admin/orders': {
            name: 'Bestellungen',
            fields: {
                product_id: 'Produkt#',
                matnr: 'Matnr',
                product: 'Produkt',
                manager: 'Manager',
                customer: 'Kunde',
                qty: 'Menge',
                price: 'Preis',
                variation: 'Variation',
                customer_name_or_email: 'Name des Kunden / E-Mail',
                from: 'Von',
                to: 'An',
                total: 'Total',
                created_at: 'Erstellt am',
                cad_url: 'CAD',
                print_url: 'Drucken',
                pending: 'Ausstehend',
                processed: 'Verarbeitet',
                payment_status: 'Zahlungsstatus',
                sap_order_id: 'Bestellung-ID in SAP',
                status: 'Status',
                source: 'Quelle',
                payment_method: 'Zahlungsmethode',
                unzer_short_id: 'Unzer Kurz-ID',
                unzer_payment_id: 'Unzer Payment ID',
                shipping_date: 'Versanddatum',
                shipping_date_notification_action: 'Aktion zum Versanddatum',
                approve_shipping_date: 'Senden Sie genehmigen Versanddatum',
                reject_shipping_date: 'Versanddatum ändern',
            },
            badge: {
                sample: 'Muster',
            },
            titles: {
                edit_order: 'Bestellung #%{id}'
            },
            buttons: {
                submit_to_sap: 'An SAP übermitteln'
            }
        },
        'admin/translations': {
            name: 'Translations',
            buttons: {
                save_translation: 'Save translation',
                publish_translation: 'Publish translation',
                reset: 'Reset',
                remove: 'Remove language',
                translate_field: 'Translate field',
                add_new_language: 'Add new language',
            },
            messages: {
                translation_saved: 'Translation saved with success!',
                translation_not_saved: 'Error! Translation not saved!',
                translation_reset: 'Translation reset with success!',
                translation_not_reset: 'Error! Translation not reset!',
                translation_published: 'Translation published with success!',
                translation_not_published: 'Error! Translation not published!',
                you_have_unsaved_changes: 'Warning! Your have unsaved translations, are you sure your want to continue?',
                language_added: 'Language added successfully.',
                language_not_added: 'Error! Language was not added.',
                cannot_remove_de_or_only_language: 'DE langauge cannot be removed.',
                removed_language: 'Language removed successfully.',
                failed_removed_language: 'Error! Language was not removed',
                please_select_language_from_and_to: 'Please select new language and template language.',

            },
            fields: {
                all_locales: "Choose new language!",
                template_language: "Choose template language!"
            }
        },
        'admin/mail-templates': {
            name: 'Mail Templates',
            fields: {
                subject: 'Subject',
                cc: 'CC',
                to: 'TO',
                bcc: 'BCC',
                to_localized: 'TO (localized)',
                to_localized_country: 'Country',
                to_localized_email: 'Email',
                to_localized_locale: 'Locale'
            },
            titles: {
                edit_mail_template: 'Edit mail template #%{id}'
            },
            statusOptions: {
                active: 'Active',
                draft: 'Draft'
            },
        },
        'admin/backups': {
            name: 'Backups',
            fields: {
                path: 'Path',
            },
            buttons: {
                restore: 'Restore'
            },
            messages: {
                'restore_with_success': 'Restore has been scheduled with success',
                'restore_failed': 'Restore failed'
            }
        },
        'admin/lod': {
            name: 'LOD',
        },
        'admin/lod-colors': {
            name: 'Colors',
            titles: {
                create: 'Add new Color',
                edit: 'Edit Color: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-foils': {
            name: 'Foils',
            titles: {
                create: 'Add new Foil',
                edit: 'Edit Foil: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-thickness': {
            name: 'Thickness',
            titles: {
                create: 'Add new Thickness',
                edit: 'Edit Thickness: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-materials': {
            name: 'Materials',
            titles: {
                create: 'Add new Material',
                edit: 'Edit Material: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-holes': {
            name: 'Holes',
            titles: {
                create: 'Add new Hole',
                edit: 'Edit Hole: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-shapes': {
            name: 'Shapes',
            titles: {
                create: 'Add new Shape',
                edit: 'Edit Shape: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-price-matrix': {
            name: 'Price Matrix',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                signs: 'signs'
            }
        },
        'admin/lod-raw-material-prices': {
            name: 'Raw Material Prices',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                color: 'Color',
                foil: 'Foil',
                price: 'Price'
            }
        },
        'admin/lod-hole-image-shape': {
            name: 'Hole/Image/Shape',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            orientation: {
                landscape: 'Landscape',
                portrait: 'Portrait'
            },
            fields: {
                image: 'Image',
                shape: 'Shape',
                hole: 'Hole',
                mass_1: 'L',
                mass_2: 'B',
                mass_3: 'D',
                mass_4: 'd',
                mass_5: 'R',
                mass_6: 'x',
                mass_7: 'y',
                mass_8: 'X1',
                mass_9: 'X2',
                mass_10: 'Y1',
                mass_11: 'Y2'
            }
        },
        'admin/lod-material-configs': {
            name: 'Material Configs',
            titles: {
                edit: 'Edit material config',
                create: 'Create material config'
            },
            sections: {
                gewicht: 'Gewicht',
                mattenbreite: 'Mattenbreite',
                menge: 'Menge',
                wkz: 'WKZ',
                rohmaterial: 'Rohmaterial',
                rabatt: 'Rabatt'
            },
            status: {
                'active': 'Active',
                'inactive': 'Inactive'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                gewicht: {
                    matte_248x248_in_gramm: 'Matte 248x248 in Gramm',
                    pro_mm2_in_g: 'Pro mm2 in g'
                },
                wkz: {
                    '1_klein': 'WKZ 1 (klein)',
                    '2_gross': 'WKZ 2 (gross)',
                    'umfang': 'WKZ Umfang'
                },
                foil_id: "Foil",
                color_id: "Color",
                extra_charge: "Extra charge"
            }
        },
        'admin/lod-images': {
            name: 'Images',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                image_path: 'Image file'
            }
        },
        'admin/lod-price-list': {
            name: 'Price list',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {}
        },
        'admin/lod-price-list-factors': {
            name: 'Price list Factors',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                price_list: 'Price list'
            }
        },
        'admin/lod-quellwerk': {
            name: 'Quellwerk',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {}
        },
        'admin/offers': {
            name: 'Angebote',
            fields: {
                total: 'Total',
                created_at: 'Erstellt am',
                company: 'Firma',
                rocessed: 'Verarbeitet',
                active: 'Aktiv',
                expired: 'Abgelaufen',
                cart_status: 'Warenkorb Status',
                client_offer_id: 'Kunde Angebots-ID',
                cart_id: 'Warenkorb ID',
                offer_type: 'Offer Type'
            },
            buttons: {
                export: 'PDF'
            },
            status_options: {
                active: 'Aktiv',
                expired: 'Abgelaufen',
                processed: 'Verarbeitet',
            },
            is_catalog_offer_options: {
                all: 'All types',
                no: 'Shop',
                yes: 'Catalog'
            }
        },
        'admin/lod-wintyps': {
            name: 'Wintyps',
            titles: {
                create: 'Add new Wintyp',
                edit: 'Edit Wintyp: %{name}'
            },
            fields: {
                blocked_at: 'Blocked',
                used_at: 'Used',
                product: 'Product',
                order_id: 'Order #'
            }
        },
        'admin/shipping-dates': {
            name: 'Shipping Dates',
            titles: {
                shipping_countries: 'Shipping Countries',
                shipping_date: 'Shipping Date',
                create: 'Add new Shipping Date',
                edit: 'Edit Record: %{name}',
            },
            sections: {
                recurrence: 'Recurrence'
            },
            fields: {
                shipping_country_ids: 'Shipping Countries (iso2)',
                is_today_allowed: 'Is today allowed (Order before 09:00)'
            }
        },
        'admin/shipping-countries': {
            name: 'Shipping Countries',
            titles: {
                create: 'Add new Shipping Country',
                edit: 'Edit Shipping Country: %{name}'
            },
            fields: {
                code: 'Country Code (iso 2)'
            }
        },
        'admin/shipping': {
            name: 'Shipping'
        },
        'admin/cob': {
            name: 'COB'
        },
        'admin/cob-cobots': {
            name: 'Cobots',
            fields: {
                manufacturer: 'Manufacturer',
                type: 'Type',
                model: 'Model',
                axis6_tape: 'Axis 6 Tape',
                system_holder_1: 'System Holder #1',
                system_holder_2: 'System Holder #2',
                hose_length: 'Hose Length',
                tension_clamp: 'Tension Clamp',

                //ua
                ua_shs_center: 'Center',
                ua_shs_outside: 'Outside',
                ua_shs_inside: 'Inside',
                ua_tape_center: 'Center',
                ua_tape_outside: 'Outside',
                ua_tape_inside: 'Inside',
                ua_feeding_system: 'Feeding System',

                //lq
                la_shs_center: 'Center',
                la_shs_outside: 'Outside',
                la_shs_inside: 'Inside',
                la_tape_center: 'Center',
                la_tape_outside: 'Outside',
                la_tape_inside: 'Inside',
                la_feeding_system: 'Feeding System'

            },
            titles: {
                create: 'Add New Cobot',
                edit: 'Edit Cobot %{name}'
            },
            fieldGroups: {
                manufacturer_details: 'Manufacturer Details',
                technical_master_data: 'Technical Master Data',
                technical_details_upper_support_arm: 'Technical Details - Upper Arm',
                technical_details_bottom_support_arm: 'Technical Details - Lower Arm',
                shs: 'SHS',
                tape: 'Tape'
            }
        },
        'admin/cob-manufacturers': {
            name: 'Manufacturers',
            titles: {
                create: 'Add New Manufacturer',
                edit: 'Edit Manufacturer: %{name}'
            }
        },
        'admin/cob-types': {
            name: 'Types',
            titles: {
                create: 'Add New Type',
                edit: 'Edit Type: %{name}'
            },
            fields: {
                manufacturer: 'Manufacturer'
            }
        },
        'admin/cob-models': {
            name: 'Models',
            titles: {
                create: 'Add New Model',
                edit: 'Edit Model: %{name}'
            },
            fields: {
                manufacturer: 'Manufacturer',
                type: 'Type'
            }
        },
        'admin/cob-products': {
            name: 'Products',
            titles: {
                create: 'Add New Product',
                edit: 'Edit Product: %{name}'
            },
            fieldGroups: {
                product_details: 'Details',
                product: 'Product'
            },
            fields: {
                name: 'Name',
                nominal_size: 'Nominal size',
                profile: 'Profile',
                configuratorStatus: 'Configurator status'
            },
            group: {
                hose: 'Hose',
                hose_hf: 'Hose Highly Flexible',
                tension_clamp: 'Tension Clamp',
                belt: 'Belt',
                system_holder: 'System Holder',
                end_grommet: 'End Grommet',
                hose_ring_zl: 'Hose Ring Zl',
                hose_ring_fixed: 'Hose Ring Fixed',
                shs: 'SHS',
                fhs_c: 'System Holder FHS-C',
                fhs_uhe: 'System Holder FHS-UHE',
                fhs_sh: 'System Holder FHS-SH',
                options: 'Option',
                velcro: 'Velcro',
                velcro_silicone_free: 'Velcro silicone free',
                stainless_steel_band: 'Stainless Steel Band',
                return_system: 'Return System'
            },
            design: {
                slotted: 'Slotted',
                closed: 'Closed'
            },
            status: {
                active: 'Active',
                inactive: 'Inactive'
            }
        },
        'admin/kdh': {
            name: 'KDH'
        },
        'admin/kdh-frames': {
            name: 'Frames',
            titles: {
                create: 'Add Frame'
            }
        },
        'admin/kdh-grommets': {
            name: 'Grommets',
            titles: {
                create: 'Add Grommet'
            }
        },
        'admin/abandoned-carts': {
            name: 'Abandoned Carts',
            titles: {
                abandoned_at: 'Abandoned at',
                abandoned_notification_1_at: 'Notification #1',
                abandoned_notification_2_at: 'Notification #2',
                abandoned_notification_3_at: 'Notification #3',
                recovered_from_1_at: 'Recovered from #1',
                recovered_from_2_at: 'Recovered from #2',
                recovered_from_3_at: 'Recovered from #3',
                order: 'Order #',
                cart: 'Cart',
            }
        },
        'admin/modals': {
            name: 'Modals',
            view: 'View Modals',
            create: 'Create Modal',
            fields: { //these are used in the forms
                status: 'Status',
                content: 'Content',
                title: 'Title',
                subtitle: 'Subtitle',
                id: 'Id',
                meta: {
                    subtitle: 'Subtitle',
                    button_cta_text: 'Button CTA text',
                    button_cta_url: 'Button CTA url',
                    button_close_text: 'Button CLOSE text',
                    button_close_url: 'Button CLOSE url',
                    newsletter: 'Newsletter',
                    promotion_code: 'Promotion Code',
                    logged_in_only: 'Logged-in users only',
                    datetime_from: 'From',
                    datetime_to: 'To',
                    countries: 'Countries',
                    image_src: 'Image Url',
                    image_path: 'Upload Image',
                    page_type: 'Page Type',
                    extra_info: 'Extra Info',
                    is_prio: 'Prio Message'
                }
            },
            statusOptions: {
                publish: 'Publish',
                draft: 'Draft',
                info: 'Select if content should be published.'
            },
            date: {
                available_from_info: 'The modal will be available from this date on',
                available_to_info: 'The modal will be available until this date',
            },
            templateOptions: {
                default: 'Default',
                newsletter: 'Newsletter',
                coupon_code: 'Coupon Code',
                service_notification: 'Service Notification',
                info: 'Select predefined template for notifications',
            },
            pageTypeOptions: {
                homepage: 'Home',
                category_search: 'Search',
                product_details: 'Product Details',
                info: 'Select if needed to restrict display on specific page type',
            },
            titles: {
                edit_modal: 'Edit modal #%{id}'
            },
            sections: {
                button_cta: 'Button CTA',
                button_close: 'Button CLOSE',
                settings: 'Settings'
            },
            info: {
                available_from: 'Choose date from when will be published',
                available_to: 'Choose date till when will be published',
                image_src: 'Use external url for image',
                image_path: 'Upload local image',
                country: 'Using this function you can restrict the notification to be displayed only for specific users depending on his country',
                button_cta_url: 'Add url to CTA button.',
                button_close_url: 'Add url to close button. If added, url will take effect alongside the close action.',
                is_prio: 'This function will force the notification to be displayed after user has logged in',
                pageTypeOptions: 'Using this function you can restrict the notification to be displayed only on specific pages',
                authenticated_only: 'If selected, this function will restrict the notification to be displayed only for logged in users',
            },
        },
        'admin/permissions': {
            name: 'Permissions',
            titles: {
                edit: 'Edit Permissions for "%{name}"'
            },
            roleOptions: {
                customer: 'Customer',
                'murr-intern': 'Murr Intern '
            },
            permissions: {
                "front-end-manage-orders": "Manage Orders",
                "front-end-manage-wishlists": "Manage Wishlists"
            }
        },
        'admin/visitor-countries': {
            name: 'Visitor Countries',
            titles: {
                create: 'Add new Visitor Country',
                edit: 'Edit Visitor Country: %{name}',
                catalog: 'Catalog',
                murr_contact_info: 'Murr Contact Info'
            },
            fields: {
                code: 'Country Code (iso 2)',
                name: 'Name',
                default_language: 'Default Language',
                vkorg: 'VKORG',
                terms_link: 'General terms and conditions',
                is_catalog_only: 'Is Catalog Only',
                catalog_show_prices: 'Show Prices',
                catalog_price_group: 'Price Group',
                catalog_vat_percentage: 'Vat Percentage',
                catalog_delivery_price: 'Delivery Price',
                catalog_currency: 'Currency',
                murr_contact_info: {
                    company_name: 'Company Name',
                    phone:'Phone',
                    fax: 'Fax',
                    address_line_1: 'Address Line 1 (Street name, Number)',
                    address_line_2: 'Address Line 2 (Zip Code, City)',
                    country: 'Country',
                    url: 'Url'
                }
            },
            statusOptions: {
                active: 'Active',
                inactive: 'Inactive'
            },
            paymentOptions: {
                invoice: 'Invoice',
                unzer: 'Unzer'
            },
        },
        'admin/product-reviews': {
            name: 'Product reviews',
            titles: {
                create: 'Add new Product Review',
                edit: 'Edit Product Review: %{name}'
            },
            fields: {
                headline: 'Headline',
                product: 'Product',
                order_id: 'Order #',
                star_rating: 'Star Rating',
                user: 'User'
            },
            statusOptions: {
                all: 'All',
                open: 'Open',
                publish: 'Publish',
                reject: 'Reject'
            },
        },
        'admin/vouchers': {
            name: 'Vouchers',
            titles: {
                voucher_usage: 'Voucher Usage'
            },
            statusOptions: {
                all: 'All',
                pending: 'Pending',
                sent: 'Sent',
                used: 'Used',
                expired: 'Expired'
            },
        },
        'admin/videos': {
            name: 'Videos',
            titles: {
                create: 'Add new Video',
                edit: 'Edit Video: %{name}',
            },
            fields: {
                title: 'Title',
                description: 'Description',
                url: 'YouTube Video URL',
                cover_photo_path: 'Cover Photo',
                video_category_ids: 'Categories',
                video_playlist_ids: 'Playlists',
                product_ids: 'Products'
            },
            info: {
                youtube_url: 'Must be a YouTube URL that contains the Video ID. Best use the "Share" function on YoutUve to get the correct URL.',
                cover_photo: 'It is not mandatory. Only if you want a different cover photo for your video instead the default preview.',
                product_ids: 'It is not mandatory. Select a product if you want this video to be displayed in the top of the product details page.',
                video_category_ids: 'Select the category your video will belong to.',
                video_playlist_ids: 'Select the playlist your video will belong to.',
                video_configurators: 'It is not mandatory. Select a configurator if you want this video to be displayed in the top of the configurator page.',
                is_recommended: 'Turn this switch ON if you want the video to be displayed in "For you" section on Videos page in Shop.',
                is_highlighted: 'Turn this switch ON if you want the video to be displayed in "Highlights" section on Videos page in Shop.',
                is_active: 'Turn this switch ON if you want the video to be published and become visible in Shop.',
                playlist_category_ids: 'Select the category your playlist will belong to.',
                playlist_cover_photo: 'It is not mandatory. Only if you want a different cover photo for your playlist instead the default preview.',
            }
        },
        'admin/video-playlists': {
            name: 'Video Playlists',
            titles: {
                create: 'Add new Video Playlist',
                edit: 'Edit Video Playlist: %{name}',
            },
            fields: {
                name: 'Name',
                cover_photo_path: 'Cover Photo'
            },
        },
        'admin/video-categories': {
            name: 'Video Categories',
            titles: {
                create: 'Add new Video Category',
                edit: 'Edit Video Category: %{name}',
            },
            fields: {
                name: 'Name',
            },
        },
        'admin/gs-flows': {
            name: 'Flows',
        },
    },
};

export default customGermanMessages;
