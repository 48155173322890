import * as React from 'react';
import {FC} from 'react';
import {
    AutocompleteArrayInput,
    BooleanInput,
    Button,
    DateField,
    DateTimeInput,
    Edit,
    EditProps,
    email,
    FieldProps,
    FormWithRedirect,
    required,
    SaveButton,
    SelectInput,
    TextField,
    TextInput,
    Toolbar,
    useLocale,
    useNotify,
    usePermissions,
    useTranslate
} from 'react-admin';
import {Box, Typography} from '@material-ui/core';

import {User} from '../../config/types';
import SapData from "./SapData";
import axiosForApi from "../../config/axios-for-api";
import cfgLocale from '../../config/locale';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockOpen from "@material-ui/icons/LockOpen";
import {filterProps, renderElement} from "../../functions/common";
import {listEditCreate} from "../../styles/listEditCreate";
import countries from "../../config/countries";

const UserEdit: FC<EditProps> = props => {
    const newProps = filterProps(props);

    return (
        <Edit
            {...newProps}
            undoable={false}
        >
            <UserForm/>
        </Edit>
    );
};

const Title: FC<FieldProps<User>> = ({record}) => {
    const translate = useTranslate();
    return record ? (<Typography variant='h6'><b>{translate('resources.admin/users.titles.edit_user', {info: `${record.id}. ${record.first_name} ${record.last_name}`})}</b></Typography>) : null;
}

const UserForm = (props: any) => {
    const translate = useTranslate();
    const locale = useLocale();
    const notify = useNotify();
    const languageOptions = cfgLocale.languageOptions;
    const newProps = filterProps(props);
    const classes = listEditCreate();
    const {permissions} = usePermissions();

    const handleSendResetPasswordLink = async (id: number) => {
        if (!id) return;

        const confirmSendLink = window.confirm(translate('resources.admin/users.messages.are_you_sure_you_want_to_sent_the_reset_password_link'));
        if (confirmSendLink) {
            await axiosForApi.post('api/admin/send-reset-password-link', {user_id: id});
            notify('resources.admin/users.messages.reset_password_link_sent', 'success');
        }
    }

    const handleSendEmailConfirmationLink = async (id: number) => {
        if (!id) return;

        const confirmSendLink = window.confirm(translate('resources.admin/users.messages.are_you_sure_you_want_to_sent_email_confirmation_link'));
        if (confirmSendLink) {
            await axiosForApi.post('api/admin/send-email-confirmation-link', {user_id: id});
            notify('resources.admin/users.messages.email_confirmation_link_sent', 'success');
        }
    }

    const handleSapActivation = async (id: number) => {
        if (!id) return;
        try {
            await axiosForApi.post(`api/admin/users/${id}/attempt-sap-activation`);
            notify('resources.admin/users.messages.sap_activation_success', 'success');
        } catch (err) {
            notify('resources.admin/users.messages.sap_activation_fail', 'error');
        }
    }


    return (
        <FormWithRedirect
            {...newProps}
            render={(formProps: any) => {
                const can = {
                    'update-email': (permissions === 'admin') && (formProps.record.role !== 'customer'),
                    'update-name': (permissions === 'admin') && (formProps.record.role !== 'customer'),
                    'update-role': (permissions === 'admin')
                };

                return (
                    <div className={classes.sidebar}>
                        <Title record={formProps.record}/>
                        {renderElement(<Box mb={2}/>)}
                        <Typography gutterBottom variant="h6">
                            {translate(
                                'resources.admin/users.fieldGroups.identity'
                            )}
                        </Typography>
                        {translate('resources.admin/users.last_login')}: {formProps.record.last_login_at ? (
                        <DateField source="last_login_at" locales={locale} showTime={true}/>) : '-'} {translate('resources.admin/users.from')} {formProps.record.last_login_ip ? (<TextField source="last_login_ip"/>) : '-'}
                        {renderElement(<Box mb={2}/>)}
                        <TextInput
                            source="first_name"
                            resource="users"
                            fullWidth
                            InputProps={{readOnly: !can["update-name"]}}
                            helperText={false}
                        />
                        <TextInput
                            source="last_name"
                            resource="users"
                            fullWidth
                            InputProps={{readOnly: !can["update-name"]}}
                            helperText={false}
                        />
                        <TextInput
                            type="email"
                            source="email"
                            resource="customers"
                            validate={[email(), required()]}
                            fullWidth
                            InputProps={{readOnly: !can["update-email"]}}
                            helperText={false}
                        />
                        {['customer', 'catalog-customer'].includes(formProps.record.role) && <TextInput
                            type="phone"
                            source="phone"
                            resource="users"
                            fullWidth
                            InputProps={{readOnly: true}}
                            helperText={false}
                        />}
                        {['customer', 'catalog-customer'].includes(formProps.record.role) && <TextInput
                            type="text"
                            source="company"
                            resource="users"
                            fullWidth
                            InputProps={{readOnly: true}}
                            helperText={false}
                        />}
                        {['catalog-customer'].includes(formProps.record.role) && <TextInput
                            type="text"
                            source="company2"
                            resource="users"
                            fullWidth
                            InputProps={{readOnly: true}}
                            helperText={false}
                        />}

                        {['catalog-customer'].includes(formProps.record.role) && <TextInput
                            type="text"
                            source="street"
                            resource="users"
                            fullWidth
                            InputProps={{readOnly: true}}
                            helperText={false}
                        />}

                        {['catalog-customer'].includes(formProps.record.role) && <TextInput
                            type="text"
                            source="city"
                            resource="users"
                            fullWidth
                            InputProps={{readOnly: true}}
                            helperText={false}
                        />}

                        <TextInput
                            type="text"
                            source="taxnumber"
                            resource="users"
                            fullWidth
                            helperText={false}
                        />

                        <TextInput
                            type="text"
                            source="price_group"
                            resource="users"
                            fullWidth
                            InputProps={{readOnly: true}}
                            helperText={false}
                        />

                        {['catalog-customer'].includes(formProps.record.role) && <BooleanInput
                            source="catalog_show_prices"
                            resource="users"
                            fullWidth
                            helperText={false}
                        />}


                        {!(formProps.record.role === 'customer' && formProps.record.status === 'inactive') && (
                            <>
                                <DateTimeInput className={classes.halfWidth} label="resources.admin/users.fields.email_verified_at" source="email_verified_at" fullWidth/>
                                <SelectInput validate={formProps.record.role !== 'customer' ? requiredValidate : () => null} source="status" choices={[
                                    {id: 'active', name: translate('resources.admin/users.statusOptions.active')},
                                    {id: 'inactive', name: translate('resources.admin/users.statusOptions.inactive')}
                                ]}/>
                            </>
                        )}
                        <SelectInput source="locale" className={classes.halfWidth} choices={
                            Object.keys(languageOptions).map(langCode => {
                                return {
                                    id: langCode,
                                    name: translate(languageOptions[langCode].label)
                                }
                            })
                        }/>
                        <SelectInput InputProps={{readOnly: !can["update-role"]}} className={classes.halfWidth} validate={[required()]} source="role" choices={[
                            {id: 'admin', name: translate('resources.admin/users.roleOptions.admin')},
                            {id: 'location-manager', name: translate('resources.admin/users.roleOptions.location-manager')},
                            {id: 'customer', name: translate('resources.admin/users.roleOptions.customer')},
                            {id: 'catalog-customer', name: translate('resources.admin/users.roleOptions.catalog-customer')},
                            {id: 'sap', name: translate('resources.admin/users.roleOptions.sap')},
                            {id: 'sales-manager', name: translate('resources.admin/users.roleOptions.sales-manager')},
                            {id: 'sales-professional', name: translate('resources.admin/users.roleOptions.sales-professional')},
                            {id: 'content-manager', name: translate('resources.admin/users.roleOptions.content-manager')},
                            {id: 'partner', name: translate('resources.admin/users.roleOptions.partner')},
                            {id: 'account-manager', name: translate('resources.admin/users.roleOptions.account-manager')},
                        ]}/>

                        <SelectInput fullWidth={true} label="resources.admin/users.fields.country" source="country" choices={countries.map(el => ({id: el.code.toLowerCase(), name: el.name}))}/>

                        <TextInput
                            source="kunnr"
                            resource="users"
                            fullWidth
                            helperText={false}
                            validate={formProps.record.role === 'customer' ? requiredValidate : () => null}
                        />


                        <TextInput
                            source="parnr"
                            resource="users"
                            fullWidth
                            helperText={false}
                            validate={formProps.record.role === 'customer' ? requiredValidate : () => null}
                        />


                        {(formProps.record.role === 'customer' && formProps.record.status === 'inactive') && (
                            <BooleanInput source="send_welcome_email" initialValue="true"/>
                        )}

                        {renderElement(<Box mb={2}/>)}
                        {formProps.record.role === 'customer' && <SapData record={formProps.record}/>}
                        {formProps.record.role === 'customer' && <SelectInput allowEmpty emptyText="No group" source="shipping_group" className={classes.halfWidth} choices={
                            [
                                {
                                    id: 'sg_1', name: 'Group 1',
                                },
                                {
                                    id: 'sg_2', name: 'Group 2',
                                }
                            ]
                        }/>}

                        {(formProps.record.role !== 'customer') &&
                            <AutocompleteArrayInput fullWidth={true} source="locations" choices={countries.map(country => ({id: country.code, name: country.name}))}/>
                        }

                        <Toolbar className={classes.toolbar} style={{background: '#f4f4f4', flexWrap: 'wrap', justifyContent: 'flex-start', gap: '1rem'}}>
                            <>
                                <SaveButton
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                />
                                <Button
                                    size="medium"
                                    variant="text"
                                    color="secondary"
                                    type="button"
                                    label={'resources.admin/users.fields.send_reset_password_link'}
                                    onClick={() => handleSendResetPasswordLink(formProps.record.id)}
                                    startIcon={<MailOutlineIcon/>}
                                />
                                {formProps.record.role === 'customer' && (
                                    <Button
                                        size="medium"
                                        variant="text"
                                        color="secondary"
                                        type="button"
                                        label={'resources.admin/users.fields.send_email_confirmation_link'}
                                        onClick={() => handleSendEmailConfirmationLink(formProps.record.id)}
                                        startIcon={<MailOutlineIcon/>}
                                    />
                                )}
                                {formProps.record.email_confirmation_link && (
                                    <a style={{color: '#002536', textDecoration: 'none', fontWeight: 500, lineHeight: '1.75', padding: '6px 16px', fontSize: '0.875rem', textTransform: 'uppercase'}} rel="noreferrer"
                                       href={`${formProps.record.email_confirmation_link}`} target="_blank">{translate('resources.admin/users.fieldButtons.email_confirmation_link')}</a>)}

                                {formProps.record.canBeActivatedBySap && (<Button
                                    size="medium"
                                    variant="text"
                                    color="secondary"
                                    type="button"
                                    label={'resources.admin/users.action_buttons.sap_activate'}
                                    onClick={() => handleSapActivation(formProps.record.id)}
                                    startIcon={<LockOpen/>}
                                />)}
                            </>
                        </Toolbar>
                    </div>
                )
            }}
        />
    );
};

const requiredValidate = [required()];

export default UserEdit;
