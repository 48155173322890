import ListAltIcon from '@material-ui/icons/ListAlt';
import ShippingDateList from './ShippingDateList';
import ShippingDateCreate from "./ShippingDateCreate";

const resource = {
    list: ShippingDateList,
    create:ShippingDateCreate,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/shipping-dates.name'
    }
};

export default resource;
