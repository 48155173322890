import * as React from 'react';
import {
    Datagrid,
    List,
    EmailField,
    ListProps,
    EditButton,
    DateField,
    ChipFieldProps,
    BulkActionProps,
    Identifier,
    useGetMany,
    useRefresh,
    useUnselectAll,
    Button, BulkDeleteButton, Toolbar, CreateButton, ExportButton, usePermissions, useRecordContext
} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {RestoreFromTrash} from "@material-ui/icons";

import UserFilter from "./UserFilter";
import UserEdit from "../users/UserEdit";
import {Route} from "react-router";
import SideDrawer from "../../components/list/SideDrawer";
import TextField from "../../components/list/TextField";
import UserCreate from "../users/UserCreate";
import Chip from "@material-ui/core/Chip";
import {RootStateOrAny, useSelector} from "react-redux";
import {useNotify} from "ra-core";
import axiosForApi from "../../config/axios-for-api";
import {handleError} from "../../functions/common";
import {list} from "../../styles/list";

const useStyles = makeStyles(
    {
        active: {backgroundColor: '#D9EAD3'},
        deleted: {backgroundColor: '#F4CCCC'},
        chip: {margin: 4, cursor: 'inherit'},
    });

const StatusField = ({record, ...props}: ChipFieldProps) => {
    const label = record?.deleted_at ? 'deleted' : record?.status;
    const classes = useStyles(props);
    const _className = [classes.chip];

    if (record?.deleted_at) _className.push(classes.deleted);
    else if (record?.status === 'active') _className.push(classes.active);

    return <Chip label={label} className={_className.join(" ")}/>;
};

const Has2FaField = ({record, ...props}: ChipFieldProps) => {
    const label = record?.has2fa;
    const classes = useStyles(props);
    const _className = [classes.chip];

    if (record?.has2fa === 'active') _className.push(classes.active);

    return <Chip label={label} className={_className.join(" ")}/>;
};


type TStatus = 'active' | 'inactive' | 'trashed';

type TFilters = {
    status?: TStatus;
}

const BulkActions = ({selectedIds, ...props}: BulkActionProps): ReactElement | null => {
    const {permissions} = usePermissions();

    const {data, loading} = useGetMany(
        'admin/users',
        selectedIds as Identifier[]
    );

    const router = useSelector((state: RootStateOrAny) => state.router);
    const filters = ((): TFilters => {
        try {
            return JSON.parse(decodeURIComponent(router.location.query.filter));
        } catch (e) {
        }

        return {};
    })();

    const refresh = useRefresh();
    const unselectAll = useUnselectAll('admin/users');
    const notify = useNotify();

    const status = filters.status ?? null;

    if (!data || loading) {
        return null;
    }

    const handleRestore = async () => {
        axiosForApi.post('api/admin/users/restore', {
            ids: selectedIds,
        })
            .then(() => {
                unselectAll();
                refresh();
                notify('Oooook!', 'success')
            })
            .catch(e => notify(e.response.message ?? handleError(e), 'error'));
    }

     if(!['admin', 'location-manager'].includes(permissions)) return null;

    return (
        <>
            {status !== 'trashed' &&
                <BulkDeleteButton selectedIds={selectedIds} {...props} />
            }
            {status === 'trashed' &&
                <Button
                    type="button"
                    color="primary"
                    label="restore"
                    onClick={handleRestore}
                    style={{backgroundColor:'#D9EAD3'}}
                >{<RestoreFromTrash />}</Button>
            }
        </>
    )
};

const ActionButtons = (props:any) => {
    return <>
        {!props.record.deleted_at && <EditButton {...props} />}
    </>
};

const ListActions = ({basePath = ''}) => {
    let classes = list();
    const {permissions} = usePermissions();

    return (
        <Toolbar className={classes.toolbar}>
            {permissions === 'admin' && <CreateButton basePath={basePath}/>}
            <ExportButton />
        </Toolbar>
    );
}

const LocationsField = (props:any) => {
     const record = useRecordContext(props);
    return <span>{(record.locations??[]).join(", ")}</span>;
}

const UserList = (props: ListProps): ReactElement => {
    const {permissions} = usePermissions();//=role

    return (
        <Fragment>
            <List {...props} filters={<UserFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>} bulkActionButtons={<BulkActions />}>
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="register_method"/>
                    <Has2FaField source="has2fa"/>
                    <StatusField source="status"/>
                    <TextField source="sap_review_status"/>
                    <TextField source="role"/>
                    <TextField source="first_name"/>
                    <TextField source="last_name"/>
                    <TextField source="company"/>
                    <EmailField source="email"/>
                    <DateField source="email_verified_at" locales={'de'} showTime={true}/>
                    <TextField source="country"/>

                    {["admin"].includes(permissions) && <LocationsField source="locations" />}
                    <TextField source="locale"/>
                    <ActionButtons />
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/create`}>
                {({match}) => {
                    return (
                        <SideDrawer open={!!match} basePath={props.basePath ?? ''}>
                            <UserCreate {...props} />
                        </SideDrawer>
                    )
                }}
            </Route>
            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''} size={800}>
                            {isMatch && (
                                <UserEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default UserList;
