import * as React from 'react';
import {Datagrid, DateField, List, ListProps, ReferenceField, ShowButton} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

import AbandonedCartFilter from "./AbandonedCartFilter";
import TextField from "../../components/list/TextField";
import {Route} from "react-router";
import SideDrawer from "../../components/list/SideDrawer";
import AbandonedCartShow from "./AbandonedCartShow";

const AbandonedCartList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<AbandonedCartFilter/>} sort={{field: 'id', order: 'DESC'}}>
                <Datagrid>
                    <TextField source="id"/>
                    <ReferenceField label={"resources.admin/abandoned-carts.titles.order"} source="order_id" reference="admin/orders">
                        <TextField source="id"/>
                    </ReferenceField>
                    <DateField source="updated_at" locales={'de-DE'} showTime={true}/>
                    <DateField source="abandoned_at" label={"resources.admin/abandoned-carts.titles.abandoned_at"} locales={'de-DE'} showTime={true}/>
                    <DateField source="abandoned_notification_1_at" label={"resources.admin/abandoned-carts.titles.abandoned_notification_1_at"} locales={'de-DE'} showTime={true}/>
                    <DateField source="abandoned_notification_2_at" label={"resources.admin/abandoned-carts.titles.abandoned_notification_2_at"} locales={'de-DE'} showTime={true}/>
                    {/*<DateField source="abandoned_notification_3_at" label={"resources.admin/abandoned-carts.titles.abandoned_notification_3_at"} locales={'de-DE'} showTime={true}/>*/}
                    <DateField source="recovered_from_1_at" label={"resources.admin/abandoned-carts.titles.recovered_from_1_at"} locales={'de-DE'} showTime={true}/>
                    <DateField source="recovered_from_2_at" label={"resources.admin/abandoned-carts.titles.recovered_from_2_at"} locales={'de-DE'} showTime={true}/>
                    {/*<TextField source="recovered_from_3_at" label={"resources.admin/abandoned-carts.titles.recovered_from_3_at"} locales={'de-DE'} showTime={true} />*/}
                    <ShowButton icon={<ShoppingCartIcon />} label={"resources.admin/abandoned-carts.titles.cart"} />
                </Datagrid>
            </List>
             <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    const showProps = {basePath: props.basePath, resource: props.resource};
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''} size={800}>
                            {isMatch && (
                                <AbandonedCartShow id={match && isMatch ? match.params.id : undefined} {...showProps} />
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default AbandonedCartList;
