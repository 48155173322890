import React from 'react';
import {Filter, TextInput, FilterProps,} from 'react-admin';

const FoilFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput source="code" alwaysOn/>
        <TextInput source="name_lt" alwaysOn/>
        <TextInput source="name_kt" alwaysOn/>
    </Filter>
);

export default FoilFilter;
