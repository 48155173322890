import React from 'react';
import {Filter, FilterProps, TextInput, SelectInput} from 'react-admin';

const VisitorCountryFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
         <TextInput source="name" alwaysOn />
         <SelectInput alwaysOn source="status" choices={[
                {id: 'active', name: 'Active'},
                {id: 'inactive', name: 'Inactive'},
            ]}/>
    </Filter>
);

export default VisitorCountryFilter;
