import * as React from 'react';
import {FC} from 'react';
import {
    BooleanInput,
    Edit,
    EditProps,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    // ImageField,
    useTranslate
} from 'react-admin';

import Title from '../../../components/list/Title';
import ImageField from '../../../components/list/ImageField';
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const HoleImageShapeEdit: FC<EditProps> = props => {
    const translate = useTranslate();
    const classes = listEditCreate();
    const newProps = filterProps(props);

    const List = ({children}:any) => (
        <div className={classes.list} >
            {children}
        </div>
    )

    return (
        <Edit {...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="id" label="resources.admin/lod-hole-image-shape.titles.edit"/>
                <ReferenceInput label="resources.admin/lod-hole-image-shape.fields.image" source="image_id" reference="admin/lod-images" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }}>
                    <SelectInput optionText="id" fullWidth={true}/>
                </ReferenceInput>
                <ImageField source="image_id" />
                <TextInput source="variable_code" fullWidth={true}/>
                <SelectInput source="orientation" choices={[
                    {id: 'landscape', name: translate('resources.admin/lod-hole-image-shape.orientation.landscape')},
                    {id: 'portrait', name: translate('resources.admin/lod-hole-image-shape.orientation.portrait')},
                ]}/>
                <ReferenceInput label="resources.admin/lod-hole-image-shape.fields.shape" source="shape_id" reference="admin/lod-shapes" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }} filter={{status: 'active'}}>
                    <SelectInput optionText="name" fullWidth={true}/>
                </ReferenceInput>
                <ReferenceInput label="resources.admin/lod-hole-image-shape.fields.hole" source="hole_id" reference="admin/lod-holes" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }} filter={{status: 'active'}}>
                    <SelectInput optionText="name_lt" fullWidth={true}/>
                </ReferenceInput>
                <List>
                    {Array.from({length: 11}, (v, k) => k + 1).map((el, i) => (
                        <BooleanInput
                            key={i}
                            className={classes.checkbox}
                            source={`mass_${el}`}
                            label={`resources.admin/lod-hole-image-shape.fields.mass_${el}`}
                            // looseValue={true}
                        />
                    ))}
                </List>
            </SimpleForm>
        </Edit>
    );
};

export default HoleImageShapeEdit;
