import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, TextInput, SimpleForm, useTranslate, SelectInput, TranslatableInputs} from 'react-admin';
import Title from '../../../components/list/Title';
import cfgLocales from '../../../config/locale';
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const ShapeEdit: FC<EditProps> = props => {
    const translate = useTranslate();
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit {...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="name_lt" label="resources.admin/lod-shapes.titles.edit" />
                <TextInput source="code" InputProps={{readOnly: true}} helperText={false}/>
                <TextInput source="pim_value" />
                <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                    <TextInput source="name" fullWidth={true} />
                    <TextInput source="slug" fullWidth={true} />
                </TranslatableInputs>
                <SelectInput source="status" choices={[
                    {id: 'active', name: translate('resources.admin/lod-shapes.status.active')},
                    {id: 'inactive', name: translate('resources.admin/lod-shapes.status.inactive')},
                ]}/>
            </SimpleForm>
        </Edit>
    );
};

export default ShapeEdit;
