import ListIcon from '@material-ui/icons/List';
import ProductList from './ProductList';
import ProductCreate from "./ProductCreate";

const resource = {
    list: ProductList,
    create: ProductCreate,
    icon: ListIcon,
    options: {
        label: 'resources.admin/cob-products.name'
    }
};

export default resource;
