import ListAltIcon from '@material-ui/icons/ListAlt';
import PriceMatrixList from './PriceMatrixList';

const resource = {
    list: PriceMatrixList,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/lod-price-matrix.name'
    }
};

export default resource;
