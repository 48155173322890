import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../theme/colors";

export const stepsStyles = makeStyles((theme) => ({
    steps: {
        borderRadius: '2.286rem',
        backgroundColor: Colors.gray.main,
        minHeight: '2.286rem',

        '&.center': {
            backgroundColor: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            gap: '1.5rem',
        }
    },
    step: {
        display: 'flex',
        borderRadius: '2.286rem',
        alignItems: 'center',
        backgroundColor: Colors.gray.main,
        color: Colors.dark.main,
        fontSize: '1rem',
        fontWeight: 500,

        '&:not(.center)': {
            flexGrow: 1,

            '&:not(:last-child)': {
                marginRight: '-2.286rem',
            },
        },

        '&:not(.active):not(.current)': {
            pointerEvents: 'none',
        },

        '&.active, &.current': {
            fontWeight: 700,
            color: Colors.white.main,
            cursor: 'pointer',
        },

        '&.active': {
            backgroundColor: Colors.dark.main,
            cursor: 'pointer',
        },

        '&.current': {
            backgroundColor: Colors.primary.main,
        },

        '&.center': {
            justifyContent: 'center',
        }
    },
    icon: {
        width: 24,
        height: 24,
        flexShrink: 0,
        fill: Colors.primary.main,
    },
    counter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '2.286rem',
        height: '2.286rem',

        '&.active': {

            '& > *': {
                backgroundColor: Colors.white.main,
                width: 20,
                height: 20,
                borderRadius: 32,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }
        }
    },
    text: {

    }
}));
