import React from 'react';
import {Filter, FilterProps, SelectInput} from 'react-admin';

const AbandonedCartFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SelectInput alwaysOn source="status" choices={[
            {id: 'active', name: 'Active carts'},
            {id: 'recovered', name: 'Recovered carts'},
        ]}/>
    </Filter>
);

export default AbandonedCartFilter;
