import GridOnIcon from '@material-ui/icons/GridOn';

import FrameList from './FrameList';
import FrameCreate from "./FrameCreate";

const resource = {
    list: FrameList,
    create: FrameCreate,
    icon: GridOnIcon,
    options: {
        label: 'resources.admin/kdh-frame.name'
    }
};

export default resource;
