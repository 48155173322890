import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, TextInput, ReferenceInput, SelectInput} from 'react-admin';

import {filterProps} from "../../../functions/common";
import Title from "../../../components/list/Title";

const TypeCreate: FC<CreateProps> = props => {
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm>
                <Title label="resources.admin/cob-types.titles.create"/>
                 <ReferenceInput label="resources.admin/cob-types.fields.manufacturer" source={"cob_manufacturer_id"} reference="admin/cob-manufacturers" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }}  sort={{ field: 'name', order: 'ASC' }}
                 >
                    <SelectInput optionText="name" fullWidth={true}/>
                </ReferenceInput>
                <TextInput autoFocus source="name" fullWidth={true}/>
            </SimpleForm>
        </Create>
    );
};

export default TypeCreate;
