import {makeStyles} from "@material-ui/core/styles";

export const loadMask = makeStyles((theme) => ({
    root: {
        height: '100vh',
        position: 'fixed !important',
    },
    default: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme.palette.background["default"],
        zIndex: 1
    },
    fullscreen: {
        height: '100vh',
        position: 'fixed !important',
        bottom: 'initial',
        zIndex: theme.zIndex.modal + 20,
    },
    transparent: {
        backgroundColor: 'rgba(255,255,255,0.5)',
    },
    alignTop: {
        alignItems: 'flex-start',
        paddingTop: '200px'
    },
}));
