import TranslationIcon from '@material-ui/icons/Translate';

import TranslationList from './TranslationList';

const resource = {
    list: TranslationList,
    icon: TranslationIcon,
    options: {
        label: 'resources.admin/translations.name'
    }
};

export default resource;
