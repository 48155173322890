import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, SimpleForm, TextInput, ReferenceInput, SelectInput} from 'react-admin';

import Title from '../../../components/list/Title';
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const PriceListFactorEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit{...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="id" label="resources.admin/lod-price-list-factors.titles.edit"/>

              <ReferenceInput label="resources.admin/lod-price-list-factors.fields.price_list" source="price_list_id" reference="admin/lod-price-list">
                    <SelectInput optionText="preisliste" fullWidth={true}/>
                </ReferenceInput>
                <TextInput source="werk" fullWidth={true} />
                <TextInput source="faktor" fullWidth={true} />

            </SimpleForm>
        </Edit>
    );
};

export default PriceListFactorEdit;
