import * as React from "react";
import {FC} from "react";
import {FieldProps, useLocale, useTranslate} from "react-admin";
import {listEditCreate} from "../../styles/listEditCreate";
import {Typography} from "@material-ui/core";

const Title: FC<FieldProps> | string = ({record, source = 'code', label}) => {
    const translate = useTranslate();
    const locale = useLocale();
    const classes = listEditCreate();

    const recordSource = record?(record[source]??null):null;

    let value = '';
    if(recordSource)
        value = record ? (typeof recordSource === 'object' ? recordSource[locale]:recordSource) : '';

    return (
        <Typography variant='h6' className={classes.title}>
            {label && typeof label === 'string' ? translate(label, {'name': value}) : null}
        </Typography>
    );
}

export default Title;
