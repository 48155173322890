import PostIcon from '@material-ui/icons/Description';
import PostList from './PostList';
import PostCreate from "./PostCreate";

const resource = {
    list: PostList,
    create: PostCreate,
    icon: PostIcon,
    options: {
        label: 'resources.admin/posts.name'
    }
};

export default resource;
