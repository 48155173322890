import {FC} from 'react';
import {AutocompleteArrayInput, Edit, EditProps, ImageField, ImageInput, Record, ReferenceArrayInput, SimpleForm, TextInput, TranslatableInputs} from 'react-admin';

import Title from '../../components/list/Title';
import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps} from "../../functions/common";
import cfgLocales from '../../config/locale';

const VideoPlaylistEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit{...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="title" label="resources.admin/video-playlists.titles.edit"/>
                <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                    <TextInput fullWidth source='name' label={'resources.admin/video-playlists.fields.name'}/>
                </TranslatableInputs>
                <ReferenceArrayInput source="video_category_ids" reference="admin/video-categories" label='resources.admin/videos.fields.video_category_ids'>
                    <AutocompleteArrayInput fullWidth optionText={(record?: Record) => {
                        if (typeof record?.name !== 'string') {
                            //console.log('Unexpected non-string record: ', record);
                            return '';
                        }
                        return record.name;
                    }}/>
                </ReferenceArrayInput>
                <ImageInput source="cover_photo" multiple={false} accept="image/*">
                    <ImageField source="url" title="caption"/>
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
};

export default VideoPlaylistEdit;
