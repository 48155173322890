import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import {
    Edit,
    EditProps,
    TextInput,
    SelectInput,
    DateInput,
    FieldProps,
    FormWithRedirect, ReferenceField, TextField, useTranslate, SaveButton, Toolbar, useNotify,
    required
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {Order} from '../../config/types';
import CartDetails from "./CartDetails";
import axios from "../../config/axios-for-api";
import SendIcon from '@material-ui/icons/Send';
import {filterProps} from "../../functions/common";

const OrderEdit: FC<EditProps> = props => {

    const newProps = filterProps(props);
    return (
        <Edit
            title={<Title/>}
            {...newProps}
            undoable={false}
        >
            <OrderInfo/>
        </Edit>
    );
};

const Title: FC<FieldProps<Order>> = ({record}) => {
    const translate = useTranslate();
    return record ? <Typography variant='h6'>{translate(`resources.admin/orders.titles.edit_order`, {'id': record.id})}</Typography> : null;
}


const OrderInfo = (props: any) => {

    const translate = useTranslate();
    const record = props.record || null;
    const notify = useNotify();
    const newProps = filterProps(props);

    const [shouldResubmitOrderToSap, setShouldResubmitOrderToSap] = useState<boolean>(false);
    //const [shouldSaveOrder, setShouldSaveOrder] = useState<boolean>(false);
    const [shouldSendShippingDateNotification, setShouldSendShippingDateNotification] = useState<boolean>(false);
    const [lastSapError, setLastSapError] = useState(record.last_sap_error??null);

    useEffect(() => {
        setShouldResubmitOrderToSap(record.status === 'pending' && !record.sap_order_id);
        //setShouldSaveOrder(record.status === 'pending');
        setShouldSendShippingDateNotification(record.status === 'processed' && record.cart?.customer?.shipping_group === 'sg_2');
    }, [record]);

    if (!record) return null;

    const handleResubmitOrderToSap = (record: Order) => {
        if (!record) return;

        const apiUrl = `${process.env['REACT_APP_API_URL'] || ''}/api`;
        axios.post(`${apiUrl}/admin/orders/${record.id}/submit-to-sap`).then(() => setShouldResubmitOrderToSap(false)).catch((err: any) => {
            setLastSapError(err.message);
            notify(err.message || 'Error!', 'error');
        });
    }

    return (
        <FormWithRedirect
            {...newProps}
            render={(formProps:any) => (
                <Box>
                    <Box p={2}>
                        <Title {...formProps} />
                        <Box mb={2}/>
                        <ReferenceField source="customer_id" reference="admin/users">
                            <>{translate('resources.admin/orders.fields.customer')}: <TextField source="first_name"/> <TextField source="last_name"/></>
                        </ReferenceField>
                        <ReferenceField source="manager_id" reference="admin/users">
                            <>{translate('resources.admin/orders.fields.manager')}: <TextField source="first_name"/> <TextField source="last_name"/></>
                        </ReferenceField>
                        <Box mb={2}/>
                        <TextInput fullWidth source="created_at_formatted" label={'resources.admin/orders.fields.created_at'} InputProps={{readOnly: true}} helperText={false}/>
                        <TextInput fullWidth source="status" label={'resources.admin/orders.fields.status'} InputProps={{readOnly: true}} helperText={false}/>
                        <TextInput fullWidth source="payment_status" label={'resources.admin/orders.fields.payment_status'} InputProps={{readOnly: true}} helperText={false}/>
                        <TextInput fullWidth source="unzer_short_id" label={'resources.admin/orders.fields.unzer_short_id'} InputProps={{readOnly: true}} helperText={false}/>
                        <TextInput fullWidth source="unzer_payment_id" label={'resources.admin/orders.fields.unzer_payment_id'} InputProps={{readOnly: true}} helperText={false}/>
                        <TextInput fullWidth source="unzer_payment_method" label={'resources.admin/orders.fields.payment_method'} InputProps={{readOnly: true}} helperText={false}/>
                        {/*<TextInput fullWidth source="company" InputProps={{readOnly: true}} helperText={false}/>*/}
                        {/*<TextInput fullWidth source="email" InputProps={{readOnly: true}} helperText={false}/>*/}
                        <TextInput fullWidth source="sap_order_id" label={'resources.admin/orders.fields.sap_order_id'} validation={[required()]}/>
                        <DateInput source="cart.shipping_date" label={'resources.admin/orders.fields.shipping_date'}/>
                        {shouldSendShippingDateNotification && (<SelectInput source="shipping_date_notification_action" label={'resources.admin/orders.fields.shipping_date_notification_action'} choices={[
                            {id: 'approve', name: 'resources.admin/orders.fields.approve_shipping_date'},
                            {id: 'reject', name: 'resources.admin/orders.fields.reject_shipping_date'},
                        ]} placeholder={"Shipping Date notification"} style={{marginLeft: '15px', width: '300px'}}/>)}
                        <CartDetails record={props.record}/>

                        {lastSapError && (<p style={{"color":"#c00"}}>Error: {lastSapError}</p>)}
                    </Box>
                    <Toolbar>
                        <>
                            <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
                                <SaveButton
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                />
                                <Button
                                    variant={'text'}
                                    color='secondary'
                                    onClick={() => handleResubmitOrderToSap(props.record)}
                                    disabled={!shouldResubmitOrderToSap}
                                    startIcon={<SendIcon/>}
                                >
                                    {translate('resources.admin/orders.buttons.submit_to_sap')}
                                </Button>
                            </Box>
                        </>
                    </Toolbar>
                </Box>
            )}
        />
    )
}

export default OrderEdit;
