import React, {useState} from 'react'
import {Box, Card, Dialog, Grid, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Hidden from "@material-ui/core/Hidden";
import FieldInputEndIcon from "../ui/FieldInputEndIcon";
import {useRedirect, useTranslate} from "react-admin";
import FieldInput from "../ui/FieldInput";
import CardContent from "@material-ui/core/CardContent";
import {Colors} from "../../styles/theme/colors";
import {hexToRgba} from "../../functions/common";

const ConfirmPassModal = ({confirming, setPassword, password, setConfirming, onConfirm, errorMessage, setErrorMessage, restrict}: any) => {

    const [showPassword, setShowPassword] = useState(false);
    const translate = useTranslate();
    const redirect = useRedirect();

    return (
        <>
            <Dialog
                open={confirming}
                onClose={() => restrict ? null : setConfirming(false)}
                maxWidth="sm"
                style={{zIndex: 2000, backdropFilter: 'blur(10px)'}}
                PaperProps={{
                    style: {maxHeight: '100%', maxWidth: 480, border: 'none', borderRadius: 5},
                    elevation: 1,
                }}
            >
                <Card style={{ overflow: "auto", boxShadow: `0 0 30px ${hexToRgba(Colors.dark.main, 0.25)}`, borderRadius: 5 }}>
                    <CardContent style={{padding: 30}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{display: 'flex', alignItems: 'center', paddingBottom: 0}}>
                                <Typography
                                    variant="h5"
                                    style={{display: 'flex', flexGrow: 1, fontWeight: 700}}
                                >
                                    {translate('confirmPassword.title')}
                                </Typography>
                                {!restrict ? (
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setConfirming(false)}
                                        style={{marginRight: -15}}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                ):null}
                            </Grid>
                            <Grid item xs={12} style={{paddingBottom: 0}}>
                                <Typography
                                    dangerouslySetInnerHTML={{__html : `${translate('confirmPassword.text')}`}}
                                    style={{ marginBottom: 10 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box style={{ marginBottom: 10 }}>
                                    <FieldInput
                                        type={showPassword ? 'text' : 'password'}
                                        label={translate('confirmPassword.passwordLabel')}
                                        value={password}
                                        onChange={(e: any) => {
                                            setPassword(e.target.value);
                                            setErrorMessage(null);
                                        }}
                                        hasError={errorMessage !== null}
                                        customStyle={{
                                            height: 45,
                                            background: Colors.background.default,
                                            padding: '0px 10px',
                                            borderRadius: 5,
                                        }}
                                        endAdornment={
                                            <FieldInputEndIcon
                                                isPassword
                                                showPassword={showPassword}
                                                setShowPassword={setShowPassword}
                                            />
                                        }
                                    />
                                    {errorMessage !== null && (
                                        <Typography
                                            variant="caption"
                                            style={{display: 'flex', width: '100%', color: Colors.primary.main, marginTop: 5}}
                                        >
                                            {errorMessage}
                                        </Typography>
                                    )}
                                </Box>
                            </Grid>

                            <Hidden xsDown>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="outlined"
                                        className="darkButton boldBorder fullWidth"
                                        size="large"
                                        onClick={() => redirect('/')}
                                        style={{textTransform: 'none', width: '100%', border: `2px solid ${hexToRgba(Colors.dark.main, 0.25)}`}}
                                    >
                                        {!restrict ? translate('confirmPassword.cancel') : translate('global.back')}
                                    </Button>
                                </Grid>
                            </Hidden>

                            <Grid item xs={12} sm={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={onConfirm}
                                    style={{textTransform: 'none', width: '100%', minHeight: 45}}
                                >
                                    {translate('confirmPassword.confirm')}
                                </Button>
                            </Grid>

                            <Hidden smUp>
                                <Grid item xs={12} sm={6}>
                                    <Button
                                        variant="outlined"
                                        size="large"
                                        className="darkButton boldBorder fullWidth"
                                        style={{textTransform: 'none', width: '100%', minHeight: 45}}
                                        onClick={() => redirect('/')}
                                    >
                                        {!restrict ? translate('confirmPassword.cancel') : translate('global.back')}
                                    </Button>
                                </Grid>
                            </Hidden>

                        </Grid>
                    </CardContent>
                </Card>
            </Dialog>
        </>
    )
}

export default ConfirmPassModal;
