import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import ShapeList from './ShapeList';

const resource = {
    list: ShapeList,
    icon: ChangeHistoryIcon,
    options: {
        label: 'resources.admin/lod-shapes.name'
    }
};

export default resource;
