import {CreateButton, ExportButton, Toolbar} from "react-admin";
import * as React from "react";

import {list} from "../../styles/list";

const ListActions = ({basePath = ''}) => {
    let classes = list();
    const iCalUrl = `${process.env.REACT_APP_API_URL}/api/shipping-dates/ical`;

    const copyToClipboard = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(iCalUrl);
        } else {
            document.execCommand("copy", true, iCalUrl);
        }
    }


    return (
        <Toolbar className={classes.toolbar} style={{justifyContent: 'space-between', paddingLeft: 0}}>
            <>
                <div>
                    <a rel='noreferrer' target={'_blank'} style={{color: '#333'}} href={iCalUrl}>Download/Import iCal</a> | <a style={{color: '#333'}} href="/#" onClick={copyToClipboard}>Copy to clipboard</a>
                </div>
                <div>
                    <CreateButton basePath={basePath}/>
                    <ExportButton/>
                </div>
            </>
        </Toolbar>
    );
}

export default ListActions;
