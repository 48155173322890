import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import GrommetList from './GrommetList';
import GrommetCreate from "./GrommetCreate";


const resource = {
    list: GrommetList,
    create: GrommetCreate,
    icon: DragIndicatorIcon,
    options: {
        label: 'resources.admin/kdh-grommet.name'
    }
};

export default resource;
