import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {Colors} from "../../styles/theme/colors";
import {hexToRgba} from "../../functions/common";
import IconError from '../../assets/icons/alert/error-white.svg';
import IconWarning from '../../assets/icons/alert/warning-white.svg';
import IconInfo from '../../assets/icons/alert/info-white.svg';
import IconSuccess from '../../assets/icons/alert/success-white.svg';
import {Box} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: (props: AlertProps) => ({
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        color: theme.palette.getContrastText(props.color),
        backgroundColor: props.color,
    }),
}));

interface AlertProps {
    color?: any;
    children: React.ReactNode;
    severity: 'success' | 'info' | 'warning' | 'error';
    className?: string;
    style?: any;
    action?: any;
}

const getColor = (severity: AlertProps['severity']): string => {
    switch (severity) {
        case 'success':
            return hexToRgba(Colors.success.main, 0.9); // green
        case 'info':
            return hexToRgba(Colors.dark.main, 0.25); // blue
        case 'warning':
            return hexToRgba(Colors.warning.main, 0.9); // orange
        case 'error':
            return hexToRgba(Colors.error.main, 0.75); // red
        default:
            return hexToRgba(Colors.dark.main, 0.25); // gray
    }
}

const getIcon = (severity: AlertProps['severity']): string => {
    switch (severity) {
        case 'success':
            return IconSuccess;
        case 'info':
            return IconInfo;
        case 'warning':
            return IconWarning;
        case 'error':
            return IconError;
        default:
            return IconInfo;
    }
}

const Alert = ({children, severity,className, style, action, ...rest}: AlertProps) => {
    const color = getColor(severity);
    // @ts-ignore
    const classes = useStyles({color});
    const textStyles = {
        color: severity === 'success' || severity === 'warning' || severity === 'error' ? Colors.white.main : Colors.dark.main,
        flexGrow: 1,
        paddingRight: 15
    };

    return (
        <Paper className={classes.root} style={{...{ display: 'flex', alignItems: 'center' }, ...style}} {...rest}>
            <Box component="span" style={{flexShrink: 0, marginRight: 15}}>
                <img alt={severity} width={20} height={20} src={getIcon(severity)} style={{objectFit: 'contain'}} />
            </Box>
            <Typography variant="body2" style={textStyles}>{children}
                {children}
            </Typography>
            {action}
        </Paper>
    );
}

export default Alert;