import {Card, Box, Grid, TableContainer, Table, TableCell, TableRow, TableBody, TableHead} from '@material-ui/core';
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import {useEffect, useState} from "react";
import {useTranslate} from "react-admin";

import {dashboard} from '../../styles/dashboard';
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosForApi from "../../config/axios-for-api";
import StatHeader from "./StatHeader";

interface StatDataRow {
    year: number;
    revenue: number;
    revenue_fmt: string;
    growth: number;
    growth_fmt: string;
    trend: number;
}

const Loading = ({classes}: any) => {
    return (
        <Card className={classes.card}>
            <Box p={2} display={'flex'} justifyContent={'center'} alignItems={'center'} height={200}>
                <CircularProgress/>
            </Box>
        </Card>
    );
}

const GrowthFmt = ({growth, growth_fmt}: any) => {
    let color = '#4a8431';
    if (growth < 0) color = '#cc0000';

    const style = {color};
    return (<span style={style}>{growth_fmt}</span>);
}

const CustomTooltip = (props: any) => {

    const {payload, separator, itemStyle, label, labelStyle} = props;

    const listStyle = {padding: 0, margin: 0};

    const items = (payload??[]).map((entry: any, i: number) => {
        if (entry.type === 'none') {
            return null;
        }

        const finalItemStyle = {
            display: 'block',
            paddingTop: 4,
            paddingBottom: 4,
            color: entry.color || '#000',
            ...itemStyle,
        };

        let name = '';
        let value = '';

        if (entry.dataKey === 'revenue') {
            name = 'Umsatz';
            value = entry.payload.revenue_fmt;
        }

        return (
            // eslint-disable-next-line react/no-array-index-key
            <li className="recharts-tooltip-item" key={`tooltip-item-${i}`} style={finalItemStyle}>
                <span className="recharts-tooltip-item-name">{name}</span>
                <span className="recharts-tooltip-item-separator">{separator}</span>
                <span className="recharts-tooltip-item-value">{value}</span>
                <span className="recharts-tooltip-item-unit">{entry.unit || ''}</span>
            </li>
        );
    });

    return (
        <div className='recharts-default-tooltip' style={{
            margin: 0,
            padding: 10,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            whiteSpace: 'nowrap'
        }}>
            <p className={'recharts-tooltip-label'} style={{margin:0, ...labelStyle}}>
                {label}
            </p>
            <ul className="recharts-tooltip-item-list" style={listStyle}>
                {items}
            </ul>
        </div>

    );
}

const RevenueByYear = ({countries}: { countries: string[] | null }) => {
    const classes = dashboard();
    const translate = useTranslate();

    const [shouldDrawChart, setShouldDrawChart] = useState(false);
    const [shouldLoadStatsData, setShouldLoadStatsData] = useState(false);

    const [tableData, setTableData] = useState<StatDataRow[]>([]);
    const [chartData, setChartData] = useState<StatDataRow[]>([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let cancel = false;
        const loadStatsData = async () => {
            const {data} = await axiosForApi.get('api/admin/stats/revenue-by-year', {
                params: {
                    countries
                }
            });

            if(!cancel) {
                const newTableData = [...data ?? []];
                setTableData(newTableData.sort((a: StatDataRow, b: StatDataRow) => b.year - a.year));

                const newChartData = [...data ?? []];
                setChartData(newChartData.map((el: StatDataRow) => {
                    return el;
                }));
                setChartData(newChartData);


                setLoading(false);
                setShouldLoadStatsData(false);
                setShouldDrawChart(true);
            }

        }

        shouldLoadStatsData && loadStatsData();

        return () => {
            cancel = true;
        }
    }, [shouldLoadStatsData, countries]);

     useEffect(() => {
        setLoading(true);
        setShouldLoadStatsData(true);
    }, [countries]);

    if (loading) return (<Loading classes={classes}/>);

    return (
        <Card className={classes.card}>
            <Box p={2}>
                <StatHeader
                    justifyContent={'center'}
                    // title='Umsatzentwicklung pro Kalenderjahr'
                    title={translate('resources.admin/dashboard.headers.help.revenue-by-year')}
                    help='resources.admin/dashboard.help.revenue-by-year'
                />
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={4}>
                        <div className={'scrollable'} style={{height: '300px', overflowY: 'auto', overflowX: 'hidden'}}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell>Umsatz</TableCell>
                                            <TableCell>Veränderung Vorjahr</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tableData.map(data => (
                                            <TableRow key={data.year}>
                                                <TableCell padding={'none'}>
                                                    {data.year}
                                                </TableCell>
                                                <TableCell>
                                                    {data.revenue_fmt}
                                                </TableCell>
                                                <TableCell>
                                                    <GrowthFmt {...data} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <div style={{width: '100%', 'height': '300px'}}>
                            {shouldDrawChart && (
                                <ResponsiveContainer width="100%" height="100%">
                                    <ComposedChart
                                        width={500}
                                        height={400}
                                        data={chartData}
                                        margin={{
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                        }}
                                    >
                                        <CartesianGrid stroke="#f5f5f5"/>
                                        <XAxis dataKey="year" interval={0} />
                                        <YAxis width={80} tickFormatter={(v:number) => v.toLocaleString('de-DE')} />
                                        <Tooltip content={<CustomTooltip />} />
                                        {/*<Legend/>*/}
                                        <Bar dataKey="revenue" barSize={20} fill="#333"/>
                                        {/*<Line type="monotone" dataKey="trend" stroke="#ff7300"/>*/}
                                    </ComposedChart>
                                </ResponsiveContainer>)}
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}

export default RevenueByYear;
