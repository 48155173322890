import {CheckboxGroupInput, SelectInput, TextInput} from "react-admin";

import Radio from '@material-ui/core/Radio';
import {useState} from "react";
import {useField} from 'react-final-form';

const RadioField = (props: any) => {
    const {name, value} = props;
    const {
        input
    } = useField(name);
    return (
        <Radio
            name={name}
            onChange={input.onChange}
            checked={input.value === value}
            value={value}
        />
    );
};

export default function Rrule(props: any) {

    const {record} = props;

    const freqChoices = [
        {
            id: 'DAILY', name: 'Daily',
        },
        {
            id: 'WEEKLY', name: 'Weekly'
        },
        {
            id: 'MONTHLY', 'name': 'Monthly'
        },
        {
            id: 'YEARLY', 'name': 'Yearly'
        },
    ];
    const weekdayChoices = [
        {id: 'SU', name: 'Sun'},
        {id: 'MO', name: 'Mon'},
        {id: 'TU', name: 'Tue'},
        {id: 'WE', name: 'Wed'},
        {id: 'TH', name: 'Thu'},
        {id: 'FR', name: 'Fri'},
        {id: 'SA', name: 'Sat'},
    ];
    const monthChoices = [
        {
            id: 1, name: 'January',
        },
        {
            id: 2, name: 'February',
        },
        {
            id: 3, name: 'March',
        },
        {
            id: 4, name: 'April',
        },
        {
            id: 5, name: 'May',
        },
        {
            id: 6, name: 'June',
        },
        {
            id: 7, name: 'Jul',
        },
        {
            id: 8, name: 'August',
        },
        {
            id: 9, name: 'September',
        },
        {
            id: 10, name: 'October',
        },
        {
            id: 11, name: 'November',
        },
        {
            id: 12, name: 'December',
        },
    ];
    const postChoices = [
        {
            id: '1', name: 'First',
        },
        {
            id: '2', name: 'Second'
        },
        {
            id: '3', 'name': 'Third'
        },
        {
            id: '4', 'name': 'Fourth'
        },
        {
            id: '-1', 'name': 'Last'
        },
    ];
    const monthdayChoices = [...Array(31)].map((_, el) => ({id: el + 1, name: `${el + 1}`}));

    //freq
    const [freq, setFreq] = useState<string | null>(() => {
        return record.rrule?.freq;
    });

    const intervalSuffix = (() => {
        switch (freq) {
            case 'DAILY':
                return 'day(s)';
            case 'WEEKLY':
                return 'week(s)';
            case 'MONTHLY':
                return 'month(s)';
        }
        return null;
    })();
    const handleFreq = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFreq(e.target.value);
    }

    const showInterval = !!freq && ['DAILY', 'WEEKLY', 'MONTHLY'].includes(freq);

    return (<>
        {/*Frequency*/}
        <div style={{display: 'flex', alignItems: "center", margin:'.5rem'}}>
            <span>Repeat</span>
            <SelectInput allowEmpty emptyText={'No recurrence'}  onChange={e => handleFreq(e)} style={{'margin': '.5rem'}} source="rrule.freq" label='Frequency' choices={freqChoices} helperText={false}/>

            {showInterval && <>
                <span>every</span>
                <TextInput style={{'margin': '.5rem'}} source="rrule.interval" helperText={false} label='Interval'/>
                <span>{intervalSuffix}</span>
            </>}
        </div>

        {/* Weekly */}
        {freq === 'WEEKLY' &&
            <CheckboxGroupInput source="rrule.byday_arr" label='Day' choices={weekdayChoices}/>
        }

        {/*Monthly*/}
        {freq === 'MONTHLY' &&
            <div>
                <div style={{display: 'flex', alignItems: "center"}}>
                    <RadioField
                        style={{'margin': '.5rem'}}
                        color="primary"
                        name="rrule.monthlyRadioSelection"
                        value={'1'}
                    />
                    <span style={{display: 'inline-block', width: '5rem', flexShrink: 0}}>on day</span>
                    <SelectInput style={{'margin': '.5rem'}} source="rrule.bymonthday" label='Day' choices={monthdayChoices} helperText={false}/>
                </div>
                <div style={{display: 'flex', alignItems: "center"}}>
                    <RadioField
                        style={{'margin': '.5rem'}}
                        color="primary"
                        name="rrule.monthlyRadioSelection"
                        value={'2'}
                    />
                    <span style={{display: 'inline-block', width: '5rem', flexShrink: 0}}>on the</span>
                    <SelectInput style={{'margin': '.5rem'}} source="rrule.bysetpos" label='Position' choices={postChoices} helperText={false}/>
                    <SelectInput style={{'margin': '.5rem'}} source="rrule.byday" label='Day' choices={weekdayChoices} helperText={false}/>
                </div>
            </div>
        }

        {/*Yearly*/}
        {freq === 'YEARLY' &&
            <div>
                <div style={{display: 'flex', alignItems: "center"}}>
                    <RadioField
                        style={{'margin': '0 .5rem'}}
                        color="primary"
                        name="rrule.yearlyRadioSelection"
                        value={'1'}
                    />
                    <span style={{display: 'inline-block', width: '5rem', flexShrink: 0}}>on</span>
                    <SelectInput style={{'margin': '.5rem'}} source="rrule.bymonth" label='Month' choices={monthChoices} helperText={false}/>
                    <SelectInput style={{'margin': '.5rem'}} source="rrule.bymonthday" label='Day' choices={monthdayChoices} helperText={false}/>
                </div>
                <div style={{display: 'flex', alignItems: "center"}}>
                    <RadioField
                        style={{'margin': '0 .5rem'}}
                        color="primary"
                        name="rrule.yearlyRadioSelection"
                        value={'2'}
                    />
                    <span style={{display: 'inline-block', width: '5rem', flexShrink: 0}}>on the</span>
                    <SelectInput style={{'margin': '.5rem'}} source="rrule.bysetpos" label='Position' choices={postChoices} helperText={false}/>
                    <SelectInput style={{'margin': '.5rem'}} source="rrule.byday" label='Day' choices={weekdayChoices} helperText={false}/>
                    <span>of</span>
                    <SelectInput style={{'margin': '.5rem'}} source="rrule.bymonth" label='Month' choices={monthChoices} helperText={false}/>
                </div>
            </div>
        }
    </>);
}
