import {CreateButton, ExportButton, Toolbar} from "react-admin";
import * as React from "react";
import {list} from "../../styles/list";

const ListActions = ({basePath = ''}) => {
    let classes = list();

    return (
        <Toolbar className={classes.toolbar}>
            <CreateButton basePath={basePath}/>
            <ExportButton/>
        </Toolbar>
    );
}

export default ListActions;
