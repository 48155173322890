import ProductsIcon from '@material-ui/icons/Apps';
import ProductList from './ProductList';

const resource = {
    list: ProductList,
    icon: ProductsIcon,
    options: {
        label:'resources.admin/products.name'
    }
};

export default resource;