import ListAltIcon from '@material-ui/icons/ListAlt';
import VideoCategoryList from './VideoCategoryList';
import VideoCategoryCreate from "./VideoCategoryCreate";

const resource = {
    list: VideoCategoryList,
    create: VideoCategoryCreate,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/video-playlists.name'
    }
};

export default resource;
