import {makeStyles} from "@material-ui/core/styles";

export const list = makeStyles((theme) => ({
    drawer: {
        background: 'transparent',
        '& .MuiPaper-root': {
            border: 0,
            borderRadius: 0,
        },
        '& .MuiCardContent-root': {
            padding: 0,
        },
        '& .MuiTab-root': {
            minWidth: 0,
        }
    },
    closeDrawer: {
        position: 'absolute',
        top: 15,
        right: 15,
    },
    toolbar: {
        margin:0,
        paddingRight: 0,
        paddingTop: 24,
        paddingBottom: 8,
        minHeight: 40,
        flexGrow: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
}));