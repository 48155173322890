import {Card, Box, TableContainer, Table, TableCell, TableRow, TableBody} from '@material-ui/core';
import {useEffect, useState} from "react";
import {useTranslate} from "react-admin";

import {dashboard} from '../../styles/dashboard';
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosForApi from "../../config/axios-for-api";
import StatHeader from "./StatHeader";

interface StatDataRow {
    product_name: string;
    product_id: number,
    revenue: number;
    revenue_fmt: string;
    product_path: string;
}

const Loading = ({classes}: any) => {
    return (
        <Card className={classes.card}>
            <Box p={2} display={'flex'} justifyContent={'center'} alignItems={'center'} height={200}>
                <CircularProgress/>
            </Box>
        </Card>
    );
}

const TopProductsByRevenueThisYear = ({countries}: { countries: string[] | null }) => {
    const classes = dashboard();
    const translate = useTranslate();

    const [data, setData] = useState<StatDataRow[]>([]);
    const [shouldLoadStatsData, setShouldLoadStatsData] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let cancel = false;
        const loadStatsData = async () => {
            const {data} = await axiosForApi.get('api/admin/stats/top-products-by-revenue-this-year', {
                params: {
                    countries
                }
            });

            if(!cancel) {
                setData(data);
                setLoading(false);
                setShouldLoadStatsData(false);
            }
        }

        shouldLoadStatsData && loadStatsData();

        return () => {
            cancel = true;
        }
    }, [shouldLoadStatsData, countries]);

    useEffect(() => {
        setLoading(true);
        setShouldLoadStatsData(true);
    }, [countries]);

    if (loading) return (<Loading classes={classes}/>);


    return (
        <Card className={classes.card}>
            <Box p={2}>
                <StatHeader
                    justifyContent={'center'}
                    // title={`Umsatz Top 5 Produkte (${new Date().getFullYear()})`}
                    title={translate('resources.admin/dashboard.headers.top-products-by-revenue-this-year')}
                    help={'resources.admin/dashboard.help.top-products-by-revenue-this-year'}
                />
                {!data.length && (
                    <div style={{height: '300px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <span style={{color: '#c00'}}>No data available</span>
                    </div>
                )}
                {!!data.length && (
                    <div className='scrollable' style={{height: '300px', overflowY: 'scroll', overflowX: 'hidden'}}>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    {data.map((data, i) => (
                                        <TableRow key={i}>
                                            <TableCell padding={'none'}>
                                                {i + 1}
                                            </TableCell>
                                            <TableCell>
                                                <a href={data.product_path} target="_blank" rel="noreferrer">{data.product_name}</a>
                                            </TableCell>
                                            <TableCell padding={'none'}>
                                                {data.revenue_fmt}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </Box>
        </Card>
    );
}

export default TopProductsByRevenueThisYear;
