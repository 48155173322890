import * as React from 'react';
import {useState, useEffect} from 'react';
import {Box, Paper, Button, TextField, Select, MenuItem, InputLabel, FormControl} from '@material-ui/core';
import "../../../node_modules/flag-icons/css/flag-icons.min.css";
import SearchIcon from '@material-ui/icons/Search';
import {ReactElement} from 'react';
import {LoadMask} from "../../components/global/LoadMask";
// import DE from "../../assets/icons/lang/icon-lang-de.svg";
// import EN from "../../assets/icons/lang/icon-lang-en.svg";
// import ES from "../../assets/icons/lang/icon-lang-es.svg";
// import IT from "../../assets/icons/lang/icon-lang-it.svg";
// import RU from "../../assets/icons/lang/icon-lang-ru.svg";
// import FR from "../../assets/icons/lang/icon-lang-fr.svg";
// import SE from "../../assets/icons/lang/icon-lang-se.svg";
// import tempLang from "../../assets/icons/lang/icon-temporary-new-lang.svg";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@material-ui/core';
import {useNotify, useTranslate} from "react-admin";
import axios from '../../config/axios-for-api';
import { makeStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {allLocales} from "./allLocales";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 'calc(100vh - 260px)',
        marginBottom: 15
    },
    tableHead: {
        backgroundColor: '#eee',
        '& *': {
            fontWeight: 700
        }
    },
    tableKey: {
        width: 320,
        fontWeight: 700,
        alignItems: 'center'
    },
    tableHeader: {
        backgroundColor: '#002536',
        color: '#fff',
        marginBottom: 15,
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttonLanguage: {
        color: '#fff',
        borderColor: 'rgba(255,255,255,0)',
        marginLeft: 5,
        '&:hover':{
            borderColor: '#fff',
        },
        '& .MuiButton-label': {
            display: 'flex',
            alignItems: 'center',
        }
    },
    buttonLanguageSelected: {
        outline: '2px solid red',
    },
    searchKey: {
        '& .MuiFilledInput-input': {
            padding: 10,
        }
    },
    field: {
        padding: 10,
        borderRadius: 5,
        minWidth: '100%',
        boxSizing: 'border-box',
        fontFamily: 'inherit'
    },
    flag: {
        borderRadius: 5,
        marginRight: 10,
        fontSize: 'medium',
    }
});

const TranslationList = (props: any): ReactElement => {

    const translate = useTranslate();
    const [translations, setTranslations] = useState<{ [key: string]: any }>({});
    const [shouldGetTranslations, setShouldGetTranslations] = useState<boolean>(true);
    const [currentLang, setCurrentLang] = useState<string>('de');
    const [searchedKey, setSearchedKey] = useState<string>('');
    const [newLanguage, setNewLanguage] = useState<any>('');
    const [templateLanguage, setTemplateLanguage] = useState<any>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const classes = useStyles();
    const notify = useNotify();
    const resetButton = window.location.href.includes('show-reset-button');

    useEffect(() => {
        const getTranslations = () => {
            axios
                .get('/api/admin/translations/get')
                .then((res) => {
                    if (!res.data) throw new Error('No translation data!');
                    setTranslations(res.data);
                    setShouldGetTranslations(false);
                })
                .catch((err) => console.error(err));
        }

        shouldGetTranslations && getTranslations();

    }, [shouldGetTranslations]);

    const langs = Object.keys(translations);
    const template = (langs.length > 0 && translations[langs[0]]) || {};

    const saveTranslation = () => {
        axios
            .put('/api/admin/translations', {
                translations: translations
            })
            .then(res => {
                notify('resources.admin/translations.messages.translation_saved', 'success');
            }).catch(e => {
            notify('resources.admin/translations.messages.translation_not_saved', 'error')
        });
    }

    const publishTranslation = () => {
        axios
            .post('/api/admin/translations/publish')
            .then(res => {
                notify('resources.admin/translations.messages.translation_published', 'success');
            }).catch(e => {
            notify('resources.admin/translations.messages.translation_not_published', 'error')
        });
    }

    const resetTranslation = () => {

        axios
            .post('/api/admin/translations/reset')
            .then(res => {
                notify('resources.admin/translations.messages.translation_reset', 'success');
                window.location.reload();
            }).catch(e => {
            notify('resources.admin/translations.messages.translation_not_reset', 'error')
        });
    }

    const translateField = (key: any, field: any) => {

        axios
            .post('/api/admin/translations/update-field', {
                language: currentLang,
                key: key,
                field: field
            })
            .then(() => {
                notify('resources.admin/translations.messages.translation_reset', 'success');
                window.location.reload();
            }).catch(() => {
            notify('resources.admin/translations.messages.translation_not_reset', 'error')
        });

    }

    const removeLanguage = () => {

        if (currentLang === 'de' || langs.length === 1) {
            notify('resources.admin/translations.messages.cannot_remove_de_or_only_language', 'error');
            return;
        }
        setIsLoading(true);
        axios
            .post('/api/admin/translations/remove-language', {
                language: currentLang
            })
            .then(() => {
                notify('resources.admin/translations.messages.removed_language', 'success');
                window.location.reload();
            }).catch(() => {
            notify('resources.admin/translations.messages.failed_removed_language', 'error');
        });

    }

    const TranslationField = ({section_key = '', item = ''}) =>  (
        <textarea
            className={classes.field}
            name={currentLang + '.' + section_key + '.' + item}
            defaultValue={translations[currentLang][section_key][item]}
            onBlur={(e) => {
                const newTranslations = translations;
                newTranslations[currentLang][section_key][item] = e.target.value;
                setTranslations(newTranslations);
            }}
        />
    )

    // const flag:any = { es: ES, en: EN, de: DE, fr: FR, it: IT, ru: RU, se: SE, temporary: tempLang };

    const getTranslationSection = async (section: string) => {
        return await axios
            .post('/api/admin/translations/translate-section', {
                new_language: newLanguage,
                template_language: templateLanguage,
                section: section
            });
    }
    const addNewLanguage = () => {


        if (!newLanguage || !templateLanguage) {
            notify('resources.admin/translations.messages.please_select_language_from_and_to', 'error');
            return;
        }
        setIsLoading(true);
        //timeout too big, with sections?
        const currentTranslation = translations[currentLang];

        let promises = [];
        for (let section of Object.keys(currentTranslation)) {

            promises.push(Promise.resolve(getTranslationSection(section)
            ).then(({data}) => { return { [section]: data } }));
        }

        Promise.all(promises).then((values) => {
            // console.log('END: ', values);
            axios
                .post('/api/admin/translations/add-language', {
                    new_language: newLanguage,
                    template_language: templateLanguage,
                    values: values
                })
                .then(() => {
                    notify('resources.admin/translations.messages.language_added', 'success');
                    window.location.reload();
                }).catch(() => {
                notify('resources.admin/translations.messages.language_not_added', 'error');
            });
        })

    }

    const filteredAllLocales = allLocales.filter((locale) => !langs.includes(locale.id));
    const alreadyAddedLocales = allLocales.filter((locale) => langs.includes(locale.id));


    return (
        <>
            <Box p={2} className={classes.tableHeader}>
                <Box display={'flex'} alignItems={'center'}>
                    <Box mr={2} fontWeight={700}>
                        Language
                    </Box>
                    {langs.length > 0 ? langs.map((lang: any, l: any) => (
                        <Button
                            key={l}
                            type={'button'}
                            variant={'outlined'}
                            color={ 'primary'}
                            size={'small'}
                            className={`${classes.buttonLanguage} ${lang === currentLang ? classes.buttonLanguageSelected : ''}`}
                            onClick={() => setCurrentLang(lang)}
                        >
                            {/* <img alt={lang} width={20} height={20} className={classes.flag} src={flag[lang]??flag['temporary']} /> */}
                            <span className={`${classes.flag} fi fi-${lang === 'en' ? 'gb' : lang}`}></span>
                            {lang}
                        </Button>
                    )) : (
                        <CircularProgress size={24} color={'inherit'} />
                    )}
                </Box>
                <Box display={'flex'} alignItems={'center'} >
                    <Box mr={2} fontWeight={700} flexShrink={0}>
                        Search Translation Key
                    </Box>
                    {langs.length > 0 ? (
                        <TextField
                            fullWidth
                            variant="filled"
                            type="text"
                            className={classes.searchKey}
                            onChange={(e: any)=> setSearchedKey(e.target.value)}
                            InputProps={{
                                endAdornment: <SearchIcon />,
                            }}
                        />
                    ) : (
                        <CircularProgress size={24} color={'inherit'} />
                    )}
                </Box>
            </Box>
            <TableContainer className={classes.container} component={Paper}>
                <Table stickyHeader aria-label={'simple table'}>
                    <TableBody>
                        {langs.length > 0 ? (
                            <>
                                {Object.keys(template).map((section_key: any, j: any) => (
                                    <React.Fragment key={j}>
                                        {searchedKey.trim() === '' || Object.keys(translations[currentLang][section_key]).filter((item: any) => item.toLowerCase().indexOf(searchedKey.toLowerCase()) !== -1 ).length > 0 ? (
                                            <TableRow className={classes.tableHead}>
                                                <TableCell scope="row" colSpan={2}>
                                                    <Typography variant="h6" component="h2">
                                                        {section_key}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ) : null }
                                        {Object.keys(template[section_key]).map((item: any, k: any) => (
                                            <React.Fragment key={k}>
                                                {searchedKey.trim() === '' || (searchedKey.length > 3 && item.toLowerCase().indexOf(searchedKey.toLowerCase()) !== -1 ) ? (
                                                    <TableRow >
                                                        <TableCell scope="row" className={classes.tableKey}>
                                                            {item}
                                                        </TableCell>
                                                        <TableCell scope="row">
                                                            <TranslationField section_key={section_key} item={item} />
                                                            {/* <Button variant="contained" color="primary" type="button" onClick={() => translateField(section_key, item)}>
                                                                {translate('resources.admin/translations.buttons.translate_field')}
                                                            </Button> */}
                                                        </TableCell>
                                                    </TableRow>
                                                ) : null }
                                            </React.Fragment>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </>
                        ) : (
                            <TableRow>
                                <TableCell align={'center'}>
                                    <Box display={'inline-flex'} alignItems={'center'}>
                                        <Box mr={2}>
                                            {translate('loading')}
                                        </Box>
                                        <CircularProgress size={24} color={'inherit'} />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {langs.length > 0 ? (
                <Paper>
                    <Box display={'flex'} p={2}>

                        {/* <Box ml={2}>
                            <Button variant="contained" color="secondary" type="button" onClick={removeLanguage}>
                                {translate('resources.admin/translations.buttons.remove')}
                            </Button>
                        </Box> */}
                        <Box ml={2}>
                            <Button variant="contained" color="primary" type="button" onClick={saveTranslation}>
                                {translate('resources.admin/translations.buttons.save_translation')}
                            </Button>
                        </Box>
                        <Box ml={2}>
                            <Button variant="contained" color="primary" type="button" onClick={publishTranslation}>
                                {translate('resources.admin/translations.buttons.publish_translation')}
                            </Button>
                        </Box>
                        <Box flexGrow={1} />
                        {resetButton && (
                            <Button variant="outlined" color="default" type="button" onClick={resetTranslation} style={{borderWidth: 2}}>
                                {translate('resources.admin/translations.buttons.reset')}
                            </Button>
                        )}
                        {/* <Box ml={2} style={{marginTop: -10}}>
                            <FormControl style={{width: 160, marginLeft: 15}}>
                                <InputLabel id="choose-new-language-label">New language</InputLabel>
                                <Select 
                                    labelId="choose-new-language-label"
                                    id="choose-new-language"
                                    label={translate('resources.admin/translations.fields.all_locales')}
                                    onChange={(e: any) => setNewLanguage(e.target.value)}
                                    value={newLanguage}                                
                                >
                                    {filteredAllLocales.map((locale) => (
                                        <MenuItem key={'opnl_' + locale.id} value={locale.id}>{locale.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl style={{width: 160, marginLeft: 15}}>
                                <InputLabel id="choose-template-language-label">Template language</InputLabel>
                                <Select
                                    labelId="choose-template-language-label"
                                    id="choose-template-language" 
                                    label={translate('resources.admin/translations.fields.template_language')}
                                    onChange={(e: any) => setTemplateLanguage(e.target.value)}
                                    value={templateLanguage}                                    
                                >
                                    {alreadyAddedLocales.map((locale) => (
                                        // <option key={'op_' + locale.id} aria-label={locale.name} value={locale.id} />
                                        <MenuItem key={'optl_' + locale.id} value={locale.id}>{locale.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box ml={2}>
                            <Button variant="contained" color="primary" type="button" onClick={addNewLanguage}>
                                {translate('resources.admin/translations.buttons.add_new_language')}
                            </Button>
                        </Box> */}
                    </Box>
                </Paper>
            ) : null }
            {isLoading && <LoadMask transparent />}
        </>
    );
};

export default TranslationList;
