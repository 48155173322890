import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import WintypList from './WintypList';

const resource = {
    list: WintypList,
    icon: FormatListNumberedIcon,
    options: {
        label: 'resources.admin/lod-wintyp.name'
    }
};

export default resource;
