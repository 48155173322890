import React from 'react';
import {Filter, FilterProps, SelectInput} from 'react-admin';

const RolesFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SelectInput alwaysOn source="role" choices={[
            {id: 'customer', name: "resources.admin/permissions.roleOptions.customer"},
            {id: 'murr-intern', name: "resources.admin/permissions.roleOptions.murr-intern"}
        ]}/>
    </Filter>
);

export default RolesFilter;
