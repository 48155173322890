import * as React from 'react';
import {Datagrid, List, ListProps, EditButton} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import {Route} from 'react-router';

import GrommetFilter from "./GrommetFilter";
import GrommetEdit from "./GrommetEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";

const GrommetList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<GrommetFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <TextField source="type"/>
                    <TextField source="category"/>
                    <TextField source="matnr"/>
                    <TextField source="material"/>
                    <EditButton/>
                </Datagrid>
            </List>

            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''}>
                            {isMatch && (
                                <GrommetEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default GrommetList;
