import ListAltIcon from '@material-ui/icons/ListAlt';
import PriceListList from './PriceListList';

const resource = {
    list: PriceListList,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/lod-price-list.name'
    }
};

export default resource;
