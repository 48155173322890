import React from 'react';
import {Filter, TextInput, FilterProps, SelectInput} from 'react-admin';

const PostFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput label='resources.admin/posts.fields.title' source="title" alwaysOn />
        <SelectInput emptyText="All types" alwaysOn source="type" choices={[
            {id: 'page', name: 'Page'},
            {id: 'content', name: 'Content'},
            //{id: 'modal', name: 'Modal'},
        ]}/>
    </Filter>
);

export default PostFilter;
