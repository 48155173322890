import React from 'react';

import {stepsStyles} from "../../styles/ui/steps";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CheckCircle from "@material-ui/icons/CheckCircle";

const Steps = ({ steps, currentStep, onClickStep , showOnlyCurrentStepLabel = false} : any) => {
    const classes = stepsStyles();



    return (
        <>
            <Grid container className={`${classes.steps} ${showOnlyCurrentStepLabel ? 'center' : ''}`}>
                {steps.map((step: any, i: number) => (
                    <Grid
                        item
                        key={i}
                        onClick={i + 1 <= currentStep ? onClickStep : null}
                        className={`${classes.step} ${i + 1 < currentStep ? 'active' : ''} ${currentStep === i + 1 ? 'current' : ''} ${showOnlyCurrentStepLabel ? 'center' : ''}`}
                        data-name={step}
                        data-value={i + 1}
                    >
                        <Box className={`${classes.counter} ${i + 1 < currentStep ? 'active' : ''}`}>
                            <Box>
                                {i + 1 < currentStep ? <CheckCircle className={classes.icon} /> : i+1}
                            </Box>
                        </Box>
                        {!showOnlyCurrentStepLabel && (
                            <Box className={`${classes.text}`}>
                                {step}
                            </Box>
                        )}
                    </Grid>
                ))}
            </Grid>
            {showOnlyCurrentStepLabel && (
                <Typography
                    variant="h6"
                    style={{fontWeight: 700, textAlign: 'center', marginTop: 15}}
                >
                    {steps[currentStep - 1]}
                </Typography>
            )}
        </>
    );
}

export default Steps;