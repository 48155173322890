import React, {useEffect} from 'react';

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import FieldLabel from "./FieldLabel";

import {fields} from "../../styles/ui/fields";
import {useTranslate} from "react-admin";

const FieldInput = ({id, value, label, onChange, type, isDark, inlineLabel, size= 'medium', min, max, mandatory = false, className = null, disabled= null, multiline = false, placeholder = '', debounce = null, endAdornment = null, endAdornmentIsHtml = false, customStyle = null, filterId = null, hasError = false, readOnly, maxLength = null, showNumericPhone = null, customInputProps = {}}: any) => {
    const translation = useTranslate();
    const classes = fields();

    const fieldType = type ?? 'number';
    const [fieldValue,setFieldValue] = React.useState<any>('');

    useEffect(() => {
        if (value === fieldValue || !value) return;

        setFieldValue(value);
    }, [value, fieldValue]);

    //debounce here
    useEffect(() => {
        if (value === fieldValue || !fieldValue) return;

        const delayInputTimeoutId = setTimeout(() => {
            onChange(filterId, fieldValue);
        }, 900);
        return () => clearTimeout(delayInputTimeoutId);
    }, [value, fieldValue, filterId, onChange]);

    return (
        <Box className={className}>
            {label && (
                <FieldLabel fieldLabel={label} fieldName={id} mandatory={mandatory} />
            )}
            <Box className="flex flexColumn fullWidth">
                <Box className="flex fullWidth">
                    {inlineLabel && (
                        <Typography
                            variant="body2"
                            className={`${classes.inputNumberLabel} ${isDark && 'dark'}`}
                        >
                            {translation(inlineLabel)}
                        </Typography>
                    )}
                    <Box className={`fullWidth ${inlineLabel ? classes.inputNumberField : null}`}>
                        <TextField
                            id={id}
                            value={fieldValue ?? ''}
                            onChange={onChange}
                            onInput={(e: any) => {
                                if ( type=== 'number' ) {
                                    let value = Number(e.target.value);
                                    if (value > max) value = max;
                                    if (value < min) value = min;
                                    setFieldValue(value??'');
                                } else {
                                    let value = e.target.value;
                                    if (showNumericPhone) {
                                        const regex = /^[0-9]*$/;
                                        if (!regex.test(value)) {
                                            e.target.value = value.slice(0, value.length - 1);
                                            return;
                                        }
                                    }
                                    if (maxLength && value.length > maxLength) {
                                        e.target.value = value.slice(0, maxLength);
                                    }
                                    setFieldValue(e.target.value);
                                }
                            }}
                            size={size??'medium'}
                            className={`${isDark && 'darkInput'}`}
                            style={{
                                borderWidth: 2
                            }}
                            variant="outlined"
                            type={fieldType ?? 'number'}
                            multiline={multiline}
                            fullWidth
                            InputProps={{
                                error: hasError ?? false,
                                inputProps: {
                                    min: min ?? 0,
                                    max: max ?? 1000,
                                    pattern: showNumericPhone ? "[0-9]+" : null,
                                    maxLength: maxLength??null,
                                    readOnly: readOnly??false,
                                },
                                endAdornment: endAdornment ? (endAdornmentIsHtml ? <Box dangerouslySetInnerHTML={{__html : endAdornment }} /> : endAdornment) : null,
                                readOnly: readOnly??false,
                                ...customInputProps,
                                style: customStyle ?? {}
                            }}
                            inputProps={{
                                style: customStyle ?? {}
                            }}
                            disabled={disabled}
                            placeholder={placeholder}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default FieldInput;