import * as React from 'react';
import {Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, Box, Link} from '@material-ui/core';
import {useRecordContext, useTranslate} from 'react-admin';

const CartDetails = () => {
    const translate = useTranslate();
    const record = useRecordContext()
    const cartItems = record.cart_items || {};

    return (
        <Card elevation={0}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                {translate(
                                    'resources.admin/orders.fields.product_id'
                                )}
                            </TableCell>
                            {cartItems[0] && cartItems[0].id !== -999 && (
                                <>
                                    <TableCell>
                                        {translate(
                                            'resources.admin/orders.fields.product'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {translate(
                                            'resources.admin/orders.fields.matnr'
                                        )}
                                    </TableCell>
                                </>
                            )}
                            <TableCell>
                                {translate(
                                    'resources.admin/orders.fields.variation'
                                )}
                            </TableCell>
                            <TableCell>
                                {translate(
                                    'resources.admin/orders.fields.qty'
                                )}
                            </TableCell>
                            <TableCell>
                                {translate(
                                    'resources.admin/orders.fields.price'
                                )}
                            </TableCell>
                            {cartItems[0] && cartItems[0].id === -999 && (
                                <>
                                    <TableCell>
                                        {translate(
                                            'resources.admin/orders.fields.cad_url'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {translate(
                                            'resources.admin/orders.fields.print_url'
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {translate(
                                            'resources.admin/orders.fields.print_url'
                                        )}
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(cartItems).map((product: any) => {
                            return (product.product_cart_variations || []).map((variation: any) => {
                                return (
                                    <TableRow key={`variation_${variation.variation_id}`}>
                                        <TableCell>
                                            <Link href={`${process.env.REACT_APP_SHOP_URL}${product.path}`} target={"_blank"}>
                                                {product.id === -999 ? 'LOD' : product.id}
                                            </Link>
                                        </TableCell>
                                        {product.id !== -999 && (
                                            <>
                                                <TableCell>
                                                    <Link href={`${process.env.REACT_APP_SHOP_URL}${product.path}`} target={"_blank"}>
                                                        {product.name}&nbsp;
                                                        {variation.is_sample && (
                                                            <Typography component="span" variant="caption" style={{padding: '0.2rem 0.4rem', borderRadius: 5, backgroundColor: '#008998', color: '#ffffff'}}>
                                                                {translate('resources.admin/orders.badge.sample')}
                                                            </Typography>
                                                        )}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <Link href={`${process.env.REACT_APP_SHOP_URL}${product.path}?matnr=${variation.matnr}`} target={"_blank"}>
                                                        {variation.matnr}
                                                    </Link>
                                                </TableCell></>
                                        )}
                                        <TableCell>
                                            {product.id !== -999 ? variation.variation_name : `#${variation.variation_id}`}
                                        </TableCell>
                                        <TableCell>
                                            x{variation.qty}
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'flex'} whiteSpace={'nowrap'}>
                                                {variation.is_sample ? '€0,00' : variation.price_formatted}
                                            </Box>
                                        </TableCell>
                                        {product.id === -999 && (
                                            <>
                                                <TableCell>
                                                    <Box display={'flex'} whiteSpace={'nowrap'}>
                                                        <div dangerouslySetInnerHTML={{__html: variation.cad_url ? `<a target="_blank" href="${variation.cad_url}">CAD</a>` : '-'}}></div>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box display={'flex'} whiteSpace={'nowrap'}>
                                                        <div dangerouslySetInnerHTML={{__html: variation.print_url ? `<a target="_blank" href="${variation.print_url}">Print XML</a>` : '-'}}></div>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>
                                                    <Box display={'flex'} whiteSpace={'nowrap'}>
                                                        <div dangerouslySetInnerHTML={{__html: variation.print2_url ? `<a target="_blank" href="${variation.print2_url}">Print XLS</a>` : '-'}}></div>
                                                    </Box>
                                                </TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                );
                            });
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
};

export default CartDetails;
