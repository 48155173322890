import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, TextInput, useTranslate} from 'react-admin';
import {Typography} from "@material-ui/core";

//import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps} from "../../functions/common";

const ShippingCountryCreate: FC<CreateProps> = props => {
    //const classes = listEditCreate();
    const newProps = filterProps(props);
    const translate = useTranslate();

    return (
        <>
            <Typography variant='h6'>
                {translate("resources.admin/shipping-countries.titles.create")}
            </Typography>
            <Create {...newProps}>
                <SimpleForm>
                    <TextInput source="code" label={'resources.admin/shipping-countries.fields.code'} />
                </SimpleForm>
            </Create>
        </>
    );
};

export default ShippingCountryCreate;
