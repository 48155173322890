import {makeStyles} from "@material-ui/core/styles";

export const dashboard = makeStyles((theme) => ({
    card: {
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        marginBottom: '1em'
    },
    cardMain: {
        overflow: 'inherit',
        padding: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
            color: 'inherit',
        },
    },
    cardTitle: {},
    statsTotal: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#000'
    }
}));
