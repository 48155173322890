import React from 'react';
import {Filter, FilterProps, SelectInput} from 'react-admin';
import countries from "../../config/countries";

const ProductReviewFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <SelectInput alwaysOn source="status" emptyText="resources.admin/product-reviews.statusOptions.all"
                     choices={[
                         {id: 'open', name: 'resources.admin/product-reviews.statusOptions.open'},
                         {id: 'publish', name: 'resources.admin/product-reviews.statusOptions.publish'},
                         {id: 'reject', name: 'resources.admin/product-reviews.statusOptions.reject'}
                     ]}/>
        <SelectInput alwaysOn source="country" choices={countries.map(el => ({id: el.code, name: el.name}))}/>
    </Filter>
);

export default ProductReviewFilter;
