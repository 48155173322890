import * as React from 'react';
import {FC} from 'react';
import {AutocompleteInput, Edit, EditProps, ReferenceInput, SelectInput, SimpleForm, TextInput, useTranslate} from 'react-admin';

import Title from '../../components/list/Title';
import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps} from "../../functions/common";

const ProductReviewEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);
    const translate = useTranslate();

    const choices = ([...Array(5)].map((_,idx)=>({id:5-idx, name:'★'.repeat(5-idx)})));


    return (
        <Edit{...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="id" label="resources.admin/product-reviews.titles.edit"/>
                <SelectInput source="star_rating" choices={choices} />
                <ReferenceInput source="product_id" reference="admin/products">
                    <AutocompleteInput />
                </ReferenceInput>
                <ReferenceInput source="order_id" reference="admin/orders">
                    <AutocompleteInput  />
                </ReferenceInput>
                <TextInput source="headline" fullWidth={true}/>
                <TextInput source="body" fullWidth={true} multiline={true} rows={10} />
                <SelectInput source="status"
                             choices={[
                                 {id: 'open', name: translate('resources.admin/product-reviews.statusOptions.open')},
                                 {id: 'publish', name: translate('resources.admin/product-reviews.statusOptions.publish')},
                                 {id: 'reject', name: translate('resources.admin/product-reviews.statusOptions.reject')}
                             ]}/>
            </SimpleForm>
        </Edit>
    );
};

export default ProductReviewEdit;
