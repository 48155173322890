import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../theme/colors";
import {hexToRgba} from "../../functions/common";

export const fields = makeStyles((theme) => ({
    endIcons: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    toggleVisibility: {
        cursor: 'pointer',
        flexShrink: 0,
        marginTop: theme.spacing(1),
    },
    endIcon: {
        flexShrink: 0,
        fill: 'inherit !important',
        display: 'inline-flex',
        alignItems: 'center',
    },
    endIconError: {
        fill: `${Colors.primary.main} !important`
    },
    whiteIcon: {
        fill: `${Colors.white.main} !important`
    },
    inputNumberLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 40,
        fontWeight: 700,
        padding: `0 ${theme.spacing(2)}`,
        backgroundColor: Colors.gray.accent,
        border: `1px solid ${hexToRgba(Colors.dark.main, 0.25)}`,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderRight: 'none',
        lineHeight: 1,
        flexShrink: 0,
    },
    inputNumberField: {
        '& .MuiOutlinedInput-root': {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        '& .MuiOutlinedInput-input': {
            paddingRight: 0,
        },
    },
    option: {
        '& .MuiFormControlLabel-root': {
            marginLeft: '-9px',
        },
        '& .radioCheckFill, & .checkboxCheckFill': {
            width: 19,
            height: 19,
            backgroundColor: Colors.secondary.main,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 1,
        },
        '& .radioCheckFill': {
            borderRadius: '50%',
        },
        '& .checkboxCheckFill': {
            borderRadius: '2px',
        },
    },
    optionWithImage: {
        border: `1px solid ${hexToRgba(Colors.dark.main, 0.15)}`,
        borderRadius: '0.25rem',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',

        '& .MuiFormControlLabel-root': {
            margin: 0,
            width: '100%',
            backgroundColor: Colors.gray.main,
        },
        '& .optionImage': {
            display: 'flex',
            justifyContent: 'center',
        }
    }
}));