import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, useTranslate} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import {Route} from 'react-router';

import ShippingCountryFilter from "./ShippingCountryFilter";
//import ShippingCountryCreate from "./ShippingCountryCreate";
import ShippingCountryEdit from "./ShippingCountryEdit";
import SideDrawer from "../../components/list/SideDrawer";
import TextField from "../../components/list/TextField";
import ListActions from "../../components/list/ListActions";
import {Typography} from "@material-ui/core";


const ShippingCountryList = (props: ListProps): ReactElement => {
    const translate = useTranslate();

    return (
        <Fragment>
            <Typography variant={'h6'}>{translate('resources.admin/shipping-countries.name')}</Typography>
            <List {...props} filters={<ShippingCountryFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source={'code'} label={'resources.admin/shipping-countries.fields.code'} />
                    <EditButton/>
                </Datagrid>
            </List>
            {/*<Route path={`${props.basePath}/create`}>*/}
            {/*    {({match}) => {*/}
            {/*        return (*/}
            {/*            <SideDrawer open={!!match} basePath={props.basePath ?? ''}>*/}
            {/*                <ShippingCountryCreate {...props} />*/}
            {/*            </SideDrawer>*/}
            {/*        )*/}
            {/*    }}*/}
            {/*</Route>*/}
            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''}>
                            {isMatch && (
                                <ShippingCountryEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default ShippingCountryList;
