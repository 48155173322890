import {Card, Box, TableContainer, Table, TableCell, TableRow, TableBody} from '@material-ui/core';
import {useEffect, useState} from "react";
import {useTranslate} from "react-admin";

import {dashboard} from '../../styles/dashboard';
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosForApi from "../../config/axios-for-api";
import StatHeader from "./StatHeader";

interface StatDataRow {
    country: string;
    nr_active_users: number,
    nr_active_users_fmt: number;
}

const Loading = ({classes}: any) => {
    return (
        <Card className={classes.card}>
            <Box p={2} display={'flex'} justifyContent={'center'} alignItems={'center'} height={200}>
                <CircularProgress/>
            </Box>
        </Card>
    );
}

const NrActiveUsersByCountry = () => {
    const classes = dashboard();
    const translate = useTranslate();

    const [data, setData] = useState<StatDataRow[]>([]);
    const [shouldLoadStatsData, setShouldLoadStatsData] = useState(true);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let cancel = false;
        const loadStatsData = async () => {
            const {data} = await axiosForApi.get('api/admin/stats/nr-active-users-by-country');
            if(!cancel) {

                setData(data);
                setLoading(false);
                setShouldLoadStatsData(false);
            }
        }

        shouldLoadStatsData && loadStatsData();

        return () => {
            cancel = true;
        }
    }, [shouldLoadStatsData]);

    if (loading) return (<Loading classes={classes}/>);


    return (
        <Card className={classes.card}>
            <Box p={2}>
                <StatHeader
                    justifyContent={'center'}
                    // title={`Anzahl der aktiven Benutzer nach Land`}
                    title={translate('resources.admin/dashboard.headers.nr_active_users_by_country')}
                    help={'resources.admin/dashboard.help.nr_active_users_by_country'}
                />
                <div className='scrollable' style={{height: '300px', overflowY: 'scroll', overflowX: 'hidden'}}>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                {data.map((data, i) => (
                                    <TableRow key={i}>
                                        <TableCell padding={'none'}>
                                            {i + 1}
                                        </TableCell>
                                        <TableCell>
                                            {data.country}
                                        </TableCell>
                                        <TableCell padding={'none'}>
                                            {data.nr_active_users_fmt}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
        </Card>
    );
}

export default NrActiveUsersByCountry;
