import { darken } from "@material-ui/core";
import {hexToRgba} from "../../functions/common";

export const Colors = {
  primary: {
    main: "#e2001a",
    accent: darken( "#e2001a", 0.15),
  },
  secondary: {
    main: "#008998",
    accent: darken( "#008998", 0.15),
  },
  dark: {
    main: "#002536",
    accent: darken( "#002536", 0.15),
  },
  white: {
    main: "#ffffff",
    accent: "#F5F2F3",
  },
  outlinedDark: {
    main: '#303030',
    accent: '#000000',
  },
  error: {
    main: "#e2001a",
  },
  success: {
    main: "#73AE42",
  },
  warning: {
    main: "#FF8C00",
  },
  lightGrey: {
    main: '#f5f2f3',
  },
  gray: {
    main: "#F5F2F3",
    accent: "#CCD3D7",
  },
  background: {
    default: "#F5F2F3"
  },
  transparent: {
    default: hexToRgba("#ffffff", 0),
  },
  text: {
    main: "#353535",
    accent: "#666666",
  },
  lightText: {
    main: "#474747",
    accent: "#777777",
  },
  textPrimary: {
    main: "#353535",
  },
  textSecondary: {
    main: "#474747",
  },
  green: {
    main: '#73AE42',
  }
};
