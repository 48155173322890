import AndroidIcon from '@material-ui/icons/Android';
import CobotList from './CobotList';
import CobotCreate from "./CobotCreate";

const resource = {
    list: CobotList,
    create: CobotCreate,
    icon: AndroidIcon,
    options: {
        label: 'resources.admin/cob-cobots.name'
    }
};

export default resource;
