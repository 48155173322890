import * as React from 'react';
import {Fragment, ReactElement} from 'react';
import {ChipField, Datagrid, EditButton, List, ListProps, ReferenceField} from 'react-admin';
import TextField from "../../components/list/TextField";
import OrderFilter from "./OrderFilter";
import OrderEdit from "./OrderEdit";
import SideDrawer from "../../components/list/SideDrawer";
import {Route} from "react-router";

const OrderList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<OrderFilter/>} sort={{field: 'created_at', order: 'DESC'}}>
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="created_at_formatted" label={'resources.admin/orders.fields.created_at'} sortBy={'created_at'} />
                    <ChipField source={'payment_status'} label={'resources.admin/orders.fields.payment_status'} />
                    <TextField source="sap_order_id" label={'resources.admin/orders.fields.sap_order_id'} />
                    <TextField source="status" label={'resources.admin/orders.fields.status'} />
                    <TextField source="source" label={'resources.admin/orders.fields.source'} />
                    <TextField source="total_formatted" label='resources.admin/orders.fields.total' />
                    <ReferenceField label='resources.admin/users.fields.first_name' reference='admin/users' source='customer_id'>
                        <TextField source="first_name"/>
                    </ReferenceField>
                    <ReferenceField label='resources.admin/users.fields.last_name' reference='admin/users' source='customer_id'>
                        <TextField source="last_name"/>
                    </ReferenceField>
                    <ReferenceField label='resources.admin/users.fields.email' reference='admin/users' source='customer_id'>
                        <TextField source="email"/>
                    </ReferenceField>
                    <ReferenceField label='resources.admin/users.fields.company' reference='admin/users' source='customer_id'>
                        <TextField source="company"/>
                    </ReferenceField>
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''} size={800}>
                            {isMatch && (
                                <OrderEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default OrderList;
