import MailIcon from '@material-ui/icons/Mail';
import MailTemplateList from './MailTemplateList'

const resource = {
    list: MailTemplateList,
    icon: MailIcon,
    options: {
        label: 'resources.admin/mail-templates.name'
    }
};

export default resource;