import {FC} from "react";
import {FieldProps, useLocale, LinearProgress} from "react-admin";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

const TextField: FC<FieldProps & {withLink?:boolean}> | string = ({record,source='code', withLink = false}) => {
    const locale = useLocale();
    if(!record) return <LinearProgress />;

    const value = record[source] ? typeof record[source] === 'object' ? record[source][locale] : record[source] : '-';
    return <Typography component="span" variant="body2" dangerouslySetInnerHTML={{__html: value ?? ''}} />;
}

export default TextField;
