import {stringify} from 'query-string';
import {DataProvider, fetchUtils} from 'ra-core';

import cfgLocale from '../config/locale';
import axios from "../config/axios-for-api";
import {CreateParams, UpdateParams} from "react-admin";

const convertFileToBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

const prepareDataImages = (params: UpdateParams|CreateParams) => {
    const promises: Promise<string | void>[] = [];
    Object.keys(params.data ?? []).forEach((maybeFile: string) => {
        if (params.data[maybeFile] ?? null) {
            const rawFile = params.data[maybeFile]['rawFile'] ?? null;
            if (rawFile && (rawFile instanceof File)) {
                promises.push(convertFileToBase64(params.data[maybeFile]).then(picture64 => {
                    params.data[maybeFile].base64image = picture64;
                }));
            }
        }
    });
    return promises;
}

const raDataJson = (apiUrl: string): DataProvider => ({

    getList: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
            _locale: localStorage.getItem('locale')
        };

        //MUR-507 - custom
        // for some reason the built query string doesn't create a valid array
        //eg: locations[]=FR&locations[]=DE
        // it will generate locations=FR&locations=DE which is not read as array by Laravel
        //solution is to create comma separated string value that later is processed by controller.
        if (resource === 'admin/users' && (Object.keys(params.filter).includes("locations"))) {
            query.locations = query.locations.join(",");
        }

        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return axios.get(url).then(({headers, data}) => {

            if (!headers.hasOwnProperty('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data,
                total: parseInt(
                    (headers['x-total-count'] || '').split('/').pop() || '',
                    10
                ),
            };
        }).catch(err => Promise.reject(err.response));
    },

    getOne: (resource, params) =>
        axios.get(`${apiUrl}/${resource}/${params.id}`, {
            params: {
                _locale: `${localStorage.getItem('locale')}`
            }
        }).then(({data}) => ({
            data
        })),

    getMany: (resource, params) => {
        const query = {
            'id[]': params.ids,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return axios.get(url).then(({data}) => ({data}));
    },

    getManyReference: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
            _locale: localStorage.getItem('locale')
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return axios.get(url).then(({headers, data}) => {
            if (!headers.hasOwnProperty('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data,
                total: parseInt(
                    (headers['x-total-count'] || '').split('/').pop() || '',
                    10
                ),
            };
        });
    },

    update: (resource, params) => {

        const doUpdate = (resource: string, params: UpdateParams) => axios.put(
            `${apiUrl}/${resource}/${params.id}`,
            params.data
        ).then(({data}) => ({data})).catch(err => {
            const response = err.response.data;
            let message = response.message ?? '';
            if (response.errors) {
                Object.keys(response.errors).forEach(field => {
                    message += " " + response.errors[field].pop();
                })
            }
            return Promise.reject(message);
        });


        if (resource === 'admin/posts' || resource === 'admin/modals') {
            //maybe some meta to be uploaded
            const meta = params.data.meta ?? null;
            const imagePromises: Promise<{ src: string, caption: string, lang: string, key: string, repeaterField?: string, repeaterRowNr?: number }>[] = [];

            if (meta) {
                const languageOptions = cfgLocale.languageOptions;
                Object.keys(languageOptions).forEach(lang => {
                    Object.keys(meta).forEach(metaKey => {
                        const rawImage = (meta[metaKey] && meta[metaKey][lang]) ? meta[metaKey][lang] : null;
                        if (rawImage && rawImage.rawFile instanceof File) {
                            imagePromises.push(Promise.resolve(convertFileToBase64(rawImage))
                                .then((picture64) => ({
                                    key: `${metaKey}`,
                                    lang: `${lang}`,
                                    src: `${picture64}`,
                                    caption: `${rawImage.caption ?? ''}`
                                })));
                        }

                        //check to see if repeater
                        const repeater = Object.assign({}, rawImage);
                        const isRepeater = repeater instanceof Object;
                        if (isRepeater) {
                            (Object.values(repeater)).forEach((repeaterRow: any, repeaterRowNr) => {
                                if (repeaterRow instanceof Object) {
                                    Object.keys(repeaterRow).forEach((repeaterField: string) => {
                                        const repeaterValue = repeaterRow[repeaterField];
                                        if (repeaterValue && repeaterValue.rawFile instanceof File) {
                                            imagePromises.push(Promise.resolve(convertFileToBase64(repeaterValue))
                                                .then((picture64) => ({
                                                    repeaterRowNr: repeaterRowNr,
                                                    repeaterField: repeaterField,
                                                    key: `${metaKey}`,
                                                    lang: `${lang}`,
                                                    src: `${picture64}`,
                                                    caption: `${rawImage.caption ?? ''}`
                                                })));
                                        }
                                    })
                                }
                            });
                        }

                    });
                });
            }

            return Promise.all(imagePromises).then(metaImages => {
                metaImages.forEach(image => {

                    if (image.repeaterRowNr !== undefined && image.repeaterField !== undefined) {
                        params.data.meta[image.key][image.lang][image.repeaterRowNr][image.repeaterField] = {
                            image_src: image.src ?? '',
                            caption: image.caption ?? ''
                        }
                    } else {
                        params.data.meta[image.key][image.lang] = {
                            image_src: image.src ?? '',
                            caption: image.caption ?? ''
                        }
                    }
                });
                return doUpdate(resource, params);
            });

        } else if (resource === 'admin/lod-images') {
            const file = params.data.file ?? null;
            if (file) {
                if (file.rawFile instanceof File) {
                    return convertFileToBase64(file).then(picture64 => {
                        params.data.file.base64 = picture64
                    }).then(() => doUpdate(resource, params));
                }
            }

        } else {
            const dataImages = prepareDataImages(params);
            if (dataImages.length > 0) return Promise.all(dataImages).then(() => doUpdate(resource, params));
        }

        return doUpdate(resource, params);
    },

    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                axios.put(`${apiUrl}/${resource}/${id}`, params.data)
            )
        ).then(responses => ({data: responses.map(({data}) => data.id)})),

    create: (resource, params) => {
        const doCreate = (resource: string, params: CreateParams) => axios.post(`${apiUrl}/${resource}`, params.data).then(({data}) => ({
            data: {...params.data, id: data.id},
        })).catch(err => {
            const response = err.response.data;
            let message = response.message ?? '';
            if (response.errors) {
                Object.keys(response.errors).forEach(field => {
                    message += " " + response.errors[field].pop();
                })
            }
            return Promise.reject(message);
        });

        if (resource === 'admin/lod-images') {
            const file = params.data.file ?? null;
            if (file) {
                if (file.rawFile instanceof File) {
                    return convertFileToBase64(file).then(picture64 => {
                        params.data.file.base64 = picture64
                    }).then(() => doCreate(resource, params));
                }
            }

        } else {
            const dataImages = prepareDataImages(params);
            if (dataImages.length > 0) return Promise.all(dataImages).then(() => doCreate(resource, params));
        }

        return doCreate(resource, params);
    },

    delete: (resource, params) =>
        axios.delete(`${apiUrl}/${resource}/${params.id}`).then(({data}) => {
            return ({data});
        }),

    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                axios.delete(`${apiUrl}/${resource}/${id}`)
            )
        ).then(responses => ({data: responses.map(({data}) => data.id)})),
});

export default raDataJson;
