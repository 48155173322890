import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {useCallback, useEffect, useRef, useState} from "react";

import axiosForApi from "../../config/axios-for-api";
import {CircularProgress} from "@material-ui/core";
import {useNotify} from "ra-core";
import {handleError} from "../../functions/common";
import {GridCheckIcon} from "@material-ui/data-grid";

const useStyles = makeStyles({
    label: {width: '10em', display: 'inline-block'},
    button: {margin: '1em'},
    value: {margin: '1em', display: 'inline-flex', alignItems: "center", gap: "10px"}
});

const ScheduleImport = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [log, setLog] = useState('');
    const logRef = useRef<HTMLTextAreaElement|null>(null);
    const [inProgress, setInProgress] = useState(false);

    const notify = useNotify();

    const scheduleImport = async () => {
        setLoading(true);
        setSuccess(false);
        axiosForApi.post('/api/admin/pim/schedule-import')
            .then(() => {
                setSuccess(true);
                setLog('');
            })
            .catch(e => {
                notify(e.response?.data?.message ?? handleError(e), 'error')
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const getLog = useCallback(async () => {
         axiosForApi.get('/api/admin/pim/import-log')
            .then(({data}) => {
                setLog(`${data}`)
            });

         if(logRef.current)
            logRef.current.scrollTop = logRef.current.scrollHeight;
    },[]);

    const checkIfCanBeScheduled = async () => {
         axiosForApi.get('/api/admin/pim/can-be-scheduled')
            .then(({data}) => {
                const inProgress = !(data.can_be_scheduled??0);
                setInProgress(inProgress);
                setSuccess(inProgress);
            });
    }

    useEffect(() => {
        getLog();
        checkIfCanBeScheduled();
        const interval = setInterval(()=>{
            getLog();
            checkIfCanBeScheduled();
        }, 5000);
        return () => clearInterval(interval);
    }, [getLog]);

    useEffect(() => {
        checkIfCanBeScheduled();
    }, []);


    return (
        <Card>
            <CardContent>
                {!inProgress && <Button
                    disabled={loading}
                    variant="contained"
                    className={classes.button}
                    color='default'
                    onClick={scheduleImport}
                >
                    Schedule Import
                    {loading && <CircularProgress style={{marginLeft: '2rem'}} color="inherit"/>}
                    {success && <GridCheckIcon style={{marginLeft: '2rem'}} color="inherit"/>}
                </Button>}
                {inProgress && <div style={{marginBottom:'2rem',fontWeight:'bold'}}>Import in progress ...</div>}
                <textarea ref={logRef} rows={20} style={{width:'100%'}} defaultValue={log} placeholder="No log yet ..."></textarea>
            </CardContent>
        </Card>
    );
};

export default ScheduleImport;
