import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";
import {Colors} from "../theme/colors";
import BgLogin from '../../assets/bgs/loginRegisterBackground.jpg';
import {hexToRgba} from "../../functions/common";

export const login = makeStyles((theme: Theme) => ({
    main: {
        display: 'block',
        height: '100vh',
        justifyContent: 'flex-start',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: Colors.dark.main,
        boxSizing: 'border-box',

        '&:before': {
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            content: "''",
            display: 'block',
            opacity: 0.05,
            zIndex: 1,
            position: 'absolute',
            backgroundSize: 'cover',
            backgroundImage: `url(${BgLogin})`,
            backgroundRepeat: 'no-repeat',
        }
    },
    toolbar: {
        display: 'flex',
        boxSizing: 'border-box',
        justifyContent: 'center',
        width: 'min(100%, 1450px)',
        height: 60,
        paddingTop: 5,
        alignItems: 'center',
        margin: '0 auto',

        [theme.breakpoints.up('sm')]: {
            height: 70,
        },
    },
    logo: {
        top: 0,
        left: 0,
        padding: '13.5px 15px',
        maxHeight: 62,
        position: 'absolute',
        backgroundColor: Colors.white.main,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        zIndex: 2,
        overflow: 'hidden',
        display: 'flex',

        '& a': {
            display: 'flex',
            flexShrink: 0,
        },

        '& img': {
            flexShrink: 0,
        }
    },
    card: {
        position: 'relative',
        zIndex: 2,
        backgroundColor: Colors.dark.main,
        width: 'calc(100% - 30px)',
        maxWidth: 475,
        border: '1px solid rgba(255, 255, 255, 0.25)',
        borderRadius: 5,
        margin: '10vw auto',
        boxSizing: 'border-box',

        '& .MuiCardContent-root': {
            padding: 15,

            [theme.breakpoints.up('sm')]: {
                padding: 30,
            },
        },
    },
    backToShop: {
        color: Colors.white.main,
        border: `2px solid ${hexToRgba(Colors.white.main, 0.5)}`,
        backgroundColor: hexToRgba(Colors.white.main, 0),
        fontSize: '0.85rem',
        borderRadius: 5,
        display: 'inline-flex',
        alignItems: 'center',
        padding: '2px 10px 1px 0',
        fontWeight: 700,
        lineHeight: 1,
        height: 27,
        boxSizing: 'border-box',
        zIndex: 2,
        flexShrink: 0,
        textDecoration: 'none',
        transition: '0.2s all ease',

        '&:hover': {
            textDecoration: 'none',
            backgroundColor: hexToRgba(Colors.white.main, 0.1),
        },

        '& *': {
            minWidth: 20,
            flexShrink: 0,
        }
    },
}));