import {Order, Product, User, Offer} from "../../config/types";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@material-ui/core";
import {DateField, Link, useLocale, useTranslate} from "react-admin";
import * as React from "react";

interface ResultsTableProps {
    columns: Array<string>;
    results: Order[] | User[] | Product[] | Offer[];
    to: string;
    linkArray?: Array<string>;
}

const ResultsTable = ({columns, results, to, linkArray}: ResultsTableProps) => {
    const translate = useTranslate();
    const locale = useLocale();

    return columns && results ? (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((key, i) => (
                            <TableCell key={i} style={{whiteSpace: 'nowrap'}}>
                                {translate(`resources.admin/dashboard.results.${key}`)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results.map((result, i) => (
                        <TableRow key={i}>
                            {columns.map((key, i) => (
                                <TableCell key={i} style={{whiteSpace: 'nowrap'}}>
                                    {key === 'created_at' ? (
                                        <DateField record={result} source={key} locales={locale} options={{day: '2-digit', month: '2-digit', year: 'numeric'}}/>
                                    ) : (
                                        <>
                                            {linkArray?.includes(key) ? (
                                                <Link to={`${to}/${result['id']}`}>
                                                    <Typography variant={'body2'} color={'textSecondary'}>
                                                        {result[key] ?? '-'}
                                                    </Typography>
                                                </Link>
                                            ) : result[key] ?? '-'}
                                        </>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ) : null;
}

export default ResultsTable;
