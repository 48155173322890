import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, SelectInput, DateInput, ReferenceArrayInput, AutocompleteArrayInput, useTranslate, TextInput, BooleanInput} from 'react-admin';
import {Typography} from "@material-ui/core";

//import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps} from "../../functions/common";
import Title from "../../components/list/Title";
import Rrule from "./Rrule";

const ShippingDateCreate: FC<CreateProps> = props => {
    //const classes = listEditCreate();
    const newProps = filterProps(props);
    const translate = useTranslate();

    return (
        <>
            <Typography variant='h6'>
                {translate("resources.admin/shipping-dates.titles.create")}
            </Typography>
            <Create {...newProps}>
                <SimpleForm>
                    <TextInput source={'kunnr'}/>
                    <DateInput source="shipping_date" style={{width: '221px'}}/>
                    <BooleanInput source="is_today_allowed" looseValue={true} label={'resources.admin/shipping-dates.fields.is_today_allowed'} style={{width: 'auto'}} />
                    <ReferenceArrayInput
                        source="shipping_country_ids"
                        reference="admin/shipping-countries"
                        sort={{field: 'code', order: 'ASC'}}
                        label={'resources.admin/shipping-dates.fields.shipping_country_ids'}
                        perPage={1000}
                    >
                        <AutocompleteArrayInput optionText="code"/>
                    </ReferenceArrayInput>
                    <TextInput source={'notes'} rows={3} multiline fullWidth/>
                    <SelectInput style={{width: '221px'}} source={'status'} choices={[
                        {name: 'Full', id: 'full'},
                        {name: 'Free', id: 'free'},
                    ]}/>

                    <Title source="id" label="Recurrence"/>
                    <Rrule {...props} />
                </SimpleForm>
            </Create>
        </>
    );
};

export default ShippingDateCreate;
