import {FieldProps, useLocale} from "react-admin";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import LinkIcon from "@material-ui/icons/Link";
import * as React from "react";
import {FC} from "react";
import {Typography} from "@material-ui/core";

const ShowOnShop: FC<FieldProps> | string = ({record, source = 'post', children}) => {
    const locale = useLocale();

    if (!record) return null;

    let url = null;
    let value = null;
    let slug = null;
    switch (source) {
        case 'post':
            if (record.type === 'page') {
                url = `${locale}/posts/${record.slug}`;
                slug = `${record.slug ?? ''}`;
                if (slug.match('^home-')) url = `${locale}`;
            }
            value = record.title ? typeof record.title === 'object' ? record['title'][locale] : record['title'] : '-';
            break;
        case 'product':
            url = `${locale}/p/${record.slug}-${record.id}`
            value = record.name ? typeof record.name === 'object' ? record['name'][locale] : record['name'] : '-';
            break;
    }

    const link = url ? new URL(url.toString(), process.env.REACT_APP_SHOP_URL) : null;

    return link ? (
        <Button rel='noreferrer' target="_blank" href={link.toString()}>
            <Typography component="span" variant="body2">{value}</Typography>
            <Box display={'inline-flex'} ml={1}>
                <LinkIcon fontSize={'small'}/>
            </Box>
        </Button>
    ) : (
        <Typography component="span" variant="body2">{value}</Typography>
    );
}

export default ShowOnShop;
