import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, BooleanField} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import {Route} from 'react-router';

import QuellwerkFilter from "./QuellwerkFilter";
import QuellwerkCreate from "./QuellwerkCreate";
import QuellwerkEdit from "./QuellwerkEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";


const QuellwerkList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<QuellwerkFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="werk" fullWidth={true}/>
                    <TextField source={'country'}/>
                    <BooleanField source={'zielwerk'} looseValue={true}/>
                    <BooleanField source={'material'} looseValue={true}/>
                    <BooleanField source={'einkaufspreis'} looseValue={true}/>
                    <BooleanField source={'ek_preiseinheit'} looseValue={true}/>
                    <BooleanField source={'verkaufspreis'} looseValue={true}/>
                    <BooleanField source={'vk_preiseinheit'} looseValue={true}/>
                    <BooleanField source={'wahrung'} looseValue={true}/>
                    <BooleanField source={'preisliste'} looseValue={true}/>
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/create`}>
                {({match}) => {
                    return (
                        <SideDrawer open={!!match} basePath={props.basePath ?? ''}>
                            <QuellwerkCreate {...props} />
                        </SideDrawer>
                    )
                }}
            </Route>
            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''}>
                            {isMatch && (
                                <QuellwerkEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default QuellwerkList;
