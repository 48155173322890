import React, {useState} from 'react'

import ConfirmPassModal from './ConfirmPassModal'
import axiosForApi from "../../config/axios-for-api";
import {useRedirect, useTranslate} from "react-admin";

const ConfirmPass = ({confirming, setConfirming, onSuccess, restrict = false}: any) => {
    const translate = useTranslate();
    const redirect = useRedirect();
    const [password, setPassword] = useState<any>('');
    const [errorMessage, setErrorMessage] = useState<any>(null);

    const confirm = () => {
        axiosForApi.post('/user/confirm-password', {password}).then(() => {
            onSuccess();
            redirect('/admin/configuration?enable-2fa');
        }).catch(({error, status}) => {
            console.log(error, status);
            setErrorMessage(translate('confirmPassword.fail'));
        })
    }

    return (
        <ConfirmPassModal
            setConfirming={setConfirming}
            confirming={confirming}
            password={password}
            setPassword={setPassword}
            onConfirm={confirm}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            restrict={restrict}
        />
    )
}

export default ConfirmPass
