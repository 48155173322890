import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, ReferenceField} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import { Route } from 'react-router';

import WintypFilter from "./WintypFilter";
import WintypCreate from "./WintypCreate";
import WintypEdit from "./WintypEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";

const WintypList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<WintypFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="code" />
                    <TextField source="blocked_at_formatted" label={'resources.admin/lod-wintyps.fields.blocked_at'} sortBy={'blocked_at'} />
                    <TextField source="used_at_formatted" label={'resources.admin/lod-wintyps.fields.used_at'} sortBy={'used_at'} />
                    <TextField source="product" label={'resources.admin/lod-wintyps.fields.product'} />
                    <ReferenceField label={'resources.admin/lod-wintyps.fields.order_id'} reference='admin/orders' source='order_id'>
                        <TextField source="id"  />
                    </ReferenceField>
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/create`}>
                {({ match }) => {
                    return (
                        <SideDrawer open={!!match} basePath={props.basePath??''}>
                            <WintypCreate {...props} />
                        </SideDrawer>
                    )
                }}
            </Route>
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''}>
                            {isMatch && (
                                <WintypEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default WintypList;
