import * as React from 'react';
import {FC} from 'react';
import {BooleanInput, Create, CreateProps, ReferenceInput, SelectInput, SimpleForm, TextInput, useTranslate} from 'react-admin';
import {Box} from '@material-ui/core';

import Title from "../../../components/list/Title";
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const HoleImageShapeCreate: FC<CreateProps> = props => {
    const translate = useTranslate();
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <Title label="resources.admin/lod-hole-image-shape.titles.create"/>
                <Box className={classes.box}>
                    <TextInput autoFocus source="image_code"/>
                    <TextInput source="variable_code" fullWidth={true}/>
                    <SelectInput source="orientation" choices={[
                        {id: 'landscape', name: translate('resources.admin/lod-hole-image-shape.orientation.landscape')},
                        {id: 'portrait', name: translate('resources.admin/lod-hole-image-shape.orientation.portrait')},
                    ]}/>
                    <ReferenceInput label="resources.admin/lod-hole-image-shape.fields.shape" source="shape_id" reference="admin/lod-shapes" options={{
                        suggestionsContainerProps: {
                            disablePortal: true,
                        }
                    }} filter={{status: 'active'}}>
                        <SelectInput optionText="name" fullWidth={true}/>
                    </ReferenceInput>
                    <ReferenceInput label="resources.admin/lod-hole-image-shape.fields.hole" source="hole_id" reference="admin/lod-holes" options={{
                        suggestionsContainerProps: {
                            disablePortal: true,
                        }
                    }} filter={{status: 'active'}}>
                        <SelectInput optionText="name_lt" fullWidth={true}/>
                    </ReferenceInput>
                    {Array.from({length: 11}, (v, k) => k + 1).map((el) => (
                        <BooleanInput source={`mass_${el}`} label={`resources.admin/lod-hole-image-shape.fields.mass_${el}`} looseValue={true}/>
                    ))}
                </Box>
            </SimpleForm>
        </Create>
    );
};

export default HoleImageShapeCreate;
