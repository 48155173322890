import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import OfferList from './OfferList';

const resource = {
    list: OfferList,
    icon: LocalOfferIcon,
    options: {
        label: 'resources.admin/offers.name'
    }
};

export default resource;
