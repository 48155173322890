import React from "react";

import {useRedirect, useTranslate} from "react-admin";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Icon2FA from "../../assets/icons/2fa/icon_2fa.png";
import CardContent from "@material-ui/core/CardContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {breakPoints} from "../../functions/common";

const TwoFANotice = () => {
    const translate = useTranslate();
    const redirect = useRedirect();
    const tabletTup = useMediaQuery(`${[breakPoints.up("md")]}`);

    return (
        <>
            <Card>
                <CardContent style={{display: 'flex', alignItems: "center", padding: '1rem'}} >
                    <Grid
                        container
                        spacing={1}
                        wrap={tabletTup ? 'nowrap' : 'wrap'}
                        alignItems="center"
                    >
                        <Hidden mdDown>
                            <Grid item xs={12} md="auto" style={{flexShrink: 0}}>
                                <img
                                    width={64}
                                    height={64}
                                    src={Icon2FA}
                                    alt="2fa"
                                    style={{objectFit: 'contain'}}
                                />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} xl="auto" style={{flexGrow: 1, paddingRight: 15}}>
                            <Typography
                                component="div"
                                style={{fontWeight: 700, display: 'flex', alignItems:'center', marginBottom: 5}}
                            >
                                <Hidden lgUp>
                                    <Box className="marginRight">
                                        <img
                                            alt="2fa"
                                            width={48}
                                            height={48}
                                            src={Icon2FA}
                                            style={{flexShrink: 0, objectFit: 'contain', marginRight: '1rem'}}
                                        />
                                    </Box>
                                </Hidden>
                                {translate("twoFA.bannerTitle")}
                            </Typography>
                            <Typography
                                component="div"
                                dangerouslySetInnerHTML={{
                                    __html: translate("twoFA.bannerShortText")
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm="auto" style={{flexShrink: 0}}>
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() => redirect('/admin/configuration')}
                                fullWidth
                                style={{ textTransform: 'initial', borderRadius: 5, marginTop: !tabletTup ? 15 : 0 }}
                            >
                                {translate('twoFA.bannerButtonText')}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default TwoFANotice;