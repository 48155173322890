import * as React from 'react';
import {FC, useState} from 'react';
import {
    Edit,
    EditProps,
    TextInput,
    FormWithRedirect,
    TranslatableInputs,
    SelectInput, useTranslate,
    //useNotify,
    Toolbar,
    FieldProps,
    ArrayInput,
    SimpleFormIterator, usePermissions, SaveButton, DeleteButton
} from 'react-admin';
//import RichTextInput from 'ra-input-rich-text';
import {Typography, Grid, Box} from '@material-ui/core';
import Quill from "quill";
import BlotFormatter from 'quill-blot-formatter';
// @ts-ignore
import htmlEditButton from 'quill-html-edit-button';


import cfgLocales from '../../config/locale';
import cfgCountries from '../../config/countries';
import {MailTemplate} from "../../config/types";
import {filterProps} from "../../functions/common";
import WarningIcon from "@material-ui/icons/Warning";

Quill.debug(false);

const EmbedBlot = Quill.import('blots/embed');

class Address extends EmbedBlot {
    static blotName = 'Address';
    static tagName = 'ADDRESS';

    static create(value: any) {
        const node = super.create(value);
        node.textContent = value;
        node.contentEditable = 'true';
        return node;
    }

    static value(domNode: any) {
        return domNode.childNodes[0].innerHTML;
    }

}

Quill.register('formats/Address', Address);

const InlineBlot = Quill.import('blots/inline');

class Br extends InlineBlot {
    static blotName = 'Br';
    static tagName = 'BR';
}

Quill.register('formats/Br', Br);

Quill.register({
    "modules/htmlEditButton": htmlEditButton,
    'modules/blotFormatter': BlotFormatter,
});

const SizeStyle = Quill.import('attributors/style/size');
SizeStyle.whitelist = ["12px", "14px", "16px"];
Quill.register(SizeStyle, true);

const MailTemplateEditToolbar = (props: any) => {
    const {permissions} = usePermissions();
    return (<Toolbar invalid={props.invalid} handleSubmit={props.handleSubmit}
                     saving={props.saving} resource="admin/mail-templates" undoable={false}>
        <Box display="flex" justifyContent="space-between" width="100%">
            <SaveButton saving={props.saving}
                        handleSubmitWithRedirect={props.handleSubmitWithRedirect}/>
            {['admin'].includes(permissions) && <DeleteButton record={props.record}/>}
        </Box>
    </Toolbar>);
}


const MailTemplateEdit: FC<EditProps> = props => {

    //const notify = useNotify();
    const newProps = filterProps(props);
    const [errorMessage, setErrorMessage] = useState<string>('');

    // const onFailure = (error: any) => {
    //     const errors = error.response.data.errors || null;
    //     const errorMessages: string[] = [];
    //     if (errors) {
    //         Object.keys(errors).forEach(errField => errorMessages.push(errors[errField]));
    //     } else if (error.response.data.message) {
    //         errorMessages.push(error.response.data.message);
    //     }
    //     notify(errorMessages.join("; "), 'error');
    // };

    return (
        <Edit
            {...newProps}
            undoable={false}
            onFailure={(error) => setErrorMessage(error)}
        >
            <MailTemplateForm errorMessage={errorMessage}/>
        </Edit>

    );
};

const Title: FC<FieldProps<MailTemplate>> = ({record}) => {
    const translate = useTranslate();
    return record ? (<Typography variant='h6'><b>{translate('resources.admin/mail-templates.titles.edit_mail_template', {id: `${record.id}`})}</b></Typography>) : null;
}

const MailTemplateForm = (props: any) => {

    const translate = useTranslate();
    const newProps = filterProps(props);

    return (
        <FormWithRedirect
            {...newProps}
            render={(formProps: any) => (
                <Box>
                    <Grid container spacing={3} style={{padding: "1rem"}}>
                        <Grid item xs={12} style={{paddingBottom: 0}}>
                            <Title record={formProps.record}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput source="from_email" fullWidth={true}/>
                            <TextInput source="from_name" fullWidth={true}/>
                            <ArrayInput source="to" resource="admin/mail-templates">
                                <SimpleFormIterator>
                                    <TextInput source='email' label={'resources.admin/mail-templates.fields.to'} fullWidth={true}/>
                                </SimpleFormIterator>
                            </ArrayInput>
                            <ArrayInput source="to_localized" resource="admin/mail-templates">
                                <SimpleFormIterator>
                                    <SelectInput fullWidth={true} source="country" label={'resources.admin/mail-templates.fields.to_localized_country'} choices={cfgCountries.map(el => ({id: el.code, name: el.name}))}/>
                                    <TextInput source='email' label={'resources.admin/mail-templates.fields.to_localized_email'} fullWidth={true}/>
                                    <SelectInput fullWidth={true} source="locale" label={'resources.admin/mail-templates.fields.to_localized_locale'} choices={Object.keys(cfgLocales.languageOptions).map(el => ({id: el, name: el}))}/>
                                </SimpleFormIterator>
                            </ArrayInput>
                            <ArrayInput source="cc" resource="admin/mail-templates">
                                <SimpleFormIterator>
                                    <TextInput source='email' label={'resources.admin/mail-templates.fields.cc'} fullWidth={true}/>
                                </SimpleFormIterator>
                            </ArrayInput>
                            <ArrayInput source="bcc" resource="admin/mail-templates">
                                <SimpleFormIterator>
                                    <TextInput source='email' label={'resources.admin/mail-templates.fields.bcc'} fullWidth={true}/>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                        <Grid item xs={12}>
                            <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                                <TextInput source="slug" fullWidth={true} InputProps={{readOnly: true}}/>
                                <TextInput source="subject" fullWidth={true}/>
                                <TextInput source="greetings" multiline={true} rows={10} fullWidth={true}/>
                                <TextInput source="body" multiline={true} rows={15} fullWidth={true}/>
                                <TextInput source="signature" multiline={true} rows={10} fullWidth={true}/>
                            </TranslatableInputs>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectInput source="status" choices={[
                                {id: 'draft', name: translate('resources.admin/mail-templates.statusOptions.draft')},
                                {id: 'active', name: translate('resources.admin/mail-templates.statusOptions.active')},
                            ]}/>
                        </Grid>
                        <Grid item xs={12}>

                        </Grid>
                        {(props.errorMessage !== '') && (
                            <Grid item xs={12}>
                                <Box
                                    display={'flex'}
                                    borderRadius={5}
                                    color={'white'}
                                    p={2}
                                    style={{backgroundColor: '#e2001ac7'}}>
                                    <Box mr={2}>
                                        <WarningIcon/>
                                    </Box>
                                    {props.errorMessage}
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                    <MailTemplateEditToolbar {...formProps} />
                </Box>
            )}
        />
    );
};

export default MailTemplateEdit;
