import {handleError} from "../functions/common";

import axiosForApi from "../config/axios-for-api";

const authProvider = {
    error: (err: any) => {
        return handleError(err);
    },
    login: ({id, role}: { id: string | number, role: string }) => {
        if (!id || !role) {
            return Promise.reject(new Error('Invalid user!'));
        }

        if (!["admin", "location-manager", "country-manager", "content-manager", "account-manager"].includes(role)) {
            return axiosForApi.post('/logout').then(response => Promise.reject(new Error("You are not authorized to access this area!")));
        }

        localStorage.setItem('user', `${id}`);
        localStorage.setItem('role', `${role}`);

        return Promise.resolve();
    },
    logout: () => {
        return !localStorage.getItem('user') ? Promise.resolve() : axiosForApi.post('/logout').then(response => {

            localStorage.removeItem('user');
            localStorage.removeItem('role');

            return Promise.resolve();
        });
    },
    checkError: ({status}: { status: number }) => {
        if (status === 401 || status === 403) {
           return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return axiosForApi.get('/api/me').then(response => {

            const data = response.data;
            if (!data) return Promise.reject();

            if (!data.id || !data.role) return Promise.reject();

            localStorage.setItem('user', data.id);
            localStorage.setItem('role', data.role);

            return Promise.resolve();
        }).catch(error => {
            return Promise.reject(authProvider.error(error));
        });
    },
    getPermissions: () => {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};

export default authProvider;
