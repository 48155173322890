import BackupIcon from '@material-ui/icons/Backup';

import BackupList from './BackupList'

const resource = {
    list: BackupList,
    icon: BackupIcon,
    options: {
        label: 'resources.admin/backups.name'
    }
};

export default resource;