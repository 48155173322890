import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, SimpleForm, DateInput, SelectInput, ReferenceArrayInput, AutocompleteArrayInput, TextInput, BooleanInput} from 'react-admin';

import Title from '../../components/list/Title';
import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps} from "../../functions/common";
import Rrule from "./Rrule";

const ShippingDateEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit {...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="id" label="resources.admin/shipping-dates.titles.edit"/>

                <TextInput source={'kunnr'} />
                <DateInput source="shipping_date" fullWidth={true}/>
                <BooleanInput source="is_today_allowed" looseValue={true} label={'resources.admin/shipping-dates.fields.is_today_allowed'} style={{width: 'auto'}} />
                <ReferenceArrayInput perPage={1000} source="shipping_country_ids" reference="admin/shipping-countries"  sort={{field: 'code', order: 'ASC'}} label={'resources.admin/shipping-dates.fields.shipping_country_ids'}>
                    <AutocompleteArrayInput optionText="code" fullWidth={true} />
                </ReferenceArrayInput>
                <TextInput source={'notes'} rows={3} multiline fullWidth />
                <SelectInput source={'status'} choices={[
                    {name: 'Full', id: 'full'},
                    {name: 'Free', id: 'free'},
                ]}/>

                <Title source="id" label="resources.admin/shipping-dates.sections.recurrence" />
                <Rrule {...props} />
            </SimpleForm>
        </Edit>
    );
};

export default ShippingDateEdit;
