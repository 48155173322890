import ListAltIcon from '@material-ui/icons/ListAlt';
import VideoList from './VideoList';
import VideoCreate from "./VideoCreate";

const resource = {
    list: VideoList,
    create: VideoCreate,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/videos.name'
    }
};

export default resource;
