import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, useTranslate, ArrayField, SingleFieldList, useRecordContext} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import {Route} from 'react-router';
import Chip from '@material-ui/core/Chip';

import RolesFilter from "./RolesFilter";
import PermissionsEdit from "./PermissionsEdit";
import SideDrawer from "../../components/list/SideDrawer";
import TextField from "../../components/list/TextField";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
}));


const MyChipField = (props: any) => {
    const classes = useStyles();
    const {source} = props;
    const record = useRecordContext(props);
    const translate = useTranslate();
    const val = translate(`resources.admin/permissions.permissions.${record[source]}`);
    return <Chip
        label={val}
        className={classes.chip} style={!val.match(/front/gi)?{backgroundColor:'#333', color:'#fff'}:{}} />
}

const RolesList = (props: ListProps): ReactElement => {
    const translate = useTranslate();

    return (
        <Fragment>
            <Typography variant={'h6'}>{translate('resources.admin/permissions.name')}</Typography>
            <List {...props} filters={<RolesFilter/>} sort={{field: 'role', order: 'ASC'}} actions={<></>}>
                <Datagrid>
                    <TextField source="role"/>
                    <ArrayField source="permissions">
                        <SingleFieldList>
                            <MyChipField source="name"/>
                        </SingleFieldList>
                    </ArrayField>
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''}>
                            {isMatch && (
                                <PermissionsEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default RolesList;
