import {Card, Box, Typography} from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import {useEffect, useState} from "react";
import {useTranslate} from "react-admin";

import {dashboard} from '../../styles/dashboard';
import axiosForApi from "../../config/axios-for-api";
import StatHeader from "./StatHeader";

const Loading = () => {
    return (
        <Box display={'flex'} justifyContent={'center'} >
            <CircularProgress size={28.8}/>
        </Box>
    );
}

const StatResult = ({classes, total}: any) => {
    return (
        <Typography className={classes.statsTotal} color="textSecondary">
            {total}
        </Typography>
    );
}

const AverageRevenueByMonthLast12Months = ({countries}: { countries: string[] | null }) => {
    const classes = dashboard();
    const translate = useTranslate();

    const [statsData, setStatsData] = useState({
        avg_fmt: ''
    });
    const [shouldLoadStatsData, setShouldLoadStatsData] = useState(false);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        let cancel = false;
        const loadStatsData = async () => {
            const {data} = await axiosForApi.get('api/admin/stats/average-revenue-by-month-last-12months', {
                params: {
                    countries
                }
            });

            if(!cancel) {
                setLoading(false);
                setStatsData(data ?? null);
                setShouldLoadStatsData(false);
            }
        }

        shouldLoadStatsData && loadStatsData();

        return () => {
            cancel = true;
        }
    }, [shouldLoadStatsData, countries]);

    useEffect(() => {
        setLoading(true);
        setShouldLoadStatsData(true);
    }, [countries]);

    return (
        <Card className={classes.card}>
            <Box p={2}>
                <StatHeader
                    // title='⌀ Umsatz p.m (net / total)'
                    title={translate('resources.admin/dashboard.headers.average-revenue-by-month-last-12months')}
                    help='resources.admin/dashboard.help.average-revenue-by-month-last-12months'
                />
                {loading?<Loading/>: <StatResult classes={classes} total={statsData.avg_fmt} />}
            </Box>
        </Card>
    );
}

export default AverageRevenueByMonthLast12Months;
