import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, ReferenceField, NumberField} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import { Route } from 'react-router';
import RawMaterialPriceFilter from "./RawMaterialPriceFilter";
import RawMaterialPriceCreate from "./RawMaterialPriceCreate";
import RawMaterialPriceEdit from "./RawMaterialPriceEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";

const PriceMatrixList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<RawMaterialPriceFilter />} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="code"/>
                    <TextField source="matnr"/>
                    <TextField source="designation"/>
                    <ReferenceField label={"resources.admin/lod-raw-material-prices.fields.material"} source="material_id" reference="admin/lod-materials" sortable={false}>
                        <TextField source="name_lt"/>
                    </ReferenceField>
                    <ReferenceField label={"resources.admin/lod-raw-material-prices.fields.thickness"} source="thickness_id" reference="admin/lod-thickness" sortable={false}>
                        <TextField source="value"/>
                    </ReferenceField>
                    <ReferenceField label={"resources.admin/lod-raw-material-prices.fields.color"} source="color_id" reference="admin/lod-colors" sortable={false}>
                        <TextField source="name_lt"/>
                    </ReferenceField>
                    <ReferenceField label={"resources.admin/lod-raw-material-prices.fields.foil"} source="foil_id" reference="admin/lod-foils" sortable={false}>
                        <TextField source="name_lt"/>
                    </ReferenceField>
                    <NumberField source="price" options={{ style: 'currency', currency: 'EUR' }} />
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/create`}>
                {({ match }) => {
                    return (
                        <SideDrawer open={!!match} basePath={props.basePath??''}>
                            <RawMaterialPriceCreate {...props} />
                        </SideDrawer>
                    )
                }}
            </Route>
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''}>
                            {isMatch && (
                                <RawMaterialPriceEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default PriceMatrixList;
