import React from 'react';

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import {loadMask} from "../../styles/ui/loadMask";

const LoadMask = ({fullscreen = false, transparent = false, alignTop = false, className = '', progressClassName = null, progressStyles = null, style = null}: any) => {

    const loadMaskClasses = loadMask();
    const loadClasses = [
        loadMaskClasses.default,
        fullscreen ? loadMaskClasses.fullscreen : '',
        transparent ? loadMaskClasses.transparent : '',
        alignTop ? loadMaskClasses.alignTop : '',
        className
    ];

    return (
        <Box
            className={loadClasses.join(' ')}
            style={style}
        >
            <CircularProgress
                aria-label="loading"
                className={progressClassName ?? null}
                style={progressStyles ?? null}
                color={"secondary"}
            />
        </Box>
    )
}

export default LoadMask;