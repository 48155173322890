import React from 'react';

// import {useTranslation} from "react-i18next";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import {loadMask} from "../../styles/loadMask";

export const LoadMask = ({
    fullscreen = false,
    transparent = false,
    alignTop = false,
    className = '',
    progressClassName = null,
    progressStyles = null,
    style = null
}) => {

    // const {t} = useTranslation("common");
    const loadMaskClasses = loadMask();

    const loadClasses = [
        loadMaskClasses.default,
        fullscreen?loadMaskClasses.fullscreen:'',
        transparent?loadMaskClasses.transparent:'',
        alignTop?loadMaskClasses.alignTop:'',
        className
    ];

    return (
        <Box
            className={loadClasses.join(' ')}
            style={style}
        >
            <CircularProgress
                aria-label={'Loading'}
                className={progressClassName ?? null}
                style={progressStyles ?? null}
                color={"secondary"}
            />
        </Box>
    )
}