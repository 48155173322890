import * as React from 'react';
import {Datagrid, List, ListProps, TextField, EditButton, BooleanField, DateField} from 'react-admin';
import {Fragment, ReactElement} from 'react';

import ModalFilter from "./ModalFilter";
import {Route} from "react-router";
import SideDrawer from "../../components/list/SideDrawer";
import ModalEdit from "./ModalEdit";
import CustomTextField from "../../components/list/TextField";
import ListActions from "../../components/list/ListActions";
// import ShowOnShop from "../../components/list/ShowOnShop";

const ModalList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
        <List {...props} filters={<ModalFilter />} sort={{field:'id', order:'DESC'}} actions={<ListActions/>} >
            <Datagrid>
                <TextField source="id" />
                <CustomTextField source="title"  />
                {/*<ShowOnShop source="post" label={'resources.admin/modals.fields.title'} />*/}
                <BooleanField source="meta.newsletter" looseValue={true} />
                <BooleanField source="meta.promotion_code" looseValue={true} />
                <CustomTextField source="status" />
                <TextField source="available_from_fmt"  />
                <TextField source="available_to_fmt" />
                <DateField source="created_at" showTime={true} locales={'de-DE'} />
                <EditButton />
            </Datagrid>
        </List>
            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''} size={800}>
                            {isMatch && (
                                <ModalEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default ModalList;
