import * as React from 'react';
import {FC} from 'react';
import {Box, Typography} from "@material-ui/core";
import {
    Create,
    CreateProps,
    TextInput, SimpleForm, useTranslate, SelectInput, ReferenceInput, BooleanInput, ReferenceArrayInput, SelectArrayInput, ArrayInput, SimpleFormIterator
} from 'react-admin';

import Title from "../../../components/list/Title";
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const MaterialConfigCreate: FC<CreateProps> = props => {
    const translate = useTranslate();
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <Title label="resources.admin/lod-material-configs.titles.create"/>
                <ReferenceInput style={{width: '100%'}} label="resources.admin/lod-material-configs.fields.material" source="material_id" reference="admin/lod-materials" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }} filter={{status: 'active'}}>
                    <SelectInput optionText="name_lt" fullWidth={true}/>
                </ReferenceInput>
                <BooleanInput source="material_is_available"/>

                <ReferenceInput style={{width: '100%'}} label="resources.admin/lod-material-configs.fields.thickness" source="thickness_id" reference="admin/lod-thickness" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }} filter={{status: 'active'}}>
                    <SelectInput optionText="name_lt" fullWidth={true}/>
                </ReferenceInput>

                <ReferenceArrayInput reference="admin/lod-shapes" source="available_shapes" fullWidth={true}>
                    <SelectArrayInput optionText={'name'}/>
                </ReferenceArrayInput>

                <ReferenceArrayInput reference="admin/lod-colors" source="available_colors" fullWidth={true}>
                    <SelectArrayInput optionText={'name_lt'}/>
                </ReferenceArrayInput>

                <ReferenceArrayInput reference="admin/lod-foils" source="available_foils" fullWidth={true}>
                    <SelectArrayInput optionText={'name_lt'}/>
                </ReferenceArrayInput>

                <TextInput autoFocus source="mattenrand" fullWidth={true}/>

                {/* Mattenbreite */}
                <>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.admin/lod-material-configs.sections.mattenbreite')}
                    </Typography>
                    <Box style={{'display': 'flex', 'width': '100%'}}>
                        <Box flex={1} mr={{xs: '0.5em'}}>
                            <TextInput source="mattenbreite_x" fullWidth={true}/>
                        </Box>
                        <Box flex={1}>
                            <TextInput source="mattenbreite_y" fullWidth={true}/>
                        </Box>
                    </Box>
                </>

                {/* Gewicht */}
                <>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.admin/lod-material-configs.sections.gewicht')}
                    </Typography>
                    <Box style={{'display': 'flex', 'width': '100%'}}>
                        <Box flex={1} mr={{xs: '0.5em'}}>
                            <TextInput source='gewicht.matte_248x248_in_gramm' label={'resources.admin/lod-material-configs.fields.gewicht.matte_248x248_in_gramm'} fullWidth={true}/>
                        </Box>
                        <Box flex={1}>
                            <TextInput source='gewicht.pro_mm2_in_g' label={'resources.admin/lod-material-configs.fields.gewicht.pro_mm2_in_g'} fullWidth={true}/>
                        </Box>
                    </Box>
                </>


                <SelectInput source="fertigung" fullWidth={true} choices={[
                    {id: 'MPAG', name: 'MPAG'},
                    {id: 'MPS', name: 'MPS'},
                ]}/>

                <TextInput source="verpackungseinheit" fullWidth={true}/>

                {/* Menge */}
                <>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.admin/lod-material-configs.sections.menge')}
                    </Typography>
                    <Box style={{'display': 'flex', 'width': '100%'}}>
                        <Box flex={1} mr={{xs: '0.5em'}}>
                            <TextInput source="menge_erstbestellung" fullWidth={true}/>
                        </Box>
                        <Box flex={1}>
                            <TextInput source="menge_folgebestellung" fullWidth={true}/>
                        </Box>
                    </Box>
                </>

                {/* Wkz */}
                <>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.admin/lod-material-configs.sections.wkz')}
                    </Typography>
                    <Box style={{'display': 'flex', 'width': '100%'}}>
                        <Box flex={1} mr={{xs: '0.5em'}}>
                            <TextInput source='wkz.1_klein' label={'resources.admin/lod-material-configs.fields.wkz.1_klein'} fullWidth={true}/>
                        </Box>
                        <Box flex={1} mr={{xs: '0.5em'}}>
                            <TextInput source='wkz.2_gross' label={'resources.admin/lod-material-configs.fields.wkz.2_gross'} fullWidth={true}/>
                        </Box>
                        <Box flex={1}>
                            <TextInput source='wkz.umfang' label={'resources.admin/lod-material-configs.fields.wkz.umfang'} fullWidth={true}/>
                        </Box>
                    </Box>
                </>

                <TextInput source="vorschlag_radius" fullWidth={true}/>

                {/* Rohmaterial */}
                <>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.admin/lod-material-configs.sections.rohmaterial')}
                    </Typography>
                    <Box style={{'display': 'flex', 'width': '100%'}}>
                        <Box flex={1} mr={{xs: '0.5em'}}>
                            <TextInput source="rohmaterial_lange_x" fullWidth={true}/>
                        </Box>
                        <Box flex={1}>
                            <TextInput source="rohmaterial_breite_y" fullWidth={true}/>
                        </Box>
                    </Box>
                </>

                <TextInput source="min_lochabstand_rand_mitte" fullWidth={true}/>
                <TextInput source="min_lochabstand" fullWidth={true}/>
                <TextInput source="min_lochdurchmesser" fullWidth={true}/>

                {/* Rabatt */}
                <>
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.admin/lod-material-configs.sections.rabatt')}
                    </Typography>
                    <Box style={{'width': '100%', 'display': 'flex'}} flexWrap={'wrap'}>
                        <Box mr={{xs: '0.5em'}} style={{width: '48%'}}>
                            <TextInput source="rabatt.stufe_unter_300_stk_mpag" fullWidth={true}/>
                        </Box>
                        <Box mr={{xs: '0.5em'}} style={{width: '48%'}}>
                            <TextInput source="rabatt.stufe_300_stk_mpag" fullWidth={true}/>
                        </Box>
                        <Box mr={{xs: '0.5em'}} style={{width: '48%'}}>
                            <TextInput source="rabatt.stufe_600_stk_mpag" fullWidth={true}/>
                        </Box>
                        <Box mr={{xs: '0.5em'}} style={{width: '48%'}}>
                            <TextInput source="rabatt.stufe_1200_stk_mpag" fullWidth={true}/>
                        </Box>
                        <Box mr={{xs: '0.5em'}} style={{width: '48%'}}>
                            <TextInput source="rabatt.stufe_3000_stk_mpag" fullWidth={true}/>
                        </Box>
                    </Box>
                    <Box style={{'display': 'flex', 'width': '100%'}} flexWrap={'wrap'}>
                        <Box mr={{xs: '0.5em'}} style={{width: '48%'}}>
                            <TextInput source="rabatt.stufe_unter_300_stk_mps" fullWidth={true}/>
                        </Box>
                        <Box mr={{xs: '0.5em'}} style={{width: '48%'}}>
                            <TextInput source="rabatt.stufe_300_stk_mps" fullWidth={true}/>
                        </Box>
                        <Box mr={{xs: '0.5em'}} style={{width: '48%'}}>
                            <TextInput source="rabatt.stufe_600_stk_mps" fullWidth={true}/>
                        </Box>
                        <Box mr={{xs: '0.5em'}} style={{width: '48%'}}>
                            <TextInput source="rabatt.stufe_1200_stk_mps" fullWidth={true}/>
                        </Box>
                        <Box mr={{xs: '0.5em'}} style={{width: '48%'}}>
                            <TextInput source="rabatt.stufe_3000_stk_mps" fullWidth={true}/>
                        </Box>
                    </Box>
                    <Box style={{'display': 'flex', 'width': '100%'}} flexWrap={'wrap'}>
                        <Box mr={{xs: '0.5em'}} style={{width: '48%'}}>
                            <TextInput source="rabatt.pl17ic" fullWidth={true}/>
                        </Box>
                    </Box>
                </>

                <TextInput source="ic_niederlassung_faktor" fullWidth={true}/>
                <TextInput source="preiseinheit" fullWidth={true}/>

                <BooleanInput source="gewichtskontrolle" fullWidth={true}/>

                <TextInput source="lieferant_im_sap" fullWidth={true}/>
                <TextInput source="name_lieferant" fullWidth={true}/>
                <TextInput source="format_material" fullWidth={true}/>
                <TextInput source="wahrung" fullWidth={true}/>
                <TextInput source="einkaufspreis" fullWidth={true}/>
                <TextInput source="preis_pro_quadratmeter" fullWidth={true}/>

                <BooleanInput source="standard_farbe_1" fullWidth={true}/>
                <BooleanInput source="standard_farbe_2" fullWidth={true}/>

                <TextInput source="vk_zuschlag_farbe_pro_ve_mpag" fullWidth={true}/>
                <TextInput source="hk1_zuschlag_farbe_pro_ve_mpag" fullWidth={true}/>
                <TextInput source="vk_zuschlag_farbe_pro_ve_mps" fullWidth={true}/>
                <TextInput source="hk1_zuschlag_farbe_pro_ve_mps" fullWidth={true}/>

                {/* Foild extra charge */}
                <ArrayInput source="mpag_zuschlag_vk">
                    <SimpleFormIterator>
                        <ReferenceInput reference="admin/lod-foils" source="foil_id">
                            <SelectInput optionText={'name_lt'}/>
                        </ReferenceInput>
                        <TextInput source="extra_charge"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source="mpag_zuschlag_hk1">
                    <SimpleFormIterator>
                        <ReferenceInput reference="admin/lod-foils" source="foil_id">
                            <SelectInput optionText={'name_lt'}/>
                        </ReferenceInput>
                        <TextInput source="extra_charge"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source="mps_zuschlag_vk">
                    <SimpleFormIterator>
                        <ReferenceInput reference="admin/lod-foils" source="foil_id">
                            <SelectInput optionText={'name_lt'}/>
                        </ReferenceInput>
                        <TextInput source="extra_charge"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source="mps_zuschlag_hk1">
                    <SimpleFormIterator>
                        <ReferenceInput reference="admin/lod-foils" source="foil_id">
                            <SelectInput optionText={'name_lt'}/>
                        </ReferenceInput>
                        <TextInput source="extra_charge"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source="preisaufschlage_farbe_pro_vpe_vk_mpag">
                    <SimpleFormIterator>
                        <ReferenceInput reference="admin/lod-colors" source="color_id">
                            <SelectInput optionText={'name_lt'}/>
                        </ReferenceInput>
                        <TextInput source="extra_charge"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source="preisaufschlage_farbe_pro_vpe_hk_mpag">
                    <SimpleFormIterator>
                        <ReferenceInput reference="admin/lod-colors" source="color_id">
                            <SelectInput optionText={'name_lt'}/>
                        </ReferenceInput>
                        <TextInput source="extra_charge"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source="preisaufschlage_farbe_pro_vpe_vk_mps">
                    <SimpleFormIterator>
                        <ReferenceInput reference="admin/lod-colors" source="color_id">
                            <SelectInput optionText={'name_lt'}/>
                        </ReferenceInput>
                        <TextInput source="extra_charge"/>
                    </SimpleFormIterator>
                </ArrayInput>

                <ArrayInput source="preisaufschlage_farbe_pro_vpe_hk_mps">
                    <SimpleFormIterator>
                        <ReferenceInput reference="admin/lod-colors" source="color_id">
                            <SelectInput optionText={'name_lt'}/>
                        </ReferenceInput>
                        <TextInput source="extra_charge"/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Create>
    );
};

export default MaterialConfigCreate;
