import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography
} from '@material-ui/core';
import {FieldProps, useTranslate} from 'react-admin';

const ClientInfo = ({clientInfo}:any) => {
    const translate = useTranslate();
    if (!clientInfo) return null;

    return (
        <>
            <Typography variant="h6">
                {translate(
                    'resources.admin/users.fieldGroups.client_info'
                )}
            </Typography>
            <Table>
                <TableBody>
                    {Object.keys(clientInfo).map((key: any) => {
                        return (
                            <TableRow key={`row_${key}`}>
                                <TableCell>
                                    {key}
                                </TableCell>
                                <TableCell>
                                    {clientInfo[key]}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
}

const BillingInfo = ({invoiceAddresses}: any) => {
    const translate = useTranslate();
    if (!invoiceAddresses) return null;

    return (
        <>
            <Typography variant="h6" style={{marginTop:'30px'}}>
                {translate(
                    'resources.admin/users.fieldGroups.invoice_address'
                )}
            </Typography>
            {invoiceAddresses.length > 0 && invoiceAddresses.map((address: any) => {
                return (<Table>
                    <TableBody>
                        {Object.keys(address).map((key: any) => {
                            return (
                                <TableRow key={`row_${key}`}>
                                    <TableCell>
                                        {key}
                                    </TableCell>
                                    <TableCell>
                                        {address[key]}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>)
            })}
        </>
    );
}

const SapData = (props: FieldProps) => {
    const {record} = props;
    const translate = useTranslate();

    if (!record) return null;

    const sapData = record.sap_data ?? null;
    const clientInfo = sapData && (sapData.client_info ?? null);
    const invoiceAddresses = sapData && (sapData.invoice_address ?? []);

    return (
        <>
            {(clientInfo || invoiceAddresses) && (
                <Typography variant="h6">
                    {translate(
                        'resources.admin/users.fieldGroups.sap_data'
                    )}
                </Typography>
            )}
            <ClientInfo clientInfo={clientInfo}/>
            <BillingInfo invoiceAddresses={invoiceAddresses}/>
        </>);
}

export default SapData;