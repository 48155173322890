import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, useTranslate} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import {Route} from 'react-router';

import VisitorCountryFilter from "./VisitorCountryFilter";
import VisitorCountryEdit from "./VisitorCountryEdit";
import SideDrawer from "../../components/list/SideDrawer";
import TextField from "../../components/list/TextField";
import ListActions from "../../components/list/ListActions";
import {Typography} from "@material-ui/core";


const VisitorCountryList = (props: ListProps): ReactElement => {
    const translate = useTranslate();

    return (
        <Fragment>
            <Typography variant={'h6'}>{translate('resources.admin/visitor-countries.name')}</Typography>
            <List {...props} filters={<VisitorCountryFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source={'status'} />
                    <TextField source={'vkorg'} />
                    <TextField source={'name'} label={'resources.admin/visitor-countries.fields.name'} />
                    <TextField source={'code'} label={'resources.admin/visitor-countries.fields.code'} />
                    <EditButton/>
                </Datagrid>
            </List>
            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''}>
                            {isMatch && (
                                <VisitorCountryEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default VisitorCountryList;
