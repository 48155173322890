import * as React from "react";
import {Grid, MenuItem} from "@material-ui/core";
import {useRedirect, useTranslate} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';

import StatsOrdersInLastDays from "../../components/dashboard/StatsOrdersInLastDays";
import LatestOrders from "../../components/dashboard/LatestOrders";
import LatestProducts from "../../components/dashboard/LatestProducts";
import LatestOffers from "../../components/dashboard/LatestOffers";
import NewCustomers from "../../components/dashboard/NewCustomers";
import StatsOrdersInLastMonths from "../../components/dashboard/StatsOrdersInLastMonths";
import RevenueThisYearTotal from "../../components/dashboard/RevenueThisYearTotal";
import RevenueThisMonthTotal from "../../components/dashboard/RevenueThisMonthTotal";
import RegisteredCustomersTotal from "../../components/dashboard/RegisteredCustomersTotal";
import RegisteredCustomers from "../../components/dashboard/RegisteredCustomers";
import RevenueThisYear from "../../components/dashboard/RevenueThisYear";
import AverageRevenueByNrOrdersLast12Months from "../../components/dashboard/AverageRevenueByNrOrdersLast12Months";
import AverageRevenueByMonthLast12Months from "../../components/dashboard/AverageRevenueByMonthLast12Months";
import TopProductsByRevenueThisYear from "../../components/dashboard/TopProductsByRevenueThisYear";
import AverageRevenueBySubscriptionsLast12Months from "../../components/dashboard/AverageRevenueBySubscriptionsLast12Months";
import RevenueByYear from "../../components/dashboard/RevenueByYear";
import OffersOrdersConversionRateLast12Months from "../../components/dashboard/OffersOrdersConversionRateThisYear";
import RevenueByMainCategoryThisMonth from "../../components/dashboard/RevenueByMainCategoryThisMonth";
import RevenueByMainCategoryThisYear from "../../components/dashboard/RevenueByMainCategoryThisYear";
import NrActiveUsersByCountry from "../../components/dashboard/NrActiveUsersByCountry";
import AbandonedCarts from "../../components/dashboard/AbandonedCarts";
import {useEffect, useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import axiosForApi from "../../config/axios-for-api";
import {Country} from "../../config/types";
import TwoFANotice from "../../components/2fa/TwoFANotice";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const Dashboard = ({permissions}: { permissions: string }) => {

    const redirect = useRedirect();

    if (permissions === 'content-manager') redirect('/admin/posts');
    if (permissions === 'account-manager') redirect('/admin/users');

    const classes = useStyles();
    const translate = useTranslate();

    const [countries, setCountries] = useState<Country[] | null>(null);
    const [selectedCountries, setSelectedCountries] = useState<string[] | null>(null);
    const [shouldLoadCountries, setShouldLoadCountries] = useState<boolean>(true);
    const [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState<boolean | null>(null);

    const handleCountryChange = (event: any) => {
        setSelectedCountries(event.target.value);
    };

    //todo: try to avoid calling me again - maybe wrap with some context
    useEffect(() => {
        axiosForApi.get('/api/me').then(result => {
            setTwoFactorAuthEnabled(!!result.data?.has2FA)
        })
    }, [])

    useEffect(() => {
        let cancel = false;
        const loadCountries = async () => {
            const {data} = await axiosForApi.get(`/api/admin/stats-countries`);
            if(!cancel) {
                setCountries(data);
                setShouldLoadCountries(false);
            }
        }

        shouldLoadCountries && loadCountries();

        return () => {
            cancel = true;
        }

    }, [shouldLoadCountries]);

    return ['admin', 'location-manager', 'country-manager'].includes(permissions) ? (
            <>
                {(twoFactorAuthEnabled !== null && !twoFactorAuthEnabled) ? (
                    <TwoFANotice />
                ) : null}

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel>{(selectedCountries??[]).length === 0 ? translate('resources.admin/dashboard.placeholders.all_countries') : translate('resources.admin/dashboard.placeholders.countries')}</InputLabel>
                            <Select
                                value={selectedCountries??[]}
                                onChange={handleCountryChange}
                                multiple
                                input={<Input/>}
                                MenuProps={MenuProps}
                                renderValue={(selected: any) => {
                                    return (countries ?? []).filter(country => selected.includes(country.code)).map(country => country.name).join(', ')
                                }}
                            >
                                {(countries ?? []).map((country: Country) => (
                                    <MenuItem key={country.code} value={country.code}>
                                        <Checkbox checked={(selectedCountries??[]).includes(country.code)}/>
                                        <ListItemText primary={country.name}/>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                       <RevenueThisYearTotal countries={selectedCountries}/> {/* Umsatz 2022 (net / total) */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <RevenueThisMonthTotal  countries={selectedCountries} /> {/* Aktueller Monat (net / total) */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <RegisteredCustomersTotal countries={selectedCountries}  /> {/* Angemeldete Kunden */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <RegisteredCustomers countries={selectedCountries} /> {/*Neukunden Monat / Total*/}
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AverageRevenueByNrOrdersLast12Months countries={selectedCountries} /> {/* ⌀ Warenkorb (net / total) */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AverageRevenueByMonthLast12Months countries={selectedCountries} />{/* ⌀ Umsatz p.m (net / total) */}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AverageRevenueBySubscriptionsLast12Months countries={selectedCountries} /> {/*Abos / Volumen (p.a.)*/}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <OffersOrdersConversionRateLast12Months countries={selectedCountries} /> {/*Angebote / Bestellungen aus Angeboten / Conversion Angebot2Bestellung*/}
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                        <RevenueThisYear countries={selectedCountries} /> {/* Umsatzentwicklung pro Monat */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <RevenueByMainCategoryThisMonth countries={selectedCountries} /> {/*Umsatz Akt. Monat nach Produktbereich*/}
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                        <RevenueByYear countries={selectedCountries} /> {/*Umsatzentwicklung pro Kalenderjahr*/}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <RevenueByMainCategoryThisYear countries={selectedCountries} /> {/*Umsatz 2022 nach Produktbereich*/}
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                        <NrActiveUsersByCountry/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TopProductsByRevenueThisYear countries={selectedCountries} /> {/* Umsatz Top 5 Produkte (2022) */}
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6}>
                        <AbandonedCarts countries={selectedCountries} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>

                    </Grid>
                </Grid>

                {
                    permissions === 'admin' &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <StatsOrdersInLastDays countries={selectedCountries} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <StatsOrdersInLastMonths countries={selectedCountries} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <LatestOrders countries={selectedCountries} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <LatestProducts/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NewCustomers countries={selectedCountries} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <LatestOffers countries={selectedCountries} />
                        </Grid>
                    </Grid>
                }
            </>
        ) :
        (<p>Invalid user!</p>);

}

export default Dashboard;
