import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, TextInput, ReferenceInput, SelectInput} from 'react-admin';

import Title from "../../../components/list/Title";
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const PriceListFactorCreate: FC<CreateProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <Title label="resources.admin/lod-price-list-factors.titles.create" />

                <ReferenceInput label="resources.admin/lod-price-list-factors.fields.price_list" source="price_list_id" reference="admin/lod-price-list">
                    <SelectInput optionText="preisliste" fullWidth={true}/>
                </ReferenceInput>
                <TextInput source="werk" fullWidth={true} />
                <TextInput source="faktor" fullWidth={true} />

            </SimpleForm>
        </Create>
    );
};

export default PriceListFactorCreate;
