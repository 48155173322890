import * as React from 'react';
import {Admin, Resource} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';
import italianMessages from './i18n/it';
import spanishMessages from './i18n/es';
import russianMessages from './i18n/ru';
import germanMessages from './i18n/de';

import './App.css';
import raDataProvider from './data-providers/ra_data_json';
import authProvider from './auth/AuthProvider';
import {Layout} from './layout';
import customRoutes from './config/routes';
import themeReducer from './themeReducer';
import Dashboard from './resources/dashboard';
import cfgLocales from './config/locale';
import usersResource from './resources/users';
import productsResource from './resources/products';
import postsResource from './resources/posts';
import ordersResource from './resources/orders';
import colorsResource from './resources/lod/colors';
import foilsResource from './resources/lod/foils';
import thicknessResource from './resources/lod/thickness';
import materialsResource from './resources/lod/materials';
import holesResource from './resources/lod/holes';
import shapesResource from './resources/lod/shapes';
import priceMatrixResource from './resources/lod/price-matrix';
import mailTemplatesResource from './resources/mail-templates';
import translationsResource from './resources/translations';
import backupsResource from './resources/backups';
import rawMaterialPriceResource from './resources/lod/raw-material-prices';
import materialConfigsResource from './resources/lod/material-configs';
import holeImageShapeResource from './resources/lod/hole-image-shape';
import imagesResource from './resources/lod/images';
import priceListResource from './resources/lod/price-list';
import priceListFactorsResource from './resources/lod/price-list-factors';
import quellwerkResource from './resources/lod/quellwerk';
import offersResource from './resources/offers';
import wintypsResource from './resources/lod/wintyps';
import LoginPage from "./auth/LoginPage";
import shippingDatesResource from './resources/shipping-dates';
import shippingCountriesResource from './resources/shipping-countries';
import cobCobotsResource from "./resources/cob/cobots";
import cobManufacturersResource from "./resources/cob/manufacturers";
import cobTypesResource from "./resources/cob/types";
import cobModelsResource from "./resources/cob/models";
import cobProductsResource from "./resources/cob/products";

import kdhFramesResource from "./resources/kdh/frames";
import kdhGrommetsResource from "./resources/kdh/grommets";
import abandonedCartsResource from "./resources/abandoned-carts";
import modalsResource from "./resources/modals";
import permissionsResource from "./resources/permissions";
import visitorCountriesResource from "./resources/visitor-countries";
import productReviewsResource from "./resources/product-reviews";
import vouchersResource from "./resources/vouchers";
import videosResource from "./resources/videos";
import videoPlaylistsResource from "./resources/video-playlists";
import videoCategoriesResource from "./resources/video-categories";
import gsFlowsResource from "./resources/gs-flows"

const apiUrl = `${process.env['REACT_APP_API_URL'] || ''}/api`;
const dataProvider = raDataProvider(apiUrl);

const messages: { [key: string]: any } = {
    en: englishMessages,
    de: germanMessages,
    fr: frenchMessages,
    it: italianMessages,
    ru: russianMessages,
    es: spanishMessages
}

const i18nProvider = polyglotI18nProvider((locale) => {

    const locales = Object.keys(cfgLocales.languageOptions);
    for (let i = 0; i <= locales.length; i++) {
        if (locales[i] === cfgLocales.defaultLocale) continue;
        if (locale === locales[i]) {
            localStorage.setItem('locale', locales[i]);
            //return import(`./i18n/${locales[i]}`).then((messages) => messages.default);
            if (messages[locale]) return messages[locale];
        }
    }

    // Always fallback
    localStorage.setItem('locale', cfgLocales.defaultLocale);
    return messages['de'];
    //return germanMessages;
}, localStorage.getItem('locale') ?? cfgLocales.defaultLocale);


const App = () => {
    const resourceByRole: { [key: string]: any } = {
        'admin': [
            <Resource name="admin/visitor-countries" {...visitorCountriesResource} />,
            <Resource name="admin/products" {...productsResource} />,
            <Resource name="admin/posts" {...postsResource} />,
            <Resource name="admin/modals" {...modalsResource} />,
            <Resource name="admin/users" {...usersResource} />,
            <Resource name="admin/permissions" {...permissionsResource} />,
            <Resource name="admin/offers" {...offersResource} />,
            <Resource name="admin/orders" {...ordersResource} />,
            <Resource name="admin/vouchers" {...vouchersResource} />,
            <Resource name="admin/abandoned-carts" {...abandonedCartsResource} />,
            <Resource name="admin/lod-colors" {...colorsResource} />,
            <Resource name="admin/lod-foils" {...foilsResource} />,
            <Resource name="admin/lod-thickness" {...thicknessResource} />,
            <Resource name="admin/lod-materials" {...materialsResource} />,
            <Resource name="admin/lod-holes" {...holesResource} />,
            <Resource name="admin/lod-images" {...imagesResource} />,
            <Resource name="admin/lod-shapes" {...shapesResource} />,
            <Resource name="admin/lod-price-matrix" {...priceMatrixResource} />,
            <Resource name="admin/lod-price-list" {...priceListResource} />,
            <Resource name="admin/lod-price-list-factors" {...priceListFactorsResource} />,
            <Resource name="admin/lod-quellwerk" {...quellwerkResource} />,
            <Resource name="admin/lod-raw-material-prices" {...rawMaterialPriceResource} />,
            <Resource name="admin/lod-hole-image-shape" {...holeImageShapeResource} />,
            <Resource name="admin/lod-material-configs" {...materialConfigsResource} />,
            <Resource name="admin/lod-wintyps" {...wintypsResource} />,
            <Resource name="admin/mail-templates" {...mailTemplatesResource} />,
            <Resource name="admin/translations"  {...translationsResource} />,
            <Resource name="admin/backups"  {...backupsResource} />,
            <Resource name="admin/shipping-dates"  {...shippingDatesResource} />,
            <Resource name="admin/shipping-countries"  {...shippingCountriesResource} />,

            <Resource name="admin/cob-cobots"  {...cobCobotsResource} />,
            <Resource name="admin/cob-manufacturers"  {...cobManufacturersResource} />,
            <Resource name="admin/cob-types"  {...cobTypesResource} />,
            <Resource name="admin/cob-models"  {...cobModelsResource} />,
            <Resource name="admin/cob-products"  {...cobProductsResource} />,

            <Resource name="admin/kdh-frames"  {...kdhFramesResource} />,
            <Resource name="admin/kdh-grommets"  {...kdhGrommetsResource} />,
            <Resource name="admin/product-reviews"  {...productReviewsResource} />,
            <Resource name="admin/videos"  {...videosResource} />,
            <Resource name="admin/video-playlists"  {...videoPlaylistsResource} />,
            <Resource name="admin/video-categories"  {...videoCategoriesResource} />,
            <Resource name="admin/gs-flows"  {...gsFlowsResource} />,
        ],
        'location-manager': [
            <Resource name="admin/products" {...productsResource} />,
            <Resource name="admin/offers" {...offersResource} />,
            <Resource name="admin/orders" {...ordersResource} />,
            <Resource name="admin/users" {...usersResource} />,
            <Resource name="admin/product-reviews"  {...productReviewsResource} />
        ],
        'country-manager': [
            <Resource name="admin/products" {...productsResource} />,
            <Resource name="admin/offers" {...offersResource} />,
            <Resource name="admin/orders" {...ordersResource} />,
            <Resource name="admin/users" {...usersResource} />,
            <Resource name="admin/product-reviews"  {...productReviewsResource} />
        ],
        'content-manager': [
            <Resource name="admin/posts" {...postsResource} />,
            <Resource name="admin/translations"  {...translationsResource} />,
            <Resource name="admin/mail-templates" {...mailTemplatesResource} />,
            <Resource name="admin/modals" {...modalsResource} />,
            <Resource name="admin/videos"  {...videosResource} />,
            <Resource name="admin/video-playlists"  {...videoPlaylistsResource} />,
            <Resource name="admin/video-categories"  {...videoCategoriesResource} />,
            <Resource name="admin/products" {...productsResource} />,
        ],
        'sap': [
            <Resource name="admin/users" {...usersResource} />
        ],
        'account-manager': [
            <Resource name="admin/users" {...usersResource} />
        ]
    }

    return (
        <Admin
            loginPage={LoginPage}
            title="MurrPlastik"
            i18nProvider={i18nProvider}
            dataProvider={dataProvider}
            dashboard={Dashboard}
            authProvider={authProvider}
            layout={Layout}
            disableTelemetry
            customRoutes={customRoutes}
            customReducers={{theme: themeReducer}}
        >
            {(permissions) => {
                return resourceByRole[permissions] ?? null;
            }}
        </Admin>
    );
}

export default App;
