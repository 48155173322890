import React, {useState} from "react";

import {useRedirect, useTranslate} from "react-admin";

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Icon2FA from "../../assets/icons/2fa/icon_2fa.png";
import CardContent from "@material-ui/core/CardContent";
import ConfirmPass from "./ConfirmPass";
import axiosForApi from "../../config/axios-for-api";
import ConfirmDeactivateModal from "./ConfirmDeactivateModal";
import IconCheckWhite from "../../assets/icons/check-white.png";
import {Colors} from "../../styles/theme/colors";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {breakPoints} from "../../functions/common";
//import {useLocation} from "react-router-dom";

const TwoFAInfo = ({twoFactorAuthEnabled, setTwoFactorAuthEnabled} : {twoFactorAuthEnabled: boolean, setTwoFactorAuthEnabled: React.SetStateAction<any>}) => {
    const translate = useTranslate();
    const redirect = useRedirect();
    //const location = useLocation();
    const tabletTup = useMediaQuery(`${[breakPoints.up("md")]}`);

    const [confirming, setConfirming] = useState(false);
    const [disabeling, setDisabeling] = useState(false);

    //cr
    // useEffect(() => {
    //     if(twoFactorAuthEnabled && location.search !== '?enable-2fa') {
    //         redirect('/admin/configuration');
    //     }
    // }, []);

    const enable = () => {
        redirect('/admin/configuration?enable-2fa');
        //cr
        // axiosForApi.post('/user/two-factor-authentication').then(() => {
        //     setConfirming(false);
        //     redirect('/admin/configuration?enable-2fa');
        //     window.location.reload();
        // }).catch((err) => {
        //     const status = err.response?.status;
        //     if (status === 423) {
        //         setConfirming(true);
        //     }
        // });
    }

    const disable = () => {
        axiosForApi.delete('/user/two-factor-authentication').then(() => {
            setConfirming(false);
            setDisabeling(false);
            redirect('/admin/configuration');
            //cr
            //window.location.reload();
        }).catch((err) => {
            const status = err.response?.status;
            if (status === 423) {
                setConfirming(true);
            }
        });
    }

    return (
        <>
            <Card elevation={0} variant="outlined">
                <CardContent style={{display: 'flex', alignItems: "center", padding: '1rem'}} >
                    <Grid
                        container
                        spacing={1}
                        wrap={tabletTup ? 'nowrap' : 'wrap'}
                        alignItems="center"
                    >
                        <Hidden mdDown>
                            <Grid item xs={12} md="auto" style={{flexShrink: 0}}>
                                <img
                                    width={64}
                                    height={64}
                                    src={Icon2FA}
                                    alt="2fa"
                                    style={{objectFit: 'contain'}}
                                />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} xl="auto" style={{flexGrow: 1, paddingRight: 15}}>
                            <Typography
                                component="div"
                                style={{fontWeight: 700, display: 'flex', alignItems:'center', marginBottom: 5}}
                            >
                                <Hidden lgUp>
                                    <Box className="marginRight">
                                        <img
                                            alt="2fa"
                                            width={48}
                                            height={48}
                                            src={Icon2FA}
                                            style={{flexShrink: 0, objectFit: 'contain', marginRight: '1rem'}}
                                        />
                                    </Box>
                                </Hidden>
                                <Box style={{display: 'flex', alignItems: 'center'}}>
                                    {translate("twoFA.infoTitle")}
                                    {twoFactorAuthEnabled ? (
                                        <Box style={{display: 'flex', alignItems:'center', backgroundColor: Colors.success.main, borderRadius:5, padding: 5, marginLeft: 10}}>
                                            <img alt="2fa-enabled" width={17} height={13} src={IconCheckWhite} style={{objectFit: 'contain', marginRight: 5}} />
                                            <Typography variant="body2" style={{color: Colors.white.main, fontWeight: 700, lineHeight: 1}}>{translate("twoFA.active")}</Typography>
                                        </Box>
                                    ) : null}
                                </Box>

                            </Typography>
                            <Typography
                                component="div"
                                dangerouslySetInnerHTML={{
                                    __html: translate("twoFA.infoShortText")
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm="auto" style={{flexShrink: 0}}>
                            <Button
                                size="small"
                                variant={twoFactorAuthEnabled ? 'outlined' : 'contained'}
                                color={twoFactorAuthEnabled ? 'default' : 'primary'}
                                onClick={twoFactorAuthEnabled?()=>{ setDisabeling(true)}:enable}
                                fullWidth
                                style={{ textTransform: 'initial', borderRadius: 5 , borderWidth: twoFactorAuthEnabled ? 2 : 1, marginTop: !tabletTup ? 15 : 0 }}
                            >
                                {translate(twoFactorAuthEnabled ? 'twoFA.disableTwoFAButtonText' : 'twoFA.bannerButtonText')}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <ConfirmDeactivateModal
                confirming={disabeling}
                setConfirming={setDisabeling}
                onConfirm={disable}
            />

            <ConfirmPass
                confirming={confirming}
                setConfirming={setConfirming}
                onSuccess={twoFactorAuthEnabled?disable:enable}
            />
        </>
    )
}

export default TwoFAInfo;
