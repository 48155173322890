import React from 'react';
import {Box, Button, CircularProgress, Typography} from "@material-ui/core";
import {loginForm} from "../styles/ui/loginForm";
import {useTranslate} from "ra-core";
import FieldInput from "../components/ui/FieldInput";
import axiosForApi from "../config/axios-for-api";
import Alert from "../components/ui/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const ForgotPasswordForm = ({setForgotPassword} : {setForgotPassword: React.SetStateAction<any>}) => {
    const classes = loginForm();
    const translate = useTranslate();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [emailAddress, setEmailAddress] = React.useState<any>(null);
    const [errorMessage, setErrorMessage] = React.useState<any>(null);
    const [emailSent, setEmailSent] = React.useState<any>(null);

    const sendEmail = () => {
        setLoading(true);

        axiosForApi.post("/forgot-password", {email:emailAddress, locale: 'de'})
            .then(response => {
                if (response.status === 200 && "error" in response.data) {
                    setErrorMessage('Already Logged in!');
                }
                setEmailSent(true);
            })
            .catch(error => {
                setErrorMessage(error?.message ?? "");
            }).then(() => {
                setLoading(false);
            });
    }

    return (
        <>
            {emailSent ? (
                <>
                    <Typography className={classes.title} variant="h5">
                        {translate('forgotPassword.successTitle')}
                    </Typography>

                    <Typography variant="body2" className={`${classes.text}`} dangerouslySetInnerHTML={{__html:translate('forgotPassword.successMessage')}} />

                    <div className={classes.actions}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className={classes.button}
                            onClick={() => setForgotPassword(false)}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('forgotPassword.loginLink')}
                        </Button>
                    </div>
                </>
            ) : (
                <>
                    <Typography className={classes.title} variant="h5">
                        {translate('global.forgotPassword')}
                    </Typography>

                    <Typography variant="body2" className={`${classes.text}`}>
                        {translate('forgotPassword.headline')}
                    </Typography>

                    <div className={classes.input} style={loading ? {opacity: 0.6, pointerEvents: 'none'} : {}}>
                        <FieldInput
                            value={emailAddress}
                            type="email"
                            label={translate('forgotPassword.email')}
                            placeholder={translate('forgotPassword.emailPlaceholder')}
                            onChange={(e: any) => setEmailAddress(e.target.value)}
                            hasError={errorMessage !== null}
                        />
                    </div>

                    <div className={classes.actions}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading}
                            className={classes.button}
                            onClick={sendEmail}
                        >
                            {loading && (
                                <CircularProgress
                                    className={classes.icon}
                                    size={18}
                                    thickness={2}
                                />
                            )}
                            {translate('ra.auth.sign_in')}
                        </Button>
                    </div>

                    <Box style={{display: 'flex', marginTop: 5}}>
                        <Typography variant="caption" className={`${classes.text}`} style={{marginRight: 5}}>
                            {translate('forgotPassword.contactSupport')}
                        </Typography>
                        <Typography
                            component="a"
                            variant="caption"
                            className={`${classes.text} isLink`}
                            href={`${process.env.REACT_APP_SHOP_URL}/de/contact`}
                        >
                            {translate('forgotPassword.support')}
                        </Typography>
                    </Box>

                    <Box style={{display: 'flex', marginTop: -5}}>
                        <Typography variant="caption" className={`${classes.text}`} style={{marginRight: 5}}>
                            {translate('forgotPassword.alreadyRegistered')}
                        </Typography>
                        <Typography
                            variant="caption"
                            className={`${classes.text} isLink`}
                            onClick={() => setForgotPassword(false)}
                        >
                            {translate('forgotPassword.loginLink')}
                        </Typography>
                    </Box>

                    {errorMessage && (
                        <Alert
                           severity="error"
                           style={{marginTop: 30}}
                           action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => setErrorMessage(null)}
                            >
                                <CloseIcon fontSize="inherit"/>
                            </IconButton>
                        }>
                            {errorMessage}
                        </Alert>
                    )}
                </>
            )}
        </>
    )
}

export default ForgotPasswordForm;
