import {FC} from "react";
import {FieldProps, useTranslate} from "react-admin";
import {Product} from "../../config/types";
import {DataGrid} from "@material-ui/data-grid";
import * as React from "react";

const Variations: FC<FieldProps<Product>> = ({record}) => {
    const translate = useTranslate();

    if (!record || !record.variations) return null;
    const variationsColumns = [
        {field: 'id', headerName: 'ID', width: 90},
        {
            field: 'matnr',
            headerName: translate('resources.admin/variations.fields.matnr'),
            minWidth: 150

        },
        {
            field: 'name',
            headerName: translate('resources.admin/variations.fields.name'),
            minWidth: 250,
            editable: false,
        }
    ];

    const extraVariationsColumns:{[key:string]:string} = {};

    record.variations.forEach((el: any) => {
        el.prices.length > 0 && el.prices.forEach((el: any) => {
            extraVariationsColumns[`price__${el.price_group}`] = `Price ${el.price_group}`;
        });

        el.variation_attributes.length > 0 && el.variation_attributes.forEach((el: any) => {
            extraVariationsColumns[`att__${el.code}`] = el.name;
        });
    });

    Object.keys(extraVariationsColumns).forEach((vCKey:any) => {
        variationsColumns.push({
            field: vCKey.replace(/(price__|att__)/g,''),
            headerName: extraVariationsColumns[vCKey],
            minWidth:250,
            editable: false
        });
    })

    const variationsRows = record.variations.map((el: any) => {

        let out: { [key: string]: any } = {
            id: el.id,
            matnr: el.matnr,
            name: el.name,
        };

        el.prices.length > 0 && el.prices.forEach((el: any) => {
            out[el.price_group] = `${el.price_formatted}`;
        });

        el.variation_attributes.length > 0 && el.variation_attributes.forEach((el: any) => {
            out[el.code] = el.pivot.value;
        });

        return out;
    });

    return (
        <div style={{height: 400, width: '100%', marginBottom: '25px'}}>
            <DataGrid
                rows={variationsRows}
                columns={variationsColumns}
                checkboxSelection={false}
                disableSelectionOnClick
            />
        </div>);
}

export default Variations;
