import * as React from 'react';
import {useDataProvider, useTranslate} from 'react-admin';
import ProductsIcon from '@material-ui/icons/Apps';
import { User } from '../../config/types';
import CardWithIcon from "./CardWithIcon";
import {useCallback, useEffect, useState} from "react";
import {subDays} from "date-fns";
import ResultsTable from "./ResultsTable";
import {Box, CircularProgress} from "@material-ui/core";

const LatestProducts = () => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [shouldFetchProducts, setShouldFetchProducts] = useState<boolean>(true);
    const [latestProducts, setLatestProducts] = useState<User[]>([]);
    const [totalProducts, setTotalProducts] = useState<number | string>('');

    const fetchLatestProducts = useCallback(async () => {
        const aMonthAgo = subDays(new Date(), 30);
        await dataProvider.getList<User>(
            'admin/products',
            {
                filter: { date_gte: aMonthAgo.toISOString() },
                sort: { field: 'id', order: 'DESC' },
                pagination: { page: 1, perPage: 10 },
            }
        ).then(({data,total}) => {
            setLatestProducts(data);
            setTotalProducts(total)
        }).catch(err => Promise.reject(err.response));
        setShouldFetchProducts(false);
    }, [dataProvider]);

    useEffect(() => {
        if(shouldFetchProducts) {
            fetchLatestProducts();
        }
    }, [shouldFetchProducts,fetchLatestProducts]);

    const tableColumns = ['id', 'created_at', 'name'];

    return (
        <CardWithIcon
            to="admin/products"
            icon={ProductsIcon}
            title={translate('resources.admin/dashboard.products')}
            subtitle={totalProducts}
            showButton={latestProducts.length > 0}
        >
            {latestProducts.length ? (
                <ResultsTable columns={tableColumns} results={latestProducts} to={'admin/products'} linkArray={['id']} />
            ) : (
                <Box display={'flex'} justifyContent={'center'} p={2}>
                    <CircularProgress />
                </Box>
            )}
        </CardWithIcon>
    );
};

export default LatestProducts;