import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import MaterialConfigList from './MaterialConfigList';

const resource = {
    list: MaterialConfigList,
    icon: SettingsApplicationsIcon,
    options: {
        label: 'resources.admin/lod-material-configs.name'
    }
};

export default resource;
