import * as React from 'react';
import {Datagrid, List, ListProps, EditButton} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import {Route} from 'react-router';

import FrameFilter from "./FrameFilter";
import FrameEdit from "./FrameEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";

const FrameList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<FrameFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>}>
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="name"/>
                    <TextField source="category"/>
                    <TextField source="matnr"/>
                    <TextField source="material"/>
                    <TextField source="cut_out_size"/>
                    <EditButton/>
                </Datagrid>
            </List>

            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''}>
                            {isMatch && (
                                <FrameEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default FrameList;
