import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, ReferenceInput, SelectInput, NumberInput, SimpleForm } from 'react-admin';
import Title from "../../../components/list/Title";
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const PriceMatrixCreate: FC<CreateProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <Title label="resources.admin/lod-price-matrix.titles.create" />
                <ReferenceInput label="resources.admin/lod-price-matrix.fields.material" source="material_id" reference="admin/lod-materials" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }} filter={{status: 'active'}}>
                    <SelectInput optionText="name_lt" fullWidth={true}/>
                </ReferenceInput>
                <ReferenceInput label="resources.admin/lod-price-matrix.fields.thickness" source="thickness_id" reference="admin/lod-thickness" options={{
                    suggestionsContainerProps: {
                        disablePortal: true,
                    }
                }} filter={{status: 'active'}}>
                    <SelectInput optionText="name_lt" fullWidth={true} />
                </ReferenceInput>
                <NumberInput source="min_signs" fullWidth={true} />
                <NumberInput source="max_signs" fullWidth={true} />
                <NumberInput source="obor_price" fullWidth={true} />
                <NumberInput source="obmr_price" fullWidth={true} />
                <NumberInput source="mbor_price" fullWidth={true} />
                <NumberInput source="mbmr_price" fullWidth={true} />
            </SimpleForm>
        </Create>
    );
};

export default PriceMatrixCreate;
