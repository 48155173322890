import * as React from 'react';
import {FC} from 'react';
import {
    Show,
    ShowProps,
    SimpleShowLayout,
    useRecordContext
} from 'react-admin';

import Title from '../../components/list/Title';
import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps} from "../../functions/common";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";

type Usage = {
    id: number;
    used_at: string;
    discount_used: number;
    discount_remained: number;
    order_id: number;
}

const UsageTable = () => {
    const record = useRecordContext();
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Used at
                    </TableCell>
                    <TableCell>
                        Order #
                    </TableCell>
                    <TableCell>
                        Discount used
                    </TableCell>
                    <TableCell>
                        Discount remained
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {(record.usages ?? []).map((usage: Usage) => {
                    return (<TableRow key={usage.id}>
                        <TableCell>{usage.used_at}</TableCell>
                        <TableCell>{usage.order_id}</TableCell>
                        <TableCell>{usage.discount_used}</TableCell>
                        <TableCell>{usage.discount_remained}</TableCell>
                    </TableRow>)
                })}
            </TableBody>
        </Table>
    );
}

export const VoucherShow: FC<ShowProps> = (props: any) => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Show {...newProps} >
            <SimpleShowLayout className={classes.sidebar}>
                <Title source="id" label="resources.admin/vouchers.titles.voucher_usage"/>
                <UsageTable/>
            </SimpleShowLayout>
        </Show>
    );
}

export default VoucherShow;
