import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import MarkDownIt from "markdown-it";


import HelpIcon from '@material-ui/icons/Help';
import {useTranslate} from "react-admin";

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

export default function Help(props:any) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const translate = useTranslate();

    return (<>
        <span style={{cursor:'pointer', lineHeight:1, 'marginLeft':'5px'}}><HelpIcon onClick={handleClick}  /></span>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Typography className={classes.typography} dangerouslySetInnerHTML={{__html: (new MarkDownIt({breaks:true})).render(translate(props.content??''))}}></Typography>
        </Popover>
    </>);
}
