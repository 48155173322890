import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";
import {Colors} from "../theme/colors";
import {hexToRgba} from "../../functions/common";

export const loginForm = makeStyles((theme: Theme) => ({
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',

        '& .MuiCardActions-root': {
            padding: 0,
        }
    },
    title: {
        fontSize: 22.75,
        fontWeight: 700,
        color: Colors.white.main,
        textAlign: 'center',
        width: '100%',
        borderBottom: `1px solid ${hexToRgba(Colors.white.main, 0.25)}`,
        paddingBottom: '1rem',

        [theme.breakpoints.up('sm')]: {
            fontSize: 26,
        },
    },
    input: {
        width: '100%',
        color: Colors.white.main,
        borderRadius: 5,
        marginTop: '1.5rem',

        '& .MuiInputLabel-root , & .MuiTypography-body1': {
            color: `${Colors.white.main} !important`,
            position: 'relative',
            top: 'auto',
            bottom: 'auto',
            left: 0,
            fontSize: '1rem',
            transform: 'none',
            fontWeight: 700,
            marginBottom: '0 !important',
        },

        '& .MuiInputBase-root': {
            border: `1px solid ${hexToRgba(Colors.white.main, 0.25)}`,
            borderRadius: 5,
            padding: '5px 0',
            marginTop: 10,
            outline: 'none',

            '&:after, &:before': {
                display: 'none',
            },

            '&.Mui-error': {
                border: `2px solid ${Colors.primary.main}`,
            },

            '&:focus-within': {
                border: `1px solid ${Colors.white.main}`,
                outline: 'none',
            }
        },

        '& .MuiFormHelperText-root': {
            fontWeight: 400,
            color: Colors.white.main,
        },

        '&.textCenter': {
            '& input': {
                textAlign: 'center',
            }
        },

        '& input': {
            color: Colors.white.main,
            padding: '5px 15px',
            borderRadius: 5,
            fontSize: '1rem',

            '&::placeholder': {
                color: Colors.white.main,
                opacity: 1,
            },
        },
    },
    button: {
        width: '100%',
        backgroundColor: Colors.primary.main,
        color: Colors.white.main,
        textTransform: 'none',
        fontSize: '1rem',
        fontWeight: 700,
        height: 45,
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'none !important',
        borderColor: 'transparent',

        '&:hover': {
            backgroundColor: Colors.primary.accent,
        },

        '&.Mui-disabled, &.disabled': {
            backgroundColor: Colors.primary.main,
            opacity: '0.6',
            color: Colors.white.main,
        },

        '& .MuiCircularProgress-colorPrimary': {
            color: Colors.white.main,
        },

        '&.MuiButton-outlined': {
            backgroundColor: hexToRgba(Colors.white.main, 0),
            border: `2px solid ${hexToRgba(Colors.white.main, 0.35)}`,

            '&:hover': {
                backgroundColor: hexToRgba(Colors.white.main, 0.1),
            },
        }
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    actions: {
        padding: 0,
        marginTop: '2rem'
    },
    text: {
        marginTop: '1rem',
        color: Colors.white.main,

        '&.isLink': {
            textDecoration: 'underline',
            cursor: 'pointer',
        }
    }
}));