import {TranslationMessages} from 'react-admin';
import germanMessages from './ra-language-german';

const customGermanMessages: TranslationMessages = {
    ...germanMessages,
    loading: 'Загрузка. Пожалуйста, подождите ...',
    pos: {
        configuration: 'Настройки',
        language: 'Язык',
        languageOptions: {
            english: 'en',
            german: 'de',
            french: 'fr',
            italian: 'it',
            spanish: 'es',
            russian: 'ru',
            swedish: 'se',
        },
        buttons: {
            save: 'Сохранить',
            delete: 'Удалить',
            close: 'Закрыть',
        },
    },
    errors: {
        invalid_record: 'Неверная запись!'
    },
    resources: {
        'admin/dashboard': {
            name: 'Приборная панель',
            orders: 'Заказы магазинов',
            ordersLastDays: 'последние дни',
            ordersLastMonths: 'последние месяцы',
            users: 'Пользователи',
            customers: 'Клиенты',
            products: 'Продукция',
            see_all: 'Смотреть все',
            see_more: 'См. подробнее',
            show_more: 'Развернуть',
            show_less: 'Усадка',
            offers: 'Предлагает',
            results: {
                id: 'Id',
                sap_order_id: 'Sap Order Id',
                cart_id: 'Id корзины',
                total: 'Всего (€)',
                total_formatted: 'Всего (€)',
                created_at: 'Создано в',
                status: 'Статус',
                first_name: 'Имя',
                last_name: 'Фамилия',
                email: 'Электронная почта',
                name: 'Имя',
                nrOrders: 'Количество заказов',
                formattedSumTotalOrders: 'Всего (€)',
                formattedStatDate: 'Дата',
                formattedCreatedAt: 'Создано в',
                formattedTotal: 'Всего (€)',
                company: 'Компания',
            },
            'headers': {
                'total-revenue-this-year': `Продажи ${new Date().getFullYear()} (нетто / всего)`,
                'total-revenue-this-month': `Текущий месяц (нетто / всего)`,
                'total-registered-customers-all-time': `Зарегистрированные клиенты`,
                'total-registered-customers-now': `Новые ежемесячные клиенты / всего`,
                'average-revenue-by-nr-orders-last-12months': `⌀ Корзина (нетто / всего)`,
                'average-revenue-by-month-last-12months' : `⌀ Продажи п. м. (нетто / всего)`,
                'average-revenue-by-subscriptions-last-12-months': `Подписка / Объем (в год)`,
                'offers-orders-conversion-rate-last-12-months': `Предложения / Заказы из предложений / Конвертация предложения в заказ`,
                'revenue-this-year': `Динамика продаж в месяц`,
                'total-revenue-by-main-category-this-month': `Фактический объем продаж за месяц по видам продукции`,
                'help.revenue-by-year': `Динамика продаж за календарный год`,
                'total-revenue-by-main-category-this-year': `Продажи ${new Date().getFullYear()} по видам продукции`,
                'nr_active_users_by_country': `Количество активных пользователей по странам`,
                'top-products-by-revenue-this-year': `5 самых продаваемых продуктов (${new Date().getFullYear()})`,
            },
            'help': {
                //Umsatz 2022 (net / total)
                'total-revenue-this-year': `Продажи для всех заказов, удовлетворяющих заданным критериям:
                    - Статус = обработан
                    - Источник = магазин
                    - Год создания заказа = текущий год
                `,
                //Aktueller Monat (net / total)
                'total-revenue-this-month': `Продажи для всех заказов, удовлетворяющих заданным критериям:
                    - Статус = обработан
                    - Источник = магазин
                    - Год создания заказа = текущий год
                    - Месяц создания заказа = текущий месяц`,

                //Angemeldete Kunden
                'total-registered-customers-all-time': `**ЧИСЛО** всех пользователей, удовлетворяющих критериям:
                    - Роль пользователя = Клиент
                    - Статус пользователя = активный
                    - Источник регистрации пользователя = Магазин`,
                //Neukunden Monat / Total
                'total-registered-customers-now': `**ЧИСЛО** всех пользователей, удовлетворяющих критериям:
                    - Роль пользователя = Клиент
                    - Статус пользователя = активный
                    - Источник регистрации пользователя = Магазин
                    - Месяц/год регистрации пользователя = текущий месяц/год`,
                //⌀ Warenkorb (net / total)
                'average-revenue-by-nr-orders-last-12months': `**AVG = SALES / количество заказов** заказов, соответствующих критериям:
                    - Заказ создан в период с настоящего времени до 12 месяцев назад
                    - Источник заказа = магазин
                    - Статус заказа = обработан`,
                //⌀ Umsatz p.m (net / total)
                'average-revenue-by-month-last-12months': `**AVG= ОБЩИЕ ПРОДАЖИ / 12** заказов, соответствующих критериям:
                    - Заказ создан в период с настоящего времени до 12 месяцев назад
                    - Источник заказа = магазин
                    - Статус заказа = обработан`,

                //Abos / Volumen (p.a.)
                'average-revenue-by-subscriptions-last-12-months': `**Подписки**
                        - Определите все товары корзины, которые имеют частоту (= подписку) в период сейчас - 12 месяцев
                        - Рассчитайте среднюю стоимость = общее количество подписок / 12
                    **Продажи (в год)**.
                        - Определите все позиции корзины, которые имеют частоту (= подписка) в период сейчас - 12 месяцев
                        - Рассчитайте среднюю стоимость = продажи (всего статей) / количество подписок`,

                //Angebote / Bestellungen aus Angeboten / Conversion Angebot2Bestellung
                'offers-orders-conversion-rate-last-12-months': `**Предложения**: Количество предложений, соответствующих критериям:
                    - Предложения, созданные в период с настоящего времени по 12 месяц и сейчас с определенной корзиной покупок
                    ---
                    **Заказы из предложений**: Количество заказов, соответствующих критериям:
                    - Заказы, созданные в период с настоящего времени до 12 месяцев назад
                    - Статус заказа = обработан
                    - Источник заказа = Магазин
                    - Корзина = все
                    ---
                    **Коэффициент конверсии**: Количество заказов из предложений / количество предложений* 100`,

                //Umsatzentwicklung pro Monat
                'revenue-this-year': `a) Сгруппируйте заказы по месяцам в текущем году со следующими критериями заказа:
                    - Статус = обработан
                    - Источник = магазин
                    - Год создания заказа = текущий год
                    b) SUM (общая сумма) для каждой группы заказов
                    c) Рассчитайте прирост для каждой группы по отношению к сумме за предыдущий месяц.`,

                //Umsatz Akt. Monat nach Produktbereich
                'total-revenue-by-main-category-this-month': `a) для каждого заказа - мы взимаем общую сумму за товар
                    Например:
                    - Продукт A, общая сумма: 1000 eur, основная категория: ACS
                    - Продукт B, общая сумма: 2000 eur, основная категория: ACS
                    б) Группировка всех заказанных продуктов по основной категории продуктов.
                    в) Для каждой группы сумма (общее количество) всех продуктов, рассчитанных в пункте а).
                    г) Заказанные продукты должны быть частью заказов, соответствующих критериям:
                        - Источник заказа = магазин
                        - Статус заказа = обработан
                        - Год создания/месяц заказа = текущий год/месяц`,

                //Umsatzentwicklung pro Kalenderjahr
                'revenue-by-year': `a) Группировка заказов по годам, заказы со следующими критериями:
                    - Статус заказа = обработан
                    - Источник заказа = магазин
                    b) Продажи для каждой группы заказов
                    c) Рассчитать для каждой группы прирост по отношению к общему объему продаж за предыдущий год`,

                //`Umsatz 2022 nach Produktbereich
                'total-revenue-by-main-category-this-year': `a) для каждого заказа - мы взимаем общую сумму за товар
                    Например:
                    - Продукт A, общая сумма: 1000 eur, основная категория: ACS
                    - Продукт B, общая сумма: 2000 eur, основная категория: ACS
                    б) Группировка всех заказанных продуктов по основной категории продуктов.
                    в) Для каждой группы сумма (общее количество) всех продуктов, рассчитанных в пункте а).
                    г) Заказанные продукты должны быть частью заказов, соответствующих критериям:
                        - Источник заказа = магазин
                        - Статус заказа = обработан
                        - Год создания/месяц заказа = текущий год`,

                //Umsatz Top 5 Produkte (2022)
                'top-products-by-revenue-this-year': `a) для каждого заказа мы начисляем общую сумму по продуктам
                    б) группируем все заказанные продукты по product_id
                    в) для каждой группы формируется сумма итогов по всем продуктам, рассчитанная в пункте а)
                    d) Заказанные продукты должны быть частью заказов, которые соответствуют критериям:
                        - Источник заказа = Магазин
                        - Статус заказа = обработан
                        - Год создания заказа = текущий год`,
                //Nr active users by country
                'nr_active_users_by_country': `Количество активных пользователей по странам`
            }
        },
        'admin/users': {
            name: 'Пользователи',
            last_login: 'Последний вход в систему',
            from: 'С сайта',
            fields: {
                name: 'Имя',
                sap_status: 'Статус SAP',
                email_verified_at: 'Проверено на',
                first_name: 'Имя',
                last_name: 'Фамилия',
                email: 'Электронная почта',
                company: 'Компания',
                send_reset_password_link: 'Отправить ссылку на сброс пароля',
                send_email_confirmation_link: 'Отправить ссылку для подтверждения по электронной почте',
                email_verified: 'Электронная почта проверена'
            },
            roleOptions: {
                admin: 'Admin',
                sap: 'Sap',
                manager: 'Manager',
                customer: 'Customer',
                "location-manager": "Location Manager"
            },
            statusOptions: {
                active: 'Active',
                inactive: 'Inactive'
            },
            fieldGroups: {
                identity: 'Identity',
                password: 'Password',
                sap_data: 'Sap Data',
                invoice_address: 'Billing Address',
                client_info: 'Client Info'
            },
            fieldButtons: {
                reset_password: 'Reset Password',
                email_confirmation_link: 'Email Confirmation Link'
            },
            page: {
                delete: 'Delete User',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
            titles: {
                edit_user: 'Edit user #%{info}',
            },
            messages: {
                are_you_sure_you_want_to_sent_the_reset_password_link: 'Are you sure you want to send the reset password link?',
                reset_password_link_sent: 'Resent password link sent with success',
                are_you_sure_you_want_to_sent_email_confirmation_link: 'Are you sure you want to send the email confirmation link?',
                email_confirmation_link_sent: 'Email confirmation link sent with success',
                are_you_sure_you_want_to_manually_confirm_the_user_email: 'Are you sure you want to manually confirm the user\'s email?',
                email_confirmed: 'Email confirmed with success'
            }
        },
        'admin/posts': {
            name: 'Posts',
            view: 'View Posts',
            create: 'Create Post',
            fields: { //these are used in the forms
                author: 'Author',
                role: 'Role',
                slug: 'Slug',
                status: 'Status',
                content: 'Content',
                title: 'Title',
                subtitle: 'Subtitle',
                id: 'Id',
                teaser_category_id: 'Category ID',
                bg_color: 'Background Color',
                bg_image_src: 'Background Image Src',
                text: 'Text',
                button_url: 'Button Url',
                button_text: 'Button Text',
                image_src: 'Image Src',
                image_path: 'Image Path',
                hierarchy: 'Hierarchy',
                template: 'Template',
                meta: {
                    price: 'Price',
                    button_text: 'Button text',
                    button_url: 'Button url',
                    title: 'Title',
                    image_src: 'Image src',
                    image_path: 'Upload image',
                    categories: 'Categories',
                    seo_title: 'SEO Title',
                    seo_description: 'SEO Description',
                    seo_keywords: 'SEO Keywords',
                    seo_robots: 'Robots'
                }
            },
            statusOptions: {
                publish: 'Publish',
                draft: 'Draft'
            },
            typeOptions: {
                page: 'Page',
                content: 'Content'
            },
            translationStatusOptions: {
                active: 'Active',
                inactive: 'Inactive'
            },
            section: {
                seo: 'Seo',
                post_info: 'Post Info',
                meta: 'Meta'
            },
            titles: {
                edit_post: 'Edit post #%{id}'
            },
            buttons: {
                show_on_shop: 'Show on Shop'
            }
        },
        'admin/products': {
            name: 'Products',
            titles: {
                show_product: 'Show product: %{name}',
                product_variations: 'Product Variations'
            },
            buttons: {
                show_on_shop: 'Show on Shop'
            },
            fields: {
                id: 'Id',
                name_or_id: 'Name or Id',
                top_seller: 'Top Seller',
                created_at: 'Created At',
                name: 'Name'
            }
        },
        'admin/variations': {
            fields: {
                name: 'Name',
                price: 'Price',
                matnr: 'OrderId'
            }
        },
        'admin/orders': {
            name: 'Заказы',
            fields: {
                product_id: 'Продукт#',
                matnr: 'Матнр',
                product: 'Продукт',
                manager: 'Менеджер',
                customer: 'Клиент',
                qty: 'Количество',
                price: 'Цена',
                variation: 'Вариация',
                customer_name_or_email: 'Имя клиента / электронная почта',
                from: 'С сайта',
                to: 'На',
                total: 'Всего',
                created_at: 'Создано в',
                cad_url: 'CAD',
                print_url: 'Печать',
                pending: 'В ожидании',
                processed: 'Переработано',
                payment_status: 'Статус платежа',
                sap_order_id: 'Идентификатор заказа SAP',
                status: 'Статус',
                source: 'Источник',
                payment_method: 'Способ оплаты',
                unzer_short_id: 'Unzer Short ID',
                unzer_payment_id: 'Unzer Payment ID',
                shipping_date: 'Дата отгрузки',
                shipping_date_notification_action: 'Действие даты отгрузки',
                approve_shipping_date: 'Отправить утверждение даты отгрузки',
                reject_shipping_date: 'Дата отправки отказа',
            },
            badge: {
                sample: 'Образец',
            },
            titles: {
                edit_order: 'Заказ #%{id}'
            },
            buttons: {
                submit_to_sap: 'Отправить в SAP'
            }
        },
        'admin/translations': {
            name: 'Translations',
            buttons: {
                save_translation: 'Save translation',
                publish_translation: 'Publish translation',
                reset: 'Reset',
                remove: 'Remove language',
                translate_field: 'Translate field',
                add_new_language: 'Add new language',
            },
            messages: {
                translation_saved: 'Translation saved with success!',
                translation_not_saved: 'Error! Translation not saved!',
                translation_reset: 'Translation reset with success!',
                translation_not_reset: 'Error! Translation not reset!',
                translation_published: 'Translation published with success!',
                translation_not_published: 'Error! Translation not published!',
                you_have_unsaved_changes: 'Warning! Your have unsaved translations, are you sure your want to continue?',
                language_added: 'Language added successfully.',
                language_not_added: 'Error! Language was not added.',
                cannot_remove_de_or_only_language: 'DE langauge cannot be removed.',
                removed_language: 'Error! Language removed successfully.',
                failed_removed_language: 'Language was not removed',
                please_select_language_from_and_to: 'Please select new language and template language.',
            },
            fields: {
                all_locales: "Choose new language!",
                template_language: "Choose template language!"
            }
        },
        'admin/mail-templates': {
            name: 'Mail Templates',
            fields: {
                subject: 'Subject',
                cc: 'CC',
                to: 'TO',
                bcc: 'BCC'
            },
            titles: {
                edit_mail_template: 'Edit mail template #%{id}'
            },
            statusOptions: {
                active: 'Active',
                draft: 'Draft'
            },
        },
        'admin/backups': {
            name: 'Backups',
            fields: {
                path: 'Path',
            },
            buttons: {
                restore: 'Restore'
            },
            messages: {
                'restore_with_success': 'Restore has been scheduled with success',
                'restore_failed': 'Restore failed'
            }
        },
        'admin/lod': {
            name: 'LOD',
        },
        'admin/lod-colors': {
            name: 'Colors',
            titles: {
                create: 'Add new Color',
                edit: 'Edit Color: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-foils': {
            name: 'Foils',
            titles: {
                create: 'Add new Foil',
                edit: 'Edit Foil: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-thickness': {
            name: 'Thickness',
            titles: {
                create: 'Add new Thickness',
                edit: 'Edit Thickness: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-materials': {
            name: 'Materials',
            titles: {
                create: 'Add new Material',
                edit: 'Edit Material: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-holes': {
            name: 'Holes',
            titles: {
                create: 'Add new Hole',
                edit: 'Edit Hole: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-shapes': {
            name: 'Shapes',
            titles: {
                create: 'Add new Shape',
                edit: 'Edit Shape: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-price-matrix': {
            name: 'Price Matrix',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                signs: 'signs'
            }
        },
        'admin/lod-raw-material-prices': {
            name: 'Raw Material Prices',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                color: 'Color',
                foil: 'Foil',
                price: 'Price'
            }
        },
        'admin/lod-hole-image-shape': {
            name: 'Hole/Image/Shape',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            orientation: {
                landscape: 'Landscape',
                portrait: 'Portrait'
            },
            fields: {
                image: 'Image',
                shape: 'Shape',
                hole: 'Hole',
                mass_1: 'L',
                mass_2: 'B',
                mass_3: 'D',
                mass_4: 'd',
                mass_5: 'R',
                mass_6: 'x',
                mass_7: 'y',
                mass_8: 'X1',
                mass_9: 'X2',
                mass_10: 'Y1',
                mass_11: 'Y2'
            }
        },
        'admin/lod-material-configs': {
            name: 'Material Configs',
            titles: {
                edit: 'Edit material config',
                create: 'Create material config'
            },
            sections: {
                gewicht: 'Gewicht',
                mattenbreite: 'Mattenbreite',
                menge: 'Menge',
                wkz: 'WKZ',
                rohmaterial: 'Rohmaterial',
                rabatt: 'Rabatt'
            },
            status: {
                'active': 'Active',
                'inactive': 'Inactive'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                gewicht: {
                    matte_248x248_in_gramm: 'Matte 248x248 in Gramm',
                    pro_mm2_in_g: 'Pro mm2 in g'
                },
                wkz: {
                    '1_klein': 'WKZ 1 (klein)',
                    '2_gross': 'WKZ 2 (gross)',
                    'umfang': 'WKZ Umfang'
                },
                foil_id: "Foil",
                color_id: "Color",
                extra_charge: "Extra charge"
            }
        },
        'admin/lod-images': {
            name: 'Images',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                image_path: 'Image file'
            }
        },
        'admin/lod-price-list': {
            name: 'Price list',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {}
        },
        'admin/lod-price-list-factors': {
            name: 'Price list Factors',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                price_list: 'Price list'
            }
        },
        'admin/lod-quellwerk': {
            name: 'Quellwerk',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {}
        },
        'admin/offers': {
            name: 'Предлагает',
            fields: {
                total: 'Всего',
                created_at: 'Создано в',
                company: 'Компания',
                processed: 'Переработано',
                active: 'Активный',
                expired: 'Просроченный',
                cart_status: 'Состояние корзины',
                client_offer_id: 'Идентификатор предложения клиента',
                cart_id: 'Идентификатор корзины'
            },
            buttons: {
                export: 'PDF'
            }
        },
        'admin/lod-wintyps': {
            name: 'Wintyps',
            titles: {
                create: 'Add new Wintyp',
                edit: 'Edit Wintyp: %{name}'
            },
            fields: {
                blocked_at: 'Blocked',
                used_at: 'Used',
                product: 'Product',
                order_id: 'Order #'
            }
        },
        'admin/shipping-dates': {
            name: 'Shipping Dates',
            titles: {
                shipping_countries: 'Shipping Countries',
                shipping_date: 'Shipping Date',
                create: 'Add new Shipping Date',
                edit: 'Edit Record: %{name}',
            },
            sections: {
                recurrence: 'Recurrence'
            },
            fields: {
                shipping_country_ids: 'Shipping Countries (iso2)'
            }
        },
        'admin/shipping-countries': {
            name: 'Shipping Countries',
            titles: {
                create: 'Add new Shipping Country',
                edit: 'Edit Shipping Country: %{name}'
            },
            fields: {
                code: 'Country Code (iso 2)'
            }
        },
        'admin/shipping': {
            name: 'Shipping'
        },
        'admin/cob': {
            name: 'COB'
        },
        'admin/cob-cobots': {
            name: 'Cobots',
            fields: {
                manufacturer: 'Manufacturer',
                type: 'Type',
                model: 'Model',
                axis6_tape: 'Axis 6 Tape',
                system_holder_1: 'System Holder #1',
                system_holder_2: 'System Holder #2',
                hose_length: 'Hose Length',
                tension_clamp: 'Tension Clamp',

                //ua
                ua_shs_center: 'Center',
                ua_shs_outside: 'Outside',
                ua_shs_inside: 'Inside',
                ua_tape_center: 'Center',
                ua_tape_outside: 'Outside',
                ua_tape_inside: 'Inside',
                ua_feeding_system: 'Feeding System',

                //lq
                la_shs_center: 'Center',
                la_shs_outside: 'Outside',
                la_shs_inside: 'Inside',
                la_tape_center: 'Center',
                la_tape_outside: 'Outside',
                la_tape_inside: 'Inside',
                la_feeding_system: 'Feeding System'

            },
            titles: {
                create: 'Add New Cobot',
                edit: 'Edit Cobot %{name}'
            },
            fieldGroups: {
                manufacturer_details: 'Manufacturer Details',
                technical_master_data: 'Technical Master Data',
                technical_details_upper_support_arm: 'Technical Details - Upper Arm',
                technical_details_bottom_support_arm: 'Technical Details - Lower Arm',
                shs: 'SHS',
                tape: 'Tape'
            }
        },
        'admin/cob-manufacturers': {
            name: 'Manufacturers',
            titles: {
                create: 'Add New Manufacturer',
                edit: 'Edit Manufacturer: %{name}'
            }
        },
        'admin/cob-types': {
            name: 'Types',
            titles: {
                create: 'Add New Type',
                edit: 'Edit Type: %{name}'
            },
            fields: {
                manufacturer: 'Manufacturer'
            }
        },
        'admin/cob-models': {
            name: 'Models',
            titles: {
                create: 'Add New Model',
                edit: 'Edit Model: %{name}'
            },
            fields: {
                manufacturer: 'Manufacturer',
                type: 'Type'
            }
        },
        'admin/cob-products': {
            name: 'Products',
            titles: {
                create: 'Add New Product',
                edit: 'Edit Product: %{name}'
            },
            fieldGroups: {
                product_details: 'Details',
                product: 'Product'
            },
            fields: {
                name: 'Name',
                nominal_size: 'Nominal size',
                profile: 'Profile',
                configuratorStatus: 'Configurator status'
            },
            group: {
                hose: 'Hose',
                hose_hf: 'Hose Highly Flexible',
                tension_clamp: 'Tension Clamp',
                belt: 'Belt',
                system_holder: 'System Holder',
                end_grommet: 'End Grommet',
                hose_ring_zl: 'Hose Ring Zl',
                hose_ring_fixed: 'Hose Ring Fixed',
                shs: 'SHS',
                fhs_c: 'System Holder FHS-C',
                fhs_uhe: 'System Holder FHS-UHE',
                fhs_sh: 'System Holder FHS-SH',
                options: 'Option',
                velcro: 'Velcro',
                velcro_silicone_free: 'Velcro silicone free',
                stainless_steel_band: 'Stainless Steel Band',
                return_system: 'Return System'
            },
            design: {
                slotted: 'Slotted',
                closed: 'Closed'
            },
            status: {
                active: 'Active',
                inactive: 'Inactive'
            }
        }
    }
};

export default customGermanMessages;
