import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, TextInput, TranslatableInputs, useTranslate, SelectInput} from 'react-admin';
import cfgLocales from '../../config/locale';
import Title from "../../components/list/Title";
import {filterProps} from "../../functions/common";
import {listEditCreate} from "../../styles/listEditCreate";

const PostCreate: FC<CreateProps> = props => {
    const translate = useTranslate();
    const newProps = filterProps(props);
    const classes = listEditCreate();

    return (
        <Create {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <Title label="resources.admin/posts.create" />
                <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                    <TextInput autoFocus source="title" fullWidth />
                </TranslatableInputs>
                <SelectInput source="type" choices={[
                    {id: 'page', name: translate('resources.admin/posts.typeOptions.page')},
                    {id: 'content', name: translate('resources.admin/posts.typeOptions.content')},
                    {id: 'modal', name: translate('resources.admin/posts.typeOptions.modal')},
                ]}/>
            </SimpleForm>
        </Create>
    );
};
export default PostCreate;
