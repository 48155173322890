import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../theme/colors";
import {hexToRgba} from "../../functions/common";

export const authcode = makeStyles((theme) => ({
    inputs: {
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'nowrap',

        '&.numeric': {

            '& input': {
                textAlign: 'center',
            }
        },

        '&.dark': {

            '& input': {
                backgroundColor: Colors.dark.main,
                border: `2px solid ${hexToRgba(Colors.white.main, 0.25)}`,
                color: Colors.white.main,
            }
        },


        '& input': {
            backgroundColor: Colors.background.default,
            border: `1px solid ${hexToRgba(Colors.dark.main, 0.25)}`,
            borderRadius: 5,
            width: 40,
            height: 40,
            margin: 5,
            fontSize: 26,
            color: Colors.dark.main,
            padding: 0,
            outline: 'none',
            fontWeight: 700,
            lineHeight: 1,
            boxSizing: 'border-box',

            '&:focus': {
                outline: 'none',
                boxShadow: `0 0 0 2px ${hexToRgba(Colors.dark.main, 0.25)}`,
            },

            [theme.breakpoints.up('sm')]: {
                width: 56,
                height: 56,
            },
        },

    },
}));
