import React from 'react';
import {
    Filter,
    FilterProps,
    TextInput
} from 'react-admin';

const GsFlowFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
       <TextInput source="name" alwaysOn />
    </Filter>
);

export default GsFlowFilter;
