import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, SimpleForm, TextInput, BooleanInput, SelectInput} from 'react-admin';

import Title from '../../../components/list/Title';
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const QuellwerkEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit{...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="id" label="resources.admin/lod-price-list-factors.titles.edit"/>

                <TextInput source="werk" fullWidth={true}/>
                <SelectInput source={'country'} choices={[
                    {name:'de', id:'de'},
                    {name:'fr', id:'fr'},
                    {name:'ru', id:'ru'},
                    {name:'es', id:'es'}
                ]}/>
                <BooleanInput source={'zielwerk'}/>
                <BooleanInput source={'material'}/>
                <BooleanInput source={'einkaufspreis'}/>
                <BooleanInput source={'ek_preiseinheit'}/>
                <BooleanInput source={'verkaufspreis'}/>
                <BooleanInput source={'vk_preiseinheit'}/>
                <BooleanInput source={'wahrung'}/>
                <BooleanInput source={'preisliste'}/>
            </SimpleForm>
        </Edit>
    );
};

export default QuellwerkEdit;
