const grommetTypes = [
    "Adaptertülle für kleine Tülle",
    "ASI-Tülle",
    "Blindstopfen ASI-Tülle",
    "Blindtülle",
    "Doppelleitungstülle",
    "Kabeldurchführungstülle",
    "Kabeldurchführungstülle FDA",
    "LAN-Tülle",
    "Mehrfachtülle 4-fach",
    "USB-Tülle",
];
export default grommetTypes;
