import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import {list} from "../../styles/list";
import {useHistory} from "react-router-dom";
import { Drawer, Box } from '@material-ui/core';

interface SideDrawerProps {
    basePath: string;
    open: boolean;
    children: any;
    size?: number;
}

const SideDrawer = ({basePath, open, children, size}:SideDrawerProps) => {
    const classes = list();
    const history = useHistory();

    const handleClose = () => {
        history.push(basePath);
    };

    return (
        <Drawer open={open} anchor="right" classes={{root: classes.drawer}} onClose={handleClose}>
            <Button className={classes.closeDrawer} onClick={handleClose}>
                <CloseIcon />
            </Button>
            <Box width={size??600}>
                {children}
            </Box>
        </Drawer>
    )
}

export default SideDrawer;