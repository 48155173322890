import {useEffect, useState} from "react";
import {useTranslate} from 'react-admin';
import {CircularProgress, Box} from "@material-ui/core";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import {OrderStatsInPeriod} from '../../config/types';
import CardWithIcon from "./CardWithIcon";
import axiosForApi from "../../config/axios-for-api";
import StatsOrdersTable from "./StatsOrdersTable";


const StatsOrdersInLastDays = ({countries}: { countries: string[] | null }) => {
    const translate = useTranslate();
    const [shouldFetchStats, setShouldFetchStats] = useState<boolean>(false);
    const [stats, setStats] = useState<OrderStatsInPeriod>({
        results: [],
        nrOrders: 0,
        sumTotalOrders: 0,
        formattedSumTotalOrders: 0
    });
    const [showLoading, setShowLoading] = useState<boolean>(true);
    const [nextDate, setNextDate] = useState<string | null>(null);

    useEffect(() => {
        let cancel = false;

        const fetchStats = async () => {
            try {
                let params = undefined;
                if (nextDate) params = {
                    params: {
                        'start_date': nextDate,
                        countries
                    }
                }

                const res = await axiosForApi.get(`api/admin/stats-orders/in-last-days`, params);
                !cancel && setStats(res.data);
            } catch (err) {
            }

            if(!cancel) {
                setShouldFetchStats(false);
                setShowLoading(false);
            }
        };

        shouldFetchStats && fetchStats();

        return () => {
            cancel = true;
        }

    }, [shouldFetchStats, nextDate, countries]);

    const handleShowMore = () => {
        setNextDate(stats.nextDate || null);
        setShouldFetchStats(true);
    }

    useEffect(() => {
        setShowLoading(true)
        setShouldFetchStats(true);
    }, [countries]);

    return (
        <CardWithIcon
            to="admin/orders"
            icon={MonetizationOnIcon}
            title={translate('resources.admin/dashboard.orders')}
            subtitle={translate('resources.admin/dashboard.ordersLastDays')}
            showButton={false}
        >
            {showLoading ? (
                <Box display={'flex'} justifyContent={'center'} p={2}>
                    <CircularProgress/>
                </Box>
            ) : <StatsOrdersTable stats={stats} handleShowMore={handleShowMore}/>}
        </CardWithIcon>
    );
};

export default StatsOrdersInLastDays;
