import {Link, useTranslate} from "react-admin";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TableFooter,
    Box,
    Button
} from "@material-ui/core";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import {OrderStatInPeriod, OrderStatsInPeriod} from "../../config/types";
import {useState} from "react";

const StatsOrdersTable = ({stats, handleShowMore}: { stats: OrderStatsInPeriod, handleShowMore: () => void }) => {
    const translate = useTranslate();
    const ths = ['formattedStatDate', 'nrOrders', 'formattedSumTotalOrders'];
    const [limitList, setLimitList] = useState(true);

    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {ths.map((key, i) => (
                                <TableCell key={i}>
                                    {translate(`resources.admin/dashboard.results.${key}`)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stats.results.map((result: OrderStatInPeriod, i: number) => !(limitList && i > 9) ? (
                            <TableRow key={i}>
                                {ths.map((key: unknown, j: number) => (
                                    <TableCell key={j}>
                                        <>
                                            <Link to={`${result.filterLink}`}>
                                                <Typography variant={'body2'} color={'textSecondary'}>
                                                    {result[key as keyof typeof result] ?? '-'}
                                                </Typography>
                                            </Link>
                                        </>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ) : null)}
                    </TableBody>
                    <TableFooter>
                        <TableRow style={{backgroundColor: '#fafafa'}}>
                            <TableCell>
                                    SUM Listed
                            </TableCell>
                            <TableCell>
                                <Typography variant={'body2'} color={'textSecondary'}>
                                    {stats.nrOrders}
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant={'body2'} color={'textSecondary'}>
                                    {stats.formattedSumTotalOrders}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Box p={2} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                {stats.results.length > 10 && (
                  <>
                      <Box mr={2}>
                          {!limitList && (
                              <Button variant={'outlined'} color={'primary'} onClick={handleShowMore}>
                                  <Box display={'inline-flex'} mr={1}>
                                      <PlaylistAddIcon fontSize={'small'} />
                                  </Box>
                                  {translate('resources.admin/dashboard.see_more')}
                              </Button>
                          )}
                      </Box>
                      <Button variant={'contained'} color={'primary'} onClick={() => setLimitList(!limitList)}>
                          <Box display={'inline-flex'} mr={1}>
                              {limitList ? <ArrowDownwardIcon fontSize={'small'}/> : <ArrowUpwardIcon fontSize={'small'}/>}
                          </Box>
                          {translate(`resources.admin/dashboard.${limitList ? 'show_more' : 'show_less'}`)}
                      </Button>
                  </>
                )}
            </Box>
        </>
    );
}

export default StatsOrdersTable;
