import {FC} from 'react';
import {Edit, EditProps, SimpleForm, TextInput, TranslatableInputs} from 'react-admin';

import Title from '../../components/list/Title';
import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps} from "../../functions/common";
import cfgLocales from '../../config/locale';

const VideoCategoryEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit{...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar}>
                <Title source="title" label="resources.admin/video-categories.titles.edit"/>
                <TranslatableInputs locales={Object.keys(cfgLocales.languageOptions)} defaultLocale={cfgLocales.defaultLocale}>
                    <TextInput fullWidth source='name' label={'resources.admin/video-categories.fields.name'}/>
                </TranslatableInputs>
            </SimpleForm>
        </Edit>
    );
};

export default VideoCategoryEdit;
