import GavelIcon from '@material-ui/icons/Gavel';
import FoilList from './FoilList';

const resource = {
    list: FoilList,
    icon: GavelIcon,
    options: {
        label: 'resources.admin/lod-foils.name'
    }
};

export default resource;
