import {TranslationMessages} from 'react-admin';
import germanMessages from './ra-language-german';

const customGermanMessages: TranslationMessages = {
    ...germanMessages,
    loading: 'Loading. Please wait ...',
    pos: {
        configuration: 'Settings',
        language: 'Language',
        languageOptions: {
            english: 'en',
            german: 'de',
            french: 'fr',
            italian: 'it',
            spanish: 'es',
            russian: 'ru',
            swedish: 'se',
        },
        buttons: {
            save: 'Save',
            delete: 'Delete',
            close: 'Close',
        },
    },
    twoFA: {
        bannerTitle: "Set up two-step verification",
        bannerShortText: "Consider enhancing your account security by enabling Two-Factor Authentication (2FA). This feature provides an extra layer of protection for your account.",
        bannerButtonText: "Setup Two-Factor Authentication",
        bannerButtonDismissText: "Not Interested",
        infoTitle: "Two-step verification",
        infoShortText: "A password alone isn't always the best protection against potential hackers. We recommend setting up two-step verification to ensure that no one else can access your account, even if your password is compromised.",
        infoText: "A password alone isn't always the best protection against potential hackers. We recommend setting up two-step verification to ensure that no one else can access your account, even if your password is compromised. <br /> <br /> Two-step verification provides an extra layer of security by asking you to enter a code sent to your smartphone when you log in. This lets us know it's really you. Setting up two-step verification is quick and easy.  ",
        infoButtonText: "Setup Two-Factor Authentication",
        recoveryCodesTitle: "Backup recovery codes",
        recoveryCodesInfo: "Use one of the codes listed to log in if you lose access to your authenticator device. Each one may be used only once.",
        recoveryCodesDownload: "Download Recovery Codes",
        qrCodeTitle: "Scan QR Code or enter key",
        qrCodeText: "Scan the code below with your authenticator app to add this account. Some authenticator apps also allow you to type in the text version instead.",
        qrCodeInfo: "Don't have an authentication app yet? Download one the authentication app that is recommended by your company. If your free to choose, you could use for example Google Authenticator.",
        qrCodeAlternativeKey: "QR alternative key",
        qrCodeConfirmCode: "Confirm key",
        disableTwoFATitle: "Disable Two-Step Verification",
        disableTwoFAText: "<strong class='primaryColor'>Are you sure you want to disable Two-Step Authentication?</strong> <br><br> Please remember that your account will be less secure and more susceptible to unauthorized access. <br> We highly recommend keeping Two-Step Authentication enabled for enhanced security. <br><br> If you still wish to proceed, click <strong>'Confirm'</strong>, otherwise click <strong>'Cancel'</strong> to maintain your current security settings.",
        disableTwoFAConfirmText: "Confirm",
        disableTwoFACancelText: "Cancel",
        enterTwoFACodeTitle: "Enter code from Authenticator App",
        enterTwoFACodeText: "Enter the code from your authenticator app below to verify and activate two-factor authentication for this account.",
        enterTwoFACodeError: "Invalid key Added",
        enterTwoFACodeButtonText: "Confirm key",
        enableTwoFAButtonText: "Setup Two-Factor Authentication",
        disableTwoFAButtonText: "Remove Two-Step Authentication",
        twoFAButtonActivationSuccess: "Two-step verification has been activated",
        active: "Active",
        confirm: "Confirm",
        cancel: "Cancel",
        notificationHeader2FATitle: "Set up two-step verification",
        notificationHeader2FAText: "Consider enhancing your account security by enabling Two-Factor Authentication (2FA). This feature provides an extra layer of protection for your account.",
        notificationHeader2FAButtonText: "Setup Two-Factor Authentication",
        notificationHeader2FADismissText: "Do not display this message anymore.",
        confirmLeave2FASetupTitle: "Leave Two-Factor Authentication Setup",
        confirmLeave2FASetupText: "<strong class='primaryColor'>Are you sure you want to leave Two-Step Authentication setup process?</strong> <br><br> Please remember that your account will be less secure and more susceptible to unauthorized access. <br> We highly recommend keeping Two-Step Authentication enabled for enhanced security. <br><br> If you still wish to proceed, click <strong>'Confirm'</strong>, otherwise click <strong>'Cancel'</strong> to continue with the setup.",
        errorFetchingRecoveryCodes: "Error fetching recovery codes",
        loginTwoFATitle: "Verification code",
        loginTwoFAText: "Enter the code from your authenticator app",
        loginTwoFANotFound: "I don't have a verification code",
        loginTwoFARestoreAccessTitle: "Restore access",
        loginTwoFARestoreAccessText: "Enter a  recovery code you received when you first set up two-factor authentication.",
        loginTwoFARestoreAccessNotFound: "I can't find my recovery code",
        loginTwoFAContactSupportTitle: "Contact Support",
        loginTwoFAContactSupportText: "To recover your account without your 2-factor authentication recovery code, please contact our support team directly. They are equipped to validate your identity and assist with regaining access to your account. Your patience and understanding are appreciated.",
        loginTwoFAContactSupportUrl: "https://www.murrplastik.de/en/contact/"
    },
    confirmPassword: {
        title: "Confirm Password",
        text: "Please confirm your password to continue.",
        passwordLabel: "Password",
        confirm: "Confirm",
        cancel: "Cancel",
        success: "Password successfully updated.",
        fail: "Incorrect password",
    },
    global: {
        further: "Next",
        back: "Back",
        backToShop: "Back to Shop",
        adminLogin: "murrplastik admin",
        forgotPassword: "Forgot password",
        loginLink: "Login",
        support: "Support",
    },
    forgotPassword: {
        successTitle: "Password reset link sent",
        resetPasswordButton: "Reset Password",
        sendButton: "Send",
        loginLink: "Login",
        sendAgain: "Send again",
        toLogin: "To login",
        contactSupport: "Need help? Please contact our ",
        headline: "Have you forgotten your password? We're here to help! We'll send you an email with a link to change your password. Please enter your email address and press \"Send\".",
        successMessage: "Thank you for your message.<br /> You will receive an email from us with an activation link. Please click the link in your email to change your password. <strong>Check your emails</strong>",
        alreadyRegistered: "Already a customer?",
        email: "Email Address*",
        emailPlaceholder: "Enter e-mail address",
        support: "Support",
    },
    resetPassword: {
        title: "Reset Password",
        loginLink: "Login",
        newPassword: "New Password",
        repeatPassword: "Repeat Password",
        submit: "Submit",
        successTitle: "Password changed",
        successMessage : "Your password was successfully changed.",
        passwordAlreadyUsedInThePast : "Password update not possible. You have already used this password. Please use a new password.",
    },
    errors: {
        invalid_record: 'Invalid record!'
    },
    resources: {
        'admin/dashboard': {
            name: 'Dashboard',
            orders: 'Shop Orders',
            ordersLastDays: 'last days',
            ordersLastMonths: 'last months',
            users: 'Users',
            customers: 'Customers',
            products: 'Products',
            see_all: 'See All',
            see_more: 'See More',
            show_more: 'Expand',
            show_less: 'Shrink',
            offers: 'Offers',
            results: {
                id: 'Id',
                sap_order_id: 'Sap Order Id',
                cart_id: 'Cart Id',
                total: 'Total (€)',
                total_formatted: 'Total (€)',
                created_at: 'Created At',
                status: 'Status',
                first_name: 'First Name',
                last_name: 'Last Name',
                email: 'Email',
                name: 'Name',
                nrOrders: 'No. Orders',
                formattedSumTotalOrders: 'Total (€)',
                formattedStatDate: 'Date',
                formattedCreatedAt: 'Created At',
                formattedTotal: 'Total (€)',
                company: 'Company',
            },
            'headers': {
                'total-revenue-this-year': `Revenue ${new Date().getFullYear()} (net / total)`,
                'total-revenue-this-month': `Current Month (net / total)`,
                'total-registered-customers-all-time': `Registered Customers`,
                'total-registered-customers-now': `New customers Month / Total`,
                'average-revenue-by-nr-orders-last-12months': `⌀ Cart (net / total)`,
                'average-revenue-by-month-last-12months': `⌀ Revenue p.m (net / total)`,
                'average-revenue-by-subscriptions-last-12-months': `Subscriptions / Volume (p.a.)`,
                'offers-orders-conversion-rate-last-12-months': `Offers / Orders from Offers / Conversion Offer2Order`,
                'revenue-this-year': `Revenue Development per Month`,
                'total-revenue-by-main-category-this-month': `Revenue Current Month by Product Area`,
                'help.revenue-by-year': `Sales development per calendar year`,
                'total-revenue-by-main-category-this-year': `Revenue ${new Date().getFullYear()} by Product Area`,
                'nr_active_users_by_country': `Number of Active Users by Country`,
                'top-products-by-revenue-this-year': `Revenue Top 5 Products (${new Date().getFullYear()})`,
                'abandoned-carts': 'Abandoned Carts in last 30 days',
                'nr_orders_from_abandoned_carts': 'Nr. orders from abandoned carts',
                'nr_abandoned_carts': 'Nr. abandoned carts',
                'nr_lost_orders': 'Nr. lost orders',
                'recover_rate': 'Recover Rate'
            },
            'help': {
                //Umsatz 2022 (net / total)
                'total-revenue-this-year': `Revenue for all orders that meet the criteria:
                    - Status = processed
                    - Source = Shop
                    - Order creation year = current year
                `,
                //Aktueller Monat (net / total)
                'total-revenue-this-month': `Revenue for all orders that meet the criteria:
                    - Status = processed
                    - Source = Shop
                    - Order creation year = current year
                    - Order creation month = current month`,
                //Angemeldete Kunden
                'total-registered-customers-all-time': `NUMBER of all users that meet the following criteria:
                    - user role = customer
                    - User status = active
                    - User registration source = Shop`,
                //Neukunden Monat / Total
                'total-registered-customers-now': `**NUMBER** of all users that meet the following criteria:
                    - User role = customer
                    - User status = active
                    - User registration source = Shop
                    - User registration month/year = current month/year`,
                //⌀ Warenkorb (net / total)
                'average-revenue-by-nr-orders-last-12months': `**AVG = REVENUE / Number Orders** of orders that meet the following criteria:
                    - Order created between now - 12 months 
                    - Order Source = Shop
                    - Order Status = processed`,
                //⌀ Umsatz p.m (net / total)
                'average-revenue-by-month-last-12months': `**AVG= SUM REVENUE  / 12** from orders that meet the following criteria:
                    - Orders, created between now - 12 months
                    - Source of order = Shop
                    - Order status = processed`,
                //Abos / Volumen (p.a.)
                'average-revenue-by-subscriptions-last-12-months': `**Subscriptions**
                    - Identify all cart items that have a frequency (= subscription) in the period now - 12 months
                    - Calculate average value = Total subscriptions / 12
                **Revenue (p.a.)**
                    - Identify all cart items that have a frequency in the period now - 12 months (= subscription)
                    - Calculate average = Revenue (total items) / number subscriptions`,
                //Angebote / Bestellungen aus Angeboten / Conversion Angebot2Bestellung
                'offers-orders-conversion-rate-last-12-months': `**Offers**: Number of offers that meet the following criteria:
                    - Offers created between now - 12 months and now with a certain cart
                    ---
                    **Orders from Offers**: Number of Orders that meet the following criteria:
                    - Orders, created between now - 12 months
                    - Order status = processed
                    - Order source = Shop
                    - Cart = all
                    ---
                    **Conversion Rate**: Number of Orders from Offers / Number of Offers* 100`,

                //Umsatzentwicklung pro Monat
                'revenue-this-year': `a) Group orders by month in the current year with the following order criteria:
                    - Status = processed
                    - Source = Shop
                    - Order creation year = current year
                    b) SUM (total) for each order group
                    c) Calculate the increase ratio for each group to the sum of the previous month`,
                 
                //Umsatz Akt. Monat nach Produktbereich
                'total-revenue-by-main-category-this-month': `a) for each order - we calculate a total sum per product
                    e.g.:
                    - Product A, Total Amount: 1000 eur, Main Category: ACS
                    - Product B, Total Amount: 2000 eur, Main Category: ACS
                    b) All ordered products grouped by product main category 
                    c) For each group, the sum (total amount) of all product total amounts calculated in point a)
                    d) The ordered products should be part of orders that meet the following criteria:
                        - Order Source = Shop
                        - Order Status = processed
                        - Order creation year/month = current year/month`,

                //Umsatzentwicklung pro Kalenderjahr
                'revenue-by-year': `a) Grouping of orders by year, orders meet the following criteria:
                    - Status = processed
                    - Source = Shop
                    b) Revenue for each order group
                    c) Calculate for each group the increase ratio to the total revenue of the previous year`,

                //`Umsatz 2022 nach Produktbereich
                'total-revenue-by-main-category-this-year': `a) For each order - we calculate a total sum per product
                    e.g.:
                    - Product A, Total Amount: 1000 eur, Main Category: ACS
                    - Product B, Total Amount: 2000 eur, Main Category: ACS
                    b) All ordered products grouped by product main category 
                    c) For each group, the sum (total amount) of all product total amounts calculated in point a)
                    d) The ordered products should be part of orders that meet the following criteria:
                        - Order Source = Shop
                        - Order Status = processed
                        - Order creation year/month = current year`,

                //Umsatz Top 5 Produkte (2022)
                'top-products-by-revenue-this-year': `a) for each order we calculate a total sum by product
                    b) All ordered products grouped by product_id
                    c) For each group, the sum of all product total amounts calculated in point a)
                    d) The ordered products should be part of orders that meet the following criteria:
                        - Order Source = Shop
                        - Order Status = processed
                        - Order creation year = current year`,
                //Nr active users by country
                'nr_active_users_by_country': `Number of active users by country`,
                'abandoned-carts': `a) nr lost orders: number of carts with a status other than "processed" that were abandoned in the last 30 days
                    b) nr abandoned carts: number of carts abandoned in the last 30 days
                    c) nr orders from abandoned carts: orders with the status "processed" created in the last 30 days from a cart marked as "abandoned"
                    d) recovery rate: (nr orders from abandoned carts / nr abandoned carts) * 100
                `
            },
            placeholders: {
                all_countries: 'All countries',
                countries: 'Countries'
            },
        },
        'admin/users': {
            name: 'Users',
            last_login: 'Last Login',
            from: 'From',
            action_buttons: {
                sap_activate: 'SAP activate',
            },
            fields: {
                name: 'Name',
                sap_status: 'Sap Status',
                email_verified_at: 'Verified At',
                first_name: 'First Name',
                last_name: 'Last Name',
                email: 'Email',
                company: 'Company',
                send_reset_password_link: 'Send Reset Password Link',
                send_email_confirmation_link: 'Send Email Confirmation Link',
                email_verified: 'Email Verified',
                country: 'SAP/VKORG country'
            },
            roleOptions: {
                admin: 'Admin',
                sap: 'Sap',
                manager: 'Manager',
                customer: 'Customer',
                "location-manager": "Location Manager",
                "murr-intern": "Murr-Intern",
                "content-manager": "Content Manager",
                "country-manager": "Country Manager",
                "sales-manager": "Sales Manager",
                "sales-professional": "Sales Professional",
                "partner": "Partner",
                "account-manager": "Account Manager",
                "catalog-customer": "Catalog Customer"
            },
            statusOptions: {
                active: 'Active',
                inactive: 'Inactive'
            },
            fieldGroups: {
                identity: 'Identity',
                password: 'Password',
                sap_data: 'Sap Data',
                invoice_address: 'Billing Address',
                client_info: 'Client Info'
            },
            fieldButtons: {
                reset_password: 'Reset Password',
                email_confirmation_link: 'Email Confirmation Link'
            },
            page: {
                delete: 'Delete User',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
            titles: {
                edit_user: 'Edit user #%{info}',
            },
            messages: {
                are_you_sure_you_want_to_sent_the_reset_password_link: 'Are you sure you want to send the reset password link?',
                reset_password_link_sent: 'Resent password link sent with success',
                are_you_sure_you_want_to_sent_email_confirmation_link: 'Are you sure you want to send the email confirmation link?',
                email_confirmation_link_sent: 'Email confirmation link sent with success',
                are_you_sure_you_want_to_manually_confirm_the_user_email: 'Are you sure you want to manually confirm the user\'s email?',
                email_confirmed: 'Email confirmed with success',
                sap_activation_success: 'User activated with success',
                sap_activation_fail: 'User activation failed'
            }
        },
        'admin/posts': {
            name: 'Posts',
            view: 'View Posts',
            create: 'Create Post',
            fields: { //these are used in the forms
                author: 'Author',
                role: 'Role',
                slug: 'Slug',
                status: 'Status',
                content: 'Content',
                title: 'Title',
                subtitle: 'Subtitle',
                id: 'Id',
                teaser_category_id: 'Category ID',
                bg_color: 'Background Color',
                bg_image_src: 'Background Image Src',
                text: 'Text',
                button_url: 'Button Url',
                button_text: 'Button Text',
                image_src: 'Image Src',
                image_path: 'Image Path',
                hierarchy: 'Hierarchy',
                template: 'Template',
                available_from: 'Available From',
                available_to: 'Available To',
                price: 'Price',
                nr_variations: 'Nr. Variations',
                visitor_countries: 'Visitor Countries',
                meta: {
                    price: 'Price',
                    button_text: 'Button text',
                    button_url: 'Button url',
                    title: 'Title',
                    image_src: 'Image src',
                    image_path: 'Upload image',
                    categories: 'Categories',
                    seo_title: 'SEO Title',
                    seo_description: 'SEO Description',
                    seo_keywords: 'SEO Keywords',
                    seo_robots: 'Robots',
                    slides: 'Slides',
                    slide_duration: 'Slide duration (seconds)',
                }
            },
            statusOptions: {
                publish: 'Publish',
                draft: 'Draft'
            },
            typeOptions: {
                page: 'Page',
                content: 'Content',
                modal: 'Modal'
            },
            translationStatusOptions: {
                active: 'Active',
                inactive: 'Inactive'
            },
            section: {
                seo: 'Seo',
                post_info: 'Post Info',
                meta: 'Meta'
            },
            titles: {
                edit_post: 'Edit post #%{id}'
            },
            buttons: {
                show_on_shop: 'Show on Shop'
            },
            info: {
                available_from: 'Choose date from when will be published',
                available_to: 'Choose date till when will be published',
            }
        },
        'admin/products': {
            name: 'Products',
            titles: {
                show_product: 'Show product: %{name}',
                product_variations: 'Product Variations'
            },
            buttons: {
                show_on_shop: 'Show on Shop'
            },
            fields: {
                id: 'Id',
                name_or_id: 'Name or Id',
                top_seller: 'Top Seller',
                created_at: 'Created At',
                name: 'Name'
            }
        },
        'admin/variations': {
            fields: {
                name: 'Name',
                price: 'Price',
                matnr: 'OrderId'
            }
        },
        'admin/orders': {
            name: 'Orders',
            fields: {
                product_id: 'Product#',
                matnr: 'Matnr',
                product: 'Product',
                manager: 'Manager',
                customer: 'Customer',
                qty: 'Qty',
                price: 'Price',
                variation: 'Variation',
                customer_name_or_email: 'Customer name / email',
                from: 'From',
                to: 'To',
                total: 'Total',
                created_at: 'Created At',
                cad_url: 'CAD',
                print_url: 'Print',
                pending: 'Pending',
                processed: 'Processed',
                payment_status: 'Payment status',
                sap_order_id: 'SAP Order ID',
                status: 'Status',
                source: 'Source',
                payment_method: 'Payment method',
                unzer_short_id: 'Unzer Short ID',
                unzer_payment_id: 'Unzer Payment ID',
                shipping_date: 'Shipping Date',
                shipping_date_notification_action: 'Shipping date action',
                approve_shipping_date: 'Send approve shipping date',
                reject_shipping_date: 'Send reject shipping date',
            },
            badge: {
                sample: 'Sample',
            },
            titles: {
                edit_order: 'Order #%{id}'
            },
            buttons: {
                submit_to_sap: 'Submit to SAP'
            }
        },
        'admin/translations': {
            name: 'Translations',
            buttons: {
                save_translation: 'Save translation',
                publish_translation: 'Publish translation',
                reset: 'Reset',
                remove: 'Remove language',
                translate_field: 'Translate field',
                add_new_language: 'Add new language',
            },
            messages: {
                translation_saved: 'Translation saved with success!',
                translation_not_saved: 'Error! Translation not saved!',
                translation_reset: 'Translation reset with success!',
                translation_not_reset: 'Error! Translation not reset!',
                translation_published: 'Translation published with success!',
                translation_not_published: 'Error! Translation not published!',
                you_have_unsaved_changes: 'Warning! Your have unsaved translations, are you sure your want to continue?',
                language_added: 'Language added successfully.',
                language_not_added: 'Error! Language was not added.',
                cannot_remove_de_or_only_language: 'DE langauge cannot be removed.',
                removed_language: 'Error! Language removed successfully.',
                failed_removed_language: 'Language was not removed',
                please_select_language_from_and_to: 'Please select new language and template language.',
            
            },
            fields: {
                all_locales: "Choose new language!",
                template_language: "Choose template language!"
            }
        },
        'admin/mail-templates': {
            name: 'Mail Templates',
            fields: {
                subject: 'Subject',
                cc: 'CC',
                to: 'TO',
                bcc: 'BCC',
                to_localized: 'TO (localized)',
                to_localized_country: 'Country',
                to_localized_email: 'Email',
                to_localized_locale: 'Locale'
            },
            titles: {
                edit_mail_template: 'Edit mail template #%{id}'
            },
            statusOptions: {
                active: 'Active',
                draft: 'Draft'
            },
        },
        'admin/backups': {
            name: 'Backups',
            fields: {
                path: 'Path',
            },
            buttons: {
                restore: 'Restore'
            },
            messages: {
                'restore_with_success': 'Restore has been scheduled with success',
                'restore_failed': 'Restore failed'
            }
        },
        'admin/lod': {
            name: 'LOD',
        },
        'admin/lod-colors': {
            name: 'Colors',
            titles: {
                create: 'Add new Color',
                edit: 'Edit Color: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-foils': {
            name: 'Foils',
            titles: {
                create: 'Add new Foil',
                edit: 'Edit Foil: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-thickness': {
            name: 'Thickness',
            titles: {
                create: 'Add new Thickness',
                edit: 'Edit Thickness: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-materials': {
            name: 'Materials',
            titles: {
                create: 'Add new Material',
                edit: 'Edit Material: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-holes': {
            name: 'Holes',
            titles: {
                create: 'Add new Hole',
                edit: 'Edit Hole: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-shapes': {
            name: 'Shapes',
            titles: {
                create: 'Add new Shape',
                edit: 'Edit Shape: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-price-matrix': {
            name: 'Price Matrix',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                signs: 'signs'
            }
        },
        'admin/lod-raw-material-prices': {
            name: 'Raw Material Prices',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                color: 'Color',
                foil: 'Foil',
                price: 'Price'
            }
        },
        'admin/lod-hole-image-shape': {
            name: 'Hole/Image/Shape',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            orientation: {
                landscape: 'Landscape',
                portrait: 'Portrait'
            },
            fields: {
                image: 'Image',
                shape: 'Shape',
                hole: 'Hole',
                mass_1: 'L',
                mass_2: 'B',
                mass_3: 'D',
                mass_4: 'd',
                mass_5: 'R',
                mass_6: 'x',
                mass_7: 'y',
                mass_8: 'X1',
                mass_9: 'X2',
                mass_10: 'Y1',
                mass_11: 'Y2'
            }
        },
        'admin/lod-material-configs': {
            name: 'Material Configs',
            titles: {
                edit: 'Edit material config',
                create: 'Create material config'
            },
            sections: {
                gewicht: 'Gewicht',
                mattenbreite: 'Mattenbreite',
                menge: 'Menge',
                wkz: 'WKZ',
                rohmaterial: 'Rohmaterial',
                rabatt: 'Rabatt'
            },
            status: {
                'active': 'Active',
                'inactive': 'Inactive'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                gewicht: {
                    matte_248x248_in_gramm: 'Matte 248x248 in Gramm',
                    pro_mm2_in_g: 'Pro mm2 in g'
                },
                wkz: {
                    '1_klein': 'WKZ 1 (klein)',
                    '2_gross': 'WKZ 2 (gross)',
                    'umfang': 'WKZ Umfang'
                },
                foil_id: "Foil",
                color_id: "Color",
                extra_charge: "Extra charge"
            }
        },
        'admin/lod-images': {
            name: 'Images',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                image_path: 'Image file'
            }
        },
        'admin/lod-price-list': {
            name: 'Price list',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {}
        },
        'admin/lod-price-list-factors': {
            name: 'Price list Factors',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                price_list: 'Price list'
            }
        },
        'admin/lod-quellwerk': {
            name: 'Quellwerk',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {}
        },
        'admin/offers': {
            name: 'Offers',
            fields: {
                total: 'Total',
                created_at: 'Created At',
                company: 'Company',
                processed: 'Processed',
                active: 'Active',
                expired: 'Expired',
                cart_status: 'Cart Status',
                client_offer_id: 'Client-Offer ID',
                cart_id: 'Cart ID',
                offer_type: 'Offer Type'
            },
            buttons: {
                export: 'PDF'
            },
            status_options: {
                active: 'Aktiv',
                expired: 'Abgelaufen',
                processed: 'Verarbeitet',
            },
            is_catalog_offer_options: {
                all: 'All types',
                no: 'Shop',
                yes: 'Catalog'
            }
        },
        'admin/lod-wintyps': {
            name: 'Wintyps',
            titles: {
                create: 'Add new Wintyp',
                edit: 'Edit Wintyp: %{name}'
            },
            fields: {
                blocked_at: 'Blocked',
                used_at: 'Used',
                product: 'Product',
                order_id: 'Order #'
            }
        },
        'admin/shipping-dates': {
            name: 'Shipping Dates',
            titles: {
                shipping_countries: 'Shipping Countries',
                shipping_date: 'Shipping Date',
                create: 'Add new Shipping Date',
                edit: 'Edit Record: %{name}',
            },
            sections: {
                recurrence: 'Recurrence'
            },
            fields: {
                shipping_country_ids: 'Shipping Countries (iso2)',
                is_today_allowed: 'Is today allowed (Order before 09:00)'
            }
        },
        'admin/shipping-countries': {
            name: 'Shipping Countries',
            titles: {
                create: 'Add new Shipping Country',
                edit: 'Edit Shipping Country: %{name}'
            },
            fields: {
                code: 'Country Code (iso 2)'
            }
        },
        'admin/shipping': {
            name: 'Shipping'
        },
        'admin/cob': {
            name: 'COB'
        },
        'admin/cob-cobots': {
            name: 'Cobots',
            fields: {
                manufacturer: 'Manufacturer',
                type: 'Type',
                model: 'Model',
                axis6_tape: 'Axis 6 Tape',
                system_holder_1: 'System Holder #1',
                system_holder_2: 'System Holder #2',
                hose_length: 'Hose Length',
                tension_clamp: 'Tension Clamp',

                //ua
                ua_shs_center: 'Center',
                ua_shs_outside: 'Outside',
                ua_shs_inside: 'Inside',
                ua_tape_center: 'Center',
                ua_tape_outside: 'Outside',
                ua_tape_inside: 'Inside',
                ua_feeding_system: 'Feeding System',

                //lq
                la_shs_center: 'Center',
                la_shs_outside: 'Outside',
                la_shs_inside: 'Inside',
                la_tape_center: 'Center',
                la_tape_outside: 'Outside',
                la_tape_inside: 'Inside',
                la_feeding_system: 'Feeding System'

            },
            titles: {
                create: 'Add New Cobot',
                edit: 'Edit Cobot %{name}'
            },
            fieldGroups: {
                manufacturer_details: 'Manufacturer Details',
                technical_master_data: 'Technical Master Data',
                technical_details_upper_support_arm: 'Technical Details - Upper Arm',
                technical_details_bottom_support_arm: 'Technical Details - Lower Arm',
                shs: 'SHS',
                tape: 'Tape'
            }
        },
        'admin/cob-manufacturers': {
            name: 'Manufacturers',
            titles: {
                create: 'Add New Manufacturer',
                edit: 'Edit Manufacturer: %{name}'
            }
        },
        'admin/cob-types': {
            name: 'Types',
            titles: {
                create: 'Add New Type',
                edit: 'Edit Type: %{name}'
            },
            fields: {
                manufacturer: 'Manufacturer'
            }
        },
        'admin/cob-models': {
            name: 'Models',
            titles: {
                create: 'Add New Model',
                edit: 'Edit Model: %{name}'
            },
            fields: {
                manufacturer: 'Manufacturer',
                type: 'Type'
            }
        },
        'admin/cob-products': {
            name: 'Products',
            titles: {
                create: 'Add New Product',
                edit: 'Edit Product: %{name}'
            },
            fieldGroups: {
                product_details: 'Details',
                product: 'Product'
            },
            fields: {
                name: 'Name',
                nominal_size: 'Nominal size',
                profile: 'Profile',
                configuratorStatus: 'Configurator status'
            },
            group: {
                hose: 'Hose',
                hose_hf: 'Hose Highly Flexible',
                tension_clamp: 'Tension Clamp',
                belt: 'Belt',
                system_holder: 'System Holder',
                end_grommet: 'End Grommet',
                hose_ring_zl: 'Hose Ring Zl',
                hose_ring_fixed: 'Hose Ring Fixed',
                shs: 'SHS',
                fhs_c: 'System Holder FHS-C',
                fhs_uhe: 'System Holder FHS-UHE',
                fhs_sh: 'System Holder FHS-SH',
                options: 'Option',
                velcro: 'Velcro',
                velcro_silicone_free: 'Velcro silicone free',
                stainless_steel_band: 'Stainless Steel Band',
                return_system: 'Return System'
            },
            design: {
                slotted: 'Slotted',
                closed: 'Closed'
            },
            status: {
                active: 'Active',
                inactive: 'Inactive'
            }
        },
        'admin/kdh': {
            name: 'KDH'
        },
        'admin/kdh-frames': {
            name: 'Frames',
            titles: {
                create: 'Add Frame'
            }
        },
        'admin/kdh-grommets': {
            name: 'Grommets',
            titles: {
                create: 'Add Grommet'
            }
        },
        'admin/abandoned-carts': {
            name: 'Abandoned Carts',
            titles: {
                abandoned_at: 'Abandoned at',
                abandoned_notification_1_at: 'Notification #1',
                abandoned_notification_2_at: 'Notification #2',
                abandoned_notification_3_at: 'Notification #3',
                recovered_from_1_at: 'Recovered from #1',
                recovered_from_2_at: 'Recovered from #2',
                recovered_from_3_at: 'Recovered from #3',
                order: 'Order #',
                cart: 'Cart',
            }
        },
        'admin/modals': {
            name: 'Modals',
            view: 'View Modals',
            create: 'Create Modal',
            fields: { //these are used in the forms
                status: 'Status',
                content: 'Content',
                title: 'Title',
                subtitle: 'Subtitle',
                id: 'Id',
                meta: {
                    subtitle: 'Subtitle',
                    button_cta_text: 'Button CTA text',
                    button_cta_url: 'Button CTA url',
                    button_close_text: 'Button CLOSE text',
                    button_close_url: 'Button CLOSE url',
                    newsletter: 'Newsletter',
                    promotion_code: 'Promotion Code',
                    logged_in_only: 'Logged-in users only',
                    datetime_from: 'From',
                    datetime_to: 'To',
                    countries: 'Countries',
                    image_src: 'Image Url',
                    image_path: 'Upload Image',
                    page_type: 'Page Type',
                    extra_info: 'Extra Info',
                    is_prio: 'Prio Message'
                }
            },
            statusOptions: {
                publish: 'Publish',
                draft: 'Draft',
                info: 'Select if content should be published.'
            },
            date: {
                available_from_info: 'The modal will be available from this date on',
                available_to_info: 'The modal will be available until this date',
            },
            templateOptions: {
                default: 'Default',
                newsletter: 'Newsletter',
                coupon_code: 'Coupon Code',
                service_notification: 'Service Notification',
                info: 'Select predefined template for notifications',
            },
            pageTypeOptions: {
                homepage: 'Home',
                category_search: 'Search',
                product_details: 'Product Details',
                info: 'Select if needed to restrict display on specific page type',
            },
            titles: {
                edit_modal: 'Edit modal #%{id}'
            },
            sections: {
                button_cta: 'Button CTA',
                button_close: 'Button CLOSE',
                settings: 'Settings'
            },
            info: {
                available_from: 'Choose date from when will be published',
                available_to: 'Choose date till when will be published',
                image_src: 'Use external url for image',
                image_path: 'Upload local image',
                country: 'Using this function you can restrict the notification to be displayed only for specific users depending on his country',
                button_cta_url: 'Add url to CTA button.',
                button_close_url: 'Add url to close button. If added, url will take effect alongside the close action.',
                is_prio: 'This function will force the notification to be displayed after user has logged in',
                pageTypeOptions: 'Using this function you can restrict the notification to be displayed only on specific pages',
                authenticated_only: 'If selected, this function will restrict the notification to be displayed only for logged in users',
            },
        },
        'admin/permissions': {
            name: 'Permissions',
            titles: {
                edit: 'Edit Permissions for "%{name}"'
            },
            roleOptions: {
                customer: 'Customer',
                'murr-intern': 'Murr Intern '
            },
            permissions: {
                "front-end-manage-orders": "Manage Orders",
                "front-end-manage-wishlists": "Manage Wishlists"
            }
        },
        'admin/visitor-countries': {
            name: 'Visitor Countries',
            titles: {
                create: 'Add new Visitor Country',
                edit: 'Edit Visitor Country: %{name}',
                catalog: 'Catalog',
                murr_contact_info: 'Murr Contact Info'
            },
            fields: {
                code: 'Country Code (iso 2)',
                name: 'Name',
                default_language: 'Default Language',
                vkorg: 'VKORG',
                terms_link: 'General terms and conditions',
                is_catalog_only: 'Is Catalog Only',
                catalog_show_prices: 'Show Prices',
                catalog_price_group: 'Price Group',
                catalog_vat_percentage: 'Vat Percentage',
                catalog_delivery_price: 'Delivery Price',
                catalog_currency: 'Currency',
                murr_contact_info: {
                    company_name: 'Company Name',
                    phone:'Phone',
                    fax: 'Fax',
                    address_line_1: 'Address Line 1 (Street name, Number)',
                    address_line_2: 'Address Line 2 (Zip Code, City)',
                    country: 'Country',
                    url: 'Url'
                }
            },
            statusOptions: {
                active: 'Active',
                inactive: 'Inactive'
            },
            paymentOptions: {
                invoice: 'Invoice',
                unzer: 'Unzer'
            },
        },
        'admin/product-reviews': {
            name: 'Product reviews',
            titles: {
                create: 'Add new Product Review',
                edit: 'Edit Product Review: %{name}'
            },
            fields: {
                headline: 'Headline',
                product: 'Product',
                order_id: 'Order #',
                star_rating: 'Star Rating',
                user: 'User'
            },
            statusOptions: {
                all: 'All',
                open: 'Open',
                publish: 'Publish',
                reject: 'Reject'
            },
        },
        'admin/vouchers': {
            name: 'Vouchers',
            titles: {
                voucher_usage: 'Voucher Usage'
            },
            statusOptions: {
                all: 'All',
                pending: 'Pending',
                sent: 'Sent',
                used: 'Used',
                expired: 'Expired'
            },
        },
        'admin/videos': {
            name: 'Videos',
            titles: {
                create: 'Add new Video',
                edit: 'Edit Video: %{name}',
            },
            fields: {
                title: 'Title',
                description: 'Description',
                url: 'YouTube Video URL',
                cover_photo_path: 'Cover Photo',
                video_category_ids: 'Categories',
                video_playlist_ids: 'Playlists',
                product_ids: 'Products'
            },
            info: {
                youtube_url: 'Must be a YouTube URL that contains the Video ID. Best use the "Share" function on YoutUve to get the correct URL.',
                cover_photo: 'It is not mandatory. Only if you want a different cover photo for your video instead the default preview.',
                product_ids: 'It is not mandatory. Select a product if you want this video to be displayed in the top of the product details page.',
                video_category_ids: 'Select the category your video will belong to.',
                video_playlist_ids: 'Select the playlist your video will belong to.',
                video_configurators: 'It is not mandatory. Select a configurator if you want this video to be displayed in the top of the configurator page.',
                is_recommended: 'Turn this switch ON if you want the video to be displayed in "For you" section on Videos page in Shop.',
                is_highlighted: 'Turn this switch ON if you want the video to be displayed in "Highlights" section on Videos page in Shop.',
                is_active: 'Turn this switch ON if you want the video to be published and become visible in Shop.',
                playlist_category_ids: 'Select the category your playlist will belong to.',
                playlist_cover_photo: 'It is not mandatory. Only if you want a different cover photo for your playlist instead the default preview.',
            }
        },
        'admin/video-playlists': {
            name: 'Video Playlists',
            titles: {
                create: 'Add new Video Playlist',
                edit: 'Edit Video Playlist: %{name}',
            },
            fields: {
                name: 'Name',
                cover_photo_path: 'Cover Photo'
            },
        },
        'admin/video-categories': {
            name: 'Video Categories',
            titles: {
                create: 'Add new Video Category',
                edit: 'Edit Video Category: %{name}',
            },
            fields: {
                name: 'Name',
            },
        },
        'admin/gs-flows': {
            name: 'Flows',
        },
    },
};

export default customGermanMessages;
