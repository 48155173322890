import * as React from 'react';
import {FunctionComponent, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {
    Box,
    Button,
    CardActions,
    CircularProgress,
    TextField, Typography,
} from '@material-ui/core';
import {useTranslate, useLogin, useNotify, useSafeSetState} from 'ra-core';
import {useHistory} from 'react-router-dom';

import axios from "../config/axios-for-api";
import TwoFALoginForm from "./TwoFALoginForm";
import {handleError} from "../functions/common";
import {loginForm} from "../styles/ui/loginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import IconEyeOpen from '../assets/icons/eye-open-white.svg';
import IconEyeClosed from '../assets/icons/eye-closed-white.svg';

interface Props {
    redirectTo?: string;
}

interface FormData {
    username: string;
    password: string;
}

const Input = ({
       // @ts-ignore
       meta: {touched, error}, // eslint-disable-line react/prop-types
       // @ts-ignore
       input: inputProps, // eslint-disable-line react/prop-types
       ...props
   }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const LoginForm: FunctionComponent<Props> = props => {
    //const {redirectTo} = props;
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const translate = useTranslate();
    const notify = useNotify();
    const classes = loginForm();

    const [twoFA, setTwoFA] = useSafeSetState(false);
    const [forgotPassword, setForgotPassword] = useSafeSetState(false);
    const [userData, setUserData] = useSafeSetState();
    const [shouldDoLogin, setShouldDoLogin] = useSafeSetState(false);
    const [showPassword, setShowPassword] = useSafeSetState(false);

    const history = useHistory();

    const doLogin = useCallback(() => {
        login(userData).then(() => {
            history.push("/");
        }).catch(error => {
            setLoading(false);
            notify(handleError(error), 'error');
        });
    },[history, login, notify, setLoading, userData]);

    useEffect(() => {
        shouldDoLogin && doLogin();
    }, [shouldDoLogin, doLogin]);

    const validate = (values: FormData) => {
        const errors: { username: undefined | string, password: undefined | string } = {username: undefined, password: undefined};

        if (!values.username) {
            errors.username = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };

    const submit = (values: any) => {
        setLoading(true);
        axios.get('/sanctum/csrf-cookie').then(response => {
            return axios.post('/login', {
                email: values.username, password: values.password
            }).then(response => {
                const data = response.data;
                setUserData(data);

                if (data?.two_factor === true) {
                    setTwoFA(true);
                } else {
                    setShouldDoLogin(true);
                }
            }).catch(error => {
                setLoading(false);
                notify(handleError(error), 'error');
            });
        });
    };

    const handleTwoFAFailure = () => {
        setTwoFA(false)
        notify(`You entered the wrong 2FA code`, 'error');
        setLoading(false);
    }

    return twoFA ? (
            <TwoFALoginForm onVerify={doLogin} onFail={handleTwoFAFailure}/>
    ) : forgotPassword ? (
            <ForgotPasswordForm setForgotPassword={setForgotPassword} />
        ) : (
        <Form
        onSubmit={submit}
        validate={validate}
        render={({handleSubmit}) => (
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.form}>
                    <Typography className={classes.title} variant="h5">
                        {translate('global.adminLogin')}
                    </Typography>

                    <div className={classes.input}>
                        <Field
                            id="username"
                            name="username"
                            component={Input}
                            label={`${translate('ra.auth.username')}*`}
                            disabled={loading}
                        />
                    </div>
                    <div className={classes.input}>
                        <Field
                            id="password"
                            name="password"
                            component={Input}
                            label={`${translate('ra.auth.password')}*`}
                            type={showPassword ? 'text' : 'password'}
                            disabled={loading}
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <Box
                                        onClick={() => setShowPassword(!showPassword)}
                                        style={{marginRight: 10, display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                                    >
                                        <img
                                            alt="toggle-password-visibility"
                                            width={21}
                                            height={21}
                                            src={showPassword ? IconEyeClosed : IconEyeOpen }
                                        />
                                    </Box>
                                )
                            }}
                        />
                    </div>

                    <Typography
                        variant="caption"
                        className={`${classes.text} isLink`}
                        onClick={() => setForgotPassword(!forgotPassword)}
                    >
                        {translate('forgotPassword.resetPasswordButton')}
                    </Typography>

                </div>
                <CardActions className={classes.actions}>
                    <Button
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={loading}
                        className={classes.button}
                    >
                        {loading && (
                            <CircularProgress
                                className={classes.icon}
                                size={18}
                                thickness={2}
                            />
                        )}
                        {translate('ra.auth.sign_in')}
                    </Button>
                </CardActions>
            </form>
        )}
    />
    )
};

LoginForm.propTypes = {
    redirectTo: PropTypes.string,
};

export default LoginForm;
