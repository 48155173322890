import {TranslationMessages} from 'react-admin';
import germanMessages from './ra-language-german';

const customGermanMessages: TranslationMessages = {
    ...germanMessages,
    loading: 'Cargando. Espere por favor ...',
    pos: {
        configuration: 'Ajustes',
        language: 'Idioma',
        languageOptions: {
            english: 'en',
            german: 'de',
            french: 'fr',
            italian: 'it',
            spanish: 'es',
            russian: 'ru',
            swedish: 'se',
        },
        buttons: {
            save: 'Salvar',
            delete: 'Eliminar',
            close: 'Cerrar',
        },
    },
    errors: {
        invalid_record: '¡Registro no válido!'
    },
    resources: {
        'admin/dashboard': {
            name: 'Cuadro de mandos',
            orders: 'Pedidos en la tienda',
            ordersLastDays: 'últimos días',
            ordersLastMonths: 'últimos meses',
            users: 'Usuarios',
            customers: 'Clientes',
            products: 'Productos',
            see_all: 'Ver todos',
            see_more: 'Más información',
            show_more: 'Ampliar',
            show_less: 'Retráctil',
            offers: 'Ofertas',
            results: {
                id: 'Id',
                sap_order_id: 'Sap Order Id',
                cart_id: 'Id de carro',
                total: 'Total (€)',
                total_formatted: 'Total (€)',
                created_at: 'Creado en',
                status: 'Estado',
                first_name: 'Nombre',
                last_name: 'Apellido',
                email: 'Email',
                name: 'Nombre',
                nrOrders: 'Nr. Pedidos',
                formattedSumTotalOrders: 'Total (€)',
                formattedStatDate: 'Fecha',
                formattedCreatedAt: 'Creado en',
                formattedTotal: 'Total (€)',
                company: 'Empresa',
            },
            'headers': {
                'total-revenue-this-year': `Ventas ${new Date().getFullYear()} (neto / total)`,
                'total-revenue-this-month': `Mes en curso (neto / total)`,
                'total-registered-customers-all-time': `Clientes registrados`,
                'total-registered-customers-now': `Nuevos clientes mensuales / total`,
                'average-revenue-by-nr-orders-last-12months': `⌀ Carro de la compra (neto / total)`,
                'average-revenue-by-month-last-12months' : `⌀ Ventas p.m (neto / total)`,
                'average-revenue-by-subscriptions-last-12-months': `Suscripciones / Volumen (p.a.)`,
                'offers-orders-conversion-rate-last-12-months': `Ofertas / Pedidos a partir de ofertas / Conversión Oferta2Pedido`,
                'revenue-this-year': `Evolución mensual de las ventas`,
                'total-revenue-by-main-category-this-month': `Ventas reales del mes por área de productos`,
                'help.revenue-by-year': `Evolución de las ventas por año civil`,
                'total-revenue-by-main-category-this-year': `Ventas ${new Date().getFullYear()} por área de productos`,
                'nr_active_users_by_country': `Número de usuarios activos por país`,
                'top-products-by-revenue-this-year': `Los 5 productos más vendidos (${new Date().getFullYear()})`,
            },
            'help': {
                //Umsatz 2022 (net / total)
                'total-revenue-this-year': `Ventas para todos los pedidos que cumplen los criterios:
                    - Estado = procesado
                    - Origen = Tienda
                    - Año de creación del pedido = año en curso
                `,
                //Aktueller Monat (net / total)
                'total-revenue-this-month': `Ventas para todos los pedidos que cumplen los criterios:
                    - Estado = procesado
                    - Origen = Tienda
                    - Año de creación del pedido = año en curso
                    - Mes de creación del pedido = mes en curso`,

                //Angemeldete Kunden
                'total-registered-customers-all-time': `**NÚMERO** de todos los usuarios que cumplen los criterios:
                    - Rol de usuario = Cliente
                    - Estado del usuario = activo
                    - Fuente de registro del usuario = Tienda`,
                //Neukunden Monat / Total
                'total-registered-customers-now': `**NÚMERO** de todos los usuarios que cumplen los criterios:
                    - Rol de usuario = Cliente
                    - Estado del usuario = activo
                    - Fuente de registro del usuario = Tienda
                    - Mes/año de registro del usuario = mes/año actual`,
                //⌀ Warenkorb (net / total)
                'average-revenue-by-nr-orders-last-12months': `**AVG = VENTAS / número de pedidos** de pedidos que cumplen los criterios:
                    - Pedido creado entre ahora y hace 12 meses
                    - Origen del pedido = Tienda
                    - Estado del pedido = procesado`,
                //⌀ Umsatz p.m (net / total)
                'average-revenue-by-month-last-12months': `**AVG= VENTAS TOTALES / 12** de pedidos que cumplen los criterios:
                    - Pedido creado entre ahora y hace 12 meses
                    - Origen del pedido = Tienda
                    - Estado del pedido = procesado`,

                //Abos / Volumen (p.a.)
                'average-revenue-by-subscriptions-last-12-months': `**Suscripciones**
                        - Identifique todos los artículos de la cesta de la compra que tienen una frecuencia (= suscripción) en el periodo ahora - 12 meses
                        - Calcular el valor medio = total de suscripciones / 12
                    **Ventas (p.a.)**
                        - Identificar todos los artículos de la cesta de la compra que tienen una frecuencia (= suscripción) en el período actual - 12 meses
                        - Calcular el valor medio = ventas (total de artículos) / número de suscripciones`,

                //Angebote / Bestellungen aus Angeboten / Conversion Angebot2Bestellung
                'offers-orders-conversion-rate-last-12-months': `**Ofertas**: Número de ofertas que cumplen los criterios:
                    - Ofertas creadas entre ahora - 12 meses y ahora con una cesta de la compra específica
                    ---
                    **Pedidos de ofertas**: Número de pedidos que cumplen los criterios:
                    - Pedidos creados entre ahora y hace 12 meses
                    - Estado del pedido = procesado
                    - Origen del pedido = Tienda
                    - Cesta de la compra = todos
                    ---
                    **Tasa de conversión**: Número de pedidos procedentes de ofertas / número de ofertas* 100`,

                //Umsatzentwicklung pro Monat
                'revenue-this-year': `a) Agrupar los pedidos por meses del año en curso con los siguientes criterios de pedido:
                    - Estado = Tramitado
                    - Origen = Tienda
                    - Año de creación del pedido = año en curso
                    b) SUMA (total) de cada grupo de pedidos
                    c) Calcular el incremento de cada grupo respecto a la suma del mes anterior.`,

                //Umsatz Akt. Monat nach Produktbereich
                'total-revenue-by-main-category-this-month': `a) por cada pedido - cobramos un importe total por producto
                    Ej:
                    - Producto A, importe total: 1000 eur, categoría principal: ACS
                    - Producto B, importe total 2000 eur, categoría principal: ACS
                    b) Agrupación de todos los productos pedidos por categoría principal de producto.
                    c) Para cada grupo, la suma (importe total) de todos los totales de productos calculados en el punto a).
                    d) Los productos pedidos deben formar parte de pedidos que cumplan los criterios:
                        - Origen del pedido = Tienda
                        - Estado del pedido = procesado
                        - Año de creación/mes del pedido = año/mes en curso`,

                //Umsatzentwicklung pro Kalenderjahr
                'revenue-by-year': `a) Agrupación de pedidos por año, pedidos con los siguientes criterios:
                    - Estado del pedido = tramitado
                    - Origen del pedido = Tienda
                    b) Ventas de cada grupo de pedidos
                    c) Calcular para cada grupo el incremento respecto al total de ventas del año anterior`,

                //`Umsatz 2022 nach Produktbereich
                'total-revenue-by-main-category-this-year': `a) por cada pedido - cobramos un importe total por producto
                    Ej:
                    - Producto A, importe total: 1000 eur, categoría principal: ACS
                    - Producto B, importe total 2000 eur, categoría principal: ACS
                    b) Agrupación de todos los productos pedidos por categoría principal de producto.
                    c) Para cada grupo, la suma (importe total) de todos los totales de productos calculados en el punto a).
                    d) Los productos pedidos deben formar parte de pedidos que cumplan los criterios:
                        - Origen del pedido = Tienda
                        - Estado del pedido = procesado
                        - Año de creación/mes del pedido = año en curso`,

                //Umsatz Top 5 Produkte (2022)
                'top-products-by-revenue-this-year': `a) para cada pedido cargamos un importe total por producto
                    b) se agrupan todos los productos pedidos por product_id
                    c) para cada grupo, se forma la suma de todos los totales de productos calculados en el punto a)
                    d) Los productos pedidos deben formar parte de pedidos que cumplan los criterios:
                        - Origen del pedido = Tienda
                        - Estado del pedido = procesado
                        - Año de creación del pedido = año en curso`,
                //Nr active users by country
                'nr_active_users_by_country': `Número de usuarios activos por país`
            }
        },
        'admin/users': {
            name: 'Usuarios',
            last_login: 'Última conexión',
            from: 'En',
            fields: {
                name: 'Nombre',
                sap_status: 'Sap Estado',
                email_verified_at: 'Verificado en',
                first_name: 'Nombre',
                last_name: 'Apellido',
                email: 'Email',
                company: 'Empresa',
                send_reset_password_link: 'Enviar enlace para restablecer contraseña',
                send_email_confirmation_link: 'Enviar enlace de confirmación por e-mail',
                email_verified: 'Email verificado'
            },
            roleOptions: {
                admin: 'Admin',
                sap: 'Sap',
                manager: 'Manager',
                customer: 'Customer',
                "location-manager": "Location Manager"
            },
            statusOptions: {
                active: 'Active',
                inactive: 'Inactive'
            },
            fieldGroups: {
                identity: 'Identity',
                password: 'Password',
                sap_data: 'Sap Data',
                invoice_address: 'Billing Address',
                client_info: 'Client Info'
            },
            fieldButtons: {
                reset_password: 'Reset Password',
                email_confirmation_link: 'Email Confirmation Link'
            },
            page: {
                delete: 'Delete User',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
            titles: {
                edit_user: 'Edit user #%{info}',
            },
            messages: {
                are_you_sure_you_want_to_sent_the_reset_password_link: 'Are you sure you want to send the reset password link?',
                reset_password_link_sent: 'Resent password link sent with success',
                are_you_sure_you_want_to_sent_email_confirmation_link: 'Are you sure you want to send the email confirmation link?',
                email_confirmation_link_sent: 'Email confirmation link sent with success',
                are_you_sure_you_want_to_manually_confirm_the_user_email: 'Are you sure you want to manually confirm the user\'s email?',
                email_confirmed: 'Email confirmed with success'
            }
        },
        'admin/posts': {
            name: 'Posts',
            view: 'View Posts',
            create: 'Create Post',
            fields: { //these are used in the forms
                author: 'Author',
                role: 'Role',
                slug: 'Slug',
                status: 'Status',
                content: 'Content',
                title: 'Title',
                subtitle: 'Subtitle',
                id: 'Id',
                teaser_category_id: 'Category ID',
                bg_color: 'Background Color',
                bg_image_src: 'Background Image Src',
                text: 'Text',
                button_url: 'Button Url',
                button_text: 'Button Text',
                image_src: 'Image Src',
                image_path: 'Image Path',
                hierarchy: 'Hierarchy',
                template: 'Template',
                meta: {
                    price: 'Price',
                    button_text: 'Button text',
                    button_url: 'Button url',
                    title: 'Title',
                    image_src: 'Image src',
                    image_path: 'Upload image',
                    categories: 'Categories',
                    seo_title: 'SEO Title',
                    seo_description: 'SEO Description',
                    seo_keywords: 'SEO Keywords',
                    seo_robots: 'Robots'
                }
            },
            statusOptions: {
                publish: 'Publish',
                draft: 'Draft'
            },
            typeOptions: {
                page: 'Page',
                content: 'Content'
            },
            translationStatusOptions: {
                active: 'Active',
                inactive: 'Inactive'
            },
            section: {
                seo: 'Seo',
                post_info: 'Post Info',
                meta: 'Meta'
            },
            titles: {
                edit_post: 'Edit post #%{id}'
            },
            buttons: {
                show_on_shop: 'Show on Shop'
            }
        },
        'admin/products': {
            name: 'Products',
            titles: {
                show_product: 'Show product: %{name}',
                product_variations: 'Product Variations'
            },
            buttons: {
                show_on_shop: 'Show on Shop'
            },
            fields: {
                id: 'Id',
                name_or_id: 'Name or Id',
                top_seller: 'Top Seller',
                created_at: 'Created At',
                name: 'Name'
            }
        },
        'admin/variations': {
            fields: {
                name: 'Name',
                price: 'Price',
                matnr: 'OrderId'
            }
        },
        'admin/orders': {
            name: 'Pedidos',
            fields: {
                product_id: 'Producto#',
                matnr: 'Matnr',
                product: 'Producto',
                manager: 'Manager',
                customer: 'Cliente',
                qty: 'Cantidad',
                price: 'Precio',
                variation: 'Variación',
                customer_name_or_email: 'Nombre del cliente / email',
                from: 'De',
                to: 'A',
                total: 'Total',
                created_at: 'Creado en',
                cad_url: 'CAD',
                print_url: 'Imprimir',
                pending: 'Pendiente',
                processed: 'Procesado',
                payment_status: 'Estado de los pagos',
                sap_order_id: 'ID de pedido en SAP',
                status: 'Estado',
                source: 'Source',
                payment_method: 'Forma de pago',
                unzer_short_id: 'Unzer Short ID',
                unzer_payment_id: 'Unzer Payment ID',
                shipping_date: 'Fecha de envío',
                shipping_date_notification_action: 'Acción de fecha de envío',
                approve_shipping_date: 'Enviar aprobar fecha de envío',
                reject_shipping_date: 'Enviar rechazar fecha de envío',
            },
            badge: {
                sample: 'Muestra',
            },
            titles: {
                edit_order: 'Pedido #%{id}'
            },
            buttons: {
                submit_to_sap: 'Enviar a SAP'
            }
        },
        'admin/translations': {
            name: 'Translations',
            buttons: {
                save_translation: 'Save translation',
                publish_translation: 'Publish translation',
                reset: 'Reset',
                remove: 'Remove language',
                translate_field: 'Translate field',
                add_new_language: 'Add new language',
            },
            messages: {
                translation_saved: 'Translation saved with success!',
                translation_not_saved: 'Error! Translation not saved!',
                translation_reset: 'Translation reset with success!',
                translation_not_reset: 'Error! Translation not reset!',
                translation_published: 'Translation published with success!',
                translation_not_published: 'Error! Translation not published!',
                you_have_unsaved_changes: 'Warning! Your have unsaved translations, are you sure your want to continue?',
                language_added: 'Language added successfully.',
                language_not_added: 'Error! Language was not added.',
                cannot_remove_de_or_only_language: 'DE langauge cannot be removed.',
                removed_language: 'Error! Language removed successfully.',
                failed_removed_language: 'Language was not removed',
                please_select_language_from_and_to: 'Please select new language and template language.',
            },
            fields: {
                all_locales: "Choose new language!",
                template_language: "Choose template language!"
            }
        },
        'admin/mail-templates': {
            name: 'Mail Templates',
            fields: {
                subject: 'Subject',
                cc: 'CC',
                to: 'TO',
                bcc: 'BCC'
            },
            titles: {
                edit_mail_template: 'Edit mail template #%{id}'
            },
            statusOptions: {
                active: 'Active',
                draft: 'Draft'
            },
        },
        'admin/backups': {
            name: 'Backups',
            fields: {
                path: 'Path',
            },
            buttons: {
                restore: 'Restore'
            },
            messages: {
                'restore_with_success': 'Restore has been scheduled with success',
                'restore_failed': 'Restore failed'
            }
        },
        'admin/lod': {
            name: 'LOD',
        },
        'admin/lod-colors': {
            name: 'Colors',
            titles: {
                create: 'Add new Color',
                edit: 'Edit Color: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-foils': {
            name: 'Foils',
            titles: {
                create: 'Add new Foil',
                edit: 'Edit Foil: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-thickness': {
            name: 'Thickness',
            titles: {
                create: 'Add new Thickness',
                edit: 'Edit Thickness: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-materials': {
            name: 'Materials',
            titles: {
                create: 'Add new Material',
                edit: 'Edit Material: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-holes': {
            name: 'Holes',
            titles: {
                create: 'Add new Hole',
                edit: 'Edit Hole: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-shapes': {
            name: 'Shapes',
            titles: {
                create: 'Add new Shape',
                edit: 'Edit Shape: %{name}'
            },
            status: {
                active: 'active',
                inactive: 'inactive',
            }
        },
        'admin/lod-price-matrix': {
            name: 'Price Matrix',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                signs: 'signs'
            }
        },
        'admin/lod-raw-material-prices': {
            name: 'Raw Material Prices',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                color: 'Color',
                foil: 'Foil',
                price: 'Price'
            }
        },
        'admin/lod-hole-image-shape': {
            name: 'Hole/Image/Shape',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            orientation: {
                landscape: 'Landscape',
                portrait: 'Portrait'
            },
            fields: {
                image: 'Image',
                shape: 'Shape',
                hole: 'Hole',
                mass_1: 'L',
                mass_2: 'B',
                mass_3: 'D',
                mass_4: 'd',
                mass_5: 'R',
                mass_6: 'x',
                mass_7: 'y',
                mass_8: 'X1',
                mass_9: 'X2',
                mass_10: 'Y1',
                mass_11: 'Y2'
            }
        },
        'admin/lod-material-configs': {
            name: 'Material Configs',
            titles: {
                edit: 'Edit material config',
                create: 'Create material config'
            },
            sections: {
                gewicht: 'Gewicht',
                mattenbreite: 'Mattenbreite',
                menge: 'Menge',
                wkz: 'WKZ',
                rohmaterial: 'Rohmaterial',
                rabatt: 'Rabatt'
            },
            status: {
                'active': 'Active',
                'inactive': 'Inactive'
            },
            fields: {
                material: 'Material',
                thickness: 'Thickness',
                gewicht: {
                    matte_248x248_in_gramm: 'Matte 248x248 in Gramm',
                    pro_mm2_in_g: 'Pro mm2 in g'
                },
                wkz: {
                    '1_klein': 'WKZ 1 (klein)',
                    '2_gross': 'WKZ 2 (gross)',
                    'umfang': 'WKZ Umfang'
                },
                foil_id: "Foil",
                color_id: "Color",
                extra_charge: "Extra charge"
            }
        },
        'admin/lod-images': {
            name: 'Images',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                image_path: 'Image file'
            }
        },
        'admin/lod-price-list': {
            name: 'Price list',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {}
        },
        'admin/lod-price-list-factors': {
            name: 'Price list Factors',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {
                price_list: 'Price list'
            }
        },
        'admin/lod-quellwerk': {
            name: 'Quellwerk',
            titles: {
                create: 'Add new Record',
                edit: 'Edit Record: %{name}'
            },
            fields: {}
        },
        'admin/offers': {
            name: 'Ofertas',
            fields: {
                total: 'Total',
                created_at: 'Creado en',
                company: 'Empresa',
                processed: 'Procesado',
                active: 'Activo',
                expired: 'Expirado',
                cart_status: 'Estado del carro',
                client_offer_id: 'ID de oferta del cliente',
                cart_id: 'Carrito ID'
            },
            buttons: {
                export: 'PDF'
            }
        },
        'admin/lod-wintyps': {
            name: 'Wintyps',
            titles: {
                create: 'Add new Wintyp',
                edit: 'Edit Wintyp: %{name}'
            },
            fields: {
                blocked_at: 'Blocked',
                used_at: 'Used',
                product: 'Product',
                order_id: 'Order #'
            }
        },
        'admin/shipping-dates': {
            name: 'Shipping Dates',
            titles: {
                shipping_countries: 'Shipping Countries',
                shipping_date: 'Shipping Date',
                create: 'Add new Shipping Date',
                edit: 'Edit Record: %{name}',
            },
            sections: {
                recurrence: 'Recurrence'
            },
            fields: {
                shipping_country_ids: 'Shipping Countries (iso2)'
            }
        },
        'admin/shipping-countries': {
            name: 'Shipping Countries',
            titles: {
                create: 'Add new Shipping Country',
                edit: 'Edit Shipping Country: %{name}'
            },
            fields: {
                code: 'Country Code (iso 2)'
            }
        },
        'admin/shipping': {
            name: 'Shipping'
        },
        'admin/cob': {
            name: 'COB'
        },
        'admin/cob-cobots': {
            name: 'Cobots',
            fields: {
                manufacturer: 'Manufacturer',
                type: 'Type',
                model: 'Model',
                axis6_tape: 'Axis 6 Tape',
                system_holder_1: 'System Holder #1',
                system_holder_2: 'System Holder #2',
                hose_length: 'Hose Length',
                tension_clamp: 'Tension Clamp',

                //ua
                ua_shs_center: 'Center',
                ua_shs_outside: 'Outside',
                ua_shs_inside: 'Inside',
                ua_tape_center: 'Center',
                ua_tape_outside: 'Outside',
                ua_tape_inside: 'Inside',
                ua_feeding_system: 'Feeding System',

                //lq
                la_shs_center: 'Center',
                la_shs_outside: 'Outside',
                la_shs_inside: 'Inside',
                la_tape_center: 'Center',
                la_tape_outside: 'Outside',
                la_tape_inside: 'Inside',
                la_feeding_system: 'Feeding System'

            },
            titles: {
                create: 'Add New Cobot',
                edit: 'Edit Cobot %{name}'
            },
            fieldGroups: {
                manufacturer_details: 'Manufacturer Details',
                technical_master_data: 'Technical Master Data',
                technical_details_upper_support_arm: 'Technical Details - Upper Arm',
                technical_details_bottom_support_arm: 'Technical Details - Lower Arm',
                shs: 'SHS',
                tape: 'Tape'
            }
        },
        'admin/cob-manufacturers': {
            name: 'Manufacturers',
            titles: {
                create: 'Add New Manufacturer',
                edit: 'Edit Manufacturer: %{name}'
            }
        },
        'admin/cob-types': {
            name: 'Types',
            titles: {
                create: 'Add New Type',
                edit: 'Edit Type: %{name}'
            },
            fields: {
                manufacturer: 'Manufacturer'
            }
        },
        'admin/cob-models': {
            name: 'Models',
            titles: {
                create: 'Add New Model',
                edit: 'Edit Model: %{name}'
            },
            fields: {
                manufacturer: 'Manufacturer',
                type: 'Type'
            }
        },
        'admin/cob-products': {
            name: 'Products',
            titles: {
                create: 'Add New Product',
                edit: 'Edit Product: %{name}'
            },
            fieldGroups: {
                product_details: 'Details',
                product: 'Product'
            },
            fields: {
                name: 'Name',
                nominal_size: 'Nominal size',
                profile: 'Profile',
                configuratorStatus: 'Configurator status'
            },
            group: {
                hose: 'Hose',
                hose_hf: 'Hose Highly Flexible',
                tension_clamp: 'Tension Clamp',
                belt: 'Belt',
                system_holder: 'System Holder',
                end_grommet: 'End Grommet',
                hose_ring_zl: 'Hose Ring Zl',
                hose_ring_fixed: 'Hose Ring Fixed',
                shs: 'SHS',
                fhs_c: 'System Holder FHS-C',
                fhs_uhe: 'System Holder FHS-UHE',
                fhs_sh: 'System Holder FHS-SH',
                options: 'Option',
                velcro: 'Velcro',
                velcro_silicone_free: 'Velcro silicone free',
                stainless_steel_band: 'Stainless Steel Band',
                return_system: 'Return System'
            },
            design: {
                slotted: 'Slotted',
                closed: 'Closed'
            },
            status: {
                active: 'Active',
                inactive: 'Inactive'
            }
        }
    }
};

export default customGermanMessages;
