import * as React from 'react';
import {FC} from 'react';
import {useTranslate, TextInput, FormWithRedirect, Toolbar, DeleteButton, SaveButton, CreateProps, Create, SelectInput} from 'react-admin';
import {Typography, Grid} from '@material-ui/core';

import {filterProps} from "../../../functions/common";
import {groups, GroupField} from "./Groups";
import ProductName from "./ProductName";

const ProductCreate: FC<CreateProps> = props => {
    const newProps = filterProps(props);
    const translate = useTranslate();

    return (
        <Create {...newProps}>
            <FormWithRedirect
                {...newProps}
                render={(formProps: any) => {
                    return (
                        <div style={{padding: '16px'}}>
                            <Typography gutterBottom variant="h5">
                                {translate(
                                    'resources.admin/cob-products.fieldGroups.product'
                                )}
                            </Typography>
                            <ProductName />

                            <Grid container style={{marginBottom: '20px'}} spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <SelectInput source="group" choices={groups} fullWidth={true} helperText={false}/>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextInput source="matnr" fullWidth={true} helperText={false}/>
                                </Grid>
                            </Grid>


                            <Grid container style={{marginBottom: '20px'}} spacing={2}>
                                <GroupField name='design'>
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <SelectInput source="design" choices={[
                                                {id: 'closed', name: translate('resources.admin/cob-products.design.closed')},
                                                {id: 'slotted', name: translate('resources.admin/cob-products.design.slotted')},
                                            ]} fullWidth={true} helperText={false}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}></Grid>
                                    </>
                                </GroupField>

                                <GroupField name='nominal_size'>
                                    <Grid item xs={12} sm={6}>
                                        <TextInput source="nominal_size" fullWidth={true} helperText={false}/>
                                    </Grid>
                                </GroupField>

                                <GroupField name="profile">
                                    <Grid item xs={12} sm={6}>
                                        <TextInput source="profile" fullWidth={true} helperText={false}/>
                                    </Grid>
                                </GroupField>

                                <GroupField name='fhsc_coefficient_min'>
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextInput source="fhsc_coefficient_min" fullWidth={true} helperText={false}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextInput source="fhsc_coefficient_max" fullWidth={true} helperText={false}/>
                                        </Grid>
                                    </>
                                </GroupField>

                                <GroupField name='clamping_diameter_from'>
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextInput source="clamping_diameter_from" fullWidth={true} helperText={false}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextInput source="clamping_diameter_to" fullWidth={true} helperText={false}/>
                                        </Grid>
                                    </>
                                </GroupField>

                                <GroupField name='inner_diameter'>
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextInput source="inner_diameter" fullWidth={true} helperText={false}/>
                                        </Grid>
                                        <Grid item xs={12} sm={6}></Grid>
                                    </>
                                </GroupField>

                                <Grid item xs={12} sm={6}>
                                    <SelectInput source="status" defaultValue={'active'} choices={[
                                        {id: 'active', name: translate('resources.admin/cob-products.status.active')},
                                        {id: 'inactive', name: translate('resources.admin/cob-products.status.inactive')},
                                    ]} fullWidth={true} helperText={false}/>
                                </Grid>

                            </Grid>


                            <Toolbar>
                                <SaveButton
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                />
                                <DeleteButton/>
                            </Toolbar>
                        </div>
                    )
                }}
                />
        </Create>
);
}

export default ProductCreate;
