import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, TextInput, SelectInput} from 'react-admin';

import {filterProps} from "../../../functions/common";
import Title from "../../../components/list/Title";
import grommetTypes from "./grommetTypes";
import grommetMaterialOptions from "./grommetMaterialOptions";

const GrommetCreate: FC<CreateProps> = props => {
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm>
                <Title label="resources.admin/kdh-grommets.titles.create"/>
                <TextInput autoFocus source="matnr" fullWidth={true}/>
                 <SelectInput source="type" choices={grommetTypes.map(el => ({id: el, name: el}))}/>
                <SelectInput source="material" choices={grommetMaterialOptions.map(el => ({id: el, name: el}))}/>
            </SimpleForm>
        </Create>
    );
};

export default GrommetCreate;
