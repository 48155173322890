import ListAltIcon from '@material-ui/icons/ListAlt';

import ManufacturerList from './ManufacturerList';
import ManufacturerCreate from "./ManufacturerCreate";

const resource = {
    list: ManufacturerList,
    create: ManufacturerCreate,
    icon: ListAltIcon,
    options: {
        label: 'resources.admin/cob-manufacturers.name'
    }
};

export default resource;
