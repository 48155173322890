import React from 'react';
import {Filter, TextInput, FilterProps} from 'react-admin';

const ManufacturerFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn/>
    </Filter>
);

export default ManufacturerFilter;
