import * as React from 'react';
import {FC} from 'react';
import {Edit, EditProps, TextInput, SimpleForm, ImageInput, ImageField} from 'react-admin';

import Title from '../../../components/list/Title';
import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const ImageEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit {...newProps} undoable={false} >
            <SimpleForm className={classes.sidebar}>
                <Title source="name" label="resources.admin/lod-images.titles.edit" />
                <TextInput source="name" />
                <ImageInput source="file" multiple={false}>
                    <ImageField source="url" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
};

export default ImageEdit;
