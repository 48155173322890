import * as React from 'react';
import {FC} from 'react';
import {Create, CreateProps, SimpleForm, TextInput, SelectInput, BooleanInput} from 'react-admin';

import {listEditCreate} from "../../../styles/listEditCreate";
import {filterProps} from "../../../functions/common";

const QuellwerkCreate: FC<CreateProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Create {...newProps}>
            <SimpleForm className={classes.sidebar}>
                <TextInput source="werk" fullWidth={true} />
                <SelectInput source={'country'} choices={[
                    {name:'de', id:'de'},
                    {name:'fr', id:'fr'},
                    {name:'ru', id:'ru'},
                    {name:'es', id:'es'}
                ]}/>
                <BooleanInput source={'zielwerk'} />
                <BooleanInput source={'material'} />
                <BooleanInput source={'einkaufspreis'} />
                <BooleanInput source={'ek_preiseinheit'} />
                <BooleanInput source={'verkaufspreis'} />
                <BooleanInput source={'vk_preiseinheit'} />
                <BooleanInput source={'wahrung'} />
                <BooleanInput source={'preisliste'} />
            </SimpleForm>
        </Create>
    );
};

export default QuellwerkCreate;
