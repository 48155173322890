import React from 'react';

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {Colors} from "../../styles/theme/colors";

const FieldLabel = ({ fieldLabel, fieldName, isDark, className = null, mandatory = false, fontWeight = 'bold' }: any) => {

    return (
        <Box className={className}>
            <Typography
                className={`flex ${fontWeight ?? 'bold'} marginBottomSmall marginRight ${isDark ? 'whiteColor' : ''}`}
                style={{
                    display: "flex",
                    fontWeight: fontWeight??700,
                    marginBottom: 5,
                    marginRight: 15,
                    color: isDark ? Colors.white.main : Colors.dark.main,
                }}
                component="label"
                htmlFor={fieldName}
                dangerouslySetInnerHTML={{
                    __html: `${fieldLabel} ${mandatory ? `<span style="${{marginLeft: 5, color: Colors.primary.main}}">*</span>` : '' }`
                }}
            />
        </Box>
    )
};

export default FieldLabel;