import React, {useEffect, useState} from "react";
import {Box, Card, Divider, Typography, Button} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import {useRedirect, useTranslate} from "react-admin";
import ConfirmPass from "./ConfirmPass";
import Steps from "../ui/Steps";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FieldInput from "../ui/FieldInput";
import LoadMask from "../ui/LoadMask";
import Alert from "../ui/Alert";
import Icon2FA from "../../assets/icons/2fa/icon_2fa.png";
import IconCheckWhite from "../../assets/icons/check-white.png";
import IconInfo from "../../assets/icons/info.svg";
import Hidden from "@material-ui/core/Hidden";
import {Colors} from "../../styles/theme/colors";
import {breakPoints, hexToRgba} from "../../functions/common";
import axiosForApi from "../../config/axios-for-api";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AuthCode from "./AuthCode";
//import {useLocation} from "react-router-dom";


const TwoFAActivation = ({twoFactorAuthEnabled}: {twoFactorAuthEnabled: boolean}) => {
    const translate = useTranslate();
    const redirect = useRedirect();
    //const location = useLocation();
    const tabletTup = useMediaQuery(`${[breakPoints.up("md")]}`);

    const [qrCode, setQrCode] = useState<any>(null);
    const [recoveryCodes, setRecoveryCodes] = useState<any>(null);
    const [setupKey, setSetupKey] = useState<any>('');
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [successMessage, setSuccessMessage] = useState<any>(null);
    const [twoFactorConfirmationCode, setTwoFactorConfirmationCode] = useState<any>('');
    const [confirming, setConfirming] = useState<boolean>(false);

    // const [maskFieldValues, setMaskFieldValues] = useState<any>({ 1: '', 2: '', 3: '', 4: '', 5: '', 6: '' });

    const steps = [
        translate('twoFA.recoveryCodesTitle'),
        translate('twoFA.qrCodeTitle'),
        translate('twoFA.enterTwoFACodeTitle'),
    ];

    // useEffect(() => {
    //     let newValue = '';
    //     Object.keys(maskFieldValues).forEach(el => newValue = newValue + maskFieldValues[el])
    //     setTwoFactorConfirmationCode(newValue);
    //
    // }, [maskFieldValues]);

    useEffect(() => {
        if (twoFactorAuthEnabled) {
            redirect('/admin/configuration');
            return;
        }
        enable();
    }, [redirect, twoFactorAuthEnabled]);

    const enable = () => {
        axiosForApi.post('user/two-factor-authentication').then(() => {
            axiosForApi.get('user/two-factor-qr-code').then(({data}) => {
                setQrCode(data.svg);
            });

            axiosForApi.get('user/two-factor-recovery-codes').then(({data}) => {
                setRecoveryCodes(data??[]);
            });

            axiosForApi.get('/user/two-factor-secret-key').then(({data}) => {
                setSetupKey(data.secretKey);
            });

            setConfirming(false);
        }).catch((err) => {
            const status = err.response?.status;
            if (status === 423) {
                setConfirming(true);
            }
        });
    };

    // const handleFocusNextInput = (index: any) => {
    //     const nextInput = document.getElementById('maskInput_' + index);
    //     if (nextInput) nextInput.focus();
    // }

    let downloadStringAsFile = () => {

        let content = translate('twoFA.recoveryCodesTitle') + '\n\n';
        content = content + recoveryCodes??[].join('\n');

        // Create new blob with mime type
        let blob = new Blob([content], {type: "text/plain;charset=utf-8"});
        let now = new Date();
        let dateStr = `${now.getFullYear()}_${(now.getMonth()+1).toString().padStart(2, '0')}_${now.getDate().toString().padStart(2, '0')}`;

        let filename = 'murrplastik_admin_recovery_codes_' + dateStr;

        // Create an anchor element
        let element = document.createElement('a');
        element.download = filename;
        element.href = URL.createObjectURL(blob);
        element.dataset.downloadurl = ['text/plain', element.download, element.href].join(':');

        // Simulate click on the element
        element.click();
    };


    const confirmTwoFactor = () => {
        if (!twoFactorConfirmationCode) return;

        axiosForApi.post('/user/confirmed-two-factor-authentication', {code: twoFactorConfirmationCode}).then(({data}) => {
            setSuccessMessage(translate('twoFA.twoFAButtonActivationSuccess'));
            //cr
            //setTimeout(() => window.location.reload(), 3000);
            setTimeout(() => redirect('/admin/configuration?confirmed=1'), 3000);

        }).catch(err => {

            const status = err.response?.status;
            if (status === 423) {
                setConfirming(true);
            } else {
                setErrorMessage(translate('twoFA.enterTwoFACodeError'))
            }
        });
    }

    return (
        <>
            <Card>
                <CardContent style={{ display: 'flex', flexDirection: 'column'}}>
                    <Box style={{display: 'flex', alignItems: 'center', marginBottom: 15}}>
                        <Box style={{marginRight: 15, flexShrink: 0}}>
                            <img
                                alt="2fa"
                                width={tabletTup ? 64 : 48}
                                height={tabletTup ? 64 : 48}
                                src={Icon2FA}
                                style={{objectFit: 'contain'}}
                            />
                        </Box>
                        <Typography variant="h5" style={{fontWeight:700}}>
                            {translate("twoFA.infoTitle")}
                        </Typography>
                    </Box>
                    <Divider style={{marginBottom: 15}} />

                    <Typography
                        dangerouslySetInnerHTML={{ __html: `${translate('twoFA.infoText')}` }}
                        style={{marginBottom: 30}}
                    />

                    <Steps
                        steps={steps}
                        currentStep={currentStep}
                        onClickStep={(e:any) => {
                            if(!successMessage) {
                                setCurrentStep(Number(e.currentTarget.dataset.value));
                            }
                        }}
                        showOnlyCurrentStepLabel={!tabletTup}
                    />

                    <Box style={{width:'100%', marginTop: 30, marginBottom: 15}}>

                        {currentStep === 1 && (
                            <>
                                <Hidden smDown>
                                    <Typography style={{width: '100%', fontWeight: 700, marginBottom: 5}}>
                                        {translate('twoFA.recoveryCodesTitle')}
                                    </Typography>
                                </Hidden>

                                <Typography style={{width: '100%', fontWeight: 400, marginBottom: 15}}>
                                    {translate('twoFA.recoveryCodesInfo')}
                                </Typography>

                                <Card
                                    elevation={0}
                                    style={{minHeight: 210, position: 'relative', marginBottom: 15}}
                                >
                                    <CardContent style={{ display: 'flex', alignItems: 'center',borderRadius:10, backgroundColor: Colors.background.default, padding: 15}}>
                                        {!recoveryCodes ? (
                                            <LoadMask />
                                        ) : (
                                            <Box>
                                                {recoveryCodes.length > 0 ? (
                                                    <>
                                                        {recoveryCodes.map((recoveryCode: any, i: number) => (
                                                            <Typography key={i}>{recoveryCode}</Typography>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <Alert severity="error">
                                                        {translate('twoFA.errorFetchingRecoveryCodes')}
                                                    </Alert>
                                                )}
                                            </Box>
                                        )}
                                    </CardContent>
                                </Card>

                                <Box style={{minHeight: 30}}>
                                    {recoveryCodes && recoveryCodes.length > 0 ? (
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            color="default"
                                            onClick={downloadStringAsFile}
                                            style={{fontSize: '0.875rem', borderRadius: 5, borderWidth: 2, textTransform: 'none', fontWeight: 700}}
                                        >
                                            <Box component="span">
                                                {translate('twoFA.recoveryCodesDownload')}
                                            </Box>
                                        </Button>
                                    ) : null}
                                </Box>

                                <Box style={{display: 'flex', justifyContent: 'right', width: '100%', marginTop: 30}}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        onClick={() => !confirming ? setCurrentStep(2) : null}
                                        style={{ textTransform: 'initial', borderRadius: 5, minWidth: 225, height: 45, fontSize: 16, fontWeight: 700 }}
                                        disabled={!recoveryCodes || ((recoveryCodes && recoveryCodes.length === 0) || confirming)}
                                    >
                                        <Box component="span">
                                            {translate('global.further')}
                                        </Box>
                                    </Button>
                                </Box>
                            </>
                        )}

                        {currentStep === 2 && (
                            <>
                                <Hidden smDown>
                                    <Typography style={{width: '100%', fontWeight: 700, marginBottom: 5}}>
                                        {translate('twoFA.qrCodeTitle')}
                                    </Typography>
                                </Hidden>

                                <Typography style={{width: '100%', fontWeight: 400, marginBottom: 15}}>
                                    {translate('twoFA.qrCodeText')}
                                </Typography>

                                <Card elevation={0} style={{border: 'none'}}>
                                    <CardContent
                                        style={{display: 'flex', alignItems: 'center', backgroundColor: Colors.white.main,borderRadius:10, border: `1px solid ${hexToRgba(Colors.dark.main, 0.25)}`, position: 'relative', justifyContent: 'center', minHeight: 225}}
                                    >
                                        {!qrCode ? (
                                            <LoadMask />
                                        ) : (
                                            <Box style={{display: 'inline-flex', alignItems: 'center'}}>
                                            <span
                                                style={{display: 'flex', alignItems: 'center'}}
                                                dangerouslySetInnerHTML={{__html: `${qrCode}`}}
                                            />
                                                <Box style={{flexGrow: 1, paddingLeft: 45}}>
                                                    <FieldInput
                                                        type="text"
                                                        label={translate('twoFA.qrCodeAlternativeKey')}
                                                        value={setupKey}
                                                        onChange={() => null}
                                                        readOnly
                                                        customStyle={{
                                                            width: 320,
                                                            height: 45,
                                                            background: Colors.background.default,
                                                            borderRadius: 5,
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                        )}
                                    </CardContent>
                                </Card>

                                <Box style={{display: 'flex', justifyContent: 'right', width: '100%', marginTop: 30}}>
                                    <Typography
                                        variant="body2"
                                        style={{opacity: 0.5, fontStyle: 'italic', display: 'flex', flexGrow: 1, paddingRight: 15, marginRight: 15}}
                                    >
                                        <Box style={{ flexShrink: 0, marginRight: 5}}>
                                            <img
                                                alt="info"
                                                width={20}
                                                height={20}
                                                src={IconInfo}
                                            />
                                        </Box>
                                        {translate("twoFA.qrCodeInfo")}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        size="medium"
                                        color="primary"
                                        onClick={() => setCurrentStep(3)}
                                        style={{ textTransform: 'initial', borderRadius: 5, minWidth: 225, height: 45, fontSize: 16, fontWeight: 700 }}
                                        disabled={!qrCode && !setupKey}
                                    >
                                        <Box component="span">
                                            {translate('global.further')}
                                        </Box>
                                    </Button>
                                </Box>
                            </>
                        )}

                        {currentStep === 3 && (
                            <>
                                <Hidden smDown>
                                    <Typography style={{width: '100%', fontWeight: 700, marginBottom: 5}}>
                                        {translate('twoFA.enterTwoFACodeTitle')}
                                    </Typography>
                                </Hidden>

                                <Typography style={{width: '100%', fontWeight: 400, marginBottom: 15}}>
                                    {translate('twoFA.enterTwoFACodeText')}
                                </Typography>

                                <Card elevation={0} style={{marginBottom: 15, border: 'none'}}>
                                    <CardContent
                                        style={{display: 'flex', alignItems: 'center', backgroundColor: Colors.white.main, borderRadius:10, border: `1px solid ${hexToRgba(Colors.dark.main, 0.25)}`, position: 'relative', justifyContent: 'center', minHeight: 225}}
                                    >

                                        <AuthCode
                                            length={6}
                                            onChange={(e) => setTwoFactorConfirmationCode(e)}
                                            allowedCharacters="numeric"
                                        />


                                        {/*<Grid container spacing={1} justify="center">*/}
                                        {/*    {*/}
                                        {/*        [...Array(6)].map((_, i) => (*/}
                                        {/*            <Grid item key={'gr_' + i}>*/}
                                        {/*                <Box style={{width: 40}} key={"box_" + i}>*/}
                                        {/*                    <FieldInput*/}
                                        {/*                        key={i}*/}
                                        {/*                        type="text"*/}
                                        {/*                        min={0}*/}
                                        {/*                        max={9}*/}
                                        {/*                        showNumericPhone*/}
                                        {/*                        maxLength={1}*/}
                                        {/*                        id={`maskInput_${i+1}`}*/}
                                        {/*                        value={maskFieldValues[i+1]}*/}
                                        {/*                        customStyle={{*/}
                                        {/*                            width: 40,*/}
                                        {/*                            height: 40,*/}
                                        {/*                            background: Colors.background.default,*/}
                                        {/*                            borderRadius: 5,*/}
                                        {/*                            textAlign: 'center',*/}
                                        {/*                        }}*/}
                                        {/*                        className="centered"*/}
                                        {/*                        onChange={(e: any) => {*/}
                                        {/*                            const value = e.target.value;*/}

                                        {/*                            setMaskFieldValues((prevMaskFieldValues: any) => ({*/}
                                        {/*                                ...prevMaskFieldValues,*/}
                                        {/*                                [i+1]: value // Update the specific field with new value*/}
                                        {/*                            }));*/}

                                        {/*                            if (value !== '') handleFocusNextInput(i+2);*/}

                                        {/*                        }}*/}
                                        {/*                    />*/}
                                        {/*                </Box>*/}
                                        {/*            </Grid>*/}
                                        {/*        ))*/}
                                        {/*    }*/}
                                        {/*</Grid>*/}
                                    </CardContent>
                                </Card>

                                <Box style={{display: 'flex', justifyContent: 'right', width: '100%', marginTop: 30}}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        onClick={() => confirmTwoFactor()}
                                        style={{ textTransform: 'initial', borderRadius: 5, minWidth: 225, height: 45, fontSize: 16, fontWeight: 700 }}
                                        disabled={!twoFactorConfirmationCode || twoFactorConfirmationCode.length < 6 || successMessage}
                                    >
                                        <Box component="span">
                                            {translate('twoFA.qrCodeConfirmCode')}
                                        </Box>
                                    </Button>
                                </Box>

                                {successMessage ? (
                                    <Card
                                        elevation={0}
                                        style={{ display: 'flex', alignItems: 'center', backgroundColor: hexToRgba(Colors.success.main, 0.9), marginTop: 30}}
                                    >
                                        <CardContent style={{padding: 15, borderRadius:10}}>
                                            <img
                                                width={17}
                                                height={13}
                                                alt="check"
                                                src={IconCheckWhite}
                                                style={{objectFit: 'contain', flexShrink: 0, marginRight: 5}}
                                            />
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                style={{marginLeft: 15, color: Colors.white.main, fontWeight: 500 }}
                                            >
                                                {successMessage}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                ) : null}

                                {errorMessage ? (
                                    <Alert
                                        severity="error"
                                        style={{marginTop: 30}}
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => setErrorMessage(null)}
                                            >
                                                <CloseIcon fontSize="inherit"/>
                                            </IconButton>
                                        }
                                    >
                                        {errorMessage}
                                    </Alert>
                                ) : null}
                            </>
                        )}

                    </Box>

                </CardContent>
            </Card>

            {confirming ? (
                <ConfirmPass
                    confirming={true}
                    setConfirming={setConfirming}
                    onSuccess={enable}
                    onFail={() => setErrorMessage(translate('passwordSecurity.fail'))}
                    restrict={true}
                />
            ) : null}
        </>
    )
}

export default TwoFAActivation;
