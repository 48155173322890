import PermMediaIcon from '@material-ui/icons/PermMedia';
import HoleImageShapeList from './HoleImageShapeList';

const resource = {
    list: HoleImageShapeList,
    icon: PermMediaIcon,
    options: {
        label: 'resources.admin/lod-hole-image-shape.name'
    }
};

export default resource;
