import React, {
    HtmlHTMLAttributes,
    ComponentType,
    ReactNode,
    useRef,
    useEffect,
    useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {Box, Card, Link} from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { StaticContext } from 'react-router';
import {useHistory, useLocation} from 'react-router-dom';
import {useCheckAuth, TitleComponent, useTranslate} from 'ra-core';

import LoginForm from "./LoginForm";
import {Notification} from "react-admin";
import {login} from "../styles/ui/login";

import Logo from '../assets/img/logo.png';
import Signet from '../assets/img/signet.svg';
import CardContent from "@material-ui/core/CardContent";
import Hidden from "@material-ui/core/Hidden";
import ResetPassword from "./ResetPassword";

export interface LoginProps
    extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
    backgroundImage?: string;
    children?: ReactNode;
    classes?: object;
    className?: string;
    notification?: ComponentType;
    staticContext?: StaticContext;
    theme?: object;
    title?: TitleComponent;
}

const LoginPage: React.FunctionComponent<LoginProps> = props => {
    const {
        theme,
        title,
        classes: classesOverride,
        className,
        children,
        staticContext,
        backgroundImage,
        ...rest
    } = props;
    const containerRef = useRef<HTMLDivElement>();
    const classes = login();
    const muiTheme = useMemo(() => createMuiTheme(theme), [theme]);
    let backgroundImageLoaded = false;
    const checkAuth = useCheckAuth();
    const translate = useTranslate();
    const history = useHistory();
    useEffect(() => {
        checkAuth({}, false)
            .then(() => {
                // already authenticated, redirect to the home page
                history.push('/');
            })
            .catch(() => {
                // not authenticated, stay on the login page
            });
    }, [checkAuth, history]);

    const updateBackgroundImage = () => {
        if (!backgroundImageLoaded && containerRef.current) {
            containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
            backgroundImageLoaded = true;
        }
    };

    // Load background image asynchronously to speed up time to interactive
    const lazyLoadBackgroundImage = () => {
        if (backgroundImage) {
            const img = new Image();
            img.onload = updateBackgroundImage;
            img.src = backgroundImage;
        }
    };

    useEffect(() => {
        if (!backgroundImageLoaded) {
            lazyLoadBackgroundImage();
        }
    });

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const _children = searchParams.has('reset-password')?<ResetPassword />:props.children;

    return (
        <ThemeProvider theme={muiTheme}>
            <div
                className={classes.main}
                {...rest}
                // @ts-ignore
                ref={containerRef}
            >
                <Box className={classes.toolbar}>
                    <Link className={classes.logo} href="https://www.murrplastik.de/de">
                        <Hidden smDown>
                            <img alt="murrplastik" width={156} height={35} src={Logo}/>
                        </Hidden>
                        <Hidden mdUp>
                            <img alt="murrplastik" width={30} height={30} src={Signet}/>
                        </Hidden>
                    </Link>

                    <Link className={classes.backToShop} href={`${process.env.REACT_APP_SHOP_URL}`}>
                        <div>
                            <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" fill="white">
                                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                            </svg>
                        </div>
                        {translate('global.backToShop')}
                    </Link>
                </Box>

                <Card className={classes.card} elevation={0}>
                    <CardContent>
                        {_children}
                    </CardContent>
                </Card>
                <Notification />
            </div>
        </ThemeProvider>
    );
};

LoginPage.propTypes = {
    backgroundImage: PropTypes.string,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};

LoginPage.defaultProps = {
    children: <LoginForm />
};

export default LoginPage;
