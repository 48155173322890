import {Card, Box, Grid, TableContainer, Table, TableCell, TableRow, TableBody, TableHead } from '@material-ui/core';
import {
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from 'recharts';
import {useEffect, useState} from "react";
import {useTranslate} from "react-admin";

import {dashboard} from '../../styles/dashboard';
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosForApi from "../../config/axios-for-api";
import StatHeader from "./StatHeader";

interface StatDataRow {
    category: string;
    revenue_this_month: number;
    revenue_this_month_fmt: string;
}

const Loading = ({classes}: any) => {
    return (
        <Card className={classes.card}>
            <Box p={2} display={'flex'} justifyContent={'center'} alignItems={'center'} height={200}>
                <CircularProgress/>
            </Box>
        </Card>
    );
}

const CustomTooltip = (props: any) => {

    const {payload, separator, itemStyle, label, labelStyle} = props;

    const listStyle = {padding: 0, margin: 0};

    const items = payload.map((entry: any, i: number) => {
        if (entry.type === 'none') {
            return null;
        }

        const finalItemStyle = {
            display: 'block',
            paddingTop: 4,
            paddingBottom: 4,
            color: entry.color || '#000',
            ...itemStyle,
        };

        let name = '';
        let value = '';

        if (entry.dataKey === 'revenue_this_month') {
            name = 'Umsatz Akt. Monat';
            value = entry.payload.revenue_this_month_fmt;
        }

        return (
            // eslint-disable-next-line react/no-array-index-key
            <li className="recharts-tooltip-item" key={`tooltip-item-${i}`} style={finalItemStyle}>
                <span className="recharts-tooltip-item-name">{name}</span>
                <span className="recharts-tooltip-item-separator">{separator}</span>
                <span className="recharts-tooltip-item-value">{value}</span>
                <span className="recharts-tooltip-item-unit">{entry.unit || ''}</span>
            </li>
        );
    });

    return (
        <div className='recharts-default-tooltip' style={{
            margin: 0,
            padding: 10,
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            whiteSpace: 'nowrap'
        }}>
            <p className={'recharts-tooltip-label'} style={{margin: 0, ...labelStyle}}>
                {label}
            </p>
            <ul className="recharts-tooltip-item-list" style={listStyle}>
                {items}
            </ul>
        </div>

    );
}

const RevenueByMainCategoryThisMonth = ({countries}: { countries: string[] | null }) => {
    const classes = dashboard();
    const translate = useTranslate();

    const [data, setData] = useState<StatDataRow[]>([]);
    const [shouldDrawChart, setShouldDrawChart] = useState(false);
    const [shouldLoadStatsData, setShouldLoadStatsData] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let cancel = false;
        const loadStatsData = async () => {
            const {data} = await axiosForApi.get('api/admin/stats/total-revenue-by-main-category-this-month', {
                params: {
                    countries
                }
            });

            if(!cancel) {
                setData(data);
                setLoading(false);
                setShouldLoadStatsData(false);
                setShouldDrawChart(true);
            }
        }

        shouldLoadStatsData && loadStatsData();

        return () => {
            cancel = true;
        }
    }, [shouldLoadStatsData, countries]);

     useEffect(() => {
        setLoading(true);
        setShouldLoadStatsData(true);
    }, [countries]);

    if (loading) return (<Loading classes={classes}/>);

    return (
        <Card className={classes.card}>
            <Box p={2}>
                <StatHeader
                    justifyContent={'center'}
                    // title = 'Umsatz Akt. Monat nach Produktbereich'
                    title={translate('resources.admin/dashboard.headers.total-revenue-by-main-category-this-month')}
                    help='resources.admin/dashboard.help.total-revenue-by-main-category-this-month'
                />
                <Grid container spacing={0}>
                    {data.length === 0 && (
                        <Grid item xs={12}>
                            <div style={{height: '300px', width:'100%', display:'flex', alignItems:'center', justifyContent:'center' }}>
                                <span style={{color:'#c00'}}>No data available</span>
                            </div>
                        </Grid>
                    )}
                    {data.length>0 && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <div className='scrollable' style={{height: '300px', overflowY: 'scroll', overflowX: 'hidden'}}>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>Umsatz Akt. Monat</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map((data, i) => (
                                                <TableRow key={i}>
                                                    <TableCell padding={'none'}>
                                                        {data.category}
                                                    </TableCell>
                                                    <TableCell>
                                                        {data.revenue_this_month_fmt}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div style={{width: '100%', 'height': '300px'}}>
                                {shouldDrawChart && (
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart
                                            width={500}
                                            height={400}
                                            data={data}
                                            margin={{
                                                top: 0,
                                                right: 0,
                                                bottom: 0,
                                                left: 0,
                                            }}
                                        >
                                            <CartesianGrid stroke="#f5f5f5"/>
                                            <XAxis dataKey="category"/>
                                            <YAxis width={80} tickFormatter={(v:number) => v.toLocaleString('de-DE')}/>
                                            <Tooltip content={<CustomTooltip/>}/>
                                            {/*<Legend/>*/}
                                            <Bar dataKey="revenue_this_month" barSize={20} fill="#333"/>
                                        </ComposedChart>
                                    </ResponsiveContainer>)}
                            </div>
                        </Grid>
                    </>)}

                </Grid>
            </Box>
        </Card>
    );
}

export default RevenueByMainCategoryThisMonth;
