import Icon from '@material-ui/icons/MoneyOff';
import VoucherList from './VoucherList';

const resource = {
    list: VoucherList,
    icon: Icon,
    options: {
        label: 'resources.admin/vouchers.name'
    }
};

export default resource;
