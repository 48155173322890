const locale:{[key:string]:any} = {
    languageOptions:{
        'de': {
            'label': 'pos.languageOptions.german'
        },
        'en': {
            'label':'pos.languageOptions.english'
        },
        'fr': {
            'label':'pos.languageOptions.french'
        },
        'it': {
            'label':'pos.languageOptions.italian'
        },
        'es': {
            'label':'pos.languageOptions.spanish'
        },
        'ru': {
            'label':'pos.languageOptions.russian'
        },
        'se': {
            'label':'pos.languageOptions.swedish'
        },
    },
    defaultLocale: 'de'
}

export default locale;
