import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, useTranslate} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import {Route} from 'react-router';

import ShippingDateFilter from "./ShippingDateFilter";
import ShippingDateEdit from "./ShippingDateEdit";
import SideDrawer from "../../components/list/SideDrawer";
import TextField from "../../components/list/TextField";
import ListActions from "./ListActions";
import {Typography} from "@material-ui/core";

const ShippingDateList = (props: ListProps): ReactElement => {
    const translate = useTranslate();
    return (
        <Fragment>
            <Typography variant={'h6'}>{translate('resources.admin/shipping-dates.name')}</Typography>
            <List {...props} filters={<ShippingDateFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>} >
                <Datagrid>
                    <TextField source="id"/>
                    <TextField source="kunnr" />
                    <TextField source="shipping_date_formatted" label={"resources.admin/shipping-dates.titles.shipping_date"} sortBy={'shipping_date'}/>
                    <TextField source="shipping_countries_formatted" label={"resources.admin/shipping-dates.titles.shipping_countries"} sortable={false}/>
                    <TextField source={'status'}/>
                    <EditButton/>
                </Datagrid>
            </List>
            {/*<Route path={`${props.basePath}/create`}>*/}
            {/*    {({match}) => {*/}
            {/*        return (*/}
            {/*            <SideDrawer open={!!match} basePath={props.basePath ?? ''}>*/}
            {/*                <ShippingDateCreate {...props} />*/}
            {/*            </SideDrawer>*/}
            {/*        )*/}
            {/*    }}*/}
            {/*</Route>*/}
            <Route path={`${props.basePath}/:id`}>
                {({match}) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath ?? ''}>
                            {isMatch && (
                                <ShippingDateEdit id={match && isMatch ? match.params.id : undefined}{...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default ShippingDateList;
