import * as React from 'react';
import {useDataProvider, useTranslate} from 'react-admin';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import {Offer} from '../../config/types';
import CardWithIcon from "./CardWithIcon";
import {useCallback, useEffect, useState} from "react";
import {subDays} from "date-fns";
import {CircularProgress, Box} from "@material-ui/core";
import ResultsTable from "./ResultsTable";

const LatestOffers = ({countries}: { countries: string[] | null }) => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const [shouldFetchOffers, setShouldFetchOffers] = useState<boolean>(false);
    const [newOffers, setNewOffers] = useState<Offer[]>([]);
    const [totalOffers, setTotalOffers] = useState<number | string>('');

    const fetchLatestOffers = useCallback(async () => {
        const aMonthAgo = subDays(new Date(), 30);
        await dataProvider.getList<Offer>(
            'admin/offers',
            {
                filter: {date_gte: aMonthAgo.toISOString(), source: 'shop', countries},
                sort: {field: 'created_at', order: 'DESC'},
                pagination: {page: 1, perPage: 10},
            }
        ).then(({data, total}) => {
            setNewOffers(data);
            setTotalOffers(total)
        }).catch(err => Promise.reject(err.response));
        setShouldFetchOffers(false);
    }, [dataProvider, countries]);

    useEffect(() => {
        if (shouldFetchOffers) {
            fetchLatestOffers();
        }
    }, [shouldFetchOffers, fetchLatestOffers]);

    useEffect(() => {
        setShouldFetchOffers(true);
    }, [countries]);


    const tableColumns = ['id', 'formattedCreatedAt', 'status', 'formattedTotal', 'company'];

    return (
        <CardWithIcon
            to="admin/offers"
            icon={LocalOfferIcon}
            title={translate('resources.admin/dashboard.offers')}
            subtitle={totalOffers}
            showButton={newOffers.length > 0}
        >
            {newOffers.length ? (
                <ResultsTable columns={tableColumns} results={newOffers} to={'admin/offers'} linkArray={['id']}/>
            ) : (
                <Box display={'flex'} justifyContent={'center'} p={2}>
                    <CircularProgress/>
                </Box>
            )}
        </CardWithIcon>
    );
};

export default LatestOffers;
