import * as React from 'react';
import {Datagrid, List, ListProps, EditButton, ChipField, BulkDeleteWithConfirmButton, useRecordContext, useTranslate} from 'react-admin';
import {Fragment, ReactElement} from 'react';
import { Route } from 'react-router';

import ProductFilter from "./ProductFilter";
import ProductEdit from "./ProductEdit";
import SideDrawer from "../../../components/list/SideDrawer";
import TextField from "../../../components/list/TextField";
import ListActions from "../../../components/list/ListActions";

const BulkActionButtons = (props:any) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const TextFieldGroup = (props:{
    label?: string,
    record?: {[key:string]:any}
    source: string,
}) => {
    const { source } = props;
    const record = useRecordContext(props);
    const translate = useTranslate();

    return <span>{translate(`resources.admin/cob-products.group.${record[source]}`)}</span>;
}


const VariationName = (props:{
    label?: string,
    record?: {[key:string]:any}
}) => {
    const record = useRecordContext(props);
    return <span>{record?.variation?.name??''}</span>;
}

const ProductList = (props: ListProps): ReactElement => {

    return (
        <Fragment>
            <List {...props} filters={<ProductFilter/>} sort={{field: 'id', order: 'DESC'}} actions={<ListActions/>} bulkActionButtons={<BulkActionButtons/>}>
                <Datagrid>
                    <TextField source="id" />
                    <TextFieldGroup source="group" />
                    <TextField source="matnr" />
                    <VariationName label={'resources.admin/cob-products.fields.name'} />
                    <ChipField source="shop_status" />
                    <ChipField source="status" label={'resources.admin/cob-products.fields.configuratorStatus'}/>
                    <EditButton/>
                </Datagrid>
            </List>

            <Route path={`${props.basePath}/:id`}>
                {({ match }) => {
                    const isMatch = !!(match && match.params && match.params.id !== 'create');
                    return (
                        <SideDrawer open={isMatch} basePath={props.basePath??''}>
                            {isMatch && (
                                <ProductEdit id={match && isMatch ? match.params.id : undefined} {...props}/>
                            )}
                        </SideDrawer>
                    );
                }}
            </Route>
        </Fragment>
    );
};

export default ProductList;
