import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.headers = {
    'Accept': 'application/json',
    'Content-Type':'application/json'
}
const axiosForApi = axios.create({
    baseURL : `${process.env['REACT_APP_API_URL']}`
});

export default axiosForApi;
