import * as React from 'react';
import {FC} from 'react';
import {Show, ShowProps, SimpleShowLayout} from 'react-admin';
import CartDetails from "./CartDetails";

const AbandonedCartShow: FC<ShowProps> = props => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <div style={{marginTop: '2em'}}>
                    <CartDetails/>
                </div>
            </SimpleShowLayout>
        </Show>
    );
};

export default AbandonedCartShow;
