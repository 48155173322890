import * as React from 'react';
import {ReactElement, SyntheticEvent} from 'react';
import {Datagrid, List, ListProps, ReferenceField, useTranslate, useRecordContext, useNotify, useLocale, Button} from 'react-admin';

import download from "downloadjs";

import TextField from "../../components/list/TextField";
import OfferFilter from "./OfferFilter";
import axiosForApi from "../../config/axios-for-api";


const Export = (props: any) => {
    const translate = useTranslate();
    const record = useRecordContext(props);
    if (!record) throw new Error(translate('errors.invalid_record'));
    const handleExport = props.onExport;

    return (<Button onClick={(e) => handleExport(e, +record.id, record.pdfName)} label={translate('resources.admin/offers.buttons.export')}/>);
}

const OfferList = (props: ListProps): ReactElement => {

    const notify = useNotify();
    const translate = useTranslate();
    const locale = useLocale();

    const handleExport = (e: SyntheticEvent, id: number, pdfName:string) => {
        e.preventDefault();

        axiosForApi.get(`api/admin/offers/${id}/export/pdf/${locale}`, {
            responseType: "blob" // had to add this one here
        }).then(response => {
            const content = response.headers["content-type"];
            download(
                response.data,
                pdfName,
                content
            );
        }).catch(error => {
            notify(translate('resources.admin/offers.messages.export_failed'), 'error');
        });
    }

    return (
        <List {...props} filters={<OfferFilter/>} sort={{field: 'created_at', order: 'DESC'}}>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="clientOfferID" label={'resources.admin/offers.fields.client_offer_id'}/>
                <TextField source="cart_id" label={'resources.admin/offers.fields.cart_id'}/>
                <TextField source="formattedCreatedAt" label={'resources.admin/offers.fields.created_at'} sortBy={'created_at'}/>
                <TextField source="status" label={'resources.admin/offers.fields.cart_status'}/>
                <TextField source="formattedTotal" label={'resources.admin/offers.fields.total'} sortBy={'total'}/>
                <ReferenceField label='resources.admin/users.fields.name' reference='admin/users' source='customer_id'>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField label='resources.admin/users.fields.company' reference='admin/users' source='customer_id'>
                    <TextField source="company"/>
                </ReferenceField>
                <Export onExport={handleExport}/>
            </Datagrid>
        </List>
    );
};

export default OfferList;
