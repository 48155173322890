import React from 'react';
import {SelectInput, ReferenceInput, Filter, TextInput, FilterProps} from 'react-admin';

const HoleImageShapeFilter = (props: Omit<FilterProps, 'children'>) => (
    <Filter {...props}>
        <TextInput source="image_code" alwaysOn/>
        <TextInput source="variable_code" alwaysOn/>
        <ReferenceInput label="resources.admin/lod-hole-image-shape.fields.shape" source="shape_id" reference="admin/lod-shapes" alwaysOn options={{
            suggestionsContainerProps: {
                disablePortal: true,
            }
        }}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="resources.admin/lod-hole-image-shape.fields.hole" source="hole_id" reference="admin/lod-holes" alwaysOn options={{
            suggestionsContainerProps: {
                disablePortal: true,
            }
        }}>
            <SelectInput optionText="name_lt" />
        </ReferenceInput>
    </Filter>
);

export default HoleImageShapeFilter;
