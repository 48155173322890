import * as React from 'react';
import {FC} from 'react';
import {CheckboxGroupInput, Edit, EditProps, SaveButton, SimpleForm, Toolbar, useRecordContext, useTranslate} from 'react-admin';

import Title from '../../components/list/Title';
import {listEditCreate} from "../../styles/listEditCreate";
import {filterProps} from "../../functions/common";

const Checkboxes = (props: any) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    return <CheckboxGroupInput {...props} choices={(record.all_permissions ?? []).map((el: any) => ({
            id: el.id,
            name: translate(`resources.admin/permissions.permissions.${el.name}`)
        }
    ))}/>
}

export const MyToolbar = (props: any) => (
    <Toolbar>
        <SaveButton
            saving={props.saving}
            handleSubmitWithRedirect={props.handleSubmitWithRedirect}
        />
    </Toolbar>
);

const PermissionsEdit: FC<EditProps> = props => {
    const classes = listEditCreate();
    const newProps = filterProps(props);

    return (
        <Edit{...newProps} undoable={false}>
            <SimpleForm className={classes.sidebar} toolbar={<MyToolbar/>}>
                <Title source="role" label="resources.admin/permissions.titles.edit"/>
                <Checkboxes source="permission_ids" />
            </SimpleForm>
        </Edit>
    );
};

export default PermissionsEdit;
